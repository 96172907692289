import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ClipSpinner } from "../../components/common/RAYSpinner";
import { AppConstant } from "../../redux/reducers";

let hideEvent = null;
let limitTime = 1000 * 10; // 10s
const LoadingSpinner = ({ loadingStack, loadingConfig }) => {
    const [cusStyle, setCusStyle] = useState({});
    const [cusClass, setCusClass] = useState('');
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadingStack === 0) { return; }
        const _tempStyle = {};
        let _tempClass = '';
        if (loadingConfig.isFixed) {
            _tempStyle.position = 'fixed';
            _tempStyle.left = 0;
            _tempStyle.right = 0;
            _tempStyle.top = 0;
            _tempStyle.bottom = 0;
            _tempStyle.zIndex = 2000;
            _tempClass = 'd-flex flex-fill align-items-center justify-content-center';
        } else {
            _tempStyle.position = 'absolute';
            _tempStyle.width = '100%';
            _tempStyle.height = '100%';
            _tempClass = 'd-flex flex-fill align-items-center justify-content-center';
        }
        if (loadingConfig.isOVerLay) {
            _tempStyle.backgroundColor = 'rgba(0, 0, 0, 0.3)'
        }
        setCusStyle(_tempStyle);
        setCusClass(_tempClass);
    }, [loadingStack, loadingConfig]);

    useEffect(() => {
        if (visible) {
            loadingStack <= 0 && setVisible(false);
        } else {
            loadingStack > 0 && setVisible(true);
        }
    }, [visible, loadingStack]);

    useEffect(() => {
        if (visible) {
            hideEvent && clearTimeout(hideEvent);
            hideEvent = setTimeout(() => {
                console.warn('The load status has ended. Axios status needs to be checked.')
                dispatch({ type: AppConstant.CLEAR_LOAD_STACK });
            }, limitTime);
        } else {
            clearTimeout(hideEvent);
        }
        return () => {
            hideEvent && clearTimeout(hideEvent);
        }
    }, [dispatch, visible]);


    return <>
        {loadingStack > 0 && <div className={cusClass} style={cusStyle}>
            <div className="text-center">
                <ClipSpinner color={loadingConfig.color} size={75} />
            </div>
        </div>}
    </>
}

const mapState = (state) => {
    const loadingStack = state.AppReducer.loadingStack;
    const loadingConfig = state.AppReducer.loadingConfig;
    return { loadingConfig, loadingStack };
};

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(LoadingSpinner);
