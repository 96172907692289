import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TYPE } from "../../constants/appTerms";
import AppSettingTermsNavigator from "./AppSettingTermsNavigator";
import AppSettingTermsRootDashboard from "./AppSettingTermsRootDashboard";
import AppSettingTermsRootLang from "./AppSettingTermsRootLang";
import AppSettingTermsRootTC from "./AppSettingTermsRootTC";
import AppSettingTermsRootVersion from "./AppSettingTermsRootVersion";
import AppSettingTermsRootVersionRelease from "./AppSettingTermsRootVersionRelease";

const VIEW_TYPE = {
    DEFAULT: 'default',
    ROOT_TC: 'root_tc',
    TEMP_ROOT_TC: 'temp_root_tc',
};

const AppSettingTerms = ({ item }) => {
    const storedTerms = useSelector(state => state.ApplicationReducer.terms);
    const [selectedRoot, setSelectedRoot] = useState(null);
    const [appTerms, setAppTerms] = useState([]);
    const [tempRootTC, setTempRootTC] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [selectedLang, setSelectedLang] = useState(null);
    const [existSelectedRelease, setExistSelectedRelease] = useState(false);

    const [viewType, setViewType] = useState(VIEW_TYPE.DEFAULT);

    useEffect(() => {
        if (storedTerms && item?.name) {
            setAppTerms(storedTerms[item.name]?.items || []);
        }
    }, [storedTerms, item?.name]);

    useEffect(() => {
        setTempRootTC(appTerms.find(x => x.type === TYPE.TEMP_TC_ROOT));
        const _selectedRoot = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_ROOT);
        setSelectedRoot(_selectedRoot ? appTerms.find(x => x.type === _selectedRoot.data.type) : null);
        const _selectedVersion = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION);
        setSelectedVersion(_selectedVersion ? appTerms.find(x => x.type === _selectedVersion.data.type) : null);
        const _selectedLang = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_LANG);
        setSelectedLang(_selectedLang ? appTerms.find(x => x.type === _selectedLang.data.type) : null)
        setExistSelectedRelease(!!appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION_RELEASE));
    }, [appTerms]);

    useEffect(() => {
        if (selectedRoot) {
            setViewType(VIEW_TYPE.ROOT_TC);
        } else {
            setViewType(VIEW_TYPE.DEFAULT);
        }
    }, [selectedRoot]);

    useEffect(() => {
        if (tempRootTC?.code) {
            setViewType(VIEW_TYPE.TEMP_ROOT_TC);
        } else {
            setViewType(VIEW_TYPE.DEFAULT);
        }
    }, [tempRootTC?.code]);

    return <>
        <div className="row">
            <div className="col-3">
                <AppSettingTermsNavigator app={item} />
            </div>
            <div className="col-9">
                <div className="border-card">
                    {viewType === VIEW_TYPE.DEFAULT && <AppSettingTermsRootDashboard app={item} />}
                    {viewType === VIEW_TYPE.ROOT_TC && <AppSettingTermsRootTC app={item} tiny={!!selectedVersion} />}
                    {viewType === VIEW_TYPE.TEMP_ROOT_TC && <AppSettingTermsRootTC app={item} create={true} />}
                    {!!selectedRoot && <hr className="divider" />}
                    <AppSettingTermsRootVersion app={item} tiny={!!selectedLang || existSelectedRelease} />
                    <AppSettingTermsRootVersionRelease app={item} version={selectedVersion} />
                    <AppSettingTermsRootLang app={item} version={selectedVersion} />
                </div>
            </div>
        </div>
    </>
}

export default AppSettingTerms;
