import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import Select from 'react-select';
import { toast } from "react-toastify";
import { LANGUAGE } from "../../constants";
import ApplicationHook from "../../hooks/ApplicationHook";
import { ApplicationAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import RAYFile from "../common/RAYFile";
import RAYTable from "../common/RAYTable";
import RAYTextField from "../common/RAYTextField";

const langItems = LANGUAGE.map(x => ({ value: x.code, label: x.label }));

const AppSettingManualsV2 = ({ auth, app, GetApplication }) => {
    const [t] = useTranslation();
    const { updateApp } = ApplicationHook();
    const manualRevisions = useMemo(() => _.orderBy(app?.manualRevisions || [], ["revision"], ["desc"]), [app?.manualRevisions]);
    const [expendAddPanel, setExpendAddPanel] = useState(false);
    const [data, setData] = useState({});
    const [fileUploadStack, setFileUploadStack] = useState([]);
    const tableSet = useMemo(() => {
        let _list = _.orderBy(manualRevisions, ["revision"], ["desc"])
        const tableHeader = [
            {
                Header: 'Revision',
                accessor: 'revision',
            },
            {
                Header: 'Language',
                accessor: 'lang',
            },
            {
                Header: 'Document',
                accessor: 'document',
            },
            {
                Header: 'Published',
                accessor: 'published',
            },
            {
                Header: '',
                accessor: 'actions',
                noneSort: true
            },
        ];
        const tableData = _list.map(x => ({
            ...x,
            lang: langItems.find(y => y.value === x.manualLang)?.label ?? x.manualLang,
            document: <RAYFile
                edit={false}
                item={x.doc}
            />,
            actions: <div className="d-flex align-items-center gap-2">
                <RAYButton
                    label={<FaEdit size={16} />}
                    color={data?.uuid === x.uuid ? "primary" : "success"}
                    size="sm"
                    onClick={() => {
                        setData(x);
                        setExpendAddPanel(true);
                    }}
                    css="p-1"
                />
                {x.uuid && <>
                    <RAYButton
                        label={<FaTrash size={16} />}
                        color="danger"
                        size="sm"
                        onClick={async () => {
                            await updateApp(app.name, { manualRevisions: manualRevisions.filter(y => y.uuid !== x.uuid) });
                            await GetApplication(app.name);
                        }}
                        css="p-1"
                    />
                </>}
            </div>,
        }));
        return {
            header: tableHeader,
            data: tableData,
        };
    }, [manualRevisions, app.name, langItems, data?.uuid]);

    const clearData = useCallback(() => {
        setData({});
        setFileUploadStack([]);
    }, []);

    const handlerSetUploadHooks = useCallback((_hook) => {
        setFileUploadStack(prev => {
            let _prev = [...prev];
            _prev = _prev.filter(x => x.name !== _hook.name);
            _prev.push(_hook);
            return _prev;
        });
    }, []);

    const uploadFile = useCallback(async (stack) => {
        if (!stack?.upload) {
            return null;
        }
        const ret = await stack.upload();
        return ret.data;
    }, []);

    const handlerSave = useCallback(async () => {
        if (!data?.revision || !data?.published || !data?.doc) {
            toast('Missing required fields', { type: 'error' });
            return;
        }
        let _manualRevisions = [...manualRevisions];
        const _data = _.cloneDeep(data);
        if (data?.doc instanceof File && fileUploadStack?.length > 0) {
            const uploadResult = await uploadFile(fileUploadStack[0])
            _data.doc = uploadResult;
        }
        _data.revision = _data.revision?.trim();
        if (_data.uuid) {
            _manualRevisions = _manualRevisions.filter(x => x.uuid !== _data.uuid);
        } else {
            _data.uuid = window.crypto.randomUUID();
            
        }
        _data.updater = auth?.sub;
        _manualRevisions.push(_data);
        const payload = { manualRevisions: _manualRevisions };
        await updateApp(app.name, payload);
        GetApplication(app.name);
        clearData();
        setExpendAddPanel(false);
    }, [manualRevisions, data, uploadFile, fileUploadStack, app?.name, auth?.sub]);

    return <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center justify-content-between">
            <div className="h5">Manuals</div>
            <div>
                <RAYButton
                    label={!expendAddPanel ? t("Add Manual Revision") : t("Close Manual Revision")}
                    size={"sm"}
                    color="primary"
                    onClick={() => {
                        clearData();
                        setExpendAddPanel(prev => !prev);
                    }}
                />
            </div>
        </div>
        <div style={{ position: "sticky", top: 16, zIndex: 1, transform: "top 0.2s" }}>
            <Collapse in={expendAddPanel}>
                <div>
                    <div className="border rounded p-3" style={{ background: "#eee" }}>
                        <Row>
                            <Col xs={4}>
                                <div>{t("Revision")} {!!data?.uuid && <small className="text-muted">Read Only</small>}</div>
                                <RAYTextField
                                    value={data.revision || ""}
                                    readOnly={!!data?.uuid}
                                    placeholder={t("Revision")}
                                    onChange={event => setData(prev => ({ ...prev, revision: event?.target?.value?.replace(/\s/g, "") }))}
                                />
                            </Col>
                            <Col xs={4}>
                                <div>{t("Language")}</div>
                                <Select
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={langItems?.find(x => x.value === data.manualLang) ?? ""}
                                    menuPlacement={"auto"}
                                    options={langItems}
                                    isClearable={true}
                                    onChange={obj => setData(prev => ({ ...prev, manualLang: obj.value }))}
                                    placeholder={t("Select")}
                                    style={{ marginTop: '11px', zIndex: 2 }}
                                    classNamePrefix="ray-select"
                                />
                            </Col>
                            <Col xs={4}>
                                <div>{t("Published")}</div>
                                <RAYTextField
                                    type="date"
                                    value={data.published || ""}
                                    placeholder={t("Published date")}
                                    onChange={event => setData(prev => ({ ...prev, published: event?.target?.value }))}
                                />
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <div>{t("File")}</div>
                            <RAYFile
                                edit={!!data?.revision?.trim()}
                                item={data?.doc || null}
                                fieldName={`doc`}
                                keyPrefix={`rayrnd/applications/${app?.name}/manuals/${data.revision?.trim()}/`}
                                callbackOnChangeFile={(event) => setData(prev => ({ ...prev, doc: event.value }))}
                                callbackUploadHook={handlerSetUploadHooks}
                                css="w-100"
                            />
                        </div>
                        <div className="mt-3 d-flex align-items-center justify-content-between">
                            <div>
                                {!data?.revision?.trim() && t("Required Revision Value")}
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <RAYButton
                                    label={t("Cancel")}
                                    onClick={() => {
                                        clearData();
                                        setExpendAddPanel(false);
                                    }}
                                />
                                <RAYButton
                                    label={t("Save")}
                                    color="primary"
                                    onClick={handlerSave}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
        <div>
            {tableSet.data?.length > 0 && <>
                <RAYTable
                    tableHeaders={tableSet.header}
                    tableData={tableSet.data}
                />
            </>}
            {tableSet.data?.length === 0 && <>
                <div className="p-4">
                    {t("Not find manuals")}
                </div>
            </>}
        </div>
    </div>
}


const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({
    GetApplication: (appName) => dispatch(ApplicationAction.GetApplication(appName)),
});

export default connect(mapState, mapDispatch)(AppSettingManualsV2);

