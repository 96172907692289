import { combineReducers } from "redux";
import { AppReducer, AppConstant } from "./AppReducer";
import { AuthReducer, AuthConstant } from "./AuthReducer";
import { UserReducer, UserConstant } from "./UserReducer";
import { ProgramReducer, ProgramConstant } from "./ProgramRed";
import { ApplicationReducer, ApplicationConstant } from "./ApplicationReducer";
import { ApplicationVersionReducer, ApplicationVersionConstant } from "./ApplicationVersionReducer";

const appReducer = combineReducers({
	AppReducer,
	AuthReducer,
	UserReducer,
    ProgramReducer,
	ApplicationReducer,
	ApplicationVersionReducer
});

const RootReducer = (state, action) => {
	if (action.type === AuthConstant.LOGOUT) {
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

export default RootReducer;
export {
	AppConstant,
	AuthConstant,
	UserConstant,
    ProgramConstant,
	ApplicationConstant,
	ApplicationVersionConstant
}
