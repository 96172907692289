import AWS from 'aws-sdk';
import { getAWS, getAWSProd, getAWSCredential } from "../config/aws";
export const UploadS3 = async (bucket,keypath, data) => {
    const s3 = new AWS.S3(await getAWSCredential());
    const ret = await s3.upload({
        Bucket : bucket,
        Key: keypath,
        Body: JSON.stringify(data),
        ACL:'public-read'
    }).promise();
}
