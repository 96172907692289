import { useCallback, useState } from "react";

const RAYTextField = ({ size, value, type = 'text', readOnly, onChange, placeholder, disabled, useLocalValue = false, name, css = "", enterSubmit, style }) => {
    const [val, setVal] = useState(value);

    const handlerOnChange = useCallback((event) => {
        if (useLocalValue) {
            setVal(event.target.value);
        }
        onChange && onChange(event);
    }, [onChange, useLocalValue]);

    const handlerOnKeyUp = useCallback((event) => {
        if (enterSubmit) {
            if (event.code === "Enter") {
                enterSubmit();
            }
        }
    }, [enterSubmit]);

    return <>
        <input
            name={name}
            type={type}
            className={`form-control ${css} ` + (size ? `form-control-${size}` : '')}
            placeholder={placeholder}
            readOnly={readOnly}
            onKeyUp={handlerOnKeyUp}
            disabled={disabled}
            value={useLocalValue ? val : value}
            onChange={handlerOnChange}
            style={style ? style : {}}
        />
    </>
};


export default RAYTextField;