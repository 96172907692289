import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { LANGUAGE } from "../../constants";
import { TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import { ApplicationConstant } from "../../redux/reducers";
import RAYButton from "../common/RAYButton";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYSelect from "../common/RAYSelect";
import { RAYToolTip } from "../common/RAYToolTip";

const AppSettingTermsRootVersion = ({ app, tiny = false, auth, storedTerms, GetAppData, UpdateAppData, RemoveAppData, PublishNewVersionEmail }) => {
    const [appTerms, setAppTerms] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [publishable, setPublishable] = useState(false);
    const [denyReasons, setDenyReasons] = useState([]);
    const [selectedLang, setSelectedLang] = useState(null);
    const [unusedLangs, setUnusedLangs] = useState([]);
    const [langs, setLangs] = useState([]);
    const [readyPublish, setReadyPublish] = useState(false);
    const [readyPublishToTC, setReadyPublishToTC] = useState(false);
    const [rootTerm, setRootTerm] = useState(null);
    const [selectedReducerLang, setSelectedReducerLang] = useState(null);
    const [versions, setVersions] = useState([]);
    const [versionRelease, setVersionRelease] = useState(null);
    const [selectedVersionRelease, setSelectedVersionRelease] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const _temp = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_ROOT);
        setRootTerm(_temp ? appTerms.find(x => x.type === _temp.data.type) : null);
        const _tempLang = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_LANG);
        setSelectedReducerLang(_tempLang ? appTerms.find(x => x.type === _tempLang.data.type) : null)
        setVersions(appTerms.filter(x => x.type.startsWith(TYPE.TC_VERSION)).sort((a, b) => a.version.localeCompare(b.version, undefined, { numeric: true }) * -1));
    }, [appTerms]);

    useEffect(() => {
        if (rootTerm?.type) {
            const versionPrefix = TYPE.TC_VERSION + rootTerm.type.replace(TYPE.TC_ROOT, "");
            setVersions(appTerms.filter(x => x.type.startsWith(versionPrefix)).sort((a, b) => a.version.localeCompare(b.version, undefined, { numeric: true }) * -1));
        }
    }, [appTerms, rootTerm?.type]);

    useEffect(() => {
        if (!selectedVersion) {
            return;
        }
        const existLang = selectedVersion.langs.map(x => x.code);
        const _langs = LANGUAGE.filter(x => !existLang.includes(x.code)).map(x => ({ value: x.code, label: x.label }));
        setUnusedLangs(_langs);
    }, [selectedVersion]);

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
        setReadyPublish(false);
    }, [storedTerms, app?.name]);

    useEffect(() => {
        const _temp = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION);
        setSelectedVersion(_temp ? appTerms.find(x => x.type === _temp.data.type) : null);
    }, [appTerms]);

    useEffect(() => {
        if (!selectedVersion) {
            return;
        }
        setLangs(appTerms.filter(x => x.type.startsWith(selectedVersion.type.replace(TYPE.TC_VERSION, TYPE.TC_DOCS))));
        setVersionRelease(appTerms.find(x => x.type === selectedVersion.type.replace(TYPE.TC_VERSION, TYPE.TC_VERSION_RELEASE)));
    }, [selectedVersion, appTerms]);

    useEffect(() => {
        setSelectedVersionRelease(!!appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION_RELEASE && x.rowType === versionRelease?.type));
    }, [appTerms, versionRelease]);

    useEffect(() => {
        if (!selectedVersion) {
            return;
        }
        const _reason = [];
        let _publishable = true;
        if (selectedVersion.isPublished) {
            _reason.push('It has already been published.')
            _publishable = false;
        } else {
            const unLangs = langs.filter(x => !x.isPublished);
            const pubLangs = langs.filter(x => x.isPublished);
            const readyLangs = langs.filter(x => !x.isPublished && x.isReady);
            if (unLangs.length === 0) {
                _reason.push('No documents have been created.')
                _publishable = false;
            } else if (langs.length !== (pubLangs.length + readyLangs.length)) {
                _reason.push('The document is not written in all languages.')
                _publishable = false;
            }
            if (versionRelease && !versionRelease.isReady) {
                _reason.push('Please write the change Document.')
                _publishable = false;
            }
        }
        setDenyReasons(_reason);
        setPublishable(_publishable);
    }, [selectedVersion, langs, versionRelease]);

    const handlerRemoveUnpublishedVersion = async () => {
        if (!selectedVersion) {
            return;
        }
        if (selectedVersion.isPublished) {
            return;
        }
        if (langs.length > 0) {
            toast('Language exists. Please remove the language.', { type: 'error' });
            return;
        }
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION });
        versionRelease?.type && await RemoveAppData({ appName: app.name, type: versionRelease.type });
        await RemoveAppData({ appName: app.name, type: selectedVersion.type });
    };

    const handlerAddLanguage = async () => {
        if (!selectedVersion || !selectedLang) {
            return;
        }
        const _type = TYPE.TC_DOCS + selectedVersion.type.replace(TYPE.TC_VERSION, "") + ":" + selectedLang.value;
        const existRet = await GetAppData({ appName: app.name, prefix: _type });
        if (!existRet) {
            toast('Server Error', { type: 'error' });
            return;
        }
        if (existRet.data.length > 0) {
            toast('Exist Language ' + selectedLang.label, { type: 'info' });
            return;
        }
        const payload = {
            appName: app.name,
            type: _type,
            item: {
                lang: selectedLang.value,
                version: selectedVersion.version,
                versionType: selectedVersion.type,
                isPublished: false,
                isReady: false,
                title: '',
                contents: '',
                creator: auth.sub,
                updater: auth.sub,
                created: new Date().getTime(),
            }
        };
        const ret = await UpdateAppData(payload);
        if (ret?.status !== 'success') {
            return;
        }
        setSelectedLang(null);
    };

    const publishVersion = async () => {
        try {
            const payload = {
                appName: app.name,
                type: selectedVersion.type,
                item: {
                    isPublished: true,
                    published: new Date().getTime(),
                    langs: langs.map(lang => ({ code: lang.lang, type: lang.type, title: lang.title }))
                },
            }
            const ret = await UpdateAppData(payload);
            if (!ret) {
                return;
            }
            for (const lang of langs) {
                const payload = {
                    appName: app.name,
                    type: lang.type,
                    item: {
                        isPublished: true
                    },
                };
                await UpdateAppData(payload);
            }
            if (versionRelease && versionRelease.type) {
                const payload = {
                    appName: app.name,
                    type: versionRelease.type,
                    item: {
                        isPublished: true
                    }
                };
                await UpdateAppData(payload);
            }

            return payload;
        } catch (error) {
            //
        }
    };

    const handlerPublishVersion = async () => {
        if (!readyPublish) {
            setReadyPublish(true);
            return;
        }
        const _payload = await publishVersion();
        if (!_payload) {
            toast('Error, please reload and try', { type: 'error' });
            setReadyPublishToTC(false);
            return;
        }
        setReadyPublish(false);
    };

    const handlerRollbackPublishVersion = async () => {
        const payload = {
            appName: app.name,
            type: selectedVersion.type,
            item: {
                isPublished: false,
                langs: []
            },
        }
        await UpdateAppData(payload);
        for (const lang of langs) {
            const payload = {
                appName: app.name,
                type: lang.type,
                item: {
                    isPublished: false,
                },
            };
            await UpdateAppData(payload);
        }
    };

    const handlerClickLang = (_lang) => {
        if (selectedReducerLang?.type === _lang.type) {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
            return;
        }
        dispatch({ type: ApplicationConstant.SET_TERM, appName: app.name, item: { type: TYPE.EXTRA_SELECT_LANG, rowType: _lang.type, data: _lang } });
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION_RELEASE });
    };

    const handlerClickRelease = () => {
        if (selectedVersionRelease) {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION_RELEASE });
            return;
        }
        dispatch({ type: ApplicationConstant.SET_TERM, appName: app.name, item: { 
            type: TYPE.EXTRA_SELECT_VERSION_RELEASE, 
            rowType: versionRelease.type, 
            data: versionRelease 
        }});
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
    };

    const handlerPublishVersionForPublishedTC = async () => {
        if (!versionRelease?.type) {
            return;
        }
        if (!readyPublishToTC) {
            setReadyPublishToTC(true);
            return;
        }
        try {
            const _payload = await publishVersion();
            if (!_payload) {
                toast('Error, please reload and try', { type: 'error' });
                setReadyPublishToTC(false);
                return;
            }
            const payload = {
                appName: app.name,
                type: rootTerm.type,
                item: {
                    isPublished: true,
                    currentVersion: selectedVersion.version,
                    currentPublished: _payload.item.published,
                    lastDoc: _payload.item.langs,
                    docs: [
                        ...rootTerm.docs,
                        {
                            version: selectedVersion.version,
                            published: _payload.item.published,
                            docs: _payload.item.langs,
                        }
                    ],
                }
            };
            await UpdateAppData(payload);
            await PublishNewVersionEmail({
                appName: app.name,
                rootType: rootTerm.type,
                versionReleaseType: versionRelease.type,
            });
        } catch (error) {
            //
        }
        setReadyPublishToTC(false);
    };

    return <>
        {!selectedVersion && !!rootTerm && <>
            {versions.length === 0 && <div>
                <h3>No version</h3>
                <div className="text-center my-6">
                    <i className="fs-1">Please create a version from the menu.</i>
                </div>
            </div>}
            {versions.length > 0 && <>
                <h4 className="mb-3">Versions</h4>
                {versions.map(v => <div key={v.type}>
                    <div className="fs-3 text-black">{v.version}</div>
                    <ul>
                        <li>
                            Created
                            <span className="text-black fw-bolder ms-2">{moment(v.created).format('LLL')}</span>
                        </li>
                        <li>
                            Published
                            <span className="text-black fw-bolder ms-2">{v.isPublished ? moment(v.published).format('LLL') : <i>No</i>}</span>
                        </li>
                        {v.isPublished && <li>
                            Languages {v.langs.length},
                            <span className="text-black fw-bolder ms-2">{v.langs.map(l => LANGUAGE.find(x => x.code === l.code)?.label || l.code).sort((a, b) => a < b ? -1 : 1).join(', ')}</span>
                        </li>}
                    </ul>
                </div>)}
            </>}
        </>}
        {!!selectedVersion && <>
            <h4>
                <i>Version</i>
                <span className="ms-2">{selectedVersion.version}</span>
            </h4>
            <div className="d-flex align-items-center my-2" style={{ gap: 6 }}>
                <span className={`badge bg-${selectedVersion.isPublished ? "primary" : "light"}`}>
                    {selectedVersion.isPublished ? "Publish" : "Draft"}
                    {selectedVersion.isPublished && <span className="ms-1">{moment(selectedVersion.published).format('LLL')}</span>}
                </span>
                <span className={`badge bg-${langs.length > 0 ? "primary" : "light"}`}>
                    Languages {langs.length}
                </span>
                {langs.map(x => <span
                    key={x.type}
                    className={`badge raycur bg-${x.isPublished ? 'primary' : (x.isReady ? 'success' : 'light')}`}
                    onClick={() => handlerClickLang(x)}
                >
                    {selectedReducerLang?.lang === x.lang && <span className="me-2">Selected</span>}
                    {LANGUAGE.find(l => l.code === x.lang)?.label || x}
                </span>)}
                {versionRelease?.type && <div className="ms-4 raycur" onClick={handlerClickRelease}>
                    {versionRelease.isPublished && <span className="badge bg-primary">
                        {selectedVersionRelease && <span className="me-2">Selected</span>}
                        <span>Change Document</span>
                    </span>}
                    {!versionRelease.isPublished && <span className={`badge bg-${versionRelease.isReady ? 'success' : 'warning'}`}>
                        {selectedVersionRelease && <span className="me-2">Selected</span>}
                        <span>Change Document</span>
                    </span>}
                </div>}
            </div>
            {!tiny && <div className="mt-3">
                <RAYFormRaw title="Publish" labelCol={2}>
                    <div className="w-100">
                        <div className="w-100 d-flex align-items-center">
                            <div className="mt-3 p-3 " style={{ border: '1px solid #dddddd', borderRadius: 6 }}>
                                {selectedVersion.isPublished && <div>
                                    <div className="text-primary">{selectedVersion.version} is published at {moment(selectedVersion.published).format('LLL')}</div>
                                    {selectedVersion.isPublished && !rootTerm.isPublished && <div className="text-warning">
                                        {rootTerm.alias} has not been published. This version can be rolled back.
                                    </div>}
                                </div>}
                                {!selectedVersion.isPublished && <>
                                    {publishable && <div>
                                        <div>{selectedVersion.version} is a publishable version.</div>
                                    </div>}
                                    {!publishable && <div>
                                        <div className="text-danger fw-bolder">
                                            {selectedVersion.version} is a version that cannot be published.<br />
                                        </div>
                                        {denyReasons.length > 0 && <div>
                                            {denyReasons.map((x, idx) => <i key={idx}>{x}</i>)}
                                        </div>}
                                    </div>}
                                </>}
                                {readyPublish && <div className="text-danger fw-bolder">
                                    Publishing will not be canceled after publishing.<br />
                                    The written terms and conditions of each language cannot be deleted or modified.
                                </div>}
                            </div>
                            <div className="flex-fill" />
                            <div className="ms-2 d-flex align-items-center">
                                {!rootTerm?.isPublished && publishable && <>
                                    <RAYButton
                                        label={readyPublish ? "Really Publish Version?" : "Publish Version"}
                                        color="primary"
                                        onClick={handlerPublishVersion}
                                    />
                                </>}
                                {rootTerm?.isPublished && publishable && <>
                                    <RAYButton
                                        label={readyPublishToTC ? "Really Publish Version?" : "Publish Version"}
                                        color={readyPublishToTC ? "warning" : "primary"}
                                        onClick={() => !readyPublishToTC && handlerPublishVersionForPublishedTC()}
                                    />
                                </>}
                                {!selectedVersion.isPublished && <>
                                    <RAYButton
                                        label="Remove Version"
                                        color="danger"
                                        onClick={handlerRemoveUnpublishedVersion}
                                    />
                                </>}
                                {selectedVersion.isPublished && !rootTerm.isPublished && <>
                                    <RAYButton
                                        label="Rollback Publish"
                                        color="success"
                                        onClick={handlerRollbackPublishVersion}
                                    />
                                </>}
                            </div>
                        </div>
                        {readyPublishToTC && <div className="p-4 mb-5 bg-danger text-white mt-3" style={{ borderRadius: 10 }}>
                            <div className="fs-1 mb-4">Attention!</div>
                            <div className="fs-3">
                                This operation is not canceled.<br />
                                After the version is published, it is exposed to the service end user.<br />
                                {versionRelease && versionRelease?.type && <>
                                    <br />
                                    The created change document will be mailed to users who are using the app.
                                </>}
                            </div>
                            <div className="mt-4 d-flex justify-content-end">
                                <RAYButton
                                    label="Publish"
                                    onClick={handlerPublishVersionForPublishedTC}
                                    color="warning"
                                />
                                <RAYButton
                                    label="Not Yet"
                                    onClick={() => setReadyPublishToTC(false)}
                                    color="light"
                                />
                            </div>
                        </div>}
                    </div>
                </RAYFormRaw>
                <RAYFormRaw title="Languages" labelCol={2}>
                    <div className="w-100 d-flex align-items-center">
                        <div>
                            {langs.length === 0 && <div>
                                <i>None Language.</i>
                            </div>}
                            {langs.length > 0 && <div className="d-flex align-items-center" style={{ gap: 6 }}>
                                {langs.map(x => <div key={x.type} className={`badge raycur bg-${x.isPublished ? 'primary' : (x.isReady ? 'success' : 'light')}`} onClick={() => handlerClickLang(x)}>
                                    <RAYToolTip tooltip={x.isPublished ? 'Published' : (x.isReady ? 'Ready' : 'Draft')}>
                                        <div>
                                            <span className="me-1">
                                                {x.isPublished ? 'P' : (x.isReady ? 'R' : 'D')}
                                            </span>
                                            <span>
                                                {LANGUAGE.find(l => l.code === x.lang)?.label || x}
                                            </span>
                                        </div>
                                    </RAYToolTip>
                                </div>)}
                            </div>}
                        </div>
                        <div className="flex-fill" />
                        {selectedVersion && !selectedVersion?.isPublished && <div className="d-flex align-items-center mt-2">
                            <RAYSelect
                                items={unusedLangs}
                                value={selectedLang || null}
                                callbackOnChange={v => setSelectedLang(v)}
                                css="w-200px"
                            />
                            <RAYButton
                                label="Add Language"
                                color="primary"
                                css="ms-2 py-0 px-3"
                                style={{ height: 38 }}
                                disabled={!selectedLang}
                                onClick={handlerAddLanguage}
                            />
                        </div>}
                    </div>
                </RAYFormRaw>
            </div>}
        </>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
    PublishNewVersionEmail: (payload) => dispatch(ApplicationAction.PublishNewVersionEmail(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsRootVersion);
