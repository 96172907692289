import { clearKeyItemToReducer, setKeyItemToReducer, unsetKeyItemToReducer } from "../../libs/functions";

const initialState = {
	allApps: [],
	apps: [],
	terms: {},
	templates: {},
};

export const ApplicationConstant = {
	SET_ALL_APPS: "ACTION_APPLICATION_SET_ALL_APPS",
	SET_APPS: "ACTION_APPLICATION_SET_APPS",
	GET_APP: "ACTION_APPLICATION_GET_APP",
	DELETE_APP: "ACTION_APPLICATION_DELETE_APP",
	SET_TERM: "ACTION_APPLICATION_SET_TERM",
	UNSET_TERM: "ACTION_APPLICATION_UNSET_TERM",
	CLEAR_TERM: "ACTION_APPLICATION_CLEAR_TERM",
	SET_TEMPLATES: "ACTION_APPLICATION_SET_TEMPLATES",
	UNSET_TEMPLATES: "ACTION_APPLICATION_UNSET_TEMPLATES",
};

export const ApplicationReducer = (state = initialState, action) => {
	let appTerms;
	switch (action.type) {
		case ApplicationConstant.SET_TEMPLATES:
			return setKeyItemToReducer(state, action, "templates");
		case ApplicationConstant.UNSET_TEMPLATES:
			return unsetKeyItemToReducer(state, action, "templates");
		case ApplicationConstant.SET_TERM:
			appTerms = state.terms[action.appName];
			if (!appTerms) {
				return { ...state, terms: { ...state.terms, [action.appName]: {
					meta: {},
					items: Array.isArray(action.item) ? action.item : [action.item],
				} } };
			} else if (Array.isArray(action.item)) {
				const _tmp = [...appTerms.items];
				const _newTmp = [];
				action.item.forEach(x => {
					const _existIndex = _tmp.findIndex(y => y.type === x.type);
					if (_existIndex > -1) {
						_tmp[_existIndex] = x;
					} else {
						_newTmp.push(x);
					}
				});
				return { ...state, terms: { ...state.terms, [action.appName]: {
					...appTerms,
					items: [..._tmp, ..._newTmp]
				} } };
			} else if (typeof action.item === 'object') {
				const _tmp = [...appTerms.items];
				const _existIndex = _tmp.findIndex(x => x.type === action.item.type)
				if (_existIndex > -1) {
					_tmp[_existIndex] = action.item;
				} else {
					_tmp.push(action.item);
				}
				return { ...state, terms: { ...state.terms, [action.appName]: {
					...appTerms,
					items: _tmp
				} } };
			} else {
				return state;
			}
		case ApplicationConstant.UNSET_TERM:
			appTerms = state.terms[action.appName];
			if (!appTerms) {
				return state;
			}
			const _tmp = appTerms.items.filter(x => x.type !== action.rowType);
			return {
				...state,
				terms: { ...state.terms, [action.appName]: {
					...appTerms,
					items: _tmp,
				} },
			}
		case ApplicationConstant.CLEAR_TERM:
			return clearKeyItemToReducer(state, action, "templates");
		case ApplicationConstant.SET_ALL_APPS:
			return {
				...state,
				allApps: action.payload
			}
		case ApplicationConstant.SET_APPS:
			return {
				...state,
				apps: action.payload
			}
		case ApplicationConstant.GET_APP:
			return {
				...state,
				apps: state.apps.map(x => x.name === action.payload.app.name ? action.payload.app : x)
			}
		case ApplicationConstant.DELETE_APP:
			return {
				...state,
				apps: state.apps.filter(x => x.name !== action.payload.appName)
			}
		default:
			return state
	}
}