import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import TinyEditor from "../tinymce/TinyEditor";

const AppSettingTermsRootVersionRelease = ({ app, storedTerms, version, GetAppData, UpdateAppData, RemoveAppData }) => {
    const [appTerms, setAppTerms] = useState([]);
    const [data, setData] = useState({});
    const [valid, setValid] = useState(false);
    const [readySave, setReadySave] = useState(false);
    const [versionRelease, setVersionRelease] = useState(null);
    const [selectedRelease, setSelectedRelease] = useState(null);

    useEffect(() => {
        setValid(!!data?.title && !!data?.contents);
        setReadySave(false);
    }, [data]);

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
    }, [storedTerms, app?.name]);

    useEffect(() => {
        version?.type && setSelectedRelease(appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION_RELEASE && x.rowType === version.type.replace(TYPE.TC_VERSION, TYPE.TC_VERSION_RELEASE)));
    }, [version?.type, appTerms]);

    useEffect(() => {
        selectedRelease?.rowType && setVersionRelease(appTerms.find(x => x.type === selectedRelease.rowType));
    }, [appTerms, selectedRelease?.rowType]);

    useEffect(() => {
        if (versionRelease) {
            setData({
                title: versionRelease.title,
                contents: versionRelease.contents,
                isReady: versionRelease.isReady,
            });
        } else {
            setData({});
        }
    }, [versionRelease]);

    const handlerSave = async () => {
        if (!readySave) {
            setReadySave(true);
            return;
        }
        const payload = {
            appName: app.name,
            type: versionRelease.type,
            item: { ...data },
        };
        await UpdateAppData(payload);
    };

    return <>
        {!!selectedRelease && !!versionRelease && <>
            <div className="d-flex align-items-end mt-6">
                <h4>Change Document</h4>
                <div className="flex-fill" />
                <div className="d-flex align-items-center">
                    {!versionRelease.isPublished && <RAYToggle
                        value={data.isReady}
                        onChange={() => setData(prev => ({ ...prev, isReady: !prev.isReady }))}
                        css="me-2"
                        disabled={!valid}
                        trueLabel="Ready to publish"
                        falseLabel={valid ? "Click to prepare for publication." : "Please enter a title and contents."}
                        labelColor="black"
                        RTL={true}
                    />}
                    {!versionRelease.isPublished && <>
                        <RAYButton
                            label={readySave ? "Really Save?" : "Save"}
                            color="primary"
                            onClick={handlerSave}
                        />
                    </>}
                </div>
            </div>
            <RAYFormRaw title="Title" required={!versionRelease.isReady} labelCol={12}>
                {(versionRelease.isPublished || versionRelease.isReady) && <div className="form-control form-control-lg">
                    {versionRelease.title}
                </div>}
                {(!versionRelease.isPublished && !versionRelease.isReady) && <div className="flex-fill">
                    <RAYTextField
                        value={data?.title || ''}
                        onChange={(e) => setData(prev => ({ ...prev, title: e.target.value }))}
                        css="w-100"
                    />
                    <div className="mt-2 small text-warning">
                        The title is used as the title of the email sent to the end user.
                    </div>
                </div>}
            </RAYFormRaw>
            <RAYFormRaw title="Contents" required={!versionRelease.isReady} labelCol={12}>
                <div className="w-100">
                    {(versionRelease.isPublished || versionRelease.isReady) && <div className="p-3" dangerouslySetInnerHTML={{ __html: versionRelease.contents }} />}
                    {(!versionRelease.isPublished && !versionRelease.isReady) && <TinyEditor
                        originContent={versionRelease?.contents}
                        callbackOnChange={_html => setData(prev => ({ ...prev, contents: _html }))}
                    />}
                </div>
            </RAYFormRaw>
        </>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsRootVersionRelease);
