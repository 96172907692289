import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppTemplateForm from "../../../components/appTemplates/AppTemplateForm";
import ApplicationToolbar from "../../../components/toolbars/ApplicationToolbar";
import PageInOutHook from "../../../hooks/PageInOutHook";
import { ApplicationAction } from "../../../redux/actions/ApplicationAction";

const AppTemplatesCreate = ({ apps, auth, allTemplates, GetAppTemplates, CreateAppTemplate }) => {
    const { appName } = useParams();
    const [app, setApp] = useState({});
    const [templates, setTemplates] = useState([]);
    const [setToolbar] = PageInOutHook();
    const navigator = useNavigate();

    useEffect(() => {
        if (app?.name) {
            setToolbar(<ApplicationToolbar
                title={<h3>Create Content Set
                    <small className="ms-2">{app?.title || app?.name}</small>
                </h3>}
                appName={app.name}
                suffixDom={<div>
                    <Link to={`/applications/${app.name}/templates`} className="btn btn-success">Manage App's Content Set
                        
                    </Link>
                </div>}
            />);
        }
    }, [app, setToolbar]);

    useEffect(() => {
        appName && apps.length && setApp(apps.find(x => x.name === appName));
    }, [apps, appName]);

    useEffect(() => {
        if (app?.name) {
            GetAppTemplates({ appName: app.name, type: "t:header:" });
        }
    }, [GetAppTemplates, app.name])

    useEffect(() => {
        if (app?.name && allTemplates[app.name]) {
            setTemplates(allTemplates[app.name]?.items?.filter(x => x.type.startsWith("t:header:")));
        }
    }, [app?.name, allTemplates]);

    const callbackClickConform = async (data) => {
        const type = `t:header:${data.version}`
        const item = {
            name: app.name,
            type,
            ...data
        };
        const ret = await CreateAppTemplate({ appName: app.name, item });
        if (ret.Attributes) {
            navigator(-1);
        }
    };

    const callbackClickCancel = () => {
        navigator(-1)
    };

    return <div>
        <div>
            {app?.name && <AppTemplateForm
                app={app}
                create={true}
                callbackClickConform={callbackClickConform}
                callbackClickCancel={callbackClickCancel}
            />}
        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    CreateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.CreateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplatesCreate);
