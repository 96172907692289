import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApplicationConstant } from "../../redux/reducers";
import AppSettingTermsNavigatorLang from "./AppSettingTermsNavigatorLang";
import AppSettingTermsNavigatorTerms from "./AppSettingTermsNavigatorTerms";
import AppSettingTermsNavigatorVersion from "./AppSettingTermsNavigatorVersion";
import AppSettingTermsNavigatorVersionRelease from "./AppSettingTermsNavigatorVersionRelease";

const AppSettingTermsNavigator = ({ app }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        app?.name && dispatch({ type: ApplicationConstant.CLEAR_TERM, appName: app.name });
    }, [dispatch, app.name]);

    return <>
        <div className="">
            <AppSettingTermsNavigatorTerms app={app} />
            <AppSettingTermsNavigatorVersion app={app} />
            <AppSettingTermsNavigatorVersionRelease app={app} />
            <AppSettingTermsNavigatorLang app={app} />
        </div>
    </>
}

export default AppSettingTermsNavigator;
