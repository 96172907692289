import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import RAYFormRaw from "../common/RAYFormRaw";

const ApidocCard = ({ apidocJsonStr, user, callbackSelectEdit }) => {
    const [apidoc, setApidoc] = useState({});

    useEffect(() => {
        try {
            const obj = JSON.parse(apidocJsonStr);
            setApidoc(obj);
        } catch (error) {
            setApidoc({});
        }
    }, [apidocJsonStr]);

    return <>
        <Card className="w-100 card-border" border="primary" style={{ border: '1 solid #ddd' }}>
            <Card.Body className="p-3">
                <Card.Title>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="raycur text-primary" onClick={() => callbackSelectEdit && callbackSelectEdit(apidoc)}>
                            {apidoc.label || apidoc.key}
                        </div>
                        <small className="text-muted ms-2">{apidoc.isDev ? 'Dev' : 'Prod'}</small>
                    </div>
                </Card.Title>
                <div>
                    <RAYFormRaw title="Key">
                        <div className="fw-bolder">{apidoc.key}</div>
                    </RAYFormRaw>
                    <RAYFormRaw title="description">
                        <div className="">{apidoc.desc}</div>
                    </RAYFormRaw>
                    <RAYFormRaw title="Origins">
                        <div>
                            {apidoc?.items?.map((x, idx) => <div key={idx} className="card">
                                <div className="card-body p-2 ps-0">
                                    <div>
                                        <span className="text-muted">Cloudformation </span>
                                        <span className="fw-bolder">{x?.doc?.formationName}</span>
                                    </div>
                                    <div>
                                        <span className="text-muted">Pipeline </span>
                                        <span className="fw-bolder">{x?.doc?.pipeName}</span>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </RAYFormRaw>
                </div>
            </Card.Body>
        </Card>
    </>
};

const mapState = (state) => {
    return {};
};

const mapDispatch = (dispatch) => ({})

export default connect(mapState, mapDispatch)(ApidocCard);