import { useCallback, useEffect, useState } from "react";
import Select from 'react-select';

const RAYSelect = ({ items, placeholder, value, callbackOnChange, multi = false, disabled = false, readOnly = false, style, css = '', menuPlacement = 'auto', clearable = true }) => {
    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal(value);
    }, [value]);

    const onChangeHandler = useCallback((data) => {
        setVal(data)
        callbackOnChange && callbackOnChange(data)
    }, [callbackOnChange])

    return <div style={{ ...style }} className={css}>
        <Select
            isMulti={multi}
            closeMenuOnSelect={!multi}
            // value={val}
            value={typeof val === "object" ? val : items?.find(x => x.value === val)}
            menuPlacement={menuPlacement}
            options={items}
            isDisabled={disabled}
            readOnly={readOnly}
            isClearable={clearable}
            onChange={onChangeHandler}
            placeholder={placeholder}
            style={{ marginTop: '11px', zIndex: 2 }}
            classNamePrefix="ray-select"
        />
    </div>
}

export default RAYSelect;
