import { useRef, useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import RAYTextField from "../common/RAYTextField";
import { v4 } from "uuid";
import RAYButton from "../common/RAYButton";
import { connect } from "react-redux";
import { ApplicationAction } from "../../redux/actions";
import { useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";


const FeatureArea = ({ app, version, features, feature, setFeature, CreateAppTemplate, UpdateAppTemplate, HeaderTitle = "Select Core Feature", addTitle = "Add New Core Feature" }) => {
    const [modalShow, setModalShow] = useState(false)
    const [data, setData] = useState({})
    const [displayFeatures, setDisplayFeatures] = useState([]);

    useEffect(() => {
        setDisplayFeatures([...features].sort((a, b) => a.order < b.order ? -1 : 1));
    }, [features])

    const clear = () => {
        setData({});
        setModalShow(false);
    };

    const handlerClickNewFeature = async () => {
        if (!data.label) {
            return;
        }
        const fId = v4();
        const item = {
            name: app.name,
            type: `t:body:${version}:feature:${fId}:header`,
            ...data,
            code: fId,
            isActive: false,
            create: new Date().getTime(),
            order: displayFeatures.length + 1
        };
        await CreateAppTemplate({ appName: app.name, item });
        clear();
    };

    const handlerItemOrderUp = async (code, e) => {
        e.stopPropagation();
        const targetIndex = displayFeatures.findIndex(x => x.code === code);
        const targetItem = { ...displayFeatures[targetIndex] };
        const otherItem = { ...displayFeatures[targetIndex - 1] };
        if (targetItem && otherItem) {
            targetItem.order = targetIndex - 1;
            otherItem.order = targetIndex;
            for (const _item of [targetItem, otherItem]) {
                await UpdateAppTemplate({ appName: app.name, item: _item });
            }
        }
    };

    const handlerItemOrderDown = async (code, e) => {
        e.stopPropagation();
        const targetIndex = displayFeatures.findIndex(x => x.code === code);
        const targetItem = { ...displayFeatures[targetIndex] };
        const otherItem = { ...displayFeatures[targetIndex + 1] };
        if (targetItem && otherItem) {
            targetItem.order = targetIndex + 1;
            otherItem.order = targetIndex;
            for (const _item of [targetItem, otherItem]) {
                await UpdateAppTemplate({ appName: app.name, item: _item });
            }
        }
    };

    return <div>
        <div className="h5">{HeaderTitle}</div>
        <div>
            <ListGroup>
                {displayFeatures.map((x, idx, items) => <ListGroup.Item
                    key={x.code}
                    active={x.code === feature.code}
                    action={true}
                    onClick={() => setFeature(x)}
                    className="d-flex align-items-center justify-content-between"
                >
                    <div>{x.label}</div>
                    {items.length > 1 && <div className="d-flex align-items-center">
                        {idx > 0 && <MdKeyboardArrowUp onClick={(e) =>handlerItemOrderUp(x.code, e)} />}
                        {idx !== items.length - 1 && <MdKeyboardArrowDown onClick={(e) =>handlerItemOrderDown(x.code, e)} />}
                    </div>}
                </ListGroup.Item>)}
                <ListGroup.Item>
                    <RAYButton
                        label="Add New Feature"
                        color="primary"
                        css="w-100"
                        onClick={() => setModalShow(true)}
                    />
                </ListGroup.Item>
            </ListGroup>
        </div>
        <Modal show={modalShow} onHide={clear}>
            <Modal.Header>
                <Modal.Title>
                    {addTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RAYTextField
                    value={data.name}
                    onChange={(e) => setData(prev => ({ ...prev, label: e.target.value }))}
                    placeholder="New Feature Label"
                />
                <div className="text-muted">
                    <i>Labels are used only by developers.</i>
                </div>
                <div className="mt-5 text-end">
                    <RAYButton
                        label="Add Feature"
                        color="primary"
                        onClick={handlerClickNewFeature}
                    />
                    <RAYButton
                        label="Cancel"
                        color="secondary"
                        onClick={clear}
                    />
                </div>

            </Modal.Body>
        </Modal>
    </div>
};


const mapState = (state) => {
    return {};
};

const mapDispatch = (dispatch) => ({
    CreateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.CreateAppTemplate({ appName, item })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(FeatureArea);

