import { useEffect, useState } from "react"
import { OverlayTrigger } from "react-bootstrap";
import { RAYToolTip } from "./RAYToolTip";

const RAYFormRawInline = ({ children, title, tooltip = "", required = false, labelCol = 3, css = '', labelCss = 'pt-6' }) => {

    return <div className={`${css} mb-2`}>
        <div className={`form-label fw-bolder ${labelCss}`}>
            <RAYToolTip hide={!tooltip} tooltip={<div className="text-start">{tooltip}</div>} >
                <span>
                    {title}
                </span>
            </RAYToolTip>
            {required && <span className="text-danger ms-2">*</span>}
        </div>
        <div className={`d-flex align-items-center py-0 my-0`}>
            {children}
        </div>
    </div>
};

export default RAYFormRawInline;

