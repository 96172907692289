import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { API_SANDBOX } from "../../constants";
import { UserAction } from "../../redux/actions";
import ApidocCard from "../apidoc/ApidocCard";
import RAYButton from "../common/RAYButton";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";

const API_DOCS = API_SANDBOX.RAYTeams;
// const API_DOCS = API_SANDBOX.Test;

const ApidocUserModal = ({ modal, callbackClose, UpdateUser, CreateStack, UpdateStack, DeleteStack }) => {
    const [edit, setEdit] = useState(false);
    const [create, setCreate] = useState(false);
    const [readySubmit, setReadySubmit] = useState(false);
    const [readyDelete, setReadyDelete] = useState(false);
    const [data, setData] = useState({});
    const [docList, setDocList] = useState([]);

    useEffect(() => {
        if (modal?.user?.apidoc && Array.isArray(modal?.user?.apidoc)) {
            setDocList(modal.user.apidoc);
        } else {
            setDocList([]);
        }
    }, [modal?.user?.apidoc]);

    const clear = useCallback(() => {
        setEdit(false);
        setCreate(false);
        setReadySubmit(false);
        setReadyDelete(false);
        setData({});
    }, []);

    useEffect(() => {
        !modal?.selectDoc && clear();
    }, [modal?.user, modal?.selectDoc, clear])

    useEffect(() => {
        if (modal?.selectDoc) {
            setData({ ...modal.selectDoc });
            setEdit(true);
        }
    }, [modal?.selectDoc]);

    const handlerClickAdd = useCallback(() => {
        setData({});
        setReadySubmit(false);
        setEdit(false);
        setCreate(true);
    }, []);

    const handlerSetData = useCallback((key, val) => {
        if (key === 'key') {
            val = val.replace(/[^a-z]/g, '');
            val.length === 0 && toast('use lowercase str', { type: 'info' });
        }
        setData(prev => ({ ...prev, [key]: val }));
    }, []);

    const handlerClickAddSubmit = useCallback(async () => {
        if (!readySubmit) {
            setReadySubmit(true);
            return;
        }
        const createItems = [];
        let success = true;
        for (const doc of API_DOCS) {
            const payload = {
                data: {
                    ...doc,
                    ...data,
                    sub: modal.user.sub
                }
            };
            const ret = await CreateStack(payload);
            if (ret.status === 'success') {
                createItems.push({
                    doc,
                    commitId: ret.data.commitId
                })
            } else {
                ret.error && toast(ret.error, { type: 'error' });
                success = false;
                break;
            }
        }
        if (!success) return;
        const userApidoc = modal?.user?.apidoc || [];
        userApidoc.push(JSON.stringify({ ...data, items: createItems, isDev: true }))
        const payloadUser = {
            sub: modal.user.sub,
            data: { data: { apidoc: userApidoc } }
        };
        await UpdateUser(payloadUser);
        clear();
    }, [readySubmit, modal.user?.apidoc, modal?.user?.sub, data, UpdateUser, CreateStack, clear]);

    const callbackSelectEdit = useCallback((doc) => {
        clear();
        setEdit(true)
        setData({ ...doc });
    }, [clear]);

    const handlerClickEditSubmit = useCallback(async () => {
        if (!readySubmit) {
            setReadySubmit(true);
            return;
        }
        let currentApidoc = modal.user.apidoc.map(x => JSON.parse(x));
        currentApidoc = currentApidoc.map(x => x.key === data.key ? data : x);
        const payload = {
            sub: modal.user.sub,
            data: {
                data: { apidoc: currentApidoc.map(x => JSON.stringify(x)) }
            }
        };
        await UpdateUser(payload);
        clear();
    }, [UpdateUser, data, modal?.user?.apidoc, modal?.user?.sub, readySubmit, clear]);

    const handlerClickDeleteSubmit = useCallback(async () => {
        if (!readyDelete) {
            setReadyDelete(true);
            return;
        }
        for (const item of data.items) {
            const payload = {
                data: {
                    sub: modal.user.sub,
                    key: data.key,
                    ...item.doc
                }
            };
            try {
                await DeleteStack(payload);
            } catch (error) {
                //
            }
        }
        let currentUserApidoc = modal.user.apidoc.map(x => JSON.parse(x));
        currentUserApidoc = currentUserApidoc.map(x => x.key === data.key ? { ...x, isDev: false } : x);
        const payloadUser = {
            sub: modal.user.sub,
            data: { data: { apidoc: currentUserApidoc.map(x => JSON.stringify(x))} }
        };
        await UpdateUser(payloadUser);
        clear();
    }, [readyDelete, modal?.user?.apidoc, modal?.user?.sub, UpdateUser, clear, data, DeleteStack]);

    const handlerClickRemoveClearSubmit = useCallback(async () => {
        if (!readyDelete) {
            setReadyDelete(true);
            return;
        }
        let currentUserApidoc = modal.user.apidoc.map(x => JSON.parse(x));
        currentUserApidoc = currentUserApidoc.filter(x => x.key !== data.key);
        const payloadUser = {
            sub: modal.user.sub,
            data: { data: { apidoc: currentUserApidoc.map(x => JSON.stringify(x))} }
        };
        await UpdateUser(payloadUser);
        clear();
    }, [UpdateUser, clear, data, modal?.user?.apidoc, modal?.user?.sub, readyDelete]);


    return <Modal show={modal?.show} size="lg" onHide={callbackClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                API Sandbox User {modal?.user?.name}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div className="d-flex justify-content-end">
                    <RAYButton label="Add Sandbox" size="sm" disabled={create} color="primary" onClick={handlerClickAdd} />
                </div>
            </div>
            {!(edit || create) && <>
                <div className="d-flex flex-wrap justify-content-around mt-2" style={{ gap: 12 }}>
                    {docList.length > 0 && docList.map((x, idx) => <ApidocCard key={idx} apidocJsonStr={x} user={modal.user} callbackSelectEdit={callbackSelectEdit} />)}
                    {docList.length === 0 && <>
                        <div className="fs-3 text-muted">
                            <i>NONE API Sandbox.</i>
                        </div>
                    </>}
                </div>
            </>}
            {(edit || create) && <div className="mt-2">
                <RAYFormRaw title="API Key" css="mb-2" required={create}>
                    <RAYTextField
                        readOnly={!create}
                        name="key"
                        value={data?.key || ''}
                        size="sm"
                        placeholder="user only lowercase and at least 8 characters"
                        onChange={(e) => handlerSetData("key", e.target.value)}
                    />
                </RAYFormRaw>
                <RAYFormRaw title="Label" css="mb-2">
                    <RAYTextField
                        name="label"
                        value={data?.label || ''}
                        size="sm"
                        placeholder="display name"
                        onChange={(e) => handlerSetData("label", e.target.value)}
                    />
                </RAYFormRaw>
                <RAYFormRaw title="Description" css="mb-2">
                    <RAYTextField
                        name="desc"
                        value={data?.desc || ''}
                        size="sm"
                        placeholder="display tooltip or description"
                        onChange={(e) => handlerSetData("desc", e.target.value)}
                    />
                </RAYFormRaw>
                <div className="d-flex justify-content-end mt-5">
                    {create && <RAYButton
                        label={readySubmit ? "Add Really?" : "Add"}
                        color="primary"
                        disabled={!data?.key || data.key.length < 8}
                        onClick={handlerClickAddSubmit}
                    />}
                    {edit && <>
                        {!data?.isDev && <RAYButton
                            label={readyDelete ? "Remove Clear Really? It is no longer visible to the user." : "Remove Clear"}
                            color="danger"
                            onClick={handlerClickRemoveClearSubmit}
                        />}
                        {data?.isDev && <RAYButton
                            label={readyDelete ? "To Prod Really? The sandbox environment is no longer available." : "To Prod"}
                            color="danger"
                            onClick={handlerClickDeleteSubmit}
                        />}
                        {data?.isDev && <RAYButton
                            label={readySubmit ? "Edit Really?" : "Edit"}
                            color="primary"
                            disabled={!data?.key || data.key.length < 8}
                            onClick={handlerClickEditSubmit}
                        />}
                    </>}
                    <RAYButton label="Cancel" color="light" onClick={clear} />
                </div>
            </div>}
        </Modal.Body>
        <Modal.Footer>
            <RAYButton label="Close" color="light" onClick={callbackClose} />
        </Modal.Footer>
    </Modal>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const pipeRecentCommitIds = state.UserReducer.pipeRecentCommitIds;
    return { auth, pipeRecentCommitIds };
};

const mapDispatch = (dispatch) => ({
    UpdateUser: (payload) => dispatch(UserAction.Update(payload)),
    CreateStack: (payload) => dispatch(UserAction.CreateStack(payload)),
    UpdateStack: (payload) => dispatch(UserAction.UpdateStack(payload)),
    DeleteStack: (payload) => dispatch(UserAction.DeleteStack(payload)),
});

export default connect(mapState, mapDispatch)(ApidocUserModal);

