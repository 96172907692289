import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { mdConverter } from '../libs/converter';
import MarkDown from './markdown';
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'

const DocMenu = ({ base, path, uri }) => {

	const dispath = useDispatch();
	const [t] = useTranslation(['translation']);

	const [data, setData] = useState("");
    const pre = path.replace(path.split('/').pop(), "");
	
	useEffect(() => {
        getData()
	},[path, uri])

    const getData = async () => {
        console.log(base + path + "/menu.md");
        const val = await axios(base + path + "/menu.md");
        setData(mdConverter(val.data, path, "/menu.md"));
    }
	
  	return <div className="card doc-menu">
        <div className="card-body pb-0">
            {data && <MarkDown data={data} />}
        </div>
    </div>
}

const mapState = (state) => {
	return { };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(DocMenu);
