import { useCallback, useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { APP_TERM_TYPES, TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import { ApplicationConstant } from "../../redux/reducers";
import AppSettingTermsAssetTermSelector from "./AppSettingTermsAssetTermSelector";

const AppSettingTermsNavigatorTerms = ({ app, storedTerms, GetAppData }) => {
    const [rootTcArr, setRootTcArr] = useState([]);
    const [existTypes, setExistTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [appTerms, setAppTerms] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            app?.name && dispatch({ type: ApplicationConstant.CLEAR_TERM, appName: app.name });
        }
    }, [GetAppData, dispatch, app.name]);

    const loadRoot = useCallback(async (appName) => {
        await GetAppData({ appName, prefix: TYPE.TC_ROOT });
        await GetAppData({ appName, prefix: TYPE.TC_VERSION });
        await GetAppData({ appName, prefix: TYPE.TC_VERSION_RELEASE });
    }, [GetAppData]);

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
    }, [storedTerms, app?.name]);

    useEffect(() => {
        setRootTcArr(appTerms.filter(x => x.type.startsWith(TYPE.TC_ROOT)))
        const _selectedRoot = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_ROOT);
        setSelectedType(_selectedRoot ? appTerms.find(x => x.type === _selectedRoot.data.type) : null);
    }, [appTerms]);

    useEffect(() => {
        app?.name && loadRoot(app.name);
    }, [loadRoot, app.name])

    useEffect(() => {
        if (rootTcArr.length > 0) {
            let _arr = [...rootTcArr];
            _arr = _arr.sort((a, b) => (a.alias || a.type) < (b.alias || b.type) ? -1 : 1);
            setExistTypes(_arr);
        } else {
            setExistTypes([]);
        }

    }, [rootTcArr]);

    const handlerAddTeamAndCondition = async (term) => {
        const _item = {
            name: app.name,
            type: TYPE.TEMP_TC_ROOT,
            code: term.code,
            isPublished: false,
            lastDoc: null,
            docs: [],
        };
        dispatch({ type: ApplicationConstant.SET_TERM, appName: app.name, item: _item });
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_ROOT });
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION });
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
    };

    const handlerClickTCRoot = async (term) => {
        const isSelected = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_ROOT)?.rowType === term.type;
        if (isSelected) {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_ROOT });
        } else {
            const prefix = TYPE.TC_VERSION + term.type.replace(TYPE.TC_ROOT, "");
            await GetAppData({ appName: app.name, prefix });
            dispatch({ type: ApplicationConstant.SET_TERM, appName: app.name, item: { type: TYPE.EXTRA_SELECT_ROOT, rowType: term.type, data: term } });
        }
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION });
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION_RELEASE });
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
    };


    return <>
        <h3 className="d-flex align-items-center justify-content-between">
            <div>Terms and Conditions</div>
            <AppSettingTermsAssetTermSelector onSelected={handlerAddTeamAndCondition} />
        </h3>
        <div>
            {existTypes.length > 0 && <ListGroup>
                {existTypes.map(x => <ListGroup.Item
                    key={x.type}
                    action={true}
                    onClick={() => handlerClickTCRoot(x)}
                    active={selectedType?.type === x.type}
                >
                    <div className="d-flex w-100 align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <div className={`small text-${selectedType?.type === x.type ? "black" : "muted"}`}>
                                    {APP_TERM_TYPES.find(y => y.code === x.code)?.label || x.code || x.type}
                                </div>
                                <div>{x.alias || x.type}</div>
                                {x.currentVersion && <div><i className={`fs-8 text-${selectedType?.type === x.type ? "black" : "muted"}`}>Current Version</i> {x.currentVersion}</div>}
                            </div>
                        </div>
                        <div className="flex-fill" />
                        <div>
                            {!x.isPublished && <div className="badge bg-light">
                                Draft
                            </div>}
                            {x.isPublished && <>
                                {x.docs.length < appTerms.filter(y => y.type.startsWith(x.type.replace(TYPE.TC_ROOT, TYPE.TC_VERSION))).length && <>
                                    <div className="badge bg-success">
                                        Published with Plan
                                    </div>
                                </>}
                                {x.docs.length === appTerms.filter(y => y.type.startsWith(x.type.replace(TYPE.TC_ROOT, TYPE.TC_VERSION))).length && <div className="badge bg-primary">
                                    Published
                                </div>}
                            </>}
                        </div>
                    </div>
                </ListGroup.Item>)}
            </ListGroup>}
            {existTypes.length === 0 && <div className="text-center py-3">
                <i>None Terms and Condition.</i>
            </div>}
        </div>
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsNavigatorTerms);
