import _ from 'lodash';
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { emailRegex, passwordRegex } from '../../libs/regexs';
import { AuthAction, UserAction } from '../../redux/actions';
import RAYButton from "../common/RAYButton";
import RAYSelect from '../common/RAYSelect';
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import { RAYToolTip } from '../common/RAYToolTip';
import PasswordGuide from './PasswordGuide';

const DataFields = ['name', 'groupName', 'is_account_manager', 'perms', 'appPerms'];
const RequireFields = ['name', 'email', 'password', 'groupName'];
const PremItems = [
    { value: 'application', label: 'Applications' },
    { value: 'sandbox', label: 'API Sandbox' }
];

const UserAttrs = ({ user, auth, Update, isCreate = false, callbackClose, Create, GetAll, GetMe, apps, isUpdate = false }) => {
    const [edit, setEdit] = useState(isCreate);
    const [editable, setEditable] = useState(false);
    const [data, setData] = useState({});
    const [submitReady, setSubmitReady] = useState(false);    
    const [rePass, setRePass] = useState('');
    const [validErrMsg, setValidErrMsg] = useState('');
    const [allApps, setAllApps] = useState([]);

    useEffect(() => {
        setAllApps(apps?.map(x => ({ value: x.name, label: (x.title || x.name) })) || []);
    }, [apps]);

    useEffect(() => {
        setEditable(auth?.is_account_manager || auth?.is_super_admin);
    }, [auth]);

    useEffect(() => {
        if (!isCreate) {
            if (user) {
                setData(_.pick(user, DataFields));
            } else {
                setData({});
            }
        }
    }, [user, isCreate]);

    const updateUser = useCallback(async () => {
        if (!submitReady) {
            setSubmitReady(true);
            return;
        }
        const payload = {
            sub: user.sub,
            data: { data }
        };
        const ret = await Update(payload);
        if (ret.status === 'success') {
            if (user.sub === auth.sub) {
                await GetMe();
            }
            setEdit(false);
            setSubmitReady(false);
            callbackClose && callbackClose()
        }
    }, [Update, data, user, submitReady, auth, GetMe]);


    const validCreate = useCallback(() => {
        const unfilledRequiredField = RequireFields.filter(key => {
            if (data[key]) {
                return !data[key].trim();
            }
            return true;
        });
        if (unfilledRequiredField.length > 0) {
            setValidErrMsg('Required Fields ' + unfilledRequiredField.join(', '));
            return false
        }
        if (!emailRegex.test(data.email)) {
            setValidErrMsg('wrong email format.');
            return false
        }
        if (!passwordRegex.test(data.password)) {
            setValidErrMsg('wrong password format.');
            return false
        }
        return true;
    }, [data]);

    const createUser = useCallback(async () => {
        setValidErrMsg('');
        const valid = validCreate();
        if (!valid) {
            setSubmitReady(false);
            return;
        }
        if (!submitReady) {
            setSubmitReady(true);
            return;
        }
        const payload = { data };
        const ret = await Create(payload);
        if (ret.status === 'success') {
            await GetAll();
            setSubmitReady(false);
            callbackClose && callbackClose()
        } else {
            console.log(ret);
        }
    }, [GetAll, Create, data, validCreate, submitReady, callbackClose]);

    const handlerClickSave = useCallback(async () => {
        if (isCreate) {
            await createUser()
        } else {
            await updateUser();
        }
    }, [updateUser, createUser, isCreate]);

    return <>
        {(isUpdate && user?.sub) && <div>
            <div className="mb-2">
                <div className="form-label">Password</div>
                <div className="">
                    <RAYToolTip tooltip={<PasswordGuide password={data?.password} />} placement='bottom' >
                        <div>
                            <RAYTextField
                                type='password'
                                name="password"
                                value={data.password || ''}
                                onChange={e => setData(prev => ({ ...prev, password: e.target.value }))}
                                size="sm"
                                placeholder="Password"
                            />
                        </div>
                    </RAYToolTip>
                </div>
            </div>
            <div className="row mb-2">
                <div className="form-label">Confirm password</div>
                <div className="">
                    <RAYToolTip tooltip={<PasswordGuide password={data?.password} />} placement='bottom' >
                        <div>
                            <RAYTextField
                                type='password'
                                name="rePass"
                                value={rePass || ''}
                                onChange={e => setRePass(e.target.value)}
                                size="sm"
                                placeholder="Password"
                            />
                        </div>
                    </RAYToolTip>
                </div>
            </div>
            {<div className="d-flex justify-content-end mt-2">
                <RAYButton label={submitReady ? "Update really?" : "Update"} disabled={rePass !== data.password} color="primary" onClick={handlerClickSave} />
            </div>}
            {validErrMsg && <div className='text-danger'>
                {validErrMsg}
            </div>}
        </div>}
        {(isCreate || user?.sub) && !isUpdate && <div>
            {editable && !isCreate && <div className="d-flex justify-content-end mb-2">
                <RAYToggle
                    value={edit}
                    size="sm"
                    onChange={() => setEdit(prev => !prev)}
                    trueLabel="Edit"
                    falseLabel="View"
                />
            </div>}
            <div className="row mb-2">
                <div className="col-4 col-form-label">Group</div>
                <div className="col-8">
                    <RAYTextField
                        readOnly={!edit}
                        name="groupName"
                        value={data.groupName || ''}
                        onChange={e => setData(prev => ({ ...prev, groupName: e.target.value }))}
                        size="sm"
                        placeholder="Group Name"
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-4 col-form-label">Name</div>
                <div className="col-8">
                    <RAYTextField
                        readOnly={!edit}
                        name="name"
                        value={data.name || ''}
                        onChange={e => setData(prev => ({ ...prev, name: e.target.value }))}
                        size="sm"
                        placeholder="Name"
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-4 col-form-label">Email(ID)</div>
                <div className="col-8">
                    {!isCreate && <RAYTextField
                        readOnly={true}
                        name="name"
                        value={user.email || ''}
                        size="sm"
                        placeholder="Email"
                    />}
                    {isCreate && <RAYTextField
                        name="name"
                        value={data.email || ''}
                        size="sm"
                        placeholder="Email"
                        onChange={e => setData(prev => ({ ...prev, email: e.target.value }))}
                    />}
                </div>
            </div>
            {isCreate && <div className="row mb-2">
                <div className="col-4 col-form-label">Password</div>
                <div className="col-8">
                    <RAYToolTip tooltip={<PasswordGuide password={data?.password} />}>
                        <div>
                            <RAYTextField
                                name="password"
                                value={data.password || ''}
                                onChange={e => setData(prev => ({ ...prev, password: e.target.value }))}
                                size="sm"
                                placeholder="Password"
                            />
                        </div>
                    </RAYToolTip>
                </div>
            </div>}
            <div className="row mb-2">
                <div className="col-4 col-form-labe">Account Manager</div>
                <div className="col-8">
                    <RAYToggle
                        name="is_account_manager"
                        disabled={!edit}
                        value={data?.is_account_manager}
                        onChange={() => setData(prev => ({ ...prev, is_account_manager: !prev.is_account_manager }))}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-4 col-form-labe">Permissions</div>
                <div className="col-8">
                    <RAYSelect
                        disabled={!edit}
                        items={PremItems}
                        value={PremItems.filter(x => (data.perms || []).includes(x.value)) || []}
                        multi={true}
                        callbackOnChange={arr => setData(prev => ({ ...prev, perms: arr.map(x => x.value) }))}
                    />
                </div>
            </div>
            {data?.perms?.includes('application') && <div className="row mb-2">
                <div className="col-4 col-form-labe">Application permissions</div>
                <div className="col-8">
                    <RAYSelect
                        disabled={!edit}
                        items={allApps}
                        value={allApps.filter(x => (data.appPerms || []).includes(x.value)) || []}
                        multi={true}
                        callbackOnChange={arr => setData(prev => ({ ...prev, appPerms: arr.map(x => x.value) }))}
                    />
                </div>
            </div>}
            {!isCreate && <div className="row mb-2">
                <div className="col-4">Super Admin</div>
                <div className="col-8">
                    {user?.is_super_admin && <>
                        <div className="badge bg-success">Super Admin</div>
                    </>}
                    {!user?.is_super_admin && <>
                        <div className="badge">-</div>
                    </>}
                </div>
            </div>}
            {!isCreate && <div className="row mb-2">
                <div className="col-4">Created</div>
                <div className="col-8">{moment(user?.created).format('LLL')}</div>
            </div>}
            {edit && <div className="d-flex justify-content-end mt-2">
                <RAYButton label={submitReady ? "Save Really?" : "Save"} color="primary" onClick={handlerClickSave} />
                <RAYButton label="Cancel" color="light" onClick={() => !isCreate ? setEdit(false) : (callbackClose && callbackClose())} />
            </div>}
            {validErrMsg && <div className='text-danger'>
                {validErrMsg}
            </div>}
        </div>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const apps = state.ApplicationReducer.allApps;
    return { auth, apps };
};

const mapDispatch = (dispatch) => ({
    Update: (payload) => dispatch(UserAction.Update(payload)),
    Create: (payload) => dispatch(UserAction.Create(payload)),
    GetAll: () => dispatch(UserAction.GetAll()),
    GetMe: () => dispatch(AuthAction.GetMe())
});

export default connect(mapState, mapDispatch)(UserAttrs);
