import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { applicationAxios } from '../../libs/axios';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import ModalHook from "../../hooks/ModalHook";
import { versionSort } from "../../libs/functions";
import { LANGUAGE } from "../../constants";
import { VersionTypes, UpdateNoticePeriod, UpdateNoticeActionTypes } from "../../constants/versiontype";
import { ApplicationVersionAction } from "../../redux/actions/ApplicationVersionAction";
import RAYButton from "../common/RAYButton";
import RAYFile from "../common/RAYFile";
import RAYFormRawInline from "../common/RAYFormRawInline";
//import Editor, { EDITOR_CONST } from "../tiptap/Editor";
//import { Editor } from '@tinymce/tinymce-react';
import TinyEditor from "../tinymce/TinyEditor";
import { useTranslation } from "react-i18next";
import { BsFillTrash3Fill } from "react-icons/bs";

const requiredFields = ['version'];
const RAYTeamsAppName = 'RayLink';

const VIEW_TYPE = {
    INFO: "info",
    NOTICE: "notice",
}

const ApplicationVersionDetailView = ({ item, create = false, callbackCancel, callbackUpdate, callbackEditChange, callbackUploadHooks, callbackProduction, apps, appVersions, GetApplicationVersions }) => {
    const [t] = useTranslation();
    const [edit, setEdit] = useState(create);
    const [viewType, setViewType] = useState(VIEW_TYPE.INFO);
    const [app, setApp] = useState(null);
    const [isProd, setIsProd] = useState(location.href.indexOf("rayteams.com") > -1);
    const [data, setData] = useState({});
    const [appVers, setAppVers] = useState([]);
    const [teamsVersions, setTeamsVersions] = useState([]);
    const [uploadHooks, setUploadHooks] = useState({});
    const { openModal, closeModal, changeFooter } = ModalHook();
    const navigator = useNavigate();
    const { appName } = useParams();

    useEffect(() => {
        callbackUploadHooks && callbackUploadHooks(uploadHooks);
    }, [callbackUploadHooks, uploadHooks]);

    useEffect(() => {
        appName && setApp(apps.find(x => x.name === appName));
    }, [appName, apps])

    useEffect(() => {
        if (app?.rayteams_apps) {
            GetApplicationVersions(RAYTeamsAppName);
        }
    }, [app?.rayteams_apps])

    useEffect(() => {
        if (app?.rayteams_apps && appVersions[appName]) {
            setAppVers(versionSort(appVersions[appName]).map(x => ({
                value: x.version,
                label: <div className="d-flex align-items-center" style={{ gap: 6 }}>
                    <div className="fw-bolder">{x.version}</div>
                    <div className="fs-8 d-flex align-items-center">
                        {x.is_active && <div className="badge bg-primary">Published</div>}
                        {!x.is_active && x.is_plan && <div className="badge bg-warning">Plan</div>}
                        {!x.is_active && !x.is_plan && <div className="badge bg-danger">Draft</div>}
                    </div>
                    {x.is_active && x.published && <div className="fs-8">{moment(x.published).format('YYYY.MM.DD HH:mm')}</div>}
                </div>,
                version: x.version,
                is_active: x.is_active,
                published: x.published,
                is_plan: x.is_plan,
            })));
        } else {
            setAppVers([]);
        }
        return () => {
            setAppVers([]);
        }
    }, [app?.rayteams_apps, appVersions[appName]])

    useEffect(() => {
        if (app?.rayteams_apps && appVersions[RAYTeamsAppName]) {
            setTeamsVersions(versionSort(appVersions[RAYTeamsAppName]).map(x => ({
                value: x.version,
                label: <div className="d-flex align-items-center" style={{ gap: 6 }}>
                    <div className="fw-bolder">{x.version}</div>
                    <div className="fs-8 d-flex align-items-center">
                        {x.is_active && <div className="badge bg-primary">Published</div>}
                        {!x.is_active && x.is_plan && <div className="badge bg-warning">Plan</div>}
                        {!x.is_active && !x.is_plan && <div className="badge bg-danger">Draft</div>}
                    </div>
                    {x.is_active && x.published && <div className="fs-8">{moment(x.published).format('YYYY.MM.DD HH:mm')}</div>}
                </div>,
                version: x.version,
                is_active: x.is_active,
                published: x.published,
                is_plan: x.is_plan,
            })));
        } else {
            setTeamsVersions([]);
        }
        return () => {
            setTeamsVersions([]);
        }
    }, [app?.rayteams_apps, appVersions[RAYTeamsAppName]])

    useEffect(() => {
        item ? setData({
            version: item.version,
            desc: item.desc,
            vtype: item.vtype,
            is_active: item.is_active || false,
            is_plan: item.is_plan || false,
            updatetype: item.updatetype || "optional",
            file: item.file_name,
            published: item.published,
            minTeamsVersion: item.minTeamsVersion,

            noticeperiod: item.noticeperiod,
            maxvernotice: item.maxvernotice,
            ignores: item.ignores,
            action: item.action,
            periodday: item.periodday,
            notices: item.notices,
            // manualDocs: item.manualDocs,
            viewapi: "https://api-rsp-application.raydevelop.com/app/" + appName + "/version_notice_views/" + item.version,
            actionapi: "https://api-rsp-application.raydevelop.com/app/" + appName + "/version_notice_clicks/" + item.version,
        }) : setData({
            updatetype: "optional",
            // viewapi : item?.version ? "https://api-rsp-application.raydevelop.com/app/" + appName + "/version_notice_views/" + item?.version : "",
            // actionapi : item?.version ? "https://api-rsp-application.raydevelop.com/app/" + appName + "/version_notice_clicks/" + item?.version : "",
        })
    }, [item]);

    useEffect(() => {
        callbackEditChange && callbackEditChange(edit)
    }, [callbackEditChange, edit]);

    const cancelHandler = useCallback(() => {
        if (edit && !create) {
            setEdit(false);
        } else {
            callbackCancel && callbackCancel();
        }
    }, [callbackCancel, edit, create]);

    const updateHandler = useCallback(async () => {
        const verify = requiredFields.every(x => !!data[x]);
        if (!verify) {
            toast('Require Field.', { type: 'error' });
            return;
        }
        const payload = { ...data };
        if (payload.is_active) {
            payload.is_plan = false;
        }
        if (!payload.is_plan && !payload.file) {
            toast('Require Field.', { type: 'error' });
            return;
        }

        const done = callbackUpdate && await callbackUpdate(data)
        if (done) {
            setEdit(false);
        }
    }, [callbackUpdate, data]);

    const deleteVersion = useCallback(async () => {
        changeFooter(<div>
            <RAYButton label="Cancel" color="light" disabled />
            <RAYButton label="Delete" color="danger" disabled />
        </div>)
        const data = {
            appName: item.name,
            data: {
                version: item.version
            }
        }
        const ret = await ApplicationVersionAction.DeleteApplicationVersion(data);
        if (ret) {
            toast('Success Delete Version', { type: 'success' });
            navigator(`/applications/${item.name}`);
            closeModal();
        } else {
            toast('Fail Delete Version', { type: 'error' });
            changeFooter(<div>
                <RAYButton label="Cancel" color="light" onClick={closeModal} />
                <RAYButton label="Delete" color="danger" onClick={deleteVersion} />
            </div>)
        }
    }, [changeFooter, closeModal, navigator, item]);

    const deleteHandler = useCallback(() => {
        if (!item.name || !item.version) {
            toast('Wrong Item', { type: 'error' });
            return;
        }
        openModal({
            title: <h3 className="text-danger">Delete {item.version} of {item.name}.</h3>,
            body: <div className="fs-4">
                <div>This operation cannot be recovered.</div>
                <div>Do you want to proceed?</div>
            </div>,
            footer: <div>
                <RAYButton label="Cancel" color="light" onClick={closeModal} />
                <RAYButton label="Delete" color="danger" onClick={deleteVersion} />
            </div>
        })
    }, [item, openModal, closeModal, deleteVersion]);

    const handlerSetUploadHooks = useCallback((obj) => {
        setUploadHooks(prev => ({ ...prev, [obj.name]: obj.upload }));
    }, []);

    const handlerToProduction = () => {
        callbackProduction && callbackProduction();
    };

    return <>
        <div className="version-content">
            <div className="card">
                <div className="card-header border-bottom">
                    <h5 className="mb-0">
                        {(edit && create) && "Add new version"}
                        {(edit && !create) && "Update version"}
                        {(!edit && !create) && "Version detail"}
                    </h5>
                </div>
                <form className="form pb-0" onSubmit={e => e.preventDefault()}>
                    <div className="px-4">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={"nav-link " + (viewType === VIEW_TYPE.INFO ? "active" : "")}
                                    onClick={() => setViewType(VIEW_TYPE.INFO)} aria-current="page" href="#">Version Info</a>
                            </li>
                            {item?.version && <>
                                <li className="nav-item">
                                    <a className={"nav-link " + (viewType === VIEW_TYPE.NOTICE ? "active" : "")}
                                        onClick={() => setViewType(VIEW_TYPE.NOTICE)} aria-current="page" href="#">Notification</a>
                                </li>
                            </>}
                        </ul>
                    </div>
                    {viewType === VIEW_TYPE.INFO && <div className="card-body">
                        <RAYFormRawInline title="Version Name" required={edit}>
                            {!edit && <span>{item?.version}</span>}
                            {edit && <input
                                type="text"
                                name="version"
                                className="form-control form-control-sm"
                                placeholder="Version Name"
                                value={data.version || ''}
                                onChange={e => setData(prev => ({ ...prev, version: e.target.value }))}
                            />}
                        </RAYFormRawInline>
                        <RAYFormRawInline title="Update Package" required={!data.is_plan}>
                            <RAYFile
                                edit={edit}
                                item={item}
                                keyPrefix={`rayrnd/applications/${item?.name || appName}/versions/${item?.version || data?.version}/files/`}
                                fieldName={'file'}
                                callbackOnChangeFile={(obj) => setData(prev => ({ ...prev, [obj.name]: obj.value }))}
                                callbackUploadHook={handlerSetUploadHooks}
                                css="w-100"
                            />
                        </RAYFormRawInline>
                        <RAYFormRawInline title="Full Package" required={!data.is_plan}>
                            <RAYFile
                                edit={edit}
                                item={item?.full_file}
                                keyPrefix={`rayrnd/applications/${item?.name || appName}/versions/${item?.version || data?.version}/files/full/`}
                                fieldName={'full_file'}
                                callbackOnChangeFile={(obj) => setData(prev => ({ ...prev, [obj.name]: obj.value }))}
                                callbackUploadHook={handlerSetUploadHooks}
                                css="w-100"
                            />
                        </RAYFormRawInline>

                        {/* <RAYFormRawInline
                            title="Manual files"
                            tooltip={t("This manual applies to downloading manuals for different versions. The latest manual for the app must be uploaded in the app settings.")}
                            required={false}
                        >
                            {(!Array.isArray(app?.manualLangs) || app?.manualLangs?.length === 0) && <>
                                <div className="text-danger">
                                    {t("This application does not have a set language. If no manual language is set, you cannot register a manual language.")}
                                    <br />
                                    {t("Please set the manual language to be used in the application settings.")}
                                </div>
                            </>}
                            {!(!Array.isArray(app?.manualLangs) || app?.manualLangs?.length === 0) && <>
                                <div className="d-flex flex-column gap-2 w-100">
                                    {app?.manualLangs?.map(l => {
                                        const manualItem = data[`manuals_${l}`] ?? (data?.manualDocs ? data?.manualDocs.find(x => x.manualLang === l) : null);
                                        const removeManual = () => {
                                            setData(prev => {
                                                const _prev = { ...prev };
                                                delete _prev[`manuals_${l}`];
                                                _prev.manualDocs = (_prev.manualDocs || []).filter(x => x.manualLang !== l);
                                                return _prev;
                                            });
                                            setUploadHooks(prev => {
                                                const _prev = { ...prev };
                                                delete _prev[`manuals_${l}`];
                                                return _prev;
                                            });
                                        };
                                        return <React.Fragment key={l}>
                                            <div className="d-flex align-items-center gap-2 w-100">
                                                <div className="flex-shrink-0">
                                                    {t("{{l}} Manual File", { l: l.toUpperCase() })}
                                                </div>
                                                <div className="flex-fill">
                                                    <RAYFile
                                                        edit={edit}
                                                        item={manualItem}
                                                        keyPrefix={`rayrnd/applications/${item?.name || appName}/versions/${item?.version || data?.version}/files/manuals/${l}/`}
                                                        fieldName={`manuals_${l}`} // Do not change this pattens.
                                                        callbackOnChangeFile={(obj) => setData(prev => ({ ...prev, [obj.name]: obj.value }))}
                                                        callbackUploadHook={handlerSetUploadHooks}
                                                        css="w-100"
                                                    />
                                                </div>
                                                {edit && manualItem && <>
                                                    <BsFillTrash3Fill
                                                        className="text-danger cursor-pointer"
                                                        onClick={removeManual}
                                                    />
                                                </>}
                                            </div>
                                        </React.Fragment>
                                    })}
                                </div>
                            </>}
                        </RAYFormRawInline> */}

                        <RAYFormRawInline title="Issue Date">
                            {(!edit || !create) && <span>{moment(item?.created).format('LLL')}</span>}
                            {(edit && create) && <>Inserts the creation date.</>}
                        </RAYFormRawInline>
                        <RAYFormRawInline title="Published Date">
                            {(!edit) && <span>{item?.published ? moment(item.published).format('LLL') : '-'}</span>}
                            {(edit || create) && <div>
                                <DatePicker
                                    selected={data.published ? new Date(data.published) : ''}
                                    showTimeSelect={true}
                                    onChange={(d) => setData(prev => ({ ...prev, published: new Date(d).getTime() }))}
                                    dateFormat="Y-MM-dd HH:mm"
                                />
                            </div>}
                        </RAYFormRawInline>
                        {app && app?.rayteams_apps && <>
                            <RAYFormRawInline title="Update Type">
                                {(!edit) && <span>
                                    {data?.updatetype && <div>
                                        {data.updatetype}
                                    </div>}
                                    {!data?.updatetype && <div>-</div>}
                                </span>}
                                {(edit || create) && <div className="btn-group" role="group" aria-label="Basic example">
                                    <button className={"btn btn-sm " + (data.updatetype === "optional" ? "btn-primary" : "btn-light")} onClick={(obj) => setData(prev => ({ ...prev, updatetype: "optional", maxvernotice: "" }))}>Optional</button>
                                    <button className={"btn btn-sm " + (data.updatetype === "force" ? "btn-primary" : "btn-light")} onClick={(obj) => setData(prev => ({ ...prev, updatetype: "force" }))}>Force</button>
                                    <button className={"btn btn-sm " + (data.updatetype === "ninja" ? "btn-primary" : "btn-light")} onClick={(obj) => setData(prev => ({ ...prev, updatetype: "ninja" }))}>Ninja</button>
                                </div>}
                            </RAYFormRawInline>
                        </>}
                        {app && app?.rayteams_apps && <>
                            <RAYFormRawInline title="Teams Minimal Version">
                                {(!edit) && <span>
                                    {data?.minTeamsVersion && <div>
                                        {data.minTeamsVersion}
                                    </div>}
                                    {!data?.minTeamsVersion && <div>-</div>}
                                </span>}
                                {(edit || create) && <>
                                    {teamsVersions && teamsVersions.length > 0 && <Select
                                        options={teamsVersions}
                                        value={teamsVersions.find(x => x.value === data.minTeamsVersion) || ''}
                                        onChange={(obj) => setData(prev => ({ ...prev, minTeamsVersion: obj.value }))}
                                        className="w-100"
                                        classNamePrefix="ray-select"
                                    />}
                                </>}
                            </RAYFormRawInline>
                        </>}
                        <RAYFormRawInline title="Version Type">
                            {(!edit) && <span>
                                {data?.vtype && <div>
                                    {VersionTypes.find(x => x.value === data.vtype)?.label}({VersionTypes.find(x => x.value === data.vtype)?.comment})
                                </div>}
                                {!data?.vtype && <div>-</div>}
                            </span>}
                            {(edit || create) && <div>
                                <Select
                                    options={VersionTypes}
                                    value={VersionTypes.find(x => x.value === data.vtype) || ''}
                                    onChange={(obj) => setData(prev => ({ ...prev, vtype: obj.value }))}
                                    className="w-200px"
                                    classNamePrefix="ray-select"
                                />
                                {data.vtype && <div className="fw-bolder mt-2 text-danger">{VersionTypes.find(x => x.value === data.vtype)?.comment}</div>}
                            </div>}
                        </RAYFormRawInline>
                        <RAYFormRawInline title="Description">
                            {!edit && <div dangerouslySetInnerHTML={{ __html: item?.desc || '-' }} />}
                            {edit && <div className="w-100">
                                <TinyEditor
                                    originContent={item?.desc || ""}
                                    callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                                    height={150}
                                    placeholder={"This Description is support only developers."}
                                //content={data.desc}
                                //callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                                //type={EDITOR_CONST.TYPE.HTML}
                                />
                            </div>}
                        </RAYFormRawInline>
                        <RAYFormRawInline title="Status">
                            {!edit && <span>{item?.is_active ? 'Published' : 'Draft'}</span>}
                            {edit && <label className="form-check form-switch form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" checked={data?.is_active ? 'checked' : ''} onChange={() => setData(prev => ({ ...prev, is_active: !prev.is_active }))} />
                                <span className="form-check-label fw-bold text-muted">{data?.is_active ? 'Published' : 'Draft'}</span>
                            </label>}
                        </RAYFormRawInline>
                        {!data?.is_active && <RAYFormRawInline title="Is Plan">
                            {!edit && <span>{item?.is_plan ? 'Plan version' : 'None'}</span>}
                            {edit && <label className="form-check form-switch form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" checked={data?.is_plan ? 'checked' : ''} onChange={() => setData(prev => ({ ...prev, is_plan: !prev.is_plan }))} />
                                <span className="form-check-label fw-bold text-muted">{data?.is_plan ? 'Plan version' : 'None'}</span>
                            </label>}
                        </RAYFormRawInline>}
                    </div>}
                    {viewType === VIEW_TYPE.NOTICE && <div className="card-body">
                        <h5>Type/Method</h5>
                        {app && app?.rayteams_apps && (data.updatetype === "force" || data.updatetype === "ninja") && <>
                            <RAYFormRawInline
                                title="Maximum Version(Upated Notice)"
                                hint={data.maxvernotice ? <div>{data.maxvernotice}버전 이하면, 업데이트 안내</div> : ""}>
                                {(!edit) && <span>
                                    {data?.maxvernotice && <div>
                                        v{data.maxvernotice}
                                        <small className="ms-1">
                                            (설치된 버전이 v{data.maxvernotice} 버전 이하인 경우, 업데이트 안내가 활성화됩니다.)
                                        </small>
                                    </div>}
                                    {!data?.maxvernotice && <div>-</div>}
                                </span>}
                                {(edit || create) && <>
                                    {appVers && appVers.length > 0 && <><Select
                                        options={appVers}
                                        value={appVers.find(x => x.value === data.maxvernotice) || ''}
                                        onChange={(obj) => setData(prev => ({ ...prev, maxvernotice: obj.value }))}
                                        className="w-100"
                                        classNamePrefix="ray-select"
                                    /></>}
                                </>}
                            </RAYFormRawInline>
                        </>}
                        {app && app?.rayteams_apps && <>
                            <RAYFormRawInline title="Upated Notice Period">
                                {(!edit) && <span>
                                    {data?.noticeperiod && <div>
                                        {UpdateNoticePeriod.find(x => x.value === data.noticeperiod).label}
                                        <small className="ms-1">
                                            ({UpdateNoticePeriod.find(x => x.value === data.noticeperiod).comment})
                                        </small>
                                    </div>}
                                    {!data?.noticeperiod && <div>-</div>}
                                </span>}
                                {(edit || create) && <>
                                    {<Select
                                        options={UpdateNoticePeriod}
                                        value={UpdateNoticePeriod.find(x => x.value === data.noticeperiod) || ''}
                                        onChange={(obj) => setData(prev => ({ ...prev, noticeperiod: obj.value }))}
                                        className="w-100"
                                        classNamePrefix="ray-select"
                                    />}
                                </>}
                            </RAYFormRawInline>
                            {data?.noticeperiod === "n/d" && <RAYFormRawInline title="N회/M일">
                                {(!edit) && <span>
                                    {data?.periodday && <div>
                                        {data.periodday}
                                    </div>}
                                    {!data?.periodday && <div>-</div>}
                                </span>}
                                {(edit || create) && <input
                                    type="text"
                                    name="version"
                                    className="form-control form-control-sm"
                                    placeholder="3/1"
                                    value={data.periodday || ''}
                                    onChange={e => setData(prev => ({ ...prev, periodday: e.target.value }))}
                                />}
                            </RAYFormRawInline>}
                            {<RAYFormRawInline title="Ignores">
                                {(!edit) && <span>
                                    {data?.ignores && <div>
                                        {data.ignores.join(", ")}
                                        <small className="ms-1">
                                            (설정된 Version에 대해서는 업데이트 알림을 하지 않습니다.)
                                        </small>
                                    </div>}
                                    {!data?.ignores && <div>-</div>}
                                </span>}
                                {(edit || create) && <input
                                    type="text"
                                    name="ignores"
                                    className="form-control form-control-sm"
                                    placeholder="1.1.X.X,1.3.2.1(콤마로 구분)"
                                    value={data.ignores || ''}
                                    onChange={e => setData(prev => ({ ...prev, ignores: e.target.value.split(",").map(x => x.trim()) }))}
                                />}
                            </RAYFormRawInline>}
                            {<RAYFormRawInline title="Action Type">
                                {(!edit) && <span>
                                    {data?.action?.type && <div>
                                        {UpdateNoticeActionTypes.find(x => x.value === data.action?.type).label}
                                        <small className="ms-1">
                                            ({UpdateNoticeActionTypes.find(x => x.value === data.action?.type).comment})
                                        </small>
                                    </div>}
                                    {!data?.action?.type && <div>-</div>}
                                </span>}
                                {(edit || create) && <>
                                    <Select
                                        options={UpdateNoticeActionTypes}
                                        value={UpdateNoticeActionTypes.find(x => x.value === data.action?.type) || ''}
                                        onChange={(obj) => setData(prev => ({
                                            ...prev,
                                            action: prev.action ? { ...prev.action, type: obj.value } : { type: obj.value }
                                        }))}
                                        size="sm"
                                        className="w-100"
                                        classNamePrefix="ray-select"
                                    />
                                </>}
                            </RAYFormRawInline>}
                            {<RAYFormRawInline title="Action URL">
                                {(!edit) && <span>
                                    {data?.action?.url && <div>
                                        {data.action?.url}
                                        {data.action?.url.indexOf("https://") > -1 && <small className="ms-1">
                                            [<a href={data.action?.url} target="_blank">
                                                Open
                                            </a>]
                                        </small>}
                                    </div>}
                                    {!data?.action?.url && <div>-</div>}
                                </span>}
                                {(edit || create) && <input
                                    type="text"
                                    name="actionurl"
                                    className="form-control form-control-sm"
                                    placeholder="https://~~~"
                                    value={data.action?.url || ''}
                                    onChange={e => setData(prev => ({
                                        ...prev,
                                        action: prev.action ? { ...prev.action, url: e.target.value } : { url: e.target.value }
                                    }))}
                                />}
                            </RAYFormRawInline>}
                            <hr className="my-4" />
                            <h5>Message</h5>
                            {apps.find(x => x.name === appName).langs?.map(x => {
                                const notice = data?.notices?.find(n => n.lang === x);
                                return <>
                                    <div className="mt-4"><b>{LANGUAGE.find(l => l.code === x).label}</b></div>
                                    <hr className="my-1" />
                                    <RAYFormRawInline title="Title">
                                        {(!edit) && <span>
                                            {notice?.title && <div>
                                                {notice?.title}
                                            </div>}
                                            {!notice?.title && <div>-</div>}
                                        </span>}
                                        {(edit || create) && <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Notice Title"
                                            value={notice?.title || ''}
                                            onChange={e => setData(prev => ({
                                                ...prev,
                                                notices: data.notices?.find(n => n.lang === x) ?
                                                    data.notices?.map(j => j.lang === x ? { ...j, title: e.target.value } : j) :
                                                    [...(data.notices || []), { lang: x, title: e.target.value }]
                                            }))}
                                        />}
                                    </RAYFormRawInline>
                                    <RAYFormRawInline title="Message">
                                        {(!edit) && <span>
                                            {notice?.msg && <div>
                                                {notice?.msg}
                                            </div>}
                                            {!notice?.msg && <div>-</div>}
                                        </span>}
                                        {(edit || create) && <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Notice Title"
                                            value={notice?.msg || ''}
                                            onChange={e => setData(prev => ({
                                                ...prev,
                                                notices: data.notices?.find(n => n.lang === x) ?
                                                    data.notices?.map(j => j.lang === x ? { ...j, msg: e.target.value } : j) :
                                                    [...(data.notices || []), { lang: x, msg: e.target.value }]
                                            }))}
                                        />}
                                    </RAYFormRawInline>
                                </>
                            })}
                        </>}
                    </div>}
                    <div className="card-footer d-flex gap-1 justify-content-end border-top pt-4">
                        {edit && <button type="reset" className="btn btn-light btn-active-light-primary" onClick={cancelHandler}>Cancel</button>}
                        {edit && <button type="submit" className="btn btn-primary" onClick={updateHandler}>Update</button>}
                        {!edit && !isProd && <button type="submit" className="btn btn-success" onClick={handlerToProduction}>To Production</button>}
                        {!edit && <button type="reset" className="btn btn-danger" onClick={deleteHandler}>Delete</button>}
                        {!edit && <button type="submit" className="btn btn-primary" onClick={() => setEdit(true)}>Edit</button>}
                    </div>
                </form>
            </div>
        </div>
    </>
};


const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const apps = state.ApplicationReducer.apps;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    return { isLogin, apps, appVersions };
};

const mapDispatch = (dispatch) => ({
    GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName))
})

export default connect(mapState, mapDispatch)(ApplicationVersionDetailView);
