import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LANGUAGE } from "../../constants";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYButton from "../common/RAYButton";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";
import TinyEditor from "../tinymce/TinyEditor";
import LangArea from "./LangArea";
import ManualFeatureArea from "./ManualFeatureArea";


const AppTemplateFormManualDev = ({ app, auth, lang, setLang, version, templates, publishable = false, UpdateAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [manualHeader, setManualHeader] = useState([]);
    const [manual, setManual] = useState([]);
    const [features, setFeatures] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        setManualHeader(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:manual_dev:header`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        setManual(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:manual_dev:${lang}`) || {});
    }, [templates, version, app.name, lang]);

    useEffect(() => {
        if (manual?.type && header.version === version && lang) {
            setData({ ...manual });
        } else {
            setData({});
        }
    }, [manual, header?.version, version, app.name, lang]);

    useEffect(() => {
        if (manualHeader?.features) {
            setFeatures([...manualHeader.features].sort((a, b) => a.order < b.order ? -1 : 1));
        } else {
            setFeatures([]);
        }
    }, [manualHeader]);

    const setFeaturesData = (code, key, value) => {
        setData(prev => ({
            ...prev,
            features: {
                ...prev.features,
                [code]: {
                    ...(prev?.features || {})[code],
                    [key]: value,
                }
            }
        }));
    };

    const handlerClick = async () => {
        const item = {
            ...data,
            name: app.name,
            type: `t:body:${version}:manual_dev:${lang}`,
        };
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const handlerClickRemoveFeature = async (code) => {
        const item = {
            name: app.name,
            type: manualHeader.type,
            features: manualHeader.features.filter(x => x.code !== code),
        };
        await UpdateAppTemplate({ appName: app.name, item });
    };

    return <div className="d-flex gap-2">
        <div style={{ maxWidth: 200, minWidth: 200 }} className="d-flex flex-column gap-4">
            <LangArea langs={header.langs || []} css="w-100" lang={lang} setLang={setLang} />
            <ManualFeatureArea app={app} version={version} manualHeader={manualHeader} manualType="manual_dev" />
        </div>
        <div className="flex-fill">
            {(!lang) && <div className="text-muted d-flex flex-column gap-4">
                {!lang && <i>Please select language.</i>}
            </div>}
            {lang && <div key={`${lang}}`}>
                <div className="h5">
                    {LANGUAGE.find(l => l.code === lang)?.label || lang}
                </div>
                <RAYFormRaw title="Introduction" labelCol={2}>
                    <div className="w-100">
                        <RAYTextField
                            value={data.intro || ""}
                            onChange={(e) => setData(prev => ({ ...prev, intro: e.target.value }))}
                            placeholder="Introduction"
                        />
                        <div className="text-muted pb-3">
                            {!data.intro && <i>Please enter a Introduction.</i>}
                            {data.intro && <i>Introduction: {data.intro}</i>}
                        </div>
                    </div>
                </RAYFormRaw>
                <RAYFormRaw title="Installation" labelCol={2}>
                    <div className="w-100">
                        <TinyEditor
                            originContent={manual?.installation}
                            callbackOnChange={(content) => setData(prev => ({ ...prev, installation: content }))}
                            height={400}
                        />
                    </div>
                </RAYFormRaw>
                <RAYFormRaw title="Features" labelCol={2}>
                    {features.length === 0 && <div className="text-muted">
                        <i>No features.</i>
                    </div>}
                    {features.length > 0 && <div className="my-4 w-100">
                        {features.map((x) => <div key={x.code}>
                            <div className="d-flex align-items-center gap-4">
                                <h6>{x.label} Feature</h6>
                                <RAYButton 
                                    label="Remove Feature"
                                    color="danger"
                                    onClick={() => handlerClickRemoveFeature(x.code)}
                                />
                            </div>
                            
                            <RAYFormRaw title="Slug" labelCol={2}>
                                <div className="w-100">
                                    <RAYTextField
                                        value={((data?.features || {})[x.code]?.intro) || ""}
                                        onChange={(e) => setFeaturesData(x.code, "intro", e.target.value.replace(/[^a-zA-Z-]/g, ""))}
                                        placeholder="Feature Slug"
                                    />
                                </div>
                            </RAYFormRaw>
                            <RAYFormRaw title="Title" labelCol={2}>
                                <div className="w-100">
                                    <RAYTextField
                                        value={((data?.features || {})[x.code]?.title) || ""}
                                        onChange={(e) => setFeaturesData(x.code, "title", e.target.value)}
                                        placeholder="Feature Title"
                                    />
                                </div>
                            </RAYFormRaw>
                            <RAYFormRaw title="Content" labelCol={2}>
                                <div className="w-100">
                                    <TinyEditor
                                        key={x.code}
                                        originContent={(manual?.features || {})[x.code]?.content}
                                        callbackOnChange={(content) => setFeaturesData(x.code, "content", content)}
                                        height={400}
                                        css="mw-100"
                                    />
                                </div>
                            </RAYFormRaw>
                        </div>)}
                    </div>}
                </RAYFormRaw>
                <RAYFormRaw labelCol={2} css="mt-3">
                    <RAYButton
                        label="Save"
                        color="primary"
                        onClick={handlerClick}
                    />
                </RAYFormRaw>
            </div>}
        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormManualDev);
