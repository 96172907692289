import { adminAxios } from '../../libs/axios';
import { UserConstant } from '../reducers';

const GetAll = () => async (dispatch) => {
    const ret = await adminAxios.get('user');
    if (ret.data.status === 'success') {
        dispatch({ type: UserConstant.SET_USERS, items: ret.data.data });
    }
    return ret.data;
};

const Get = (sub) => async (dispatch) => {
    const ret = await adminAxios.get(`user/${sub}`);
    if (ret.data.status === 'success') {
        dispatch({ type: UserConstant.SET_USER, items: ret.data.data });
    }
    return ret.data;
};

const Update = (payload) => async (dispatch) => {
    const ret = await adminAxios.put(`user/${payload.sub}`, payload.data);
    if (ret.data.status === 'success') {
        await dispatch(Get(payload.sub));
        // dispatch({ type: UserConstant.SET_USER, items: ret.data.data });
    }
    return ret.data;
};

const Create = (payload) => async (dispatch) => {
    const ret = await adminAxios.post('user', payload);
    return ret.data;
};

const Remove = (sub) => async (dispatch) => {
    const ret = await adminAxios.delete(`user/${sub}`);
    if (ret.data.status === 'success') {
        dispatch({ type: UserConstant.REMOVE_USER, sub });
    }
    return ret.data;
};

const GetPipelineRecentCommitIds = (payload) => async (dispatch) => {
    const ret = await adminAxios.post('apidoc/getpipilinerecentcommitids', payload);
    if (ret.data.status === 'success') {
        dispatch({ type: UserConstant.SET_PIPE_RECENT_COMMIT_IDS, item: ret.data.data });
    }
    return ret.data;
};

const CreateStack = (payload) => async (dispatch) => {
    const ret = await adminAxios.post('apidoc/createstack', payload);
    return ret.data;
}

const UpdateStack = (payload) => async (dispatch) => {
    const ret = await adminAxios.post('apidoc/updatestack', payload);
    return ret.data;
}

const DeleteStack = (payload) => async (dispatch) => {
    const ret = await adminAxios.post('apidoc/deletestack', payload);
    return ret.data;
}



export const UserAction = {
    GetAll,
    Get,
    Update,
    Create,
    Remove,
    GetPipelineRecentCommitIds,
    CreateStack,
    UpdateStack,
    DeleteStack
};
