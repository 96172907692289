import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { versionSort } from "../../libs/functions";
import RAYFile from "../common/RAYFile";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import { Badge } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import RAYSelect from "../common/RAYSelect";

const RAYTeamsAppName = 'RayLink';

const AppSettingTeams = ({ edit = false, create = false, item, data, setData, setUploadHooks }) => {
  const [teamsVersions, setTeamsVersions] = useState([]);
  const [tempTagStr, setTempTagStr] = useState("");
  const appVersions = useSelector(state => state.ApplicationVersionReducer.appVersions);
  const allApps = useSelector(state => state.ApplicationReducer.allApps);
  const [apps, setApps] = useState([]);
  useEffect(() => {
    const _apps = allApps.filter(x => {
      if (!x.is_active) {
        return false;
      }
      if (!x.rayteams_apps) {
        return false;
      }
      return true;
    });
    setApps(_apps.map(x => ({ value: x.name, label: (x.title || x.name) })));
  }, [allApps]);

  const handlerSetUploadHooks = useCallback((obj) => setUploadHooks(prev => ({
    ...prev, [obj.name]: {
      name: obj.name,
      fieldName: obj.fieldName,
      inArr: obj.inArr,
      inArrId: obj.inArrId,
      fnc: obj.upload
    }
  })), [setUploadHooks]);

  useEffect(() => {
    if (item?.rayteams_apps && appVersions[RAYTeamsAppName]) {
      setTeamsVersions(versionSort(appVersions[RAYTeamsAppName]).map(x => ({
        value: x.version,
        label: <div className="d-flex align-items-center" style={{ gap: 6 }}>
          <div className="fw-bolder">{x.version}</div>
          <div className="fs-8 d-flex align-items-center">
            {x.is_active && <div className="badge bg-primary">Published</div>}
            {!x.is_active && x.is_plan && <div className="badge bg-warning">Plan</div>}
            {!x.is_active && !x.is_plan && <div className="badge bg-danger">Draft</div>}
          </div>
          {x.is_active && x.published && <div className="fs-8">{moment(x.published).format('YYYY.MM.DD HH:mm')}</div>}
        </div>,
        version: x.version,
        is_active: x.is_active,
        published: x.published,
        is_plan: x.is_plan,
      })));
    } else {
      setTeamsVersions([]);
    }
    return () => {
      setTeamsVersions([]);
    }
  }, [item?.rayteams_apps, appVersions[RAYTeamsAppName]]);

  const checkBackslash = (val) => {
    if (val?.target?.value) {
      val = val.target.value;
    }
    if (val !== "" && (!val || typeof val !== "string")) {
      toast("check only string.", { type: "error" });
    }
    if (val.includes("\\")) {
      val = val.replace(/\\/g, "/");
      toast("A backslash is changed slash.", { type: "info" });
    }
    return val
  };

  const callbackUploadHookPopular = useCallback(async (obj) => {
    if (!!obj?.upload) {
      try {
        const ret = await obj.upload();
        if (ret?.data) {
          setData(prev => ({
            ...prev,
            popularImg: ret.data,
          }));
        }
      } catch (error) {
        //
      }
    }
  }, []);



  const addTagToData = useCallback(() => {
    const _tag = tempTagStr;
    setTempTagStr("");
    setData(prev => {
      const _tmp = { ...prev };
      if (!_tmp.tags) {
        _tmp.tags = [];
      }
      if (!_tmp.tags.find(x => x === _tag)) {
        _tmp.tags.push(_tag);
      }
      return _tmp;
    });
  }, [tempTagStr]);

  const removeTagInData = useCallback((str) => {
    setData(prev => {
      const _tmp = { ...prev };
      if (!_tmp.tags) {
        _tmp.tags = [];
      }
      _tmp.tags = _tmp.tags.filter(x => x !== str);
      return _tmp;
    });
  }, []);

  const callbackOnChangeRelatedApps = useCallback((arr) => {
    setData(prev => {
      const _tmp = { ...prev };
      if (!_tmp.relatedApps) {
        _tmp.relatedApps = [];
      }
      _tmp.relatedApps = arr ? arr.map(x => x.value) : [];
      return _tmp;
    });
  }, []);

  // useEffect(() => {
  //   console.log("data", data);
  //   console.log(apps)
  // }, [data])

  return <>
    <div>
      <RAYFormRawInline title="RAYTeams Apps" labelCss="" labelCol={3}>
        {!edit && <div>{item?.rayteams_apps ? 'Include' : 'Exclude'}</div>}
        {edit && <RAYToggle
          name="rayteams_apps"
          value={data?.rayteams_apps}
          trueLabel="Include"
          falseLabel="Exclude"
          onChange={() => setData(prev => ({ ...prev, rayteams_apps: !prev.rayteams_apps }))}
        />}
      </RAYFormRawInline>
      {/*!data?.rayteams_apps && <div className="text-muted">
        The settings below are valid for RAYTeams App only.
      </div>*/}
      {data?.rayteams_apps && <>
        {/*<div className="text-info mb-12">
          The settings below apply to the RAYTeams App Store.
        </div>*/}
        <RAYFormRawInline title="Teams Minimal Version" labelCss="" labelCol={3}>
          <div className="w-100">
            {(!edit) && <div>
              {data?.minTeamsVersion && <div>
                {data.minTeamsVersion}
              </div>}
              {!data?.minTeamsVersion && <div>-</div>}
            </div>}
            {(edit || create) && <>
              {teamsVersions && teamsVersions.length > 0 && <Select
                options={teamsVersions}
                value={teamsVersions.find(x => x.value === data.minTeamsVersion) || ''}
                onChange={(obj) => setData(prev => ({ ...prev, minTeamsVersion: obj?.value }))}
                className="w-100"
                classNamePrefix="ray-select"
                isClearable={true}
              />}
            </>}
            {(edit || create) && <div className="px-2 small text-muted">
              If the RAYTeams version is lower than the above value, this app will be hidden.
            </div>}
          </div>
        </RAYFormRawInline>
        <RAYFormRawInline title="Tags" labelCss="" labelCol={3}>
          {!edit && <>
            {item?.tags?.length > 0 && <>
              <div className="d-flex align-items-center gap-2">
                {item.tags?.map(tag => <React.Fragment key={tag}>
                  <Badge bg="primary">#{tag}</Badge>
                </React.Fragment>)}
              </div>
            </>}
            {(!item?.tags || item?.tabs?.length === 0) && <>
              <i>None Tags</i>
            </>}
          </>}
          {edit && <div className="d-flex flex-column gap-1">
            <div>
              <div className="d-flex align-items-center gap-2">
                {data.tags?.map(tag => <React.Fragment key={tag}>
                  <Badge bg="primary" className="d-flex align-items-center gap-1">
                    <div>
                      #{tag}
                    </div>
                    <GrClose className="text-muted cursor-pointer" onClick={() => removeTagInData(tag)} />
                  </Badge>
                </React.Fragment>)}
              </div>
            </div>
            <div className="d-flex">
              <RAYTextField
                name="tagInput"
                placeholder="Type tag and enter"
                value={tempTagStr}
                onChange={(event) => setTempTagStr(checkBackslash((event.target.value || '')))}
                enterSubmit={addTagToData}
              />
            </div>
          </div>}
        </RAYFormRawInline>
        <RAYFormRawInline title="App Icon" labelCss="" labelCol={3}>
          <RAYFile
            item={item?.app_icon || null}
            edit={edit}
            fieldName={'app_icon'}
            keyPrefix={`rayrnd/applications/${item?.name}/icons/rayteams/`}
            callbackOnChangeFile={(obj) => setData(prev => ({ ...prev, [obj.name]: obj.value }))}
            callbackUploadHook={handlerSetUploadHooks}
          />
          {(!edit && item?.app_icon?.direct_link) && <div className="ms-4">
            <img src={item.app_icon.direct_link} width="36" height="36" style={{ objectFit: 'contain' }} alt="icon" />
          </div>}
        </RAYFormRawInline>
        {!data?.inAppFeature && <>
          <RAYFormRawInline title="Is Copy(.zip) Package" labelCss="" labelCol={3}>
            {!edit && <div>{item?.is_copy ? 'Copy Files' : 'Install Package'}</div>}
            {edit && <RAYToggle
              name="is_copy"
              value={data?.is_copy}
              trueLabel="Copy Files"
              falseLabel="Install Package"
              onChange={() => setData(prev => ({ ...prev, is_copy: !prev.is_copy }))}
            />}
          </RAYFormRawInline>
          {(!data?.is_copy) && <RAYFormRawInline title="Install Argument" labelCss="" labelCol={3}>
            {(!edit) && <div>{item?.install_argument || <span>-</span>}</div>}
            {edit && <RAYTextField
              name="install_argument"
              value={data.install_argument}
              onChange={(event) => setData(prev => ({ ...prev, install_argument: checkBackslash((event.target.value || '')) }))}
            />}
          </RAYFormRawInline>}
        </>}

        <RAYFormRawInline title="Is Beta Apps" labelCss="" labelCol={3}>
          {!edit && <div>{item?.is_beta ? 'Beta' : 'Prod'}</div>}
          {edit && <RAYToggle
            name="is_beta"
            value={data?.is_beta}
            trueLabel="Beta"
            falseLabel="Production"
            onChange={() => setData(prev => ({ ...prev, is_beta: !prev.is_beta }))}
          />}
        </RAYFormRawInline>
        {!data?.inAppFeature && <>
          <RAYFormRawInline title="Run Exe File Name" labelCss="" labelCol={3}>
            {(!edit) && <div>{item?.exe_file_name || '-'}</div>}
            {edit && <RAYTextField
              name="exe_file_name"
              value={data.exe_file_name}
              onChange={(event) => setData(prev => ({ ...prev, exe_file_name: checkBackslash((event.target.value || '')) }))}
            />}
          </RAYFormRawInline>
          {(!data?.is_copy) && <RAYFormRawInline title="Fixed Exe File Folder" labelCss="" labelCol={3}>
            {(!edit) && <div>{item?.exeFolder || <span>-</span>}</div>}
            {edit && <div className="w-100">
              <RAYTextField
                name="exeFolder"
                value={data.exeFolder}
                onChange={(event) => setData(prev => ({ ...prev, exeFolder: checkBackslash((event.target.value || '')) }))}
              />
              <small>{data?.exeFolder && data.exe_file_name ? data.exeFolder + "/" + data.exe_file_name : ""}</small>
            </div>}
          </RAYFormRawInline>}
          {(!data?.is_copy) && <>
            <RAYFormRawInline title="Uninstall Exe File Name" labelCss="" labelCol={3}>
              {(!edit) && <div>{item?.uninstall_file_name || "-"}</div>}
              {edit && <RAYTextField
                name="uninstall_file_name"
                value={data.uninstall_file_name}
                onChange={(event) => setData(prev => ({ ...prev, uninstall_file_name: checkBackslash((event.target.value || '')) }))}
              />}
            </RAYFormRawInline>
            <RAYFormRawInline title="Uninstall Argument" labelCss="" labelCol={3}>
              {(!edit) && <div>{item?.uninstall_argument || <span>-</span>}</div>}
              {edit && <RAYTextField
                name="uninstall_file_name"
                value={data.uninstall_argument || ""}
                onChange={(event) => setData(prev => ({ ...prev, uninstall_argument: checkBackslash((event.target.value || '')) }))}
              />}
            </RAYFormRawInline>
          </>}
          <RAYFormRawInline title="Built In" labelCss="" labelCol={3}>
            {!edit && <div>{item?.built_in ? 'Built In' : 'None'}</div>}
            {edit && <RAYToggle
              name="built_in"
              value={data?.built_in}
              trueLabel="Built In"
              falseLabel="None"
              onChange={() => setData(prev => ({ ...prev, built_in: !prev.built_in }))}
            />}
          </RAYFormRawInline>
          <RAYFormRawInline title="Auto Run" labelCss="" labelCol={3}>
            {!edit && <div>{item?.auto_run ? 'Auto Run' : 'Manual Run'}</div>}
            {edit && <RAYToggle
              name="auto_run"
              value={data?.auto_run}
              trueLabel="Auto Run"
              falseLabel="Manual Run"
              onChange={() => setData(prev => ({ ...prev, auto_run: !prev.auto_run }))}
            />}
          </RAYFormRawInline>
        </>}
        <RAYFormRawInline title="Popular App" labelCss="" labelCol={3}>
          {!edit && <div>{item?.isPopular ? 'Popular App' : 'Normal App'}</div>}
          {edit && <RAYToggle
            name="isPopular"
            value={data?.isPopular}
            trueLabel="Popular App"
            falseLabel="Normal App"
            onChange={() => setData(prev => ({ ...prev, isPopular: !prev.isPopular }))}
          />}
        </RAYFormRawInline>
        {data?.isPopular && <RAYFormRawInline title="Popular Placeholder" labelCss="" labelCol={3}>
          {!edit && <div>
            {item?.popularDesc || "-"}
          </div>}
          {edit && <>
            <RAYTextField
              name="popularDesc"
              value={data.popularDesc}
              onChange={(event) => setData(prev => ({ ...prev, popularDesc: checkBackslash((event.target.value || '')) }))}
            />
          </>}
        </RAYFormRawInline>}
        {data?.isPopular && <RAYFormRawInline title="Popular App Img" labelCss="" labelCol={3}>
          {!edit && <>
            {item?.popularImg?.direct_link && <div className="ms-4">
              <img src={item.popularImg.direct_link} width="260" height="130" style={{ objectFit: 'cover' }} alt="popularImg" />
            </div>}
            {!item?.popularImg?.direct_link && <>
              <i>No image</i>
            </>}
          </>}
          {edit && <>
            <div>
              <div className="d-flex align-items-center gap-3">
                <div>
                  <div>
                    Before
                  </div>
                  <div>
                    {item?.popularImg?.direct_link && <>
                      <img src={item.popularImg.direct_link} width="260" height="130" style={{ objectFit: 'cover' }} alt="popularImg Before" />
                    </>}
                    {!item?.popularImg?.direct_link && <>
                      <i>No image</i>
                    </>}
                  </div>
                </div>
                <div>
                  <div>After</div>
                  <div>
                    {data?.popularImg?.direct_link && <>
                      <img src={data.popularImg.direct_link} width="260" height="130" style={{ objectFit: 'cover' }} alt="popularImg After" />
                    </>}
                    {!data?.popularImg?.direct_link && <>
                      <i>No image</i>
                    </>}
                  </div>
                </div>
              </div>
              <RAYFile
                item={data?.popularImg || null}
                edit={edit}
                fieldName={'popularApp'}
                keyPrefix={`rayrnd/applications/${item?.name}/images/popular/`}
                callbackUploadHook={callbackUploadHookPopular}
              />
              <div>260 x 130 Image</div>
            </div>
          </>}
        </RAYFormRawInline>}
        <RAYFormRawInline title="Related Apps" labelCss="" labelCol={3}>
          {!edit && <>
            <div className="d-flex align-items-center gap-1">
              {item?.relatedApps?.length > 0 && <>
                {item?.relatedApps?.map(appName => <React.Fragment key={appName}>
                  <div className="rounded px-3 py-2 bg-secondary text-white">
                    {apps.find(x => x.value === appName)?.label || appName}
                  </div>
                </React.Fragment>)}
              </>}
            </div>
          </>}
          {edit && <>
            <div>
              <RAYSelect
                items={apps}
                value={apps.filter(x => (data?.relatedApps || []).includes(x.value))}
                multi={true}
                callbackOnChange={callbackOnChangeRelatedApps}
                style={{ minWidth: 400 }}
              />
            </div>
          </>}
        </RAYFormRawInline>
      </>}
    </div>
  </>
}

export default AppSettingTeams;
