// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
//import './rsp.scss';
import AppRouter from './router/AppRouter';
import { Store } from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "./config/i18n";
import { AppConstant } from './redux/reducers';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <AppRouter />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// const config = {
//   onUpdate: (registration) => { Store.dispatch({ type: AppConstant.SW_UPDATE, registration: registration }) },
//   onSuccess: (registration) => { Store.dispatch({ type: AppConstant.SW_SUCCESS, registration: registration }) },
// };
// serviceWorkerRegistration.register(config);

try{
  serviceWorkerRegistration.unregister();
}catch(e){}
