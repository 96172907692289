import axios from 'axios';
import { AppConstant } from '../redux/reducers';
import { Store } from '../store';
import { AppConfig } from './config';

export const adminAxios = axios.create({
    baseURL: AppConfig.REACT_APP_ADMIN_USER_API
});
export const adminAxiosProd = axios.create({
    baseURL: AppConfig.REACT_APP_ADMIN_USER_API_PROD
});
adminAxios.interceptors.request.use(function (config) {
    config.headers['token'] = localStorage.getItem('token');
    config.headers['Authorization'] = localStorage.getItem('authToken');
    Store.dispatch({ type: AppConstant.ADD_LOAD_STACK });
    return config;
}, function (error) {
    return Promise.reject(error);
});
adminAxios.interceptors.response.use(function (response) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return response;
}, function (error) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return Promise.reject(error);
});

export const applicationAxiosFile = axios.create({
    baseURL: AppConfig.REACT_APP_APPLICATION_API_PROD,
});
applicationAxiosFile.interceptors.request.use(function (config) {
    config.headers['token'] = localStorage.getItem('token');
    Store.dispatch({ type: AppConstant.ADD_LOAD_STACK });
    return config;
}, function (error) {
    return Promise.reject(error);
});
applicationAxiosFile.interceptors.response.use(function (response) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return response;
}, function (error) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return Promise.reject(error);
});


export const applicationAxios = axios.create({
    baseURL: AppConfig.REACT_APP_APPLICATION_API,
});
applicationAxios.interceptors.request.use(function (config) {
    config.headers['token'] = localStorage.getItem('token');
    Store.dispatch({ type: AppConstant.ADD_LOAD_STACK });
    return config;
}, function (error) {
    return Promise.reject(error);
});
applicationAxios.interceptors.response.use(function (response) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return response;
}, function (error) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return Promise.reject(error);
});

export const extraAxios = axios.create({});
extraAxios.interceptors.request.use(function (config) {
    config.headers['token'] = localStorage.getItem('token');
    Store.dispatch({ type: AppConstant.ADD_LOAD_STACK });
    return config;
}, function (error) {
    return Promise.reject(error);
});
extraAxios.interceptors.response.use(function (response) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return response;
}, function (error) {
    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
    return Promise.reject(error);
});



//export const raycloudAxios = axios.create({
//    baseURL: process.env.REACT_APP_RAYCLOUD_PATH,
//    headers: {
//        'x-raycloud-appname': process.env.REACT_APP_RAYCLOUD_APPNAME,
//        'x-raycloud-appusername': process.env.REACT_APP_RAYCLOUD_APPUSERNAME,
//        'x-raycloud-apptoken': process.env.REACT_APP_RAYCLOUD_APPTOKEN,
//    }
//});
//raycloudAxios.interceptors.request.use(function (config) {
//    const user = JSON.parse(localStorage.getItem('user'));
//    if (!user || !user.email) {
//        return Promise.reject("not find user");
//    }
//    config.headers['x-raycloud-userid'] = user.email;
//    Store.dispatch({ type: AppConstant.ADD_LOAD_STACK });
//    return config;
//}, function (error) {
//    return Promise.reject(error);
//});
//raycloudAxios.interceptors.response.use(function (response) {
//    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
//    return response;
//}, function (error) {
//    Store.dispatch({ type: AppConstant.SUB_LOAD_STACK });
//    return Promise.reject(error);
//});
//
