import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import ReactDragListView from "react-drag-listview";
import { FaRegMinusSquare, FaRegPlusSquare } from "react-icons/fa";
import { connect } from "react-redux";
import { CommonAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import { useTranslation } from "react-i18next";

const SettingHomepageAppsMenuApps = ({ auth, homeApps, preset, callbackRefresh }) => {
    const [t] = useTranslation();
    const [edit, setEdit] = useState(false);
    const menuApps = useMemo(() => preset?.menuApps || [], [preset?.menuApps]);
    const [dragProps, setDragProps] = useState({});
    const [data, setData] = useState([]);

    useEffect(() => {
        if (edit) {
            setData(_.cloneDeep(menuApps));
        }
    }, [edit]);

    useEffect(() => {
        setDragProps({
            onDragEnd(fromIndex, toIndex) {
                let _data = _.cloneDeep(data);
                const item = _data.splice(fromIndex, 1)[0];
                _data.splice(toIndex, 0, item);
                setData(_data);
            },
            nodeSelector: ".item-drag",
            handleSelector: ".item-drag",
        });
    }, [data]);

    const handlerClickSave = useCallback(async () => {
        const con = window.confirm("Save Menu Apps?");
        if (!con) {
            return;
        }
        const payload = {
            type: "update",
            region: "ap-northeast-2",
            params: {
                TableName: "rayrnd-applications",
                Key: {
                    name: "homepage",
                    type: "preset",
                },
                UpdateExpression: "SET #menuApps = :menuApps",

                ExpressionAttributeNames: { "#menuApps": "menuApps" },
                ExpressionAttributeValues: { ":menuApps": data },
                ReturnValues: "ALL_NEW",
            },
        };
        await CommonAction.CallDB(payload);
        callbackRefresh && callbackRefresh();
        setEdit(false);
    }, [data]);

    return <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-2">
            <h3 className="mb-0">Menu App List</h3>
            <div className="d-flex align-items-center gap-2">
                <RAYButton
                    label={!edit ? "Edit" : "Edit Cancel"}
                    color={!edit ? "secondary" : "primary"}
                    onClick={() => setEdit(prev => !prev)}
                    size="sm"
                />
                {edit && <>
                    <RAYButton
                        label="save"
                        color={"success"}
                        onClick={handlerClickSave}
                        size="sm"
                    />
                </>}
            </div>
        </div>
        {!edit && <>
            <div>
                {menuApps?.length === 0 && <div>
                    There has no items.
                </div>}
                <div className="d-inline-flex flex-column gap-2">
                    {menuApps?.map(x => <div key={x} className="border rounded px-2 py-1 ">
                        {homeApps.find(a => a.name === x)?.title}
                    </div>)}
                </div>
            </div>
        </>}
        {edit && <>
            <div className="d-flex gap-3">
                <Card className="h-100">
                    <Card.Header>
                        <Card.Title className="mb-0">Menu Apps</Card.Title>
                    </Card.Header>
                    <Card.Body className="flex-fill">
                        <div
                            className="d-flex flex-column gap-2"
                            style={{ minWidth: 500 }}
                        >
                            {data?.length === 0 && <div>
                                There has no items.<br />
                                Please Insert new Item.
                            </div>}
                            <ReactDragListView {...dragProps}>
                                <div className="d-flex flex-column gap-2">
                                    {data?.map(x => <div key={x} className="border rounded px-2 py-1 cursor-pointer item-drag">
                                        <div className="d-flex align-items-center justify-content-between gap-4">
                                            <div>
                                                {homeApps.find(a => a.name === x)?.title}
                                            </div>
                                            <FaRegMinusSquare
                                                className="text-danger cursor-pointer"
                                                onClick={() => setData(prev => prev.filter(z => z !== x))}
                                            />
                                        </div>
                                    </div>)}
                                </div>
                            </ReactDragListView>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <div className="d-flex flex-column gap-2">
                            {_.orderBy(homeApps, ["title"], ["asc"]).map(x => <div
                                key={x.name}
                                className="border rounded d-inline-flex align-items-center justify-content-between gap-4 px-2 py-1"
                            >
                                <div>{x.title}</div>
                                {!data.find(y => y === x.name) && <>
                                    <FaRegPlusSquare className="text-primary cursor-pointer" onClick={() => setData(prev => [...prev, x.name])} />
                                </>}
                                {data.find(y => y === x.name) && <>
                                    <FaRegMinusSquare className="text-danger cursor-pointer" onClick={() => setData(prev => prev.filter(z => z !== x.name))} />
                                </>}
                            </div>)}
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div>
                <ul>
                    <li>
                        {t("These are the settings for the main apps displayed in the hover menu on the homepage.")}
                    </li>
                    <li>
                        {t("You can add or remove apps displayed in the menu using the +/- buttons on the right panel.")}
                    </li>
                    <li>
                        {t("You can remove the app using the - button in the left panel.")}
                    </li>
                    <li>
                        {t("In the left panel, you can change the order of the apps by clicking and then dragging and dropping them.")}
                    </li>
                    <li>
                        {t("When you click the Save button, the values are immediately saved and can be checked on the homepage.")}
                    </li>
                </ul>
            </div>
        </>}
    </div>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(SettingHomepageAppsMenuApps);
