import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationAction, ApplicationVersionAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import { TYPE } from "../../constants/appTerms";
import RAYSelectAdmin from "../../components/common/RAYSelectAdmin";
import RAYSelect from "../../components/common/RAYSelect";
import { LANGUAGE } from "../../constants";

const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const AppSettingLang = ({ item, UpdateApplication }) => {
    const storedLanguages = useSelector(state => state.ApplicationReducer.languages);
    const [viewType, setViewType] = useState(VIEW_TYPE.DEFAULT);
    const [data, setData] = useState({});
    const [lan, setLan] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
    }, []);

    useEffect(() => {
        console.log(item);
    }, [item]);

    const updateTransUser = async () => {
        await ApplicationAction.UpdateApplication({
            appName : item.name,
            data : { tusers : data.tusers },
        })
    }

    return <>
        <div className="row">
            <div className="col-2">

                <div className="w-100 btn-block mb-4">
                    <button className="btn btn-primary w-100" onClick={() => {
                        setLan("");
                        setViewType(VIEW_TYPE.LANGKEYS);
                    }}>Language Keys</button>
                    <button className="btn btn-info w-100 mt-1" onClick={() => {
                        setLan("");
                        setViewType(VIEW_TYPE.TRANSUSER);
                    }}>Translators</button>
                </div>

                <h5>Support Language</h5>
                <div className="list-group">
                    {item?.langs?.map(l => <div 
                        onClick={() => {
                            setLan(l);
                            setViewType(VIEW_TYPE.TRANS);
                        }}
                        className={ lan === l ? "list-group-item active cursor-pointer" : "list-group-item cursor-pointer"}>
                        {LANGUAGE.find(x => x.code === l).label}
                    </div>)}
                </div>

            </div>
            <div className="col-10">
                {viewType === VIEW_TYPE.LANGKEYS && <div className="border-card">
                    <h4>Language Keys</h4>
                </div>}
                {viewType === VIEW_TYPE.TRANSUSER && <div className="border-card">
                    <h4>Translators</h4>
                    <div className="d-flex align-items-center justify-content-center">
                        <RAYSelectAdmin 
                            css={"w-100"}
                            value={data?.tusers || []} 
                            callbackOnChange={v => setData(prev => ({ ...prev, tusers: v.map(x => x.value) }))} />
                        <RAYButton
                            label="Add User"
                            color="primary"
                            style={{ width: 200, maxWidth: 200 }}
                            onClick={updateTransUser}
                        />
                    </div>
                    <div>
                        {item?.tusers?.map(x => <span>{x}</span>)}
                    </div>
                </div>}
                {viewType === VIEW_TYPE.TRANS && <div className="border-card">
                    <h4>{lan && LANGUAGE.find(x => x.code === lan).label}</h4>
                </div>}
            </div>
        </div>
    </>
}

export default AppSettingLang;
