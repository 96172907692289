import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationAction, ApplicationVersionAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import { TYPE } from "../../constants/appTerms";
import RAYSelectAdmin from "../../components/common/RAYSelectAdmin";
import RAYSelect from "../../components/common/RAYSelect";
import RAYTable from "../../components/common/RAYTable";
import { Query } from "../../libs/dynamo";
import { LANGUAGE } from "../../constants";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import moment from "moment";

const AppClickDownloadChart = ({ item }) => {
    const storedLanguages = useSelector(state => state.ApplicationReducer.languages);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [dataChart, setDataChart] = useState({});
    const [allInfo, setAllInfo] = useState({});
    const dispatch = useDispatch();

    const options = {
        plugins: {
            title: { display: true, text: 'Click Download(Last 30 Days)' },
        },
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: { stacked: true, },
            y: { stacked: true, },
        },
    }

    ChartJS.register(CategoryScale, LinearScale, BarElement,ArcElement, Title, Tooltip, Legend, Colors);

    useEffect(() => {
        getQuery();
    }, []);

    useEffect(() => {
        console.log(item);
    }, [item]);

    const getQuery = async () => {
        const params = {
            TableName : "rayrnd-applications",
            KeyConditions: {
                name: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [item.name],
                },
                type: {
                    ComparisonOperator: "BEGINS_WITH",
                    AttributeValueList: ["stat:"],
                }
            },
        };
        const ret = (await Query(params)).Items;
        const actionList = [...new Set(ret.map(x => x.type.indexOf(":click") > -1 && x.type.split(":")[1]))];

        const labels_tmp = []
        for(var d = 0; d < 30; d++){
            labels_tmp.push(moment().add( -1 * d, "days").format("YYYY-MM-DD"));
        }
        const labels = labels_tmp.reverse();

        console.log(ret);
        console.log(actionList);
        console.log(labels);

        let dtset = [];
        for(const action of actionList){
            const adata = [];
            labels.map(l => {
                adata.push(ret
                    .filter(x => x.type.indexOf(":click") > -1)
                    .find(x => x.type === "stat:" + action + ":click:" + l.replace("-", ":").replace("-", ":"))?.clickcnt || 0);
            });
            dtset.push({ label: action, data: adata });
        }
        setDataChart({
            labels : labels.map(x => x.substring(5, 10)),
            datasets: dtset,
        })
    }

    return <div className="mb-2">
        <div className="d-flex justify-content-between align-items-center ">
            <h4>
                Click download-link
                <small className="ms-2 fs-6">
                    Total Install : {allInfo.install} / 
                    Update : {allInfo.update} / 
                    Remove : {allInfo.remove} 
                </small>
            </h4>
            <div className="text-muted small">
                이 집계는 RAYTeams v1.0.36.x 이상(2023.10.05)부터 집계됨
            </div>
        </div>
        <div style={{ height : "400px" }}>
            {dataChart.datasets && <Bar options={options} data={dataChart} />}
        </div>
    </div>
}

export default AppClickDownloadChart;
