import { extraAxios } from '../../libs/axios';
import { AppConfig } from '../../libs/config';
import { Store } from '../../store';

const CallDB = async (payload, callback, forceRAY = false) => {
    try {
        if (!payload?.type || !payload.params) {
            return false;
        }
        const apiDomain = AppConfig.REACT_APP_INFO_API;
        const apiUrl = `${apiDomain}/common/dynamo`;
        payload.region = payload.region || Store.getState().AuthReducer.user?.region;
        const ret = await extraAxios.post(apiUrl, { data: payload });
        callback && typeof callback === "function" && callback(ret.data);
        return ret?.data
    } catch (error) {
        //
        console.log("ERROR[CallDB]", error?.response?.data || error?.toString());
        return false;
    }
};

export const CommonAction = {
    CallDB,
};