import ClipLoader from "react-spinners/ClipLoader";
import PuffLoader from "react-spinners/PuffLoader";

export const ClipSpinner = ({ play = true, color = 'gray', size = 150, style }) => {
    return <>
        <ClipLoader color={color} loading={play} cssOverride={{ ...style }} size={size} />
    </>
}

export const PuffSpinner = ({ play = true, color = 'gray', size = 150, style }) => {

    return <>
        <PuffLoader color={color} loading={play} cssOverride={{ ...style }} size={size} />
    </>
}


