import { connect } from 'react-redux';
import { ApplicationVersionAction } from '../../../redux/actions';


const ApplicationCreate = ({ apps }) => {

    return <div>
        ApplicationCreate
    </div>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const apps = state.ApplicationReducer.apps;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    return { isLogin, apps, appVersions };
};

const mapDispatch = (dispatch) => ({
    GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName))
})

export default connect(mapState, mapDispatch)(ApplicationCreate);
