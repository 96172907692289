import { useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import RAYTextField from "../common/RAYTextField";
import { v4 } from "uuid";
import RAYButton from "../common/RAYButton";
import { connect } from "react-redux";
import { ApplicationAction } from "../../redux/actions";
import { useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";


const ManualFeatureArea = ({ app, version, manualHeader, UpdateAppTemplate, nowFeature, onChanged, HeaderTitle = "Feature List", addTitle = "Add New Feature", manualType = "manual" }) => {
    const [modalShow, setModalShow] = useState(false)
    const [data, setData] = useState({})
    const [displayFeatures, setDisplayFeatures] = useState([]);

    useEffect(() => {
        if (manualHeader?.features) {
            setDisplayFeatures([...manualHeader.features].sort((a, b) => a.order < b.order ? -1 : 1));
        } else {
            setDisplayFeatures([]);
        }
    }, [manualHeader])

    const clear = () => {
        setData({});
        setModalShow(false);
    };

    const handlerClickNewFeature = async () => {
        const newCode = v4();
        if (!data.label) {
            return;
        }
        const item = {
            name: app.name,
            type: manualHeader?.type || `t:body:${version}:${manualType}:header`,
            features: [...(manualHeader?.features || []), {
                ...data,
                code: newCode,
                order: (manualHeader?.features?.length || 0) + 1
            }]
        };
        await UpdateAppTemplate({ appName: app.name, item });
        onChanged(newCode);
        clear();
    };

    const handlerItemOrderUp = async (code, e) => {
        e.stopPropagation();
        if (!Array.isArray(manualHeader.features)) {
            return;
        }
        const _tmp = [...displayFeatures];
        const targetIndex = _tmp.findIndex(x => x.code === code);
        const targetItem = _tmp[targetIndex];
        const otherItem = _tmp[targetIndex - 1];
        if (targetItem && otherItem) {
            _tmp[targetIndex].order = targetIndex - 1;
            _tmp[targetIndex - 1].order = targetIndex;
            const item = {
                name: app.name,
                type: manualHeader.type,
                features: _tmp
            };
            await UpdateAppTemplate({ appName: app.name, item });
        }
    };

    const handlerItemOrderDown = async (code, e) => {
        e.stopPropagation();
        if (!Array.isArray(manualHeader.features)) {
            return;
        }
        const _tmp = [...displayFeatures];
        const targetIndex = _tmp.findIndex(x => x.code === code);
        const targetItem = _tmp[targetIndex];
        const otherItem = _tmp[targetIndex + 1];
        if (targetItem && otherItem) {
            targetItem.order = targetIndex + 1;
            otherItem.order = targetIndex;
            const item = {
                name: app.name,
                type: manualHeader.type,
                features: _tmp
            };
            await UpdateAppTemplate({ appName: app.name, item });
        }
    };

    return <div>
        <div className="h5">Basic</div>
        <div className="mb-4">
            <ListGroup>
                <ListGroup.Item key={"installation"} 
                    className={"cursor-pointer" + (nowFeature === "installation" ? " active" : "")} onClick={(e) => onChanged("installation")} >
                    <div>Installation</div>
                </ListGroup.Item>
            </ListGroup>
        </div>
        <div className="h5">{HeaderTitle}</div>
        <div>
            <ListGroup>
                {displayFeatures.map((x, idx, items) => <ListGroup.Item
                    key={x.code}
                    className={"d-flex align-items-center justify-content-between cursor-pointer" + (nowFeature === x.code ? " active" : "")}
                    onClick={(e) => onChanged(x.code)}
                >
                    <div>{x.label}</div>
                    {items.length > 1 && <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                        {idx > 0 && <MdKeyboardArrowUp onClick={(e) =>handlerItemOrderUp(x.code, e)} />}
                        {idx !== items.length - 1 && <MdKeyboardArrowDown onClick={(e) =>handlerItemOrderDown(x.code, e)} />}
                    </div>}
                </ListGroup.Item>)}
                <ListGroup.Item>
                    <RAYButton
                        label="Add New Feature"
                        color="primary"
                        css="w-100"
                        onClick={() => setModalShow(true)}
                    />
                </ListGroup.Item>
            </ListGroup>
        </div>
        <Modal show={modalShow} onHide={clear}>
            <Modal.Header>
                <Modal.Title>
                    {addTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RAYTextField
                    value={data.name}
                    onChange={(e) => setData(prev => ({ ...prev, label: e.target.value }))}
                    placeholder="New Feature Label"
                />
                <div className="text-muted">
                    <i>Labels are used only by developers.</i>
                </div>
                <div className="mt-5 text-end">
                    <RAYButton
                        label="Add Feature"
                        color="primary"
                        onClick={handlerClickNewFeature}
                    />
                    <RAYButton
                        label="Cancel"
                        color="secondary"
                        onClick={clear}
                    />
                </div>

            </Modal.Body>
        </Modal>
    </div>
};


const mapState = (state) => {
    return {};
};

const mapDispatch = (dispatch) => ({
    CreateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.CreateAppTemplate({ appName, item })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(ManualFeatureArea);

