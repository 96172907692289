import './RAYTabs.scss';

const RAYTabs = ({ tabs, tab, setTab, tabType = 'line', css = "", itemCss = "", activeCss = "active", hideLine = false }) => {
    return <>
        <div className={`ray-tabs ray-tabs-${tabType} ${css}`}>
            {tabs.map(x => <div 
                key={x.code} 
                className={`ray-tab ${itemCss} ${x.code === tab.code ? activeCss : ''} ${hideLine ? 'none-underline' : ''} ${x?.disabled ? 'disabled' : ''}`}
                onClick={() => !x?.disabled && setTab(x)}
            >
                <div>{x.label}</div>
                {x.subLabel && <div className='small fw-normal'>
                    <span>{x.subLabel}</span>
                </div>}
            </div>)}
        </div>
    </>
};

export default RAYTabs;
