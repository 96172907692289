import { AuthConstant } from '../reducers/AuthReducer';
import { toast } from "react-toastify";
import { adminAxios, adminAxiosProd } from '../../libs/axios';

const Login = (payload) => async (dispatch) => {
    try{
        const ret = await adminAxios.post('auth', payload);
        if (ret.data.status === 'success') {
            const payload = {
                token: ret.data.data.jwt.token,
                authToken: ret.data.data.authToken,
                user: ret.data.data.user.find(x => x.type === 'info:detail'),
                perms: ret.data.data.user.filter(x => x.type.includes('info:perm:'))
            }
            localStorage.setItem('token', payload.token);
            localStorage.setItem('authToken', payload.authToken);
            localStorage.setItem('user', JSON.stringify(payload.user));
            localStorage.setItem('perms', JSON.stringify(payload.perms));
            dispatch({ type: AuthConstant.LOGIN_SUCCESS, payload })
        } else {
            toast("Login failed", { type: "error" });
            console.log(ret);
        }
    }catch{
        toast("Login failed", { type: "error" });
    }
}

const LoginProd = (payload) => async (dispatch) => {
    try{
        const ret = await adminAxiosProd.post('auth', payload);
        if (ret.data.status === 'success') {
            const payload = {
                token: ret.data.data.jwt.token,
                authToken: ret.data.data.authToken,
                user: ret.data.data.user.find(x => x.type === 'info:detail'),
                perms: ret.data.data.user.filter(x => x.type.includes('info:perm:'))
            }
            localStorage.setItem('prdtoken', payload.token);
            localStorage.setItem('prdauthtoken', payload.authToken);
            localStorage.setItem('prduser', JSON.stringify(payload.user));
            localStorage.setItem('prdperms', JSON.stringify(payload.perms));
            dispatch({ type: AuthConstant.LOGIN_SUCCESS_PRD, payload })
        } else {
            toast("Login failed", { type: "error" });
            console.log(ret);
        }
    }catch{
        toast("Login failed", { type: "error" });
    }
}

const Logout = () => (dispatch) => {
    localStorage.removeItem('prdtoken');
    localStorage.removeItem('prdauthtoken');
    localStorage.removeItem('prduser');
    localStorage.removeItem('prdperms');

    localStorage.removeItem('token');
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('perms');

    dispatch({ type: AuthConstant.LOGOUT });
}

const GetMe = () => async (dispatch) => {
    try {
        const ret = await adminAxios.get('auth');
        if (ret.data.status === 'success') {
            const payload = {
                token: ret.data.data.jwt.token,
                user: ret.data.data.user.find(x => x.type === 'info:detail'),
                perms: ret.data.data.user.filter(x => x.type.includes('info:perm:'))
            }
            dispatch({ type: AuthConstant.LOGIN_SUCCESS, payload })
        } else {
            dispatch({ type: AuthConstant.SET_IS_LOGIN, payload: false })
        }
    } catch (error) {
        dispatch({ type: AuthConstant.SET_IS_LOGIN, payload: false })
    }
}

const UpdateVersion = (version) => async (dispatch) => {
    dispatch({ type: AuthConstant.VERSION_UPDATE, value: version });
};
const UpdateInfo = (payload) => async (dispatch) => {
    dispatch({ type: AuthConstant.INFO_UPDATE, value: payload });
};

export const AuthAction = {
    UpdateVersion,
    UpdateInfo,
    Login,
    LoginProd,
    GetMe,
    Logout
};
