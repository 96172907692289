import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { AppConstant } from "../../redux/reducers";

const RAYModal = ({ modal }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(modal.show || false);
    }, [modal?.show]);

    const handleClose = useCallback(() => {
        setShow(false);
        dispatch({ type: AppConstant.SET_MODAL_CLOSE });
    }, [dispatch]);

    return <>
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {modal.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-0">
                {modal.body}
            </Modal.Body>
            {modal.footer && <Modal.Footer>
                {modal.footer}
            </Modal.Footer>}
        </Modal>
    </>
}

const mapState = (state) => {
    const modal = state.AppReducer.modal;
    return { modal };
};

const mapDispatch = (dispatch) => ({
    
})

export default connect(mapState, mapDispatch)(RAYModal);
