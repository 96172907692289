import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { AppConstant } from "../../redux/reducers";

export const RAYPanelNoRecord = ({ title = "", content = "No record" }) => {
    const dispatch = useDispatch();

    return <div className="card">
        {title && <div className="card-header text-center">
            <h4>{title}</h4>
        </div>}
        {content && <div className="card-body text-center">
            <span>{content}</span>
        </div>}
    </div>
}
