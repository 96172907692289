import { applicationAxios } from '../../libs/axios';
import { Query, Update, Delete, Get } from '../../libs/dynamo';
import { Query as QueryProd, Update as UpdateProd, Delete as DeleteProd, Get as GetProd } from '../../libs/dynamoProd';
import { getAttributeUpdatesSet } from '../../libs/functions';
import { Store } from '../../store';
import { ApplicationConstant } from '../reducers/ApplicationReducer';

const TableName = "rayrnd-applications";


const GetAllApplication = () => async (dispatch) => {
    const ret = await applicationAxios.get('app');
    if (ret.data.status === 'success') {
        dispatch({ type: ApplicationConstant.SET_ALL_APPS, payload: ret.data.data });
        const user = Store.getState().AuthReducer.user;

        // let _apps = ret.data.data?.filter(x => x.creator === user.sub || (x?.relatedUser ? x?.relatedUser?.includes(user.sub) : false));
        // if (user.appPerms && user.appPerms.length > 0) {
        //     const _temp_apps = ret.data.data.filter(x => user.appPerms.includes(x.name) && !_apps.map(y => y.name).includes(x.name));
        //     console.log(_temp_apps);
        //     _apps = [..._apps, ..._temp_apps];
        // }
        // dispatch({ type: ApplicationConstant.SET_APPS, payload: _apps });

        if (user.is_super_admin) {
            dispatch({ type: ApplicationConstant.SET_APPS, payload: ret.data.data });
        } else {
            let _apps = ret.data.data?.filter(x => x.creator === user.sub || (x?.relatedUser ? x?.relatedUser?.includes(user.sub) : false));
            if (user.appPerms && user.appPerms.length > 0) {
                const _temp_apps = ret.data.data.filter(x => user.appPerms.includes(x.name) && !_apps.map(y => y.name).includes(x.name));
                _apps = [..._apps, ..._temp_apps];
            }
            dispatch({ type: ApplicationConstant.SET_APPS, payload: _apps });
        }

        return ret.data.data;
    } else {
        dispatch({ type: ApplicationConstant.SET_APPS, payload: [] });
        return ret.data.error;
    }
};

const GetApplication = (appName) => async (dispatch) => {
    // const user = Store.getState().AuthReducer.user;
    // if (user.appPerms && user.appPerms.length > 0) {
    //     const allowCall = user.appPerms.includes(appName);
    //     if (!allowCall) {
    //         return {};
    //     }
    // }
    const ret = await applicationAxios.get('app/' + appName);
    if (ret.data.status === 'success') {
        dispatch({ type: ApplicationConstant.GET_APP, payload: ret.data.data });
        return ret.data.data;
    }
    return {};
}

const CreateApplication = async (data) => {
    // const user = Store.getState().AuthReducer.user;
    // if (user.appPerms && user.appPerms.length > 0) {
    //     return;
    // }
    const ret = await applicationAxios.post('app', { data });
    if (ret.data.status === 'success') {
        return ret.data;
    }
    return;
}

const UpdateApplication = async ({ appName, data }) => {
    const ret = await applicationAxios.put('app/' + appName, { data });
    if (ret.data.status === 'success') {
        return ret.data;
    }
    return;
}

const DeleteApplication = async (appName) => {
    const ret = await applicationAxios.delete('app/' + appName);
    if (ret.data.status === 'success') {
        return ret.data;
    }
    return;
}

const GetAppData = ({ appName, prefix, filters }) => async (dispatch) => {
    if (!appName || !prefix) {
        return;
    }
    const formData = {
        data: { prefix, filters }
    };
    const ret = await applicationAxios.post('app/' + appName + '/data/getappdata', formData);
    if (ret.data.status === 'success') {
        dispatch({ type: ApplicationConstant.SET_TERM, appName, item: ret.data.data });
        return ret.data;
    }
    return;
};

const UpdateAppData = ({ appName, type, item }) => async (dispatch) => {
    if (!appName || !type || !item) {
        return;
    }
    const formData = {
        data: { type, item }
    };
    const ret = await applicationAxios.post('app/' + appName + '/data/updateappdata', formData);
    if (ret.data.status === 'success') {
        dispatch({ type: ApplicationConstant.SET_TERM, appName, item: ret.data.data });
        return ret.data;
    }
    return;
};

const RemoveAppData = ({ appName, type }) => async (dispatch) => {
    if (!appName || !type) {
        return;
    }
    const formData = {
        data: { type }
    };
    const ret = await applicationAxios.post('app/' + appName + '/data/removeappdata', formData);
    if (ret.data.status === 'success') {
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName, rowType: type });
        return ret.data;
    }
    return;
};

const PublishNewVersionEmail = async ({ appName, rootType, versionReleaseType }) => {
    if (!appName || !rootType || !versionReleaseType) {
        return;
    }
    const formData = { data: { rootType, versionReleaseType } };
    const ret = await applicationAxios.post('app/' + appName + '/version/publishemail ', formData);
    if (ret.data.status === 'success') {
        return ret.data;
    }
    return;
};

const GetAppVersion = async (appName, version, isdev = true, iseq = true) => {
    const params = {
        TableName,
        KeyConditions: {
            name: {
                ComparisonOperator: "EQ",
                AttributeValueList: [appName],
            },
            type: {
                ComparisonOperator: iseq ? "EQ" : "BEGINS_WITH",
                AttributeValueList: ["v:production:" + version],
            },
        },
    };
    const ret = isdev ? await Query(params) : await QueryProd(params);
    return ret.Items;
};

const DeployAppVersion = async (items) => {
    const now = new Date().getTime();
    const user = Store.getState().AuthReducer.prduser;
    for(const item of items){
        item.updated = now;
        item.updater = user._id;
        if(typeof item.is_active === "boolean")
            item.is_active = false;
        const params = {
            TableName,
            ...getAttributeUpdatesSet(["name", "type"], item),
        };
        await UpdateProd(params);
    }
    return true;
};

const GetAppTemplates = ({ appName, type }) => async (dispatch) => {
    const params = {
        TableName,
        KeyConditions: {
            name: {
                ComparisonOperator: "EQ",
                AttributeValueList: [appName],
            },
            type: {
                ComparisonOperator: "BEGINS_WITH",
                AttributeValueList: [type ?? "t:"],
            },
        },
    };
    const ret = await Query(params);
    if (ret.Items) {
        dispatch({ type: ApplicationConstant.SET_TEMPLATES, appName, item: ret.Items })
    }
    return ret;
};

const CreateAppTemplate = ({ appName, item }) => async (dispatch) => {
    const now = new Date().getTime();
    const user = Store.getState().AuthReducer.user;
    item.created = now;
    item.creator = user._id;
    const params = {
        TableName,
        ...getAttributeUpdatesSet(["name", "type"], item),
    };
    const ret = await Update(params);
    if (ret?.Attributes) {
        dispatch({ type: ApplicationConstant.SET_TEMPLATES, appName, item: ret.Attributes })
    }
    return ret;
};

const UpdateAppTemplate = ({ appName, item }) => async (dispatch) => {
    const now = new Date().getTime();
    const user = Store.getState().AuthReducer.user;
    item.updated = now;
    item.updater = user._id;
    const params = {
        TableName,
        ...getAttributeUpdatesSet(["name", "type"], item),
    };
    const ret = await Update(params);
    if (ret?.Attributes) {
        dispatch({ type: ApplicationConstant.SET_TEMPLATES, appName, item: ret.Attributes })
    }
    return ret;
};

const DeleteAppTemplate = ({ appName, item }) => async (dispatch) => {
    if (!item?.name || !item?.type) {
        return;
    }
    if (!item.type.startsWith("t:")) {
        return;
    }
    const params = {
        TableName,
        Key: { name: item.name, type: item.type }
    };
    try {
        await Delete(params);
        dispatch({ type: ApplicationConstant.UNSET_TEMPLATES, appName, item: item })
    } catch (error) {
        //
    }
};

const CopyAppTemplate = ({ appName, targetVersion, reVersion }) => async (dispatch) => {
    try {
        const paramsHeaderRe = {
            TableName,
            Key: {
                name: appName,
                type: `t:header:${reVersion}`
            },
        };
        let { Item: existReHeader } = await Get(paramsHeaderRe);
        if (existReHeader && existReHeader?.name) {
            return false;
        }

        const paramsHeader = {
            TableName,
            Key: {
                name: appName,
                type: `t:header:${targetVersion}`
            },
        };
        let { Item: existHeader } = await Get(paramsHeader);
        if (!existHeader?.name) {
            return false;
        }
        const items = [];
        existHeader = { ...existHeader };
        const now = new Date().getTime();
        const user = Store.getState().AuthReducer.user;

        existHeader.isVisible = false;
        existHeader.created = now;
        existHeader.creator = user._id;
        existHeader.version = reVersion;
        delete existHeader.updated;
        delete existHeader.updater;
        existHeader.type = existHeader.type.replace(targetVersion, reVersion);

        const paramsUpdateHeader = {
            TableName,
            ...getAttributeUpdatesSet(["name", "type"], existHeader),
        };

        const newHeader = await Update(paramsUpdateHeader);
        if (newHeader?.Attributes) {
            items.push(newHeader.Attributes)
        }

        const paramsContents = {
            TableName,
            KeyConditionExpression: "#name = :name and begins_with(#type, :type)",
            ExpressionAttributeNames: {
                "#name": "name",
                "#type": "type",
            },
            ExpressionAttributeValues: {
                ":name": appName,
                ":type": `t:body:${targetVersion}`
            },
        };
        const { Items: existContents } = await Query(paramsContents);

        for (const existContent of existContents) {
            const _existContent = { ...existContent };
            _existContent.type = _existContent.type.replace(targetVersion, reVersion);
            const paramsUpdateBody = {
                TableName,
                ...getAttributeUpdatesSet(["name", "type"], _existContent),
            };
            const newBody = await Update(paramsUpdateBody);
            if (newBody?.Attributes) {
                items.push(newBody.Attributes)
            }
        }
        if (items.length > 0) {
            dispatch({ type: ApplicationConstant.SET_TEMPLATES, appName, item: items })
        }

    } catch (error) {
        //
    }
};



export const ApplicationAction = {
    GetAllApplication,
    GetApplication,
    CreateApplication,
    UpdateApplication,
    DeleteApplication,
    GetAppData,
    UpdateAppData,
    RemoveAppData,
    PublishNewVersionEmail,
    GetAppTemplates,
    CreateAppTemplate,
    UpdateAppTemplate,
    DeleteAppTemplate,
    CopyAppTemplate,
    GetAppVersion,
    DeployAppVersion
};
