function Dashboard() {

  return <div className="p-12 mb-12">
    <div className="fw-bolder h1">Welcome to the RAYTeams Developer.</div>
    <div className="mt-5 fs-4">
      It offers a variety of features, including applications and other services available to RAYTeams.<br />
      If you have a problem, contact your RAY manager directly. I will check and reply as soon as possible.<br />
    </div>
  </div>
}

export default Dashboard
