import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RAYIconBI, RAYIconMenu, RAYIconSetting } from "../../components/common/RAYIcon";
import { AppAction, AuthAction } from "../../redux/actions";
import MenuItem from "./MenuItem";

const Header = ({ name, version, info, UpdateVersion, UpdateInfo, menus, Logout }) => {
    const location = useLocation();
    const [routeGroup, setRouterGroup] = useState('');
    useEffect(() => {
        setRouterGroup(location.pathname.split('/')[1]);
    }, [location]);

    const logout = () => {
        Logout();
    }

    return <div className="header">
        <div className="header-content">
            <div className="mobile-logo">
                <Link to="/">
                    <RAYIconBI size="100" />
                </Link>
            </div>
            <div className="header-wrapper">
                <div className="header-menu d-none d-md-flex align-items-center fw-bolder">
                    <div className="menu">
                        <Link to="/" className={"menu-item" + (location.pathname === '/' ? ' here show ' : '')}>
                            <span className="menu-link text-gray-700">
                                <span className="menu-title">Home</span>
                            </span>
                        </Link>
                        {menus?.length > 0 && menus.map((x, index) => <Link 
                            to={x.path ? x.path : '#'}
                            className={"menu-item" + (routeGroup === x.group ? ' here show ' : '')} 
                            key={index}
                        >
                            <span className="menu-link">
                                <span className={"menu-title " + (routeGroup === x.group ? 'text-primary' : 'text-muted')}>{x.label}</span>
                            </span>
                        </Link>)}
                    </div>
                </div>
                <div className="toolbar-wrapper">
                    <Dropdown align="end">
                        <Dropdown.Toggle variant='default' style={{ height: '100%' }} className="tool-list">
                            <div className="btn btn-icon">
                                <span className="svg-icon">
                                    <RAYIconSetting size="24" />
                                </span>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className="menu menu-column">
                                <div className="menu-item">
                                    <div className="menu-link" onClick={logout}>Logout</div>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="mobile-toggle" title="Show header menu">
                        <Dropdown align="end">
                            <Dropdown.Toggle variant='default' style={{ height: '100%' }} className="tool-list">
                                <div className="btn btn-icon">
                                    <span className="svg-icon">
                                        <RAYIconMenu size="24" />
                                    </span>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="menu menu-column" style={{ width: "max-content" }}>
                                    {menus.length > 0 && menus.map((x, idx) => <MenuItem item={x} key={idx} />)}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapState = (state) => {
    const name = state.AppReducer.name;
    const version = state.AppReducer.version;
    const info = state.AppReducer.info;
    const menus = state.AppReducer.menus;
    return { name, version, info, menus };
};

const mapDispatch = (dispatch) => ({
    UpdateVersion: (version) => dispatch(AppAction.UpdateVersion(version)),
    UpdateInfo: (payload) => dispatch(AppAction.UpdateInfo(payload)),
    Logout: () => dispatch(AuthAction.Logout())

})

export default connect(mapState, mapDispatch)(Header);
