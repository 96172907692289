const RAYButton = ({ label, onClick, color, size, disabled = false, css = "", style }) => {
    return(
        <button 
            className={`btn btn-${color} ${size ? `btn-${size}` : ""} ${css}`} 
            disabled={disabled}
            onClick={onClick}
            style={style ? style : {}}
        >{label}</button>
    )
}

export default RAYButton;