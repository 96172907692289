import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppTemplateFormManualDev from "../../../components/appTemplates/AppTemplateFormManualDev";
import AppCMSView from "../../../components/application/AppCMSView";
import RAYTabs from "../../../components/common/RAYTabs";
import RAYSelect from "../../../components/common/RAYSelect";
import ApplicationToolbar from "../../../components/toolbars/ApplicationToolbar";
import PageInOutHook from "../../../hooks/PageInOutHook";
import { LANGUAGE, APP_CMS_TYPE } from "../../../constants";
import { versionSort } from '../../../libs/functions';
import { ApplicationAction } from "../../../redux/actions/ApplicationAction";
import { ApplicationVersionAction } from '../../../redux/actions';
import { history } from '../../../history'

const TAB_CODES = {
    STARTUP: "startup",
    HELP_SCREEN: "help_screen",
    HELP_PANEL: "help_panel",
    HELP_BUTTON: "help_button",
    TSG: "tsg"
}

const AppCMS = ({ apps, auth, GetApplicationVersions, appVersions }) => {
    const { appName, version = "" } = useParams();
    const navigate = useNavigate();
    const [app, setApp] = useState({});
    const [versions, setVersions] = useState([]);
    const [header, setHeader] = useState({});
    const [versionList, setVersionList] = useState([]);
    const [params, setParams] = useSearchParams();
    const [lang, setLang] = useState(params.get("lang") || "ko");
    const [tp, setTp] = useState(params.get("tp") || "");

    const [setToolbar] = PageInOutHook();
    const navigator = useNavigate();
    const [tabs, setTabs] = useState([
        { label: "Start-up", code: TAB_CODES.STARTUP, confirmed: false },
        { label: "Screens", code: TAB_CODES.HELP_SCREEN, confirmed: false },
        { label: "Panels", code: TAB_CODES.HELP_PANEL, confirmed: false },
        { label: "Buttons", code: TAB_CODES.HELP_BUTTON, confirmed: false },
        { label: "TSGs", code: TAB_CODES.TSG, confirmed: false }
    ]);
    const [tab, setTab] = useState(tabs[0]);

    useEffect(() => {
        setLang(params.get("lang") || "ko");
    }, [params]);

    useEffect(() => {
        if (!appName) { return; }
        GetApplicationVersions(appName);
    }, [appName, GetApplicationVersions]);

    useEffect(() => {
        appName && setVersions(appVersions[appName] || []);
    }, [appVersions, appName]);

    useEffect(() => {
        if (app?.name) {
            setToolbar(<ApplicationToolbar
                title={<>{app.title} <small>Content Management Service</small></>}
                appName={app.name}
                suffixDom={<div>
                    <Link to={`/applications/${app.name}`} className="btn btn-warning me-1">Application Detail</Link>
                </div>}
            />);
        }
    }, [app, version, setToolbar]);

    useEffect(() => {
        appName && apps.length && setApp(apps.find(x => x.name === appName));
    }, [apps, appName]);

    useEffect(() => {
        const tmpList = versions.map(item => {
            const published = item.is_active ? "Published" : (item.is_plan ? "Plan" : "Draft");
            return {
                value : item.version,
                label : "v" + item.version + "(" + published + ")",
            }
        });
        setVersionList([...versionSort(tmpList, "value")]);
    }, [versions])

    return <div className="pb-5">
        <div className="card p-2 mb-2">
            <div className="d-flex justify-content-between">
                <div></div>
                {/*<RAYTabs tabs={tabs} tab={tab} setTab={setTab} hideLine={true} itemCss="px-2 py-2" css="" />*/}
                <div className="d-flex align-items-center">
                    <div className="me-2">Version </div>
                    {versionList && <RAYSelect value={version} items={versionList}
                        clearable={false}
                        callbackOnChange={(e) => 
                            navigate("/applications/" + appName + "/cms" + (e?.value ? "/" + e?.value: "common") + "?lang=" + lang + "&type=" + tp)
                        }
                        style={{ width : 300 }} />}
                    <span className="ms-2">Language : </span>
                    <RAYSelect value={lang} items={LANGUAGE.map(x => ({ value : x.code, label : x.label }))}
                        clearable={false}
                        callbackOnChange={(e) => 
                            navigate("/applications/" + appName + "/cms/" + version + "?lang=" + e.value + "&type=" + tp)
                        }
                        style={{ width : 200, marginLeft : 4 }} />
                    <span className="ms-2">Type : </span>
                    <RAYSelect value={tp} items={[{value : "", label : "All"}, ...APP_CMS_TYPE]}
                        clearable={false}
                        callbackOnChange={(e) => 
                            navigate("/applications/" + appName + "/cms/" + version + "?lang=" + lang + "&type=" + e.value)
                        }
                        style={{ width : 150, marginLeft : 4 }} />
                </div>
            </div>
        </div>
        {app?.name && <AppCMSView app={app} version={version} lang={lang} setLang={setLang}/>}
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    return { apps, auth, appVersions };
};

const mapDispatch = (dispatch) => ({
    GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName))
});

export default connect(mapState, mapDispatch)(AppCMS);
