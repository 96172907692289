//import { raycloudAxios } from '../../libs/axios';
import { applicationAxiosFile } from '../../libs/axios';
import axios from 'axios';

const getUploadUrl = async (data) => {
    const ret = await applicationAxiosFile.post('file/get-upload-url', data);
    if (ret.data.status === 'success') {
        return ret.data.data;
    }
};

const uploadFileToS3 = async (url, file, onUploadProgress) => {
    let { type: mimetype } = file;
    const options = {
        headers: {
            'Content-Type': mimetype,
        },
        onUploadProgress: onUploadProgress
    }
    await axios.put(url, file, options);
}

const Upload = async (payload) => {
    //const formData = new FormData();
    //Object.keys(payload).forEach(key => {
    //    formData.append(key, payload[key]);
    //})
    //const ret = await raycloudAxios.post('file/upload', formData);
    //if (ret.data.status === 'success') {
    //    return ret.data.data;
    //}
};

export const FileAction = {
	Upload,
    getUploadUrl,
    uploadFileToS3
};
