import AWS from 'aws-sdk';
import { Store } from '../store';
import { Buffer } from 'buffer';

const getAWSCredential = async () => {
    const user = Store.getState().AuthReducer.user;
    if (!user?.pc) {
        return null;
    }
    const pc = Buffer.from(user.pc, "base64").toString('utf8');
	const cp = pc.split("|rayteams|");
	
	if (cp.length !== 2) {
		return null;
	}
	return {
		accessKeyId: cp[0],
		secretAccessKey: cp[1],
		region: "ap-northeast-2",
	};
};

const getAWS = async () => {
    const user = Store.getState().AuthReducer.user;
    if (!user?.pc) {
        return null;
    }
    const pc = Buffer.from(user.pc, "base64").toString('utf8');
	const cp = pc.split("|rayteams|");
	
	if (cp.length !== 2) {
		return null;
	}
	const CR = {
		accessKeyId: cp[0],
		secretAccessKey: cp[1],
		region: "ap-northeast-2",
	};
	var crds = new AWS.Credentials(CR);
	AWS.config.update({ credentials : crds, region: "ap-northeast-2" });
	return AWS;
};

const getAWSProd = async () => {
    const user = Store.getState().AuthReducer.prduser;
    if (!user?.pc) {
        return null;
    }
    const pc = Buffer.from(user.pc, "base64").toString('utf8');
	const cp = pc.split("|rayteams|");
	
	if (cp.length !== 2) {
		return null;
	}
	const CR = {
		accessKeyId: cp[0],
		secretAccessKey: cp[1],
		region: "ap-northeast-2",
	};
	var crds = new AWS.Credentials(CR);
	AWS.config.update({ credentials : crds, region: "ap-northeast-2" });
	return AWS;
};

export {
    getAWSCredential,
    getAWS,
    getAWSProd
};
