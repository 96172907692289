import moment from "moment";
import { useCallback } from "react";
import Select from 'react-select';
import { CONST_APPLICATION } from "../../constants";
import RAYAppArrAttrSetter from "../common/RAYAppArrAttrSetter";
import RAYFile from "../common/RAYFile";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";

const AppSettingAttrs = ({ edit = false, create = false, item, data, setData }) => {

    const callbackOnChangeAttrs = useCallback((_data) => {
        setData(prev => ({ ...prev, app_attrs: _data }));
    }, []);

    return <>
        <div>
            <RAYFormRawInline title="RAYTeams Apps" labelCss="" labelCol={3}>
                {!edit && <div>{item?.rayteams_apps ? 'Include' : 'Exclude'}</div>}
                {edit && <RAYToggle
                    name="rayteams_apps"
                    value={data?.rayteams_apps}
                    trueLabel="Include"
                    falseLabel="Exclude"
                    onChange={() => setData(prev => ({ ...prev, rayteams_apps: !prev.rayteams_apps }))}
                />}
            </RAYFormRawInline>
            {/*!data?.rayteams_apps && <div className="text-muted">
                The settings below are valid for RAYTeams App only.
            </div>*/}
            {data?.rayteams_apps && <>
                {/*(<div className="text-info mb-12">
                    The settings below apply to the RAYTeams App Store.
                </div>*/}
                <RAYAppArrAttrSetter list={item?.app_attrs} edit={edit} callbackOnChangeData={callbackOnChangeAttrs} />
            </>}
        </div>
    </>
}

export default AppSettingAttrs;
