export const versionSort = (arr, nested = 'version') => {
    let result = arr;
    try {
        result = arr.sort((b, a) => a[nested].localeCompare(b[nested], undefined, { numeric: true }));
    } catch (error) {
        console.log('ERROR[versionSort]: ', error);
    }
    return result
};

export const poolColors = (a) => {
    const pool = [];
    for(var i=0; i<a; i++){
        pool.push(dynamicColors());
    }
    console.log(pool);
    return pool;
}

const dynamicColors = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
}

export const setKeyItemToReducer = (state, action, rKey) => {
    const originObj = state[rKey][action.appName];
    if (!originObj) {
        return {
            ...state,
            [rKey]: {
                ...state[rKey], [action.appName]: {
                    meta: {},
                    items: Array.isArray(action.item) ? action.item : [action.item],
                }
            }
        };
    } else if (Array.isArray(action.item)) {
        const _tmp = [...originObj.items];
        const _newTmp = [];
        action.item.forEach(x => {
            const _existIndex = _tmp.findIndex(y => y.type === x.type);
            if (_existIndex > -1) {
                _tmp[_existIndex] = x;
            } else {
                _newTmp.push(x);
            }
        });
        return {
            ...state,
            [rKey]: {
                ...state[rKey],
                [action.appName]: {
                    ...originObj,
                    items: [..._tmp, ..._newTmp]
                }
            }
        };
    } else if (typeof action.item === 'object') {
        const _tmp = [...originObj.items];
        const _existIndex = _tmp.findIndex(x => x.type === action.item.type)
        if (_existIndex > -1) {
            _tmp[_existIndex] = action.item;
        } else {
            _tmp.push(action.item);
        }
        return {
            ...state,
            [rKey]: {
                ...state[rKey],
                [action.appName]: {
                    ...originObj,
                    items: _tmp
                }
            }
        };
    } else {
        return state;
    }
};

export const unsetKeyItemToReducer = (state, action, rKey) => {
    const originObj = state[rKey][action.appName];
    if (!originObj) {
        return state;
    }
    const _tmp = originObj.items.filter(x => x.type !== (action.item.type));
    return {
        ...state,
        [rKey]: {
            ...state[rKey],
            [action.appName]: {
                ...originObj,
                items: _tmp,
            }
        },
    }
};

export const clearKeyItemToReducer = (state, action, rKey) => {
    return {
        ...state,
        [rKey]: { ...state[rKey], [action.appName]: undefined },
    }
};

export const getAttributeUpdatesSet = (keys, item, returnValue = "ALL_NEW") => {
    const Key = {};
    keys.forEach(key => {
        Key[key] = item[key];
        delete item[key];
    });
    const AttributeUpdates = Object.keys(item).reduce((acc, cur) => {
        acc[cur] = {
            Action: "PUT",
            Value: item[cur],
        };
        return acc;
    }, {});
    return {
        Key,
        AttributeUpdates,
        ReturnValues: returnValue,
    }
};

export const getYoutubeIdByLink = (link) => {
    if (!link) {
        return null;
    }
    if (link.startsWith("https://www.youtube.com/watch?v=")) {
        const query = link.split("watch?")[1];
        const params = new URLSearchParams(query);
        const _yId = params.get("v");
        return _yId;
    } else  if (link.startsWith("https://youtu.be/")) {
        const _yId = link.split("https://youtu.be/")[1]?.split("?")[0]?.split("/")[0];
        return _yId;
    } else {
        return null;
    }
};
