import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from 'react-select';
import RAYSelect from "../../components/common/RAYSelect";
import { LANGUAGE } from "../../constants";
import { toast } from "react-toastify";
import { CONST_APPLICATION } from "../../constants";
import { alphabetsNumbersTest } from "../../libs/regexs";
import { UserAction } from "../../redux/actions";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYSelectAdmin from "../common/RAYSelectAdmin";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import { RAYToolTip } from "../common/RAYToolTip";
//import Editor, { EDITOR_CONST } from "../tiptap/Editor";
import TinyEditor from "../tinymce/TinyEditor";

const AppSettingBasic = ({ edit = false, create = false, item, data, setData }) => {
  const users = useSelector(state => state.UserReducer.items);
  const auth = useSelector(state => state.AuthReducer.user);
  const [admins, setAdmins] = useState([]);
  const dispatch = useDispatch();
  const allApps = useSelector(state => state.ApplicationReducer.allApps);
  const [apps, setApps] = useState([]);
  useEffect(() => {
    const _apps = allApps.filter(x => {
      if (!x.is_active) {
        return false;
      }
      if (!x.rayteams_apps) {
        return false;
      }
      return true;
    });
    setApps(_apps.map(x => ({ value: x.name, label: (x.title || x.name) })));
  }, [allApps]);

  useEffect(() => {
    dispatch(UserAction.GetAll());
  }, [dispatch]);

  useEffect(() => {
    setAdmins([...users]);
  }, [users]);

  const appNameConverter = (event) => {
    const hasWrongKeyword = alphabetsNumbersTest.test(event?.target?.value || '');
    if (hasWrongKeyword) {
      toast('wrong keyword includes "' + event.target.value + '"', { type: 'error' });
    }
    const name = (event?.target?.value || '').replace(/([^a-zA-Z0-9])/g, '');
    setData(prev => ({ ...prev, name }));
  };

  return <>
    <div>
      {create && <RAYFormRawInline title="App Name" labelCss="" labelCol={3}>
        {(!create || !edit) && <span>{item?.name}</span>}
        {(create && edit) && <>
          <RAYToolTip tooltip="You can only enter numbers and alphabets.">
            <div className="w-100">
              <RAYTextField
                name="name"
                placeholder="AppName"
                value={data.name}
                onChange={appNameConverter}
              />
            </div>
          </RAYToolTip>
        </>}
      </RAYFormRawInline>}
      <RAYFormRawInline title="App Title(Label)" labelCss="" labelCol={3}>
        {!edit && <span>{item?.title}</span>}
        {edit && <RAYTextField
          name="title"
          value={data.title}
          onChange={(event) => setData(prev => ({ ...prev, title: event.target.value }))}
        />}
      </RAYFormRawInline>
      <RAYFormRawInline title="App Type" labelCss="" labelCol={3}>
        {!edit && <span>{item?.inAppFeature ? "In-App Feature" : "Standalone App"}</span>}
        {edit && <RAYToggle
          name="in_app_feature"
          value={data?.inAppFeature}
          trueLabel="In-App Feature"
          falseLabel="Standalone App"
          onChange={() => setData(prev => ({ ...prev, inAppFeature: !prev.inAppFeature, parentAppName: (!prev.inAppFeature ? "" : (prev?.parentAppName || item?.parentAppName)) }))}
        />}
      </RAYFormRawInline>
      {data?.inAppFeature && <>
        <RAYFormRawInline title="Parent App" labelCss="" labelCol={3}>
          {!edit && <span>{item?.parentAppName ? (apps.find(x => x.value === item?.parentAppName)?.label || item?.parentAppName) : "none"}</span>}
          {edit && <>
            <div>
              <RAYSelect
                items={apps}
                value={apps.find(x => x.value === data.parentAppName)}
                multi={false}
                callbackOnChange={({ value, label }) => setData(prev => ({ ...prev, parentAppName: value }))}
                style={{ minWidth: 400 }}
              />
            </div>
          </>}
        </RAYFormRawInline>
      </>}
      <RAYFormRawInline title="Issue Date" labelCss="" labelCol={3}>
        {(!edit || !create) && <span>{moment(item?.created).format('LLL')}</span>}
        {(edit && create) && <>
          Inserts the creation date.
        </>}
      </RAYFormRawInline>
      <RAYFormRawInline title="Category" labelCss="" labelCol={3}>
        {!edit && <span>{item?.category ? (CONST_APPLICATION.CATEGORIES.find(x => x.value === data.category)?.label || '-') : '-'}</span>}
        {edit && <>
          <div style={{ minWidth: 300 }}>
            <Select
              options={CONST_APPLICATION.CATEGORIES}
              value={CONST_APPLICATION.CATEGORIES.find(x => x.value === data.category) || ''}
              onChange={(obj) => setData(prev => ({ ...prev, category: obj.value }))}
              classNamePrefix="ray-select"
              className="w-100"
            />
          </div>
        </>}
      </RAYFormRawInline>
      <RAYFormRawInline title="License ID" labelCss="" labelCol={3}>
        {!edit && <span>{item?.licenseId || '-'}</span>}
        {edit && <RAYTextField
          value={data.licenseId || ""}
          placeholder="License ID for check licenses."
          onChange={(event) => setData(prev => ({ ...prev, licenseId: event.target.value }))}
        />}
      </RAYFormRawInline>
      <RAYFormRawInline title="Update Force" labelCss="" labelCol={3}>
        {!edit && <span>{item?.update_force ? 'Force' : 'Manual'}</span>}
        {edit && <RAYToggle
          name="update_force"
          value={data?.update_force}
          trueLabel="Force"
          falseLabel="Manual"
          onChange={() => setData(prev => ({ ...prev, update_force: !prev.update_force }))}
        />}
      </RAYFormRawInline>
      <RAYFormRawInline title="Show Home Page" labelCss="" labelCol={3}>
        {!edit && <span>{item?.showHome ? 'Show Home Page' : 'Hide Home Page'}</span>}
        {edit && <RAYToggle
          name="is_active"
          value={data?.showHome}
          trueLabel="Show Home Page"
          falseLabel="Hide Home Page"
          onChange={() => setData(prev => ({ ...prev, showHome: !prev.showHome }))}
        />}
      </RAYFormRawInline>
      <RAYFormRawInline title="Support Language" labelCss="" labelCol={3}>
        {!edit && <>
          {(item?.langs || []).length > 0 && <span>{LANGUAGE.filter(x => (item?.langs || []).includes(x.code)).map(x => (x.label)).join(", ")}</span>}
          {(item?.langs || []).length == 0 && <span><i>NOT ASSIGNED</i></span>}
        </>}
        {edit && <RAYSelect
          multi={true}
          items={LANGUAGE.map(x => ({ label: x.label, value: x.code }))}
          value={LANGUAGE.filter(x => (data?.langs || []).includes(x.code)).map(x => ({ label: x.label, value: x.code }))}
          callbackOnChange={v => setData(prev => ({ ...prev, langs: v.map(n => n.value) }))} />}
      </RAYFormRawInline>
      {/* <RAYFormRawInline title="Manual Language" labelCss="" labelCol={3}>
        {!edit && <>
          {(item?.manualLangs || []).length > 0 && <span>{LANGUAGE.filter(x => (item?.manualLangs || []).includes(x.code)).map(x => (x.label)).join(", ")}</span>}
          {(item?.manualLangs || []).length == 0 && <span><i>NOT ASSIGNED</i></span>}
        </>}
        {edit && <RAYSelect
          multi={true}
          items={LANGUAGE.map(x => ({ label: x.label, value: x.code }))}
          value={LANGUAGE.filter(x => (data?.manualLangs || []).includes(x.code)).map(x => ({ label: x.label, value: x.code }))}
          callbackOnChange={v => setData(prev => ({ ...prev, manualLangs: v.map(n => n.value) }))} />}
      </RAYFormRawInline> */}
      <RAYFormRawInline title="Dev App" labelCss="" labelCol={3}>
        {!edit && <span>{item?.isdev === 'Y' ? 'Development App' : 'Production App'}</span>}
        {edit && <RAYToggle
          name="is_active"
          value={data?.isdev === 'Y'}
          trueLabel="Development App"
          falseLabel="Production App"
          onChange={() => setData(prev => ({ ...prev, isdev: prev.isdev === 'Y' ? 'N' : 'Y' }))}
        />}
      </RAYFormRawInline>
      {!create && <RAYFormRawInline title="Status" labelCss="" labelCol={3}>
        {!edit && <span>{item?.is_active ? 'Published' : 'Draft'}</span>}
        {edit && <RAYToggle
          name="is_active"
          value={data?.is_active}
          trueLabel="Published"
          falseLabel="Draft"
          onChange={() => setData(prev => ({ ...prev, is_active: !prev.is_active }))}
        />}
      </RAYFormRawInline>}
      {!create && <RAYFormRawInline title="Manage Users" labelCss="" labelCol={3}>
        <div>
          {(!edit || (edit && !(item.creator === auth.sub || auth.is_super_admin))) && <div>
            {item?.relatedUser?.length > 0 && item?.relatedUser?.map(x => <div key={x}>
              {admins?.find(y => y.sub === x) && <div>
                <div>
                  {admins.find(y => y.sub === x).name} - {admins.find(y => y.sub === x).email}
                </div>
              </div>}
            </div>)}
            {(!item?.relatedUser || item?.relatedUser?.length === 0) && <div>
              -
            </div>}
          </div>}
          {edit && (item?.creator === auth?.sub || auth?.is_super_admin) && <>
            <div style={{ minWidth: 500 }}>
              <RAYSelectAdmin
                value={data?.relatedUser || []}
                callbackOnChange={v => setData(prev => ({ ...prev, relatedUser: v.map(x => x.value) }))}
                css="w-100"
              />
            </div>
          </>}
          {edit && !(item.creator === auth.sub || auth.is_super_admin) && <div className="text-danger">
            This setting is changed by the author or super administrator.
          </div>}
        </div>
      </RAYFormRawInline>}
      {!create && <RAYFormRawInline title="App Log Key" labelCss="" labelCol={3}>
        {!edit && <span>{item?.alkey || '-'}</span>}
        {edit && <RAYTextField
          value={data.alkey || ""}
          placeholder="App Log Key"
          style={{ width: "200px" }}
          onChange={(event) => setData(prev => ({ ...prev, alkey: event.target.value }))}
        />}
      </RAYFormRawInline>}
      {!create && <RAYFormRawInline title="Description" labelCss="" labelCol={3}>
        {!edit && <div className="html-contents w-100 html-preview" dangerouslySetInnerHTML={{ __html: item?.desc || '-' }} />}
        {edit && <div className="">
          <TinyEditor
            name={data.name}
            originContent={item?.desc || ""}
            callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
            //s3KeyPrefix={`rayrnd/applications/${version?.name}/versions/${version?.version}/release_attachments/`}
            height={150}
          //name={data.name}
          //content={data.desc} callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
          //type={EDITOR_CONST.TYPE.HTML}
          />
        </div>}
      </RAYFormRawInline>}
    </div>
  </>
}

export default AppSettingBasic;
