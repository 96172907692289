import { useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { LANGUAGE } from "../../constants";

const LangArea = ({ langs, lang, setLang, callbackSelectLang }) => {

    useEffect(() => {
        lang && callbackSelectLang && callbackSelectLang(lang);
    }, [lang, callbackSelectLang]);

    return <div>
        <div className="h5">Language(s)</div>
        {langs?.length === 0 && <i>
            Please setting langs on Basic
        </i>}
        {langs?.length > 0 && <div>
            <ListGroup>
                {langs.map(x => <ListGroup.Item
                    key={x}
                    active={x === lang}
                    action={true}
                    onClick={() => setLang(x)}
                    className="d-flex align-items-center justify-content-between"
                >
                    <div>{LANGUAGE.find(l => l.code === x)?.label || x}</div>
                </ListGroup.Item>)}
            </ListGroup>
        </div>}
    </div>
};

export default LangArea;
