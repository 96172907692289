import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { UserAction } from "../../redux/actions";

const RAYSelectAdmin = ({ auth, GetAll, users,
    placeholder, value, callbackOnChange, readOnly = false, style, css = '', menuPlacement = 'auto', clearable = true }) => {
    const [admins, setAdmins] = useState([]);
    const [val, setVal] = useState(value);

    useEffect(() => {
        if (!auth?.sub) {
            setAdmins([]);
            return;
        }
        GetAll();
    }, [GetAll, auth?.sub]);

    useEffect(() => {
        setAdmins(users?.filter(x => x?.perms?.includes("application"))?.map(x => ({ label: `${x.name} - ${x.email}` , value: x.sub })));
    }, [users]);

    useEffect(() => {
        setVal(value);
    }, [value]);

    const onChangeHandler = useCallback((data) => {
        setVal(data)
        callbackOnChange && callbackOnChange(data)
    }, [callbackOnChange])

    return <div style={{ ...style }} className={css}>
        <Select
            isMulti={true}
            closeMenuOnSelect={false}
            // value={typeof val === "object" ? val : admins.find(x => x.value === val)}
            value={Array.isArray(val) ? admins.filter(x => val.includes(x.value)) : admins.find(x => x.value === val)}
            menuPlacement={menuPlacement}
            options={admins}
            readOnly={readOnly}
            isClearable={clearable}
            onChange={onChangeHandler}
            placeholder={placeholder}
            style={{ zIndex: 2 }}
            classNamePrefix="ray-select"
        />
    </div>
}


const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const users = state.UserReducer.items;
    return { auth, users };
};

const mapDispatch = (dispatch) => ({
    GetAll: () => dispatch(UserAction.GetAll())
    // GetAllApplication: () => dispatch(ApplicationAction.GetAllApplication()),
    // GetApplication: (appName) => dispatch(ApplicationAction.GetApplication(appName)),
    // GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName)),
})

export default connect(mapState, mapDispatch)(RAYSelectAdmin);
