export const VersionTypes = [
    { value : "alpha", label : "Alpha", comment : "개발중인 버전" },
    { value : "beta", label : "Beta", comment : "SQA 또는 검증 단계중인 버전" },
    { value : "rc", label : "RC", comment : "SQA 및 검증이 완료되었으나 사용자 배포전 버전" },
    { value : "official", label : "Official", comment : "사용자에게 공식 배포된 버전" },
]

export const UpdateNoticePeriod = [
    { value : "start", label : "앱이 실행될때", comment : "앱이 실행될때 RAYTeams가 업데이트 안내를 보여줍니다." },
    { value : "n/d", label : "N회/M일", comment : "M일에 N회, RAYTeams가 업데이트 안내를 보여줍니다." },
]

export const UpdateNoticeActionTypes = [
    { value : "openbrowser", label : "웹 페이지 오픈", comment : "브라우저를 열어 아래 링크의 화면을 띄워줍니다." },
    { value : "openrayteamspath", label : "RAYTeams Path", comment : "RAYTeams의 특정화면으로 이동합니다." },
    { value : "downloadfile", label : "Download File", comment : "아래 링크에 접속하여 파일을 다운로드 합니다." },
]
