import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RAYButton from "./RAYButton";
import { athenaQuery, athenaGetAll, athenaGet } from "../../libs/athena2";
import RAYChart from "./RAYChart";
import RAYSelect from "./RAYSelect";
import { AppConstant } from "../../redux/reducers";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const RAYFilterChartItem = ({ item, style, app, callbackfilter }) => {

    const [data, setData] = useState({
        title : "",
        period : 30,
        data : []
    });
    const dispatch = useDispatch();

    const [notready, setNotready] = useState(false);
    const [show, setShow] = useState(false);
    const [total, settotal] = useState(-1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, [data.period]);

    const getData = async () => {
        setLoading(true);
        const q = "select " + item.grp + " , SUBSTR(cast(yymmddhh as varchar), 1, 8) as dt, sum(cnt) as tcnt " +
            " from rayteams_filter_results_" + item.fid + 
            " where yymmddhh > " + (moment().add(-1 * data.period, "day").format("yyyyMMDD") + "00") + 
            " group by " + item.grp + ", SUBSTR(cast(yymmddhh as varchar), 1, 8) " + 
            " order by dt";
        
        try{
            const ret = await athenaQuery({ q, withdata : true, itemcount : 1000 });
            setData({...item, ...data, data : ret.data.items.map(x => ({...x, tcnt : parseInt(x.tcnt)} )), title : data.title});
            setNotready(true);
        }catch{
            setNotready(false);
        }
        setLoading(false);
    }

    const periodOptions = [
        { value : 7, label : "최근 7일" },
        { value : 14, label : "최근 14일" },
        { value : 30, label : "최근 30일" },
        { value : 60, label : "최근 60일" },
        { value : 90, label : "최근 90일" },
    ]

    const callbackclick = (e) => {
        console.log(e);
        const w = e.split('and');
        dispatch({ type: AppConstant.SET_SW_LOG_FILTER, filter : {...item, filter : [...new Set(w)].join(" and ")}});
    }

    return <div className="card mb-3" style={{ ...style }}>
        <div className="card">
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-bolder">
                        {!show && <FaAngleUp size={24} className="me-2 cursor-pointer" onClick={() => setShow(!show)} />}
                        {show && <FaAngleDown size={24} className="me-2 cursor-pointer" onClick={() => setShow(!show)} />}
                        {item.title}
                    </div>
                    <div className="d-flex align-items-center">
                        {total !== -1  && <div className="me-1 fw-bolder">{total.toLocaleString()}</div>}
                        <RAYSelect
                            items={periodOptions}
                            value={periodOptions.find(x => x.value === data.period)}
                            multi={false}
                            clearable={false}
                            callbackOnChange={({ value, label }) => setData({...data, period: value })}
                            style={{ minWidth: 100 }}
                        />
                    </div>
                </div>
            </div>
            {!loading && <>
                {notready && <div className="card-body" style={{ display: show ? "" : "none"}}>
                    <p className="w-100">Loaging...</p>
                    <RAYChart item={data} app={app} 
                        callbackclick={callbackclick}
                        callbnackcount={(t) => settotal(t)} />
                </div>}
                {!notready && <div className="card-body" style={{ display: show ? "" : "none"}}>
                    <p>데이터가 아직 준비되지 않았습니다. 잠시후에 다시 접속바랍니다.</p>
                    <RAYButton color="primary" label={"Reload"} onClick={() => getData()} />
                </div>}
            </>}
        </div>
    </div>
}

export default RAYFilterChartItem;