import { useEffect } from "react";
import { useState } from "react";
import ReactDragListView from "react-drag-listview";

const RAYDragItems = ({ items, children, callBackItems, itemQss = "div", css = "d-inline-flex gap-2 flex-wrap" }) => {
    const [dragProps, setDragProps] = useState({});

    useEffect(() => {
        setDragProps({
            onDragEnd(fromIndex, toIndex) {
                let data = [...items];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                data = data.map((x, idx) => ({ ...x, order: idx + 1 }));
                callBackItems && callBackItems({ items: data });
            },
            nodeSelector: itemQss,
            handleSelector: itemQss,
        });
    }, [items, callBackItems, itemQss]);

    return <>
        {items && Array.isArray(items) && <ReactDragListView  {...dragProps}>
            <div className={css}>
                {children}
            </div>
        </ReactDragListView>}
    </>
};

export default RAYDragItems;

