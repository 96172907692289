import { useCallback, useEffect, useState } from "react";
import { athenaQuery, athenaGetAll, athenaGet } from "../../libs/athena2";
import RAYButton from "./RAYButton";
import RAYFormRawInline from './RAYFormRawInline';
import { Query, Update, Delete } from "../../libs/dynamo";
import { getAttributeUpdatesSet } from "../../libs/functions";
import RAYTextField from './RAYTextField';
import { Store } from '../../store';
import RAYFilterChartItem from "./RAYFilterChartItem";

const RAYFilterChart = ({ style, app, callbackfilter }) => {

    const [list, setList] = useState([]);

    useEffect(() => {
        getFilters()
    }, [app]);

    const getFilters = async() => {
        const user = Store.getState().AuthReducer.user;
        const params = {
            TableName: "rayteams-manager-user",
            KeyConditions: {
                _id: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [user._id],
                },
                sk: {
                    ComparisonOperator: "BEGINS_WITH",
                    AttributeValueList: ["filter:"],
                }
            },
            QueryFilter: {
                appKey: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [app.name],
                },
            },
        };
        const ret = await Query(params);
        setList(ret.Items)
    }

    const callback = (e) => {
        callbackfilter && callbackfilter(e);
    }

    return <div style={{ ...style }}>
        {list.map((x, idx) => <RAYFilterChartItem
            callbackfilter={callback}
            app={app} item={x} key={x.fid} />)}
    </div>
}

export default RAYFilterChart;