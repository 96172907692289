import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const BulletIcon = <span className="menu-bullet"><span className="bullet bullet-dot" /></span>

const MenuItem = ({ item, isSub = false }) => {
	const [open, setOpen] = useState(true);
	const [isAccordion, setIsAccordion] = useState(false);
	const [accordionClass, setAccordionClass] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [hasFocus, setHasFucus] = useState(false);
	const location = useLocation();

	useEffect(() => {
		let inRouter = false;
		if (item?.path) {
			const locationPathArr = location.pathname.split('/');
			const pathArr = item.path.split('/');
			inRouter = pathArr.every((x, index) => x === locationPathArr[index]);
		}
		setHasFucus(inRouter || isActive)
	}, [isActive, location, item?.path])

	useEffect(() => {
		if (!item?.label) {
			return;
		}
		const _isAccordion = item?.sub?.length > 0;
		const _accordionClass = isSub ? ' menu-sub-accordion ' : (_isAccordion ? ' menu-accordion ' : '');
		setIsAccordion(_isAccordion);
		setAccordionClass(_accordionClass);
	}, [item, isSub]);

	return <>
		<div
			className={"menu-item" + (item?.sub?.length > 0 && open ? ' show ' : '') + accordionClass + (isSub ? ' menu-sub ' : '')}>
			<Link
				to={item?.path || '#'}
				className={"menu-link" + (hasFocus ? ' active ' : '')}
				onMouseOver={() => setIsActive(true)}
				onMouseOut={() => setIsActive(false)}
			>
				{item?.icon && <span className="menu-icon">
					<span className="svg-icon">
						{item.icon}
					</span>
				</span>}
				{!item?.icon && BulletIcon}
				<span className="menu-title">{item.label}</span>
				{item?.sub?.length > 0 && <span className="menu-arrow" onClick={() => isAccordion && setOpen(!open)} />}
			</Link>
			{(item.sub.length > 0) && item.sub.map((x, index) => <MenuItem item={x} key={index} isSub={true} />)}
		</div>
	</>
}

export default MenuItem;

