import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const ApplicationItem = ({ item, favItems, callbackFavItem }) => {

    return <>
        <div className="col-xl-4 col-lg-6 col-md-6">
            <div className='card'>
                <div className='card-header'>
                    <div className="d-flex align-items-start">
                        <div className="d-flex align-items-start">
                            <div className="avatar me-2">
                                {item?.app_icon?.direct_link && <img src={item?.app_icon?.direct_link} alt="Avatar" className="rounded-circle" />}
                                {!item?.app_icon?.direct_link && <span className="ti ti-box" size={48} />}
                            </div>
                            <div className="me-2 ms-1">
                                <h5 className="mb-0">
                                    <Link to={'/applications/' + item.name} className="stretched-link text-body">{item?.title || item?.name}</Link>
                                </h5>
                                <div className="client-info">
                                    {item?.last_published_version && <><strong>Latest Version: </strong><span className="text-muted">v{item?.last_published_version}</span></>}
                                    {!item?.last_published_version && <strong>Not published </strong>}
                                </div>
                            </div>
                        </div>
                        <div className="ms-auto z-index-1" style={{ zIndex: 1001 }}>
                            {favItems.includes(item.name) && <>
                                <AiFillStar 
                                    size={24}
                                    className="text-success cursor-pointer"
                                    onClick={() => callbackFavItem && callbackFavItem({ type: 'out', data: item.name })}
                                />
                            </>}
                            {!favItems.includes(item.name) && <>
                                <AiOutlineStar 
                                    size={24}
                                    className="cursor-pointer"
                                    onClick={() => callbackFavItem && callbackFavItem({ type: 'in', data: item.name })}
                                />
                            </>}
                        </div>
                    </div>
                </div>
                <div className="card-body border-top pt-3">
                    <div className="py-1">
                        {!item?.is_active && <div><b>STATUS :</b> <>Draft</></div>}
                        {item?.is_active && <div><b>STATUS :</b> <>Published</></div>}
                    </div>
                    <div className="py-1">
                        {item?.rayteams_apps && <div><b>TYPE :</b> <>RAYTeams App</></div>}
                        {!item?.rayteams_apps && <div><b>TYPE :</b> <>Stand-alone App</></div>}
                    </div>
                    <div className="py-1"><b>CATEGORY</b> : <>{item.category?.toUpperCase() || '-'}</></div>
                    <div className="py-1"><b>CREATED</b> : <>{moment(item.created).format('LLL')}</></div>
                    {/*
                    <div className="d-flex align-items-center gap-2">
                        {!item?.rayteams_apps && <>
                            <div className="badge bg-primary">Solo Application</div>
                        </>}
                        {item?.licenseId && <div className="badge bg-warning"><small>License ID</small> {item.licenseId}</div>}
                    </div>
                    <div className='html-content mt-3'>
                        {item?.desc && <div dangerouslySetInnerHTML={{ __html: item?.desc }} className="text-muted" />}
                        {!item?.desc && <div className="text-muted">
                            <i>None description</i>
                        </div>}
                    </div>
                    */}
                </div>
            </div>
        </div>
    </>
}

const mapState = (state) => {
    return {};
};

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(ApplicationItem);
