import { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import { ApplicationConstant } from "../../redux/reducers";
import RAYButton from "../common/RAYButton";

const AppSettingTermsNavigatorVersion = ({ app, auth, storedTerms, GetAppData, UpdateAppData }) => {
    const [selectedType, setSelectedType] = useState(null);
    const [appTerms, setAppTerms] = useState([]);
    const dispatch = useDispatch();

    const [termVersions, setTermVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);

    useEffect(() => {
        app?.name && dispatch({ type: ApplicationConstant.CLEAR_TERM, appName: app.name });
    }, [dispatch, app.name]);

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
    }, [storedTerms, app?.name]);

    useEffect(() => {
        const _selectedRoot = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_ROOT);
        setSelectedType(_selectedRoot ? appTerms.find(x => x.type === _selectedRoot.data.type) : null);
        const _selectedVersion = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION);
        setSelectedVersion(_selectedVersion ? appTerms.find(x => x.type === _selectedVersion.data.type) : null);
    }, [appTerms]);

    useEffect(() => {
        if (selectedType) {
            const prefix = TYPE.TC_VERSION + selectedType.type.replace(TYPE.TC_ROOT, "");
            setTermVersions(appTerms.filter(x => x.type.startsWith(prefix)).sort((a, b) => a.version.localeCompare(b.version, undefined, { numeric:true  }) * -1));
        } else {
            setTermVersions([]);
        }
    }, [appTerms, selectedType]);

    const handlerCreateVersion = async () => {
        if (!selectedType?.type) {
            return;
        }
        const prefix = TYPE.TC_VERSION + selectedType.type.replace(TYPE.TC_ROOT, "");
        const ret = await GetAppData({ appName: app.name, prefix });
        if (!ret) {
            toast('Server Error.', { type: 'error' });
            return;
        }
        const hasUnpublishedVersion = ret.data.find(x => !x.published);
        if (hasUnpublishedVersion) {
            toast('Exist unpublished version.', { type: 'error' });
            return;
        }
        const versionArr = ret.data.map(x => Number(x.version.replace("V", "")));
        const maxVersionNumber = versionArr.length > 0 ? Math.max(...versionArr) : 0;
        const currentVersion = 'V' + maxVersionNumber;
        const version = 'V' + (maxVersionNumber + 1);
        const newVersionType = prefix + ':' + version;
        const payload = {
            appName: app.name,
            type: newVersionType,
            item: {
                version,
                langs: [],
                isPublished: false,
                published: null,
                creator: auth.sub,
                updater: auth.sub,
                created: new Date().getTime(),
            }
        };
        await UpdateAppData(payload);
        if (maxVersionNumber > 0) {
            const CurrentPublishedVersion = ret.data.find(x => x.version === currentVersion);
            const langsPrefix = TYPE.TC_DOCS + CurrentPublishedVersion.type.replace(TYPE.TC_VERSION, '');
            const langArr = await GetAppData({ appName: app.name, prefix: langsPrefix });
            if (!langArr) {
                toast('Load Language error', { type: 'error' });
                return;
            }
            for(const existLang of langArr.data) {
                const langPayload = {
                    appName: app.name,
                    type: existLang.type.replace(currentVersion, version),
                    item: {
                        title: existLang.title,
                        contents: existLang.contents,
                        lang: existLang.lang,
                        version: version,
                        versionType: prefix + ':' + version,
                        creator: auth.sub,
                        updater: auth.sub,
                        created: new Date().getTime(),
                        isPublished: false,
                        isReady: false,
                    }
                };
                await UpdateAppData(langPayload);
            }
            const releaseParams = {
                appName: app.name,
                type: newVersionType.replace(TYPE.TC_VERSION, TYPE.TC_VERSION_RELEASE),
                item: {
                    title: '',
                    contents: '',
                    isPublished: false,
                    isReady: false,
                    version: version,
                    creator: auth.sub,
                    updater: auth.sub,
                    created: new Date().getTime(),
                }
            };
            await UpdateAppData(releaseParams);
        }
    };

    const handlerClickVersion = async (ver) => {
        const isSelected = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION)?.rowType === ver.type;
        if (isSelected) {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION });
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION_RELEASE });
        } else {
            const prefix = TYPE.TC_DOCS + ver.type.replace(TYPE.TC_VERSION, "");
            const ret = await GetAppData({ appName: app.name, prefix });
            if (ret?.status === 'success') {
                dispatch({
                    type: ApplicationConstant.SET_TERM, appName: app.name, item: {
                        type: TYPE.EXTRA_SELECT_VERSION,
                        rowType: ver.type,
                        data: ver
                    }
                });
            }
        }
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
    };

    return <>
        {selectedType?.type && <>
            <h4 className="d-flex align-items-center justify-content-between mt-3" style={{ minHeight: 45 }}>
                <div>{selectedVersion ? "Version" : "Versions"}</div>
                {!selectedVersion && <RAYButton
                    label="Create"
                    color="light"
                    onClick={handlerCreateVersion}
                />}
            </h4>
            {termVersions?.length === 0 && <div className="text-muted text-center my-2">
                <i>None Versions</i>
            </div>}
            {termVersions?.length > 0 && <ListGroup>
                {termVersions.filter(x => selectedVersion ? x.type === selectedVersion.type : true).map(x => <ListGroup.Item
                    key={x.type}
                    action={true}
                    onClick={() => handlerClickVersion(x)}
                    active={selectedVersion?.type === x.type}
                >
                    <div className="d-flex w-100 align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <div>{x.version}</div>
                                {x.isPublished && <div className={`small text-${selectedVersion?.type === x.type ? 'black' : 'muted'}`}>
                                    {x.langs.length} langs
                                </div>}
                                {!x.isPublished && <div className={`small text-${selectedVersion?.type === x.type ? 'black' : 'muted'}`}>
                                    Unpublished
                                </div>}
                            </div>
                        </div>
                        <div className="flex-fill" />
                        <div>
                            {x.isPublished && <div className="badge bg-primary">
                                Published
                            </div>}
                            {!x.isPublished && <div className="badge bg-light">
                                Draft
                            </div>}
                        </div>
                    </div>
                </ListGroup.Item>)}
            </ListGroup>}
        </>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsNavigatorVersion);
