import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationAction, ApplicationVersionAction } from "../../redux/actions";
import RAYButton from "./RAYButton";
import { TYPE } from "../../constants/appTerms";
import RAYSelectAdmin from "./RAYSelectAdmin";
import RAYSelect from "./RAYSelect";
import RAYTable from "./RAYTable";
import { Query } from "../../libs/dynamo";
import { LANGUAGE } from "../../constants";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement,ArcElement, Title, Tooltip, Legend, Colors);

const RAYChart = ({ item, callbnackcount, app, callbackclick }) => {
    const [dataChart, setDataChart] = useState({});
    const [allInfo, setAllInfo] = useState([]);
    const [xval, setXval] = useState([]);
    const options= {
        plugins: { legend: { display: false } },
        maintainAspectRatio: false,
        responsive: true,
        interaction: { intersect: false, },
        scales: { x: { stacked: true, }, y: { stacked: true, }, },
        onClick: function(evt, element) {
            if(element.length > 0) {
                var ind = element[0]._index;
                const dd = data.datasets[0].data[ind];
            }
        }
    }

    useEffect(() => {
        setInit();
    }, [item]);

    const sumByKey = (array, key) => {
        return array.reduce((sum, item) => {
            return sum + (item[key] || 0);
        }, 0);
    }

    const setInit = () => {
        if(item.data){

            const labels_tmp = []
            for(var d = 0; d < item?.period; d++){
                labels_tmp.push(moment().add( -1 * d, "days").format("YYYY-MM-DD"));
            }
            const labels = labels_tmp.reverse();
            setXval(labels);

            let totals = [];
            let dtset = [];
            for(const col of [...new Set(item.data.map(x => x[item.grp]))]){
                const adata = [];
                totals.push({
                    label : col, 
                    tcnt : sumByKey(item.data.filter(c => c[item.grp] === col), "tcnt")
                })
                labels.map(l => {
                    const cnt = item.data.find(x => x[item.grp] === col && x.dt === l.replace("-", "").replace("-", ""))?.tcnt;
                    adata.push(cnt ? parseInt(cnt) : 0);
                });
                dtset.push({ label: col, data: adata });
            }
            setAllInfo(totals);
            callbnackcount && callbnackcount(sumByKey(totals, "tcnt"));

            setDataChart({
                labels : labels.map(x => x.substring(5, 10)),
                datasets: dtset,
            })
        }
    }

    const makeFilter = (date, col) => {
        console.log(date, col);
        const start = moment.utc(date).valueOf();
        const end = moment.utc(date).add(1, "day").valueOf();
        const wh = "tp='" + app.alkey + "' and " + item.grp + "='" + col + "' and " + (item.wh ? item.wh + " and " : " ") + " st >= " + start + " and st < " + end;
        const w = wh.split('and');
        callbackclick && callbackclick([...new Set(w.map(n=>n.trim()))].join(" and "));
    }

    return <div className="mb-2">
        <div className="d-flex justify-content-between align-items-center ">
            {/*<h4>
                <small className="ms-2 fs-6">
                    {allInfo.map(x => <span key={x.label}>
                        {x.label} : {x.tcnt.toLocaleString()}
                    </span>)}
                </small>
            </h4>*/}
            {/*<div className="text-muted small">
                이 집계는 RAYTeams v1.0.36.x 이상(2023.10.05)부터 집계됨
            </div>*/}
        </div>
        <div style={{ height : "400px" }}>
            {dataChart.datasets && <Bar options={{
                    plugins: { legend: { display: false } },
                    maintainAspectRatio: false,
                    responsive: true,
                    interaction: { intersect: false, },
                    scales: { x: { stacked: true, }, y: { stacked: true, }, },
                    onClick: function(evt, element) {
                        if(element.length > 0) {
                            var ind = element[0].index;
                            var dind = element[0].datasetIndex;
                            const date = xval[ind];
                            const col = dataChart.datasets[dind].label;
                            makeFilter(date, col);
                        }
                    }
                }
            } data={dataChart} />}
        </div>
    </div>
}

export default RAYChart;
