import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Badge, Card, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import RAYButton from "../../../components/common/RAYButton";
import RAYTextField from "../../../components/common/RAYTextField";
import ApplicationToolbar from "../../../components/toolbars/ApplicationToolbar";
import PageInOutHook from "../../../hooks/PageInOutHook";
import { ApplicationAction } from "../../../redux/actions/ApplicationAction";

const AppTemplates = ({ apps, auth, allTemplates, GetAppTemplates, CopyAppTemplate }) => {
    const { appName } = useParams();
    const [app, setApp] = useState({});
    const [templates, setTemplates] = useState([]);
    const [setToolbar] = PageInOutHook();
    const navigator = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [targetApp, setTargetApp] = useState({});
    const [copyVersion, setCopyVersion] = useState("");
    const [readyCopy, setReadyCopy] = useState(false);

    useEffect(() => {
        if (appName && auth?.pc) {
            GetAppTemplates({ appName, type: "t:header" });
        }
    }, [GetAppTemplates, appName, auth?.pc]);

    useEffect(() => {
        setTemplates(allTemplates[appName]?.items?.filter(x => x.type.startsWith("t:header")) || []);
    }, [appName, allTemplates]);

    useEffect(() => {
        appName && apps.length && setApp(apps.find(x => x.name === appName));
    }, [apps, appName]);

    useEffect(() => {
        if (app?.name) {
            setToolbar(<ApplicationToolbar
                title={<>{app.title} <small>Homepage Contents</small></>}
                appName={app.name}
                suffixDom={<div>
                    <Link to={`/applications/${app.name}`} className="btn btn-warning me-1">Application Detail</Link>
                    <Link to={`/applications/${app.name}/templates/create`} className="btn btn-success">Create New Content Set</Link>
                </div>}
            />);
        }
    }, [app, setToolbar]);

    const handlerClickCreateNewTemplate = () => {
        navigator(`/applications/${app.name}/templates/create`);
    };

    const handlerClickModify = (version) => {
        navigator(`/applications/${app.name}/templates/${version}`)
    };

    const handlerClickCopy = (_app) => {
        console.log(_app);
        setTargetApp(_app);
        setModalShow(true);
    };

    const closeCopyModal = () => {
        setModalShow(false);
        setTargetApp({});
        setCopyVersion("");
        setReadyCopy(false);
    }

    const handlerClickModalCopy = async () => {
        const _copyVersion = copyVersion.trim();
        if (!_copyVersion) {
            return;
        }
        if (!readyCopy) {
            setReadyCopy(true);
            return;
        }
        await CopyAppTemplate({
            appName: app.name,
            targetVersion: targetApp.version,
            reVersion: _copyVersion,
        });
        closeCopyModal();
    };

    return <>
        <div className="pb-5">
            <div>
                {templates.length === 0 && <div>
                    <div>There are no templates in this app.</div>
                    <RAYButton
                        color="primary"
                        label="Create New Content Set"
                        onClick={handlerClickCreateNewTemplate}
                    />
                </div>}
                {templates.length > 0 && <div>
                    {templates.map(x => <div key={x.type}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-2 mb-1">
                                <div className="card-title">
                                    <h4>
                                        v{x.version}
                                        {/*
                                        <span bg={x.isVisible ? "badge bg-primary" : "badge bg-secondary"}>
                                            {x.isVisible ? "Visible" : "None Visible"}
                                        </span>
                                        <span bg={x.isPreventUnpublish ? "badge bg-success" : "badge bg-secondary"}>
                                            {x.isPreventUnpublish ? "Prevent" : "None Prevent"}
                                        </span>
                                        */}
                                    </h4>
                                    <div className="d-flex gap-1">
                                        <small>
                                            <div>{x.created && <span>Created : {moment(x.created).format("LLL")}</span>}</div>
                                            <div>{x.updated && <span>Last Updated : {moment(x.updated).format("LLL")}</span>}</div>
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <RAYButton
                                        label="Copy"
                                        color="secondary"
                                        onClick={() => handlerClickCopy(x)}
                                        css="me-1"
                                    />
                                    <RAYButton
                                        label="Modify"
                                        color="primary"
                                        onClick={() => handlerClickModify(x.version)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>}
            </div>
        </div>
        <Modal show={modalShow} onHide={closeCopyModal}>
            <Modal.Header>
                <Modal.Title>
                    Copy Content Set {targetApp?.version}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>New Version</h3>
                <div className="mt-4">
                    <RAYTextField
                        value={copyVersion}
                        placeholder={"new version."}
                        onChange={(event) => setCopyVersion(event.target?.value?.replace(/[^0-9\.]/g, ""))}
                    />
                </div>
                <div className="my-5 fw-bolder fs-4 d-flex gap-2">
                    <span>Copy</span>
                    <span className="text-muted">{targetApp?.version}</span>
                    <span>to</span>
                    <span className="text-primary">{copyVersion || "..."}</span>
                </div>
                <div className="d-flex justify-content-end">
                    <RAYButton
                        label={readyCopy ? "Really Copy?" : "Copy"}
                        color="primary"
                        onClick={handlerClickModalCopy}
                    />
                </div>
            </Modal.Body>
        </Modal>
    </>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    CopyAppTemplate: ({ appName, targetVersion, reVersion }) => dispatch(ApplicationAction.CopyAppTemplate({ appName, targetVersion, reVersion })),
});

export default connect(mapState, mapDispatch)(AppTemplates);
