import { useEffect, useState } from "react";
import { passwordRegex, passwordRegexLower, passwordRegexNumber, passwordRegexSpecial, passwordRegexUpper } from "../../libs/regexs";

const PasswordGuide = ({ password }) => {
    const [allPass, setAllPass] = useState(false);
    const [lowerCase, setLowerCase] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [lengthCase, setLengthCase] = useState(false);
    const [numberCase, setNumberCase] = useState(false);
    const [specialCase, setSpecialCase] = useState(false);

    useEffect(() => {
        const target = (password || '').trim();
        setLowerCase(passwordRegexLower.test(target));
        setUpperCase(passwordRegexUpper.test(target));
        setLengthCase(target.length >= 8);
        setNumberCase(passwordRegexNumber.test(target));
        setSpecialCase(passwordRegexSpecial.test(target));
        setAllPass(passwordRegex.test(target));
    }, [password]);



    return <>
        <div className="text-start">
            The password has the following rules:
            <ul className='mt-2'>
                <li className={lengthCase ? 'text-success' : 'text-danger'}>At least 8 characters</li>
                <li className={upperCase ? 'text-success' : 'text-danger'}>At least one uppercase letter</li>
                <li className={lowerCase ? 'text-success' : 'text-danger'}>One or more lowercase letters</li>
                <li className={numberCase ? 'text-success' : 'text-danger'}>One or more numbers</li>
                <li className={specialCase ? 'text-success' : 'text-danger'}>One or more special characters</li>
                <li className={allPass ? 'text-success' : 'text-danger'}>Pass all rules</li>
            </ul>
        </div>
    </>
}

export default PasswordGuide;