import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CheckClientHook from '../../hooks/CheckClientHook';

import DocAside from './DocAside';
import Header from './Header';
import LoadingSpinner from './LoadingSpinner';

const Layout = ({ children, isLogin, toolbar }) => {
    const navigate = useNavigate();
    CheckClientHook()

    useEffect(() => {
        if (!isLogin) {
            navigate('/login');
        }
    }, [isLogin, navigate]);

    return <>
        {toolbar}
        <div>
            {children}
            <DocAside />
            <LoadingSpinner />
        </div>
    </>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const toolbar = state.AppReducer.toolbar;
    return { isLogin, toolbar };
};

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Layout);
