import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { saveFile } from "../libs/fileSaver";
import { AppConfig } from "../libs/config";

const DownloadHook = () => {
    const [progress, setProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);

    const updateProgress = useCallback((event) => {
        const { loaded, total } = event
        const percent = Math.ceil(loaded / total * 100);
        setProgress(percent < 100 ? percent : 0)
    }, []);

    const downloadFile = useCallback(async (link, name) => {
        if (!link || !name) {
            toast('Wrong File', { type: 'error' })
            return;
        }
        setIsDownloading(true);
        if (link.startsWith('https://')) {
            await saveFile(link, name, updateProgress);
        } else {
            await saveFile(AppConfig.REACT_APP_S3_PATH + link, name, updateProgress);
        }
        setIsDownloading(false);
    }, [updateProgress]);


    return [downloadFile, progress, isDownloading];
}

export default DownloadHook;
