import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import RAYButton from "../common/RAYButton";
import RAYTextField from "../common/RAYTextField";
import UserDetailModal from "../user/UserDetailModal";

const UsersToolbar = ({ auth, callbackQ }) => {
    const [showCreate, setShowCreate] = useState(false);
    const [q, setQ] = useState("");
    useEffect(() => {
        callbackQ && callbackQ(q);
    }, [q, callbackQ])
    return <>
        <div className="toolbar">
            <div className="toolbar-wrapper">
                <div className="page-title">
                    <h1>Users</h1>
                    {/* <span className="border-seperate"></span>
                <small>{appName}</small> */}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    {callbackQ && <RAYTextField
                        value={q || ""}
                        placeholder={"Search"}
                        onChange={e => setQ(e.target.value)}
                    />}
                    {(auth?.is_super_admin || auth?.is_account_manager) && <>
                        <RAYButton
                            label="Add Admin"
                            color="primary"
                            style={{ width: 200, maxWidth: 200 }}
                            onClick={() => setShowCreate(true)}
                        />
                    </>}
                </div>
            </div>
        </div>
        <div>
            <UserDetailModal show={showCreate} callbackClose={() => setShowCreate(false)} isCreate={true} />
        </div>
    </>
}
const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({

})

export default connect(mapState, mapDispatch)(UsersToolbar);
