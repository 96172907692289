import React, { useState } from "react";
import { useEffect } from "react";
import { Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import RAYButton from "./RAYButton";
import RAYTextField from "./RAYTextField";
import ReactDragListView from "react-drag-listview";
import { MdClose } from "react-icons/md";


export const RAYTags = ({ items, as = "div", css = "", emptyValue, orderKey = "order", callbackTagChange }) => {
    const [Wrapper] = useState(() => as ? (children) => React.createElement(as, children) : (children) => <>{children}</>);
    const [hasValues, setHasValues] = useState(false);
    const [tagList, setTagList] = useState([]);
    const [dragProps, setDragProps] = useState({});

    useEffect(() => {
        setDragProps({
            onDragEnd(fromIndex, toIndex) {
                let data = [...tagList];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                data = data.map((x, idx) => ({ ...x, order: idx + 1 }));
                setTagList(data);
                callbackTagChange && callbackTagChange({ items: data });
            },
            nodeSelector: 'span',
            handleSelector: 'span',
        });
    }, [tagList, callbackTagChange]);


    useEffect(() => {
        const _valid = items && Array.isArray(items) && items?.length > 0;
        setHasValues(_valid);
        setTagList(_valid ? [...items].sort((a, b) => a[orderKey] < b[orderKey] ? -1 : 1) : []);
    }, [items, orderKey]);

    const handlerRemoveTag = (tag) => {
        const _tags = tagList.filter(x => x.tag !== tag.tag);
        setTagList(_tags);
        callbackTagChange && callbackTagChange({ items: _tags, item: tag });
    };

    return <Wrapper className={css}>
        {hasValues && <>
            <ReactDragListView  {...dragProps}>
                <div className="d-inline-flex gap-1 flex-wrap">
                    {tagList.map(tag => <Badge key={tag.tag}>
                        <span>{tag.tag}</span>
                        <MdClose 
                            className="ms-2" 
                            style={{ cursor: "pointer" }}
                            onClick={() => handlerRemoveTag(tag)}
                        />
                    </Badge>)}
                </div>
            </ReactDragListView>
        </>}
        {!hasValues && <>
            {emptyValue ? emptyValue : "-"}
        </>}
    </Wrapper>
};

export const RAYTagInput = ({ items, as = "div", callbackAddTag, css = "" }) => {
    const [data, setData] = useState({});
    const [Wrapper] = useState(() => as ? (children) => React.createElement(as, children) : (children) => <>{children}</>);

    const clear = () => {
        setData({});
    };

    const handlerClickAddTag = () => {
        if (!data?.val || !data.val.trim()) {
            return;
        }
        const _tag = data.val.trim();
        if (items && items.map(x => x.tag).includes(_tag)) {
            toast(`"${_tag}" is a tag that exists.`, { type: "error" });
            clear();
            return;
        }
        const tagItem = {
            tag: _tag,
            order: (items || []).length + 1,
        };
        callbackAddTag && callbackAddTag({ items: [...(items || []), tagItem], item: tagItem });
        clear();
    };

    return <Wrapper className={css}>
        <div className="d-inline-flex align-items-center">
            <RAYTextField
                value={data?.val || ""}
                placeholder="Insert Tag"
                size="sm"
                onChange={e => setData(prev => ({ ...prev, val: e.target.value }))}
                enterSubmit={handlerClickAddTag}
            />
            <RAYButton
                label="Add Tag"
                color="primary"
                size="sm"
                style={{ flexShrink: 0 }}
                onClick={handlerClickAddTag}
            />
        </div>
    </Wrapper>
}

