import { useState } from "react";
import { toast } from "react-toastify";
import RAYButton from "../common/RAYButton";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
//import Editor, { EDITOR_CONST } from "../tiptap/Editor";
import TinyEditor from "../tinymce/TinyEditor";

const AppTemplateForm = ({ app, create = false, template, callbackClickConform, callbackClickCancel }) => {
    const [data, setData] = useState({
        version: "",
        isVisible: false,
        isPreventUnpublish: false,
        desc: "",
    });

    const handlerClick = () => {
        if (!data?.version) {
            toast("Missing version", { type: "error" });
            return;
        }
        callbackClickConform && callbackClickConform(data);
    };

    const handlerClickCancel = () => {
        callbackClickCancel && callbackClickCancel();
    };

    return <div className="card">
        <div className="card-body">
            <RAYFormRawInline title="Content Set Version" labelCol={3} required={true}>
                <RAYTextField
                    value={data.version || ""}
                    placeholder="ex) 1.0.3"
                    onChange={e => setData(prev => ({ ...prev, version: e.target.value.replace(/[^a-z0-9.]/g, "") }))}
                />
            </RAYFormRawInline>
            <RAYFormRawInline title="Visible" labelCol={3}>
                <RAYToggle
                    value={data?.isVisible || ""}
                    trueLabel="Visible"
                    disabled={create}
                    falseLabel="None"
                    onChange={e => setData(prev => ({ ...prev, isVisible: e.target.checked }))}
                />
                {create && <div className="text-warning ms-2">You cannot activate this setting when you create a template.</div>}
            </RAYFormRawInline>
            <RAYFormRawInline title="Prevent Unpublish" labelCol={3}>
                <RAYToggle
                    value={data?.isPreventUnpublish || ""}
                    trueLabel="Prevent Unpublish"
                    falseLabel="Unpublish from other versions"
                    onChange={e => setData(prev => ({ ...prev, isPreventUnpublish: e.target.checked }))}
                />
            </RAYFormRawInline>
            <RAYFormRawInline title="Content Set Description" labelCol={3}>
                <div className="w-100">
                    <div className="form-control form-control-lg">
                        <TinyEditor
                            originContent={""}
                            callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                            height={300}

                            //content={data.desc}
                            //callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                            //type={EDITOR_CONST.TYPE.HTML}
                        />
                    </div>
                    <i className="text-muted">Content Set descriptions are only used by developers.</i>
                </div>
            </RAYFormRawInline>
            <RAYFormRawInline labelCol={3} css="mt-3">
                <RAYButton 
                    css="me-1"
                    label={create ? "Create" : "Update"}
                    color="primary"
                    onClick={handlerClick}
                />
                <RAYButton 
                    label="Cancel"
                    color="secondary"
                    onClick={handlerClickCancel}
                />
            </RAYFormRawInline>
        </div>
    </div>
};

export default AppTemplateForm;
