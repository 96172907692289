import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import RAYButton from "../common/RAYButton";
import { getEnv } from "../../libs/config";
import { UploadS3 } from "../../libs/s3";
import RAYTable from "../common/RAYTable";
import { TYPE } from "../../constants/appTerms";
import { versionSort } from "../../libs/functions";
import RAYSelectAdmin from "../../components/common/RAYSelectAdmin";
import { RAYPanelNoRecord } from "../../components/common/RAYPanel";
import RAYSelect from "../../components/common/RAYSelect";
import { LANGUAGE } from "../../constants";
import { ApplicationCMSAction } from "../../redux/actions/ApplicationCMSAction";
import { AppCMSContentsHeader } from '../../constants/tableHeaders';
import AppSettingCMSEdit from "../../components/application/AppSettingCMSEdit";

const VIEW_TYPE = {
    LIST: 'list',
    EDIT: 'edit',
    VIEW: 'view'
};

const AppCMSView = ({ app, version, templates, lang, setLang }) => {
    const storedLanguages = useSelector(state => state.ApplicationReducer.languages);
    const [viewType, setViewType] = useState(VIEW_TYPE.LIST);
    const [data, setData] = useState({});
    const [lan, setLan] = useState("");
    const [pk, setPk] = useState("");
    const [type, setType] = useState("");
    const [list, setList] = useState([]);
    const [params, setParams] = useSearchParams();
    const [helpall, setHelpAll] = useState([]);
    const [allitems, setAllItems] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(allitems.length > 0)
            getList();
    }, [allitems]);

    useEffect(() => {
        getAllList();
    }, [app, version, lang]);

    useEffect(() => {
        if (params.get("pk")){
            setPk(params.get("pk"));
        }else{
            setPk("");
            getList();
        }
    }, [params]);

    const getLang = (code) => {
        return LANGUAGE.find(x => x.code === code)?.label || code;
    }

    const remove = async () => {
    }

    const getCommon = async () => {
        const tmpList = await ApplicationCMSAction.GetCMSAllVersionContents({ 
            appName : app.name
        });
        return tmpList;
    }

    const getHelpPath = () => {
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/"+ app.name + "/help-" 
                + version + "_" + lang
                + ".json";
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/"+ app.name 
                + "/help-" + version + "_" + lang
                + ".json";
        }

        //let langs = [];
        //app.langs.map(l => {
        //    if(getEnv() === "production"){
        //        langs.push(<div>https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/{app.name}/help{version}_{l}.json</div>);
        //    }else{
        //        langs.push(<div>https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/{app.name}/help{version}_{l}.json</div>);
        //    }
        //});
        //return langs
    }

    const publish = async () => {
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/applications/"+ app.name + "/help-" + version + "_" + lang + ".json",
            list
        );
        alert("OK");
    }

    const getLangData = (ver, code) => {
        let langs = [];
        app.langs?.map(l => {
            const tmp = allitems.find(x => x.sk.split(":")[1] === ver && x.sk.split(":")[2] === l && x.code.split(":")[1] === code);
            if(tmp){
                langs.push(<Link to={"/applications/" + app.name + "/cms/" + ver + "?lang=" + l} className="me-1" > {getLang(l)} </Link>);
            }else{
                const tmpEx = allitems.find(x => x.sk.split(":")[1] === ver && x.code.split(":")[1] === code);
                if(tmpEx){
                    langs.push(<button className='btn btn-sm btn-dark me-1' 
                        onClick={() => copyCreate(tmpEx.pk, ver, l)}>{getLang(l)} - Copy/Create({getLang(tmpEx.sk.split(":")[2])})</button>);
                }
            }
        });
        return langs
    }

    const getAllList = async () => {
        const tmpList = await ApplicationCMSAction.GetCMSAllVersionContents({ 
            appName : app.name
        });
        setAllItems(tmpList);
    }

    const getList = async () => {
        const tmpallitems = allitems
            .filter(x => x.sk.split(":")[2] === lang)
            .filter(x => x.sk.split(":")[1].localeCompare(version) <= 0)
            .map(x => {
            return {...x,
                compare : x.code + x.sk,
                version : x.sk.split(":")[1],
                code : x.code,
                lang : x.sk.split(":")[2],
            }
        });
        let allnewitems = [];
        [...new Set(tmpallitems?.map(x => x.code))].map(x => {
            allnewitems.push(versionSort(tmpallitems.filter(y => y.code === x))[0]);
        });
        setList(allnewitems.map(x => {
            const codeTmp = x.code.split(":");
            const skTmp = x.sk.split(":");
            return {...x,
                title : <Link to={"/applications/" + app.name + "/cms/" + skTmp[1] + "?lang=" + lang + "&pk=" + x.pk}>{x.title}</Link>,
                code : codeTmp[1],
                version : (skTmp[1] !== version) ? <i>{skTmp[1]}</i> : <b>{skTmp[1]}</b>,
                lang : getLangData(skTmp[1], codeTmp[1]),
                link : x.link ? "Y" : "N",
                updated : moment(x.updated).format("YYYY-MM-DD hh:mm:ss"),
            }
        }));
    }

    const copyCreate = async (pk, ver, newlang) => {
        const tmpItem = await ApplicationCMSAction.CopyCreateCMSContent(pk, newlang);
        navigate("/applications/" + app.name + "/cms/" + ver + "?lang=" + newlang + "&pk=" + tmpItem.pk + "&type=edit");
    }

    return <>
        <div>
            {(!pk && params.get("type") !== "new") && <div className="border-card">
                <div className="d-flex justify-content-between w-100 mt-2 align-items-center">
                    <h4>Contents</h4>
                    <div className="d-flex">
                        <button className="btn btn-success me-2" onClick={() => publish()}>Publish(Version : {version})</button>
                        <button className="btn btn-primary" onClick={() => {
                            navigate("/applications/" + app.name + "/cms/" + version + "?lang=" + lang + "&type=new");
                        }}>Add Content</button>
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-body">
                        <div className="fw-bolder">Help file link</div>
                        <a href={getHelpPath()} target="_blank">{getHelpPath()}</a>
                    </div>
                </div>
                {list.length > 0 && <RAYTable
                    tableHeaders={AppCMSContentsHeader}
                    tableData={list}
                    useSort
                    usePaginate={true}
                />}
                {list.length === 0 && <RAYPanelNoRecord />}
            </div>}
            {(pk || params.get("type") === "new") && <div className="mb-2">
                <AppSettingCMSEdit 
                    version={version} 
                    pk={pk} 
                    edit={params.get("type") === "edit" || params.get("type") === "new"} 
                    lang={lang} 
                    appName={app.name} />
            </div>}
        </div>
    </>
}

export default AppCMSView;
