import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LANGUAGE } from "../../constants";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYButton from "../common/RAYButton";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";
import TinyEditor from "../tinymce/TinyEditor";
import LangArea from "./LangArea";

const AppTemplateFormIntroduction = ({ app, auth, lang, setLang, version, templates, publishable = false, UpdateAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [intro, setIntro] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        if (lang) {
            setIntro(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:intro:${lang}`) || {});
        }
    }, [templates, version, app.name, lang]);

    useEffect(() => {
        if (intro?.type && header.version === version) {
            setData({ ...intro });
        } else {
            setData({});
        }
    }, [intro, header?.version, version, app.name])

    const handlerClick = async () => {
        const item = {
            ...data,
            name: app.name,
            type: `t:body:${version}:intro:${lang}`,
        };
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const handlerChangeSummary = (e) => {
        const _value = e.target.value;
        // if (_value.length > 50) {
        //     return;
        // }
        setData(prev => ({ ...prev, summary: _value }));
    };

    return <div className="d-flex gap-2">
        <div style={{ maxWidth: 200, minWidth: 200 }}>
            <LangArea langs={header.langs || []} css="w-100" lang={lang} setLang={setLang} />
        </div>
        <div className="flex-fill">
            <div className="h5">
                {lang && <>
                    {LANGUAGE.find(l => l.code === lang)?.label || lang}
                </>}
                {!lang && <div className="text-muted">
                    <i>Please select a language.</i>
                </div>}
            </div>
            {lang && <div key={lang}>
                <RAYFormRawInline title="App Summary" labelCol={2}>
                    <div className="w-100">
                        <RAYTextField
                            value={data.summary || ""}
                            onChange={handlerChangeSummary}
                            placeholder="Title Summary"
                        />
                    </div>
                </RAYFormRawInline>
                <RAYFormRawInline title="App Introduction" labelCol={2}>
                    <div className="w-100">
                        <TinyEditor
                            originContent={intro?.introduction}
                            callbackOnChange={(content) => setData(prev => ({ ...prev, introduction: content }))}
                            height={400}
                        />
                    </div>
                </RAYFormRawInline>
                <RAYFormRawInline labelCol={2} css="mt-3">
                    <RAYButton
                        label="Save"
                        color="primary"
                        onClick={handlerClick}
                    />
                </RAYFormRawInline>
            </div>}

        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormIntroduction);
