import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppTemplateFormBasic from "../../../components/appTemplates/AppTemplateFormBasic";
import AppTemplateFormFAQ from "../../../components/appTemplates/AppTemplateFormFAQ";
import AppTemplateFormFeature from "../../../components/appTemplates/AppTemplateFormFeature";
import AppTemplateFormIntroduction from "../../../components/appTemplates/AppTemplateFormIntroduction";
import AppTemplateFormManual from "../../../components/appTemplates/AppTemplateFormManual";
import AppTemplateFormManualDev from "../../../components/appTemplates/AppTemplateFormManualDev";
import AppTemplateFormMovie from "../../../components/appTemplates/AppTemplateFormMovie";
import AppTemplateFormScreenshot from "../../../components/appTemplates/AppTemplateFormScreenshot";
import RAYTabs from "../../../components/common/RAYTabs";
import ApplicationToolbar from "../../../components/toolbars/ApplicationToolbar";
import PageInOutHook from "../../../hooks/PageInOutHook";
import { ApplicationAction } from "../../../redux/actions/ApplicationAction";

const TAB_CODES = {
    BASIC: "basic",
    INTRODUCTION: "introduction",
    FEATURE: "feature",
    MANUALS: "manuals",
    DEV_MANUALS: "dev_manuals",
    FAQ: "faq",
    SCREENSHOT: "screenshot",
    MOVIES: "movies",
}

const AppTemplate = ({ apps, auth, allTemplates, GetAppTemplates }) => {
    const { appName, version } = useParams();
    const [app, setApp] = useState({});
    const [templates, setTemplates] = useState([]);
    const [header, setHeader] = useState({});
    const [lang, setLang] = useState("");

    const [setToolbar] = PageInOutHook();
    const navigator = useNavigate();
    const [tabs, setTabs] = useState([
        { label: "Basic", code: TAB_CODES.BASIC, confirmed: false },
        { label: "Introduction", code: TAB_CODES.INTRODUCTION, confirmed: false },
        { label: "Core Features", code: TAB_CODES.FEATURE, confirmed: false },
        { label: "Screenshot", code: TAB_CODES.SCREENSHOT, confirmed: false },
        { label: "Movie", code: TAB_CODES.MOVIES, confirmed: false },
        { label: "FAQ", code: TAB_CODES.FAQ, confirmed: false },
        { label: "Manuals", code: TAB_CODES.MANUALS, confirmed: false },
        /*{ label: "Dev Manuals", code: TAB_CODES.DEV_MANUALS, confirmed: false },(*/
    ]);
    const [tab, setTab] = useState(tabs[0]);

    useEffect(() => {
        if (app?.name) {
            setToolbar(<ApplicationToolbar
                title={<>{app.title} <small>Homepage Content : {version}</small></>}
                appName={app.name}
                suffixDom={<div>
                    <Link to={`/applications/${app.name}`} className="btn btn-warning me-1">Application Detail</Link>
                    <Link to={`/applications/${app.name}/templates`} className="btn btn-dark me-1">Content versions</Link>
                    <Link to={`/applications/${app.name}/templates/create`} className="btn btn-success">Create New Content Set</Link>
                </div>}
            />);
        }
    }, [app, version, setToolbar]);

    useEffect(() => {
        app.name && setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        if (appName && auth?.pc && version) {
            GetAppTemplates({ appName, type: `t:body:${version}` });
            GetAppTemplates({ appName, type: `t:header:${version}` });
        }
    }, [GetAppTemplates, appName, version, auth?.pc]);

    useEffect(() => {
        setTemplates(allTemplates[appName]?.items?.filter(x => x.type.startsWith(`t:body:${version}`) || x.type.startsWith(`t:header:${version}`)) || []);
    }, [appName, version, allTemplates]);

    useEffect(() => {
        appName && apps.length && setApp(apps.find(x => x.name === appName));
    }, [apps, appName]);

    return <div className="pb-5">
        <div className="card p-2 mb-2">
            <RAYTabs tabs={tabs} tab={tab} setTab={setTab} hideLine={true} itemCss="px-2 py-2" css="" />
        </div>
        <div className="card p-2">
            <div className="card-body">
                {app?.name && <div className="d-flex gap-2">
                    <div className="flex-fill">
                        {tab.code === TAB_CODES.BASIC && <AppTemplateFormBasic app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        {tab.code === TAB_CODES.INTRODUCTION && <AppTemplateFormIntroduction app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        {tab.code === TAB_CODES.FEATURE && <AppTemplateFormFeature app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        {tab.code === TAB_CODES.MANUALS && <AppTemplateFormManual app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        {tab.code === TAB_CODES.DEV_MANUALS && <AppTemplateFormManualDev app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        {tab.code === TAB_CODES.FAQ && <AppTemplateFormFAQ app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        {tab.code === TAB_CODES.SCREENSHOT && <AppTemplateFormScreenshot app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        {tab.code === TAB_CODES.MOVIES && <AppTemplateFormMovie app={app} version={version} templates={templates} lang={lang} setLang={setLang} />}
                        <pre>
                            {/* {JSON.stringify(tab, null, 2)} */}
                            <br></br>
                            {/* {JSON.stringify(templates, null, 2)} */}
                            <br></br>
                            {/* {JSON.stringify(allTemplates, null, 2)} */}
                        </pre>
                    </div>
                </div>}
            </div>
        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
});

export default connect(mapState, mapDispatch)(AppTemplate);
