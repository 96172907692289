import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import RAYTable from '../../components/common/RAYTable';
import UsersToolbar from '../../components/toolbars/UsersToolbar';
import UserDetailModal from '../../components/user/UserDetailModal';
import { UsersTableHeader } from '../../constants/tableHeaders';
import PageInOutHook from '../../hooks/PageInOutHook';
import { UserAction } from '../../redux/actions';

function Users({ items, GetAll, auth }) {
  const [list, setList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const { sub } = useParams();
  const navigator = useNavigate();
  const [setToolbar] = PageInOutHook();
  const [q, setQ] = useState("");

  useEffect(() => {
    setToolbar(<UsersToolbar callbackQ={setQ} />)
  }, [setToolbar, auth, q]);

  useEffect(() => {
    setModalShow(!!sub);
  }, [sub])

  useEffect(() => {
    GetAll();
  }, [GetAll])

  useEffect(() => {
    setList(items.filter(x => q.trim() ? x.name.includes(q) || x.email.includes(q) : true).map(x => {
      x.created = moment(x.created).format('LL');
      x.roles = <div className='d-flex' style={{ gap: 2 }}>
        {(!x?.perms || x.perms?.length === 0) && <>
          <i>NONE</i>
        </>}
        {x.perms && x.perms.length > 0 && x.perms.sort((a, b) => a > b ? 1 : -1).map((perm, idx) => <div key={idx} className='badge bg-success'>
          {perm}
        </div>)}
      </div>
      x.user_status = <div className={'badge ' + (x.UserStatus === 'CONFIRMED' ? 'bg-primary' : 'bg-warning')}>{x.UserStatus}</div>
      x.actions = <>
        <Link to={`/users/${x.sub}`} className="btn btn-primary">Detail</Link>
      </>
      return x;
    }))
  }, [items, q]);

  const callbackClose = useCallback(() => {
    navigator('/users');
  }, [navigator]);

  return <>
    <div className="pt-4">
      <RAYTable
        tableHeaders={UsersTableHeader}
        tableData={list}
        useSort={true}
        usePaginate={true}
        initPageSize={20}
      />
    </div>
    <div className="pt-4">
        <UserDetailModal show={modalShow} sub={sub} callbackClose={callbackClose} />
    </div>
  </>
}


const mapState = (state) => {
  const items = state.UserReducer.items;
  const auth = state.AuthReducer.user;
  return { items, auth };
};

const mapDispatch = (dispatch) => ({
  GetAll: () => dispatch(UserAction.GetAll())
})

export default connect(mapState, mapDispatch)(Users);

