import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Delete, Get, Update } from "../../libs/dynamo";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";
import TinyEditor from "../tinymce/TinyEditor";
import { getAttributeUpdatesSet } from "../../libs/functions";

const AppReleaseDetailNote = ({ auth, version, lang, edit, callbackUpdateFunction, callbackDeleteFunction }) => {
    const [item, setItem] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        if (item?.type) {
            setData({ ...item });
        } else {
            setData({});
        }
    }, [item]);

    useEffect(() => {
        if (version?.version && lang) {
            const cb = async () => {
                const params = {
                    TableName: "rayrnd-applications",
                    Key: {
                        name: version.name,
                        type: `v:production:${version.version}:d_lang:${lang}`
                    },
                };
                const { Item: _item } = await Get(params);
                setItem(_item || {});
            }
            cb();
        }
    }, [version?.version, version?.name, lang]);

    const handlerClickUpdate = useCallback(async () => {
        if (!data?.title || !data?.desc) {
            toast("Insert Title and content", { type: "error" });
            return;
        }
        const item = {
            ...data,
            name: version.name,
            type: `v:production:${version.version}:d_lang:${lang}`,
            updated: new Date().getTime(),
            updater: auth.sub,
            lang,
        };
        const params = {
            TableName: "rayrnd-applications",
            ...getAttributeUpdatesSet(["name", "type"], item)
        };
        const { Attributes: _item }  = await Update(params);
        setItem(_item)
        toast("Success Update User Detail Release Note.", { type: "success" });
    }, [data, version.name, version.version, lang, auth.sub]);

    const removeItem = useCallback(async () => {
        if (!item.type || !item.name) {
            return;
        }
        const params = {
            TableName: "rayrnd-applications",
            Key: {
                name: item.name,
                type: item.type,
            }
        };
        await Delete(params);
        setItem({});
    }, [item.name, item.type]);

    useEffect(() => {
        callbackUpdateFunction && callbackUpdateFunction(async () => await handlerClickUpdate())
    }, [callbackUpdateFunction, handlerClickUpdate]);

    useEffect(() => {
        callbackDeleteFunction && callbackDeleteFunction(async () => await removeItem())
    }, [callbackDeleteFunction, removeItem]);
    
    return <>
        <div>
            <RAYFormRawInline title="Title">
                {!edit && <div>
                    {item?.title || "-"}
                </div>}
                {edit && <RAYTextField
                    value={data?.title || ""}
                    onChange={e => setData(prev => ({ ...prev, title: e.target.value }))}
                    placeholder="Insert Release Note title"
                />}
            </RAYFormRawInline>
            <RAYFormRawInline title="Content">
                <div className="w-100">
                    {!edit && <div dangerouslySetInnerHTML={{ __html: item?.desc || '-' }} className="html-contents mt-2" />}
                    {edit && <TinyEditor
                        name={"t_" + lang}
                        originContent={item?.desc || ""}
                        callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                        s3KeyPrefix={`rayrnd/applications/${version?.name}/versions/${version?.version}/release_attachments/`}
                        height={800}
                    />}
                </div>
            </RAYFormRawInline>
            <RAYFormRawInline title="Status">
                {!edit && <span>{item?.is_active ? 'Published' : 'Draft'}</span>}
                {edit && <label className="form-check form-switch form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" checked={data?.is_active ? 'checked' : ''} onChange={() => setData(prev => ({ ...prev, is_active: !prev.is_active }))} />
                    <span className="form-check-label fw-bold text-muted">
                        {data?.is_active ? 'Published' : 'Draft'}
                    </span>
                </label>}
            </RAYFormRawInline>
        </div>
    </>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(AppReleaseDetailNote);
