import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import RAYButton from "./RAYButton";
import { v4 } from 'uuid';
// import Editor, { EDITOR_CONST } from "../tiptap/Editor";
import TinyEditor from "../tinymce/TinyEditor";

const DATA_TYPE = [
    { value: 'string', label: 'String' },
    { value: 'number', label: 'Integer' },
    { value: 'object', label: 'Object' },
    { value: 'array', label: 'Array' }
];
const RECEIVE_METHODS = [
    { value: 'browser_file', label: 'File' },
    { value: 'browser_folder', label: 'Folder' },
    { value: 'input', label: 'Input' },
    { value: 'etc', label: 'Etc' }
];

const ItemValidKeys = ['key', 'data_type', 'receive_method'];

const RAYAppArrAttrSetterItem = ({ item, index, edit, callbackOnChange, callbackOnRemove }) => {
    const [data, setData] = useState(item);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setValid(ItemValidKeys.every(key => !!data[key]));
    }, [data]);

    useEffect(() => {
        valid && callbackOnChange && callbackOnChange(index, data);
    }, [data, index, callbackOnChange, valid])

    const handlerClickRemove = useCallback(() => {
        callbackOnRemove && callbackOnRemove(index);
    }, [callbackOnRemove, index]);

    return <>
        <tr style={{ borderBottom: 'none' }}>
            <td>
                <input
                    type="text"
                    className={"form-control form-control-sm " + (valid ? '' : 'bg-danger')}
                    placeholder="LocalStorage Key"
                    readOnly={!edit}
                    value={data.key || ''}
                    onChange={(event) => setData(prev => ({ ...prev, key: (event.target.value || '').trim().replace(/\s/gi, '') }))}
                />
            </td>
            <td>
                {!edit && <>{DATA_TYPE.find(x => x.value === item.data_type)?.label || '-'} </>}
                {edit && <Select
                    options={DATA_TYPE}
                    value={DATA_TYPE.find(x => x.value === data.data_type) || ''}
                    onChange={(obj) => setData(prev => ({ ...prev, data_type: obj.value }))}
                    className="w-100"
                    classNamePrefix="ray-select"
                />}
            </td>
            <td>
                {!edit && <>{RECEIVE_METHODS.find(x => x.value === item.receive_method)?.label || '-'} </>}
                {edit && <Select
                    options={RECEIVE_METHODS}
                    value={RECEIVE_METHODS.find(x => x.value === data.receive_method) || ''}
                    onChange={(obj) => setData(prev => ({ ...prev, receive_method: obj.value }))}
                    className="w-100"
                    classNamePrefix="ray-select"
                />}
            </td>
            <td>
                {!edit && <>{item?.is_required ? 'Required' : 'Optional'}</>}
                {edit && <label className="form-check form-switch form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" checked={data?.is_required ? 'checked' : ''} onChange={() => setData(prev => ({ ...prev, is_required: !prev.is_required }))} />
                    <span className="form-check-label fw-bold text-muted">
                        {data?.is_required ? 'Required' : 'Optional'}
                    </span>
                </label>}
            </td>
            {edit && <td>
                <RAYButton label="Remove" color="danger" onClick={handlerClickRemove} />
            </td>}
        </tr>
        <tr style={{ borderBottom: 'none' }}>
            <td colSpan={edit ? 5 : 4}>
                <div>Title</div>
                <input
                    type="text"
                    className="form-control form-control-sm "
                    placeholder="Attribute Title"
                    readOnly={!edit}
                    value={data.title || ''}
                    onChange={(event) => setData(prev => ({ ...prev, title: event.target.value }))}
                />
            </td>
        </tr>
        <tr>
            <td colSpan={edit ? 5 : 4}>
                <div>Description</div>
                <div className="form-control form-control-sm">
                    {!edit && (item?.desc ? <div dangerouslySetInnerHTML={{ __html: item.desc}} /> : '-')}
                    {edit && <TinyEditor
                        originContent={item?.desc || ""}
                        callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                        height={300}
                        //name={item.name}
                        //content={data.desc} callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                        //type={EDITOR_CONST.TYPE.HTML}
                    />}
                </div>
            </td>
        </tr>
    </>
};

const RAYAppArrAttrSetter = ({ list, edit, callbackOnChangeData }) => {
    const [data, setData] = useState(list || []);

    useEffect(() => {
        setData(list || []);
    }, [list])

    const handlerAddAttr = useCallback(() => {
        setData(prev => [...prev, { id: v4(), key: '', data_type: '', receive_method: '', is_required: false, title: '', desc: '' }]);
    }, []);

    const callbackOnChange = useCallback((index, _data) => {
        setData(prev => {
            const _temp = [...prev];
            _temp[index] = _data;
            return _temp;
        })
    }, []);

    useEffect(() => {
        callbackOnChangeData && callbackOnChangeData(data);
    }, [data, callbackOnChangeData]);

    const callbackOnRemove = useCallback((index) => {
        setData(prev => {
            const _temp = [...prev];
            _temp.splice(index, 1);
            return _temp;
        })
    }, []);

    return <>
        {(data && data.length === 0 && !edit) && '-'}
        {((data && data.length > 0) || edit) && <div className="w-100">
            {edit && <div className="text-end">
                <RAYButton label="Add Attribute" color="primary" onClick={handlerAddAttr} />
            </div>}
            <table className="table">
                <thead>
                    <tr>
                        <td>LocalStorage Key</td>
                        <td>Data Type</td>
                        <td>Receive Method</td>
                        <td>Is Required</td>
                        {edit && <td></td>}
                    </tr>
                </thead>
                <tbody>
                    {!edit && list && list?.map((x, idx) => <RAYAppArrAttrSetterItem
                        item={x}
                        index={idx}
                        edit={edit}
                        key={x.id + 'prod'}
                        callbackOnChange={callbackOnChange}
                        callbackOnRemove={callbackOnRemove}
                    />)}
                    {edit && data?.map((x, idx) => <RAYAppArrAttrSetterItem
                        item={x}
                        index={idx}
                        edit={edit}
                        key={x.id}
                        callbackOnChange={callbackOnChange}
                        callbackOnRemove={callbackOnRemove}
                    />)}
                </tbody>
            </table>
        </div>}
    </>
}

export default RAYAppArrAttrSetter;
