import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const options = {
    resources: {},
    debug: false,    
    keySeparator: ".",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    react: { useSuspense: false },
};

i18n.use(detector).use(initReactI18next).init(options);