import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppConstant } from "../redux/reducers";
import { checkPerm as CheckPerm } from "../libs/permission";

const DefaultToolbar = <div className="toolbar" style={{ height: 55 }} />

const PageInOutHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [_toolbar, _setToolbar] = useState(DefaultToolbar);

    const setToolbar = useCallback((payload) => {
        _setToolbar(payload);
    }, []);

    const checkPerm = useCallback((perm, role) => {
        const allow = CheckPerm(perm, role);
        if (!allow) {
            navigate('/')
        }
    }, [navigate]);

    useEffect(() => {
        dispatch({ type: AppConstant.UPDATE_TOOLBAR, payload: _toolbar });
        return () => dispatch({ type: AppConstant.UPDATE_TOOLBAR, payload: DefaultToolbar });
    }, [dispatch, _toolbar])

    return [setToolbar, checkPerm];
}

export default PageInOutHook;
