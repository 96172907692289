import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Query, Update, Delete } from "../../libs/dynamo";
import { getAttributeUpdatesSet } from "../../libs/functions";
import RAYButton from "../common/RAYButton";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";

const AppLogCodeModal = ({ show, item, callback }) => {

    const [data, setData] = useState({ code : "", desc : ""});

    useEffect(() => {
        console.log(item);
        setData({...item});
    }, [item]);

    const updateCodes = async (x) => {
        const dt = new Date().getTime();
        const item = {
            name : data.appname,
            type: "code:" + data.code,
            desc: data.desc,
            updated: dt
        };
        console.log(item);
        const params = {
            TableName: "rayrnd-applications",
            ...getAttributeUpdatesSet(["name", "type"], item)
        };
        const { Attributes: _item }  = await Update(params);
        callback && callback(_item);
    }

    return <>
        <Modal show={show} onHide={callback} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Code 등록</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-0">
                <RAYFormRawInline title="Code" labelCss="" labelCol={3}>
                    <RAYTextField
                        type="text"
                        value={data.code}
                        placeholder="Code 이름"
                        onChange={e => setData({...data, code : e.target.value})}
                        style={{ height: 40 }}
                    />
                </RAYFormRawInline>
                <RAYFormRawInline title="Code 설명" labelCss="" labelCol={3}>
                    <RAYTextField
                        type="text"
                        value={data.desc}
                        placeholder="Code 설명"
                        onChange={e => setData({...data, desc : e.target.value})}
                        style={{ height: 40 }}
                    />
                </RAYFormRawInline>
            </Modal.Body>
            <Modal.Footer>
                <RAYButton 
                    label="Cancel"
                    color="light"
                    onClick={() => callback()} />
                <RAYButton 
                    label="Save"
                    color="primary"
                    disabled={!(data.code && data.desc) ? true : false}
                    onClick={() => updateCodes()} css="ms-1" />
            </Modal.Footer>
        </Modal>
    </>
}

export default AppLogCodeModal;
