import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { UserAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import UserAttrs from "./UserAttrs";

const UserDetailModal = ({ auth, show, sub, callbackClose, Get, Remove, user, isCreate = false, isUpdate = false }) => {
    const [open, setOpen] = useState(show);
    const [detail, setDetail] = useState({});
    const [unregisterReady, setUnregisterReady] = useState(false);


    useEffect(() => setOpen(show), [show]);

    useEffect(() => {
        sub && Get(sub);
    }, [Get, sub]);

    useEffect(() => {
        if (isUpdate) {
            sub && Get(sub);
        }
    }, [isUpdate])

    useEffect(() => {
        setDetail(user?.find(x => x.type === 'info:detail') || {});
    }, [user]);


    const handleClose = useCallback(() => {
        callbackClose && callbackClose();
    }, [callbackClose]);

    const handleClickUnregister = useCallback(async () => {
        if (!detail?.sub) return;
        if (!unregisterReady) {
            setUnregisterReady(true);
            return;
        }
        const ret = await Remove(detail.sub);
        if (ret.status === 'success') {
            callbackClose && callbackClose();
        }
    }, [unregisterReady, callbackClose, Remove, detail]);

    return <>
        <Modal show={open} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {!isCreate && !isUpdate && <>
                        {detail?.name || 'User'} Detail
                    </>}
                    {isCreate && <>
                        Create Admin
                    </>}
                    {isUpdate && <>
                        {detail?.name || 'User'} - Change password
                    </>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserAttrs user={detail} isCreate={isCreate} isUpdate={isUpdate} callbackClose={handleClose} />
            </Modal.Body>
            {!isCreate && !isUpdate && <Modal.Footer>
                {detail?.sub !== auth?.sub && <RAYButton label={unregisterReady ? "Unregister Really?" : "Unregister"} onClick={handleClickUnregister} color="danger" />}
                <RAYButton label="Close" onClick={handleClose} color="light" />
            </Modal.Footer>}
        </Modal>
    </>
}

const mapState = (state) => {
    const user = state.UserReducer.item;
    const auth = state.AuthReducer.user;
    return { user, auth };
};

const mapDispatch = (dispatch) => ({
    Get: (sub) => dispatch(UserAction.Get(sub)),
    Remove: (sub) => dispatch(UserAction.Remove(sub))
})

export default connect(mapState, mapDispatch)(UserDetailModal);
