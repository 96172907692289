import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DownloadHook from "../../hooks/DownloadHook";
import UploadHook from "../../hooks/UploadHook";
import { getFileSize } from "../../libs/fileSaver";
import { RAYIconClip } from "./RAYIcon";


const RAYFile = ({ fieldName, item, isArrItem = false, arrId, arrFiledName, keyPrefix, accept = "",
    hideFileName, callbackOnChangeFile, callbackUploadHook, edit = false, css = "", uploadFileName }) => {
    const [ref, setRef] = useState(null);
    const [downloadFile, progress, isDownloading] = DownloadHook();
    const [file, setFile] = useState(null);
    const { setUpload, changeKey, progress: uploadProgress, isUploading } = UploadHook({ callbackReturnHook: callbackUploadHook });

    useEffect(() => {
        if (!item) {
            setFile(null);
            if (ref?.value) {
                ref.value = null;
            }
        }
    }, [item]);

    const changeFileHandler = useCallback((event) => {
        if (event.target.files.length > 0) {
            const _file = event.target.files[0];
            setFile(_file);
            callbackOnChangeFile && callbackOnChangeFile({ name: fieldName, value: _file })
            if (callbackUploadHook) {
                const key = (keyPrefix ? keyPrefix : 'rayrnd/unknown/') + _file.name;
                const _fieldName = isArrItem ? arrFiledName : fieldName;
                setUpload(key, _file, _fieldName, isArrItem, arrId);
            }
        }
    }, [keyPrefix, callbackOnChangeFile, callbackUploadHook, fieldName, isArrItem, arrFiledName, arrId, setUpload]);

    useEffect(() => {
        if (file) {
            const key = (keyPrefix ? keyPrefix : 'rayrnd/unknown/') + (uploadFileName ? `${uploadFileName}-${file.name}` : file.name);
            changeKey(key);
        }
    }, [changeKey, keyPrefix, file, uploadFileName]);

    const handlerClickLink = useCallback(() => {
        window.navigator.clipboard.writeText(item.direct_link);
        toast('Copy Link!', { type: 'success' });
    }, [item]);

    return <>
        {!edit && <div className={css}>
            {!!item && item?.file_name && <>
                {!isDownloading && <button className="btn btn-primary btn-sm" onClick={() => !isDownloading && downloadFile(item?.direct_link, item?.file_name)}>
                    Download - 
                    {hideFileName ? '' : `${item?.file_name} `}({getFileSize(item?.file_size)})
                </button>}
                {isDownloading && <span className="fw-bolder text-danger font-italic">Downloading.... ({progress}%)</span>}
                {item.direct_link && <span className="badge bg-dark ms-2 raycur" onClick={handlerClickLink}>COPY LINK</span>}
            </>}
            {(!item || !item?.file_name) && <>-</>}
        </div>}
        {edit && <>
            <div className={`d-flex position-relative mb-2 ${css}`}>
                <input
                    type="text"
                    className="form-control form-control-sm raycur"
                    readOnly
                    name={'input-' + fieldName}
                    onClick={() => !isUploading && ref.click()}
                    placeholder="Choice File"
                    value={file?.name || item?.file_name || ''}
                />
                <button
                    className="btn btn-light-primary fw-bolder flex-shrink-0 btn-sm"
                    onClick={() => !isUploading && ref.click()}
                >Search</button>
                {uploadProgress > 0 && <div className="position-absolute bg-right-primary z-index-1" style={{ left: 0, top: 0, bottom: 0, width: `${uploadProgress}%`, backgroundColor: '#009ef755', transition: 'width 0.2s' }} />}
            </div>
        </>}
        <input ref={setRef} type="file" className="d-none" accept={accept} onChange={changeFileHandler} />
    </>
}

export default RAYFile;
