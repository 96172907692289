import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FileAction } from "../redux/actions/FileAction";
import { AppConfig } from "../libs/config";

const UploadHook = ({ callbackReturnHook }) => {
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [inArr, setInArr] = useState(null);
    const [inArrId, setInArrId] = useState('');
    const [key, setKey] = useState('');
    const [file, setFile] = useState(null);

    const setUpload = useCallback((_key, _file, _fieldName, isArrItem = false, arrId ='') => {
        setInArrId(arrId);
        setInArr(isArrItem);
        setKey(_key);
        setFile(_file);
        setFieldName(_fieldName);;
    }, []);

    const changeKey = useCallback((_key) => {
        setKey(_key);
    }, []);

    const valid = useCallback(() => {
        return !!key && !!file && !!fieldName;
    }, [key, file, fieldName]);

    const updateProgress = useCallback((event) => {
        const { loaded, total } = event
        const percent = Math.ceil(loaded / total * 100);
        setProgress(percent < 100 ? percent : 0)
    }, []);

    const uploadFile = useCallback(async () => {
        if (file instanceof Blob && !!key) {
            setIsUploading(true);
            let { type: mimetype } = file;
            try {
                // mimetype = mimetype === "image/svg+xml" ? "image/svg+xml" : mimetype; // "image/svg"
                const urlRet = await FileAction.getUploadUrl({ key, mimetype });
                await FileAction.uploadFileToS3(urlRet, file, updateProgress);
                setIsUploading(false);
                return {
                    name: !inArr ? fieldName : `${fieldName}-${inArrId}`,
                    data: {
                        s3_path: key,
                        file_name: file.name,
                        file_size: file.size,
                        file_type: file.type,
                        direct_link: AppConfig.REACT_APP_S3_PATH + key
                    } 
                };
            } catch (error) {
                setIsUploading(false);
                toast('Fail Upload File', { type: 'error' });
            }
        }
        return;
    }, [updateProgress, key, inArr, inArrId, file, fieldName]);

    const uploadFileDirect = useCallback(async (_key, _file, localUpdateProgress, unValidFileInstance = false) => {
        if ((_file instanceof Blob || unValidFileInstance) && !!_key) {
            const { type: mimetype } = _file;
            try {
                const urlRet = await FileAction.getUploadUrl({ key: _key, mimetype });
                await FileAction.uploadFileToS3(urlRet, _file, localUpdateProgress);
                return {
                    data: {
                        s3_path: _key,
                        file_name: _file.name,
                        file_size: _file.size,
                        file_type: _file.type,
                        direct_link: AppConfig.REACT_APP_S3_PATH + _key
                    } 
                };
            } catch (error) {
                toast('Fail Upload File', { type: 'error' });
            }
        }
        return;
    }, []);

    useEffect(() => {
        if (callbackReturnHook && !!key && !!file && !!fieldName && typeof(inArr) === 'boolean') {
            if (inArr && !inArrId) return;
            const name = inArr ? `${fieldName}-${inArrId}` : fieldName;
            callbackReturnHook({ name, fieldName, inArr, inArrId, upload: uploadFile });
        }
        // callbackReturnHook && !!key && !!file && !!fieldName && typeof(inArr) === 'boolean' && callbackReturnHook({ name: fieldName, inArr, upload: uploadFile });
    }, [callbackReturnHook, inArr, inArrId, fieldName, file, key, uploadFile])

    return { setUpload, changeKey, valid, uploadFile, progress, isUploading, fieldName, key, file, inArr, inArrId, uploadFileDirect };
}

export default UploadHook;
