import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import YouTube from 'react-youtube';
import { LANGUAGE } from "../../constants";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYButton from "../common/RAYButton";
import RAYFile from "../common/RAYFile";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYSelect from "../common/RAYSelect";
import RAYTextField from "../common/RAYTextField";
import TinyEditor from "../tinymce/TinyEditor";
import FeatureArea from "./FeatureArea";
import LangArea from "./LangArea";

const opts = {
    height: '300',
    width: '460',
    playerVars: {
        autoplay: 0,
        origin: window.location.origin
    },
};


const AppTemplateFormFeature = ({ app, auth, lang, setLang, version, templates, publishable = false, UpdateAppTemplate, DeleteAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [data, setData] = useState({});
    const [features, setFeatures] = useState([]);
    const [feature, setFeature] = useState({});
    const [langFeature, setLangFeature] = useState({});
    const [uploadQueues, setUploadQueues] = useState([]);
    const [youtubeId, setYoutubeId] = useState(null);

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        setFeatures(templates?.filter(x =>
            x.name === app.name &&
            x.type.startsWith(`t:body:${version}:feature:`) &&
            x.type.endsWith("header") &&
            x.type.split(":").length === 6
        ) || []);
    }, [templates, version, app.name]);

    useEffect(() => {
        if (lang && feature?.code) {
            setLangFeature(templates.find(x => x.type === `t:body:${version}:feature:${feature.code}:${lang}`));
        } else {
            setLangFeature({})
        }
    }, [lang, feature?.code, version, templates]);

    const getYoutubeId = useCallback((link) => {
        if (!link) {
            setYoutubeId(null);
            return;
        }
        if (link.startsWith("https://www.youtube.com/watch?v=")) {
            const query = link.split("watch?")[1];
            const params = new URLSearchParams(query);
            const _yId = params.get("v");
            if (_yId) {
                setYoutubeId(_yId);
            }
        } else if (link.startsWith("https://youtu.be/")) {
            const _yId = link.split("https://youtu.be/")[1]?.split("?")[0]?.split("/")[0];
            if (_yId) {
                setYoutubeId(_yId);
            }
        } else {
            setYoutubeId(null);
        }
    }, []);

    useEffect(() => {
        if (langFeature?.type && header.version === version && lang) {
            setData({ ...langFeature });
            setImageDataUrl(null);
        } else {
            setData({});
            setImageDataUrl(null);
        }
        getYoutubeId(langFeature?.movie);
    }, [langFeature, header?.version, version, app.name, lang, getYoutubeId])



    const handlerSetUploadHooks = useCallback((payload) => {
        setUploadQueues([payload]);
    }, []);

    const [imageDataUrl, setImageDataUrl] = useState(null);

    const callbackOnChangeFile = useCallback((obj) => {
        setData(prev => ({ ...prev, image: obj.value }));
        fileToDataUrl(obj.value);
    }, []);

    const fileToDataUrl = (file) => {
        if (!file || !(file instanceof File)) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageDataUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handlerClick = async () => {
        const item = {
            ...data,
            name: app.name,
            type: `t:body:${version}:feature:${feature.code}:${lang}`,
        };
        if (uploadQueues.length > 0) {
            for (const q of uploadQueues) {
                const fileRet = await q.upload();
                item[q.fieldName] = fileRet.data;
            }
            setUploadQueues([]);
        }
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const handlerClickRemove = () => {
        const cb = () => {
            // setFeature({});
            const targetItems = templates.filter(x => x.type.startsWith(`t:body:${version}:feature:${feature.code}:`));
            for(const _item of targetItems) {
                DeleteAppTemplate({ appName: app.name, item: _item});
            }
        };
        toast(<div>
            <div className="text-danger fw-bolder">Remove the "{feature.label}"'s all langs data.</div>
            <div>Really remove the "{feature.label}"?</div>
            <div className="d-flex justify-content-end mt-3">
                <RAYButton
                    label="Yes"
                    color="danger"
                    onClick={cb}
                    css="py-1"
                />
                <RAYButton
                    label="No"
                    color="light"
                    css="py-1"
                />
            </div>
        </div>, { type: "warning", style: {} });
    }

    return <div className="d-flex gap-2">
        <div style={{ maxWidth: 200, minWidth: 200 }} className="d-flex flex-column gap-4">
            <LangArea langs={header.langs || []} css="w-100" lang={lang} setLang={setLang} />
            <FeatureArea app={app} version={version} features={features} feature={feature} setFeature={setFeature} />
        </div>
        <div className="flex-fill">
            {(!lang || !feature?.type) && <div className="text-muted d-flex flex-column gap-4">
                {!lang && <i>Please select language.</i>}
                {!feature?.type && <i>Please select core feature.</i>}
            </div>}
            {lang && feature.type && <div key={`${lang}-${langFeature?.type || feature?.type}`}>
                <div className="h5">
                    {feature.label} - {LANGUAGE.find(l => l.code === lang)?.label || lang}
                </div>
                <RAYFormRawInline title="Title" labelCol={2}>
                    <div className="w-100">
                        <RAYTextField
                            value={data.title || ""}
                            onChange={(e) => setData(prev => ({ ...prev, title: e.target.value }))}
                            placeholder="Please enter a title of 30 characters or less."
                        />
                    </div>
                </RAYFormRawInline>
                <RAYFormRawInline title="Visible Media Type" labelCol={2}>
                    <RAYSelect
                        items={[{ value: "image", label: "Image" }, { value: "movie", label: "Movie" }]}
                        value={data.mediaType}
                        css="w-200px"
                        style={{ zIndex: 2 }}
                        callbackOnChange={v => setData(prev => ({ ...prev, mediaType: v?.value }))}
                    />
                </RAYFormRawInline>
                {data?.mediaType === "image" && <RAYFormRawInline title="Media - Image" labelCol={2}>
                    <div>
                        <RAYFile
                            item={langFeature?.image || null}
                            edit={true}
                            hideFileName={true}
                            isArrItem={false}
                            fieldName={'image'}
                            keyPrefix={`rayrnd/applications/${app?.name}/template/${version}/feature/${feature.code}/images/${lang}/`}
                            callbackOnChangeFile={callbackOnChangeFile}
                            callbackUploadHook={handlerSetUploadHooks}
                        />
                        <div style={{ maxHeight: 100 }} className="my-4">
                            {imageDataUrl && <>
                                <img src={imageDataUrl} alt="file thumbnail" style={{ maxHeight: 100 }} />
                            </>}
                            {!imageDataUrl && data?.image && <>
                                <img src={data.image.direct_link} alt="file thumbnail" style={{ maxHeight: 100 }} />
                            </>}
                        </div>
                    </div>
                </RAYFormRawInline>}
                {data?.mediaType === "movie" && <RAYFormRawInline title="Media - Movie" labelCol={2}>
                    <div>
                        <RAYTextField
                            value={data.movie || ""}
                            onChange={(e) => setData(prev => ({ ...prev, movie: e.target.value }))}
                            placeholder="Insert youtube Link"
                        />
                        {!!youtubeId && <>
                            <YouTube videoId={youtubeId} id={youtubeId} opts={opts} />
                        </>}
                    </div>
                </RAYFormRawInline>}
                <RAYFormRawInline title="Content" labelCol={2}>
                    <div className="w-100">
                        <TinyEditor
                            originContent={langFeature?.content}
                            callbackOnChange={(content) => setData(prev => ({ ...prev, content: content }))}
                            height={400}
                        />
                    </div>
                </RAYFormRawInline>
                <RAYFormRawInline labelCol={2} css="mt-3">
                    <RAYButton
                        css={"me-1"}
                        label="Save"
                        color="primary"
                        onClick={handlerClick}
                    />
                    <RAYButton
                        label="Remove"
                        color="danger"
                        onClick={handlerClickRemove}
                    />
                </RAYFormRawInline>
            </div>}
        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
    DeleteAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.DeleteAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormFeature);
