import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import RAYMakeFilter from "../../components/common/RAYMakeFiltery";
import RAYFilterChart from "../common/RAYFilterChart";
import AppLogCodes from "./AppLogCodes";
import AppLogs from "./AppLogs";

const AppSettingActivityLog = ({ item, logfilter }) => {
    const [menu, setMenu] = useState({
        code : "action",
        from : "user"
    });

    useEffect(() => {
        callbackfilter(logfilter);
    }, [logfilter]);

    const callbackfilter = (data) => {
        if(data.table === "rayteams_actions_logs"){
            setMenu({ code : "action", from : "reducer" });
        }else if(data.table === "rayteams_infos_logs"){
            setMenu({ code : "info", from : "reducer" });
        }else if(data.table === "rayteams_errors_logs"){
            setMenu({ code : "error", from : "reducer" });
        }
    }

    return <>
        <div className="row">
            <div className="col-12 px-4 pb-4">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={"nav-link " + (menu.code === "action" ? "active": "")} onClick={() => setMenu({ code : "action", from : "user" })} aria-current="page" href="#">Activity</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (menu.code === "info" ? "active": "")} onClick={() => setMenu({ code :"info", from : "user" })} aria-current="page" href="#">Info</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (menu.code === "error" ? "active": "")} onClick={() => setMenu({ code : "error", from : "user" })} aria-current="page" href="#">Error</a>
                    </li>
                    <li className="pt-2"><span className="mx-4">|</span></li>
                    <li className="nav-item">
                        <a className={"nav-link " + (menu.code === "filters" ? "active": "")} onClick={() => setMenu({ code :"filters", from : "user" })} aria-current="page" href="#">Filters</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (menu.code === "chart" ? "active": "")} onClick={() => setMenu({ code :"chart", from : "user" })} aria-current="page" href="#">Chart</a>
                    </li>
                    <li className="pt-2"><span className="mx-4">|</span></li>
                    <li className="nav-item">
                        <a className={"nav-link " + (menu.code === "codes" ? "active": "")} onClick={() => setMenu({ code :"codes", from : "user" })} aria-current="page" href="#">Codes</a>
                    </li>
                </ul>
            </div>
            <div className="col-12">
                {menu.code === "chart" && <RAYFilterChart app={item} callbackfilter={callbackfilter}/>}
                {menu.code === "filters" && <RAYMakeFilter app={item}/>}
                {menu.code === "codes" && <AppLogCodes app={item}/>}
                {(menu.code === "action" || menu.code === "info" || menu.code === "error") && <AppLogs item={item} ltype={menu} />}
            </div>
        </div>
    </>
}

const mapState = (state) => {
    const logfilter = state.AppReducer.logfilter;
    return { logfilter };
};

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(AppSettingActivityLog);