import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { LANGUAGE } from "../../constants";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYButton from "../common/RAYButton";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";
import TinyEditor from "../tinymce/TinyEditor";
import FaqArea from "./FaqArea";
import LangArea from "./LangArea";


const AppTemplateFormFAQ = ({ app, auth, lang, setLang, version, templates, publishable = false, UpdateAppTemplate, GetAppTemplates, DeleteAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [faqHeader, setFaqHeader] = useState([]);
    const [faq, setFaq] = useState([]);
    const [selectedFaq, setSelectedFaq] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        setFaqHeader(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:faq:header`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        selectedFaq?.code && setFaq(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:faq:${selectedFaq.code}:${lang}`) || {});
    }, [templates, version, app.name, lang, selectedFaq?.code]);

    useEffect(() => {
        if (faq?.type && header.version === version && lang) {
            setData({ ...faq });
        } else {
            setData({});
        }
    }, [faq, header?.version, version, app.name, lang]);

    const handlerClick = async () => {
        const item = {
            ...data,
            name: app.name,
            type: `t:body:${version}:faq:${selectedFaq.code}:${lang}`,
        };
        await UpdateAppTemplate({ appName: app.name, item });
        toast('Updated!!!', { type: 'success' });
    };

    const handlerClickRemove = async () => {
        const cb = async () => {
            const item = {
                name: app.name,
                type: faqHeader.type,
                faqs: faqHeader.faqs.filter(x => x.code !== selectedFaq.code),
            };
            const _list = await GetAppTemplates({ appName: app.name, type: faqHeader.type.replace("header", selectedFaq.code)});
            const q = [
                async () => await UpdateAppTemplate({ appName: app.name, item }),
            ];
            for (const _item of _list.Items) {
                q.push(async () => await DeleteAppTemplate({ appName: app.name, item: _item }));
            }
            await Promise.all(q.map(async f => await f()));
            setSelectedFaq({});
        };

        toast(<div>
            <h3>Really Remove all {faqHeader.label} FAQs?</h3>
            <div className="mt-5 d-flex justify-content-end">
                <RAYButton 
                    label="Yes"
                    color="danger"
                    css="py-0"
                    style={{ height: 30 }}
                    onClick={cb}
                />
                <RAYButton 
                    label="No"
                    color="light"
                    css="py-0"
                    style={{ height: 30 }}
                />
            </div>
        </div>, { type: "warning" });
        
    };

    return <div className="d-flex gap-2">
        <div style={{ maxWidth: 200, minWidth: 200 }} className="d-flex flex-column gap-4">
            <LangArea langs={header.langs || []} css="w-100" lang={lang} setLang={setLang} />
            <FaqArea app={app} version={version} faqHeader={faqHeader} selectedFaq={selectedFaq} setSelectedFaq={setSelectedFaq} />
        </div>
        <div className="flex-fill">
            {(!lang || !selectedFaq?.code) && <div className="text-muted d-flex flex-column gap-4">
                {!lang && <i>Please select language.</i>}
                {!selectedFaq?.code && <i>Please select FAQ.</i>}
            </div>}
            {lang && selectedFaq.code && <div key={`${lang}-${selectedFaq.code}}`}>
                <div className="h5">
                    {selectedFaq.label} - {LANGUAGE.find(l => l.code === lang)?.label || lang}
                </div>
                <RAYFormRawInline title="Question" labelCol={2}>
                    <div className="w-100">
                        <RAYTextField
                            value={data.question || ""}
                            onChange={(e) => setData(prev => ({ ...prev, question: e.target.value }))}
                            placeholder="Please enter a Question."
                        />
                    </div>
                </RAYFormRawInline>
                <RAYFormRawInline title="Answer" labelCol={2}>
                    <div className="w-100">
                        <TinyEditor
                            originContent={faq?.answer}
                            callbackOnChange={(content) => setData(prev => ({ ...prev, answer: content }))}
                            height={400}
                        />
                    </div>
                </RAYFormRawInline>
                <RAYFormRawInline labelCol={2} css="mt-3">
                    <RAYButton
                        css="me-1"
                        label="Save"
                        color="primary"
                        onClick={handlerClick}
                    />
                    <RAYButton
                        label="Remove"
                        color="danger"
                        onClick={handlerClickRemove}
                    />
                </RAYFormRawInline>
            </div>}
        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
    DeleteAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.DeleteAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormFAQ);
