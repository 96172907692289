
const RAYToggle = ({ size, onChange, value, disabled, trueLabel = 'ON', falseLabel = 'OFF', hideLabel = false, name, css = "", labelColor = "muted", RTL = false }) => {
    return <>
        <label className={`form-check d-inline-flex form-switch form-check-custom form-check-solid ${css} ` + (size ? `form-check-${size}` : '')} style={{ userSelect: 'none' }}>
            {!RTL && <input className="form-check-input" name={name} type="checkbox" disabled={disabled} checked={value ? 'checked' : ''} onChange={onChange} />}
            {!hideLabel && <span className={`form-check-label ms-2 fw-bold text-${labelColor}`}>
                {value ? trueLabel : falseLabel}
            </span>}
            {RTL && <input className="form-check-input ms-2" name={name} type="checkbox" disabled={disabled} checked={value ? 'checked' : ''} onChange={onChange} />}
        </label>
    </>
};

export default RAYToggle;
