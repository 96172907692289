import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LANGUAGE } from "../../constants";
import { toast } from 'react-toastify';
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYButton from "../common/RAYButton";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";
import TinyEditor from "../tinymce/TinyEditor";
import LangArea from "./LangArea";
import ManualFeatureArea from "./ManualFeatureArea";


const AppTemplateFormManual = ({ app, auth, lang, setLang, version, templates, publishable = false, UpdateAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [manualHeader, setManualHeader] = useState([]);
    const [manual, setManual] = useState([]);
    const [features, setFeatures] = useState([]);
    const [data, setData] = useState({});
    const [nowFeature, setNowFeature] = useState("installation");

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        setManualHeader(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:manual:header`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        setManual(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:manual:${lang}`) || {});
    }, [templates, version, app.name, lang]);

    useEffect(() => {
        if (manual?.type && header.version === version && lang) {
            setData({ ...manual });
        } else {
            setData({});
        }
    }, [manual, header?.version, version, app.name, lang]);

    useEffect(() => {
        if (manualHeader?.features) {
            setFeatures([...manualHeader.features].sort((a, b) => a.order < b.order ? -1 : 1));
        } else {
            setFeatures([]);
        }
    }, [manualHeader]);

    const setFeaturesData = (code, key, value) => {
        setData(prev => ({
            ...prev,
            features: {
                ...prev.features,
                [code]: {
                    ...(prev?.features || {})[code],
                    [key]: value,
                }
            }
        }));
    };

    const handlerClick = async () => {
        const item = {
            ...data,
            name: app.name,
            type: `t:body:${version}:manual:${lang}`,
        };
        await UpdateAppTemplate({ appName: app.name, item });
        toast('Updated!!!', { type: 'success' });
    };

    const handlerClickRemoveFeature = async (code) => {
        const item = {
            name: app.name,
            type: manualHeader.type,
            features: manualHeader.features.filter(x => x.code !== code),
        };
        await UpdateAppTemplate({ appName: app.name, item });
        toast('Removed!!!', { type: 'success' });
    };

    return <div className="d-flex gap-2">
        <div style={{ maxWidth: 200, minWidth: 200 }} className="d-flex flex-column gap-4">
            <LangArea langs={header.langs || []} css="w-100" lang={lang} setLang={setLang} />
            <ManualFeatureArea app={app} version={version} 
                manualHeader={manualHeader} 
                nowFeature={nowFeature} 
                onChanged={(e) => setNowFeature(e)} />
        </div>
        <div className="flex-fill">
            {lang && <div key={`${lang}}`}>
                {nowFeature === "installation" && <RAYFormRawInline title="Basic - Installation" labelCol={2} labelCss={"fs-3"}>
                    <div className="w-100">
                        <TinyEditor
                            originContent={manual?.installation}
                            callbackOnChange={(content) => setData(prev => ({ ...prev, installation: content }))}
                            height={600}
                        />
                    </div>
                </RAYFormRawInline>}
                {features.filter(x => x.code === nowFeature).length > 0 && <>
                    {features.length === 0 && <div className="text-muted">
                        <i>No features.</i>
                    </div>}
                    {features.length > 0 && <div className="w-100">
                        {features.filter(x => x.code === nowFeature).map((x) => <div key={x.code}>
                            <div className="d-flex align-items-center justify-content-between gap-4">
                                <h3>Feature - {x.label}</h3>
                                <RAYButton 
                                    css="btn-sm"
                                    label="Remove Feature"
                                    color="danger"
                                    onClick={() => handlerClickRemoveFeature(x.code)}
                                />
                            </div>
                            <RAYFormRawInline title="Slug" labelCol={2}>
                                <div className="w-100">
                                    <RAYTextField
                                        value={((data?.features || {})[x.code]?.intro) || ""}
                                        onChange={(e) => setFeaturesData(x.code, "intro", e.target.value.replace(/[^a-zA-Z-]/g, ""))}
                                        placeholder="Feature Slug"
                                    />
                                </div>
                            </RAYFormRawInline>
                            <RAYFormRawInline title="Title" labelCol={2}>
                                <div className="w-100">
                                    <RAYTextField
                                        value={((data?.features || {})[x.code]?.title) || ""}
                                        onChange={(e) => setFeaturesData(x.code, "title", e.target.value)}
                                        placeholder="Feature Title"
                                    />
                                </div>
                            </RAYFormRawInline>
                            <RAYFormRawInline title="Content" labelCol={2}>
                                <div className="w-100">
                                    <TinyEditor
                                        key={x.code}
                                        originContent={(manual?.features || {})[x.code]?.content}
                                        callbackOnChange={(content) => setFeaturesData(x.code, "content", content)}
                                        height={600}
                                        css="mw-100"
                                    />
                                </div>
                            </RAYFormRawInline>
                        </div>)}
                    </div>}
                </>}
                <RAYFormRawInline labelCol={2} css="mt-3">
                    <RAYButton
                        label="Save"
                        color="primary"
                        onClick={handlerClick}
                    />
                </RAYFormRawInline>
            </div>}
        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormManual);
