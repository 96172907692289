import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { RAYIconUser, RAYLogoDark } from '../../components/common/RAYIcon';
import Menu from './Menu';

const DocAside = ({ isLogin, auth }) => {

    return <Menu />
    {/* <div className="docs-aside">
        <div className="docs-aside-logo">
            <Link to="/">
                <RAYLogoDark style={{ width: 130 }} />
            </Link>
        </div>
        <div className="docs-aside-menu">
            <div className="docs-aside-menu-wrapper">
                <Menu />
            </div>
        </div>
        {isLogin && <div className="aside-footer pb-2">
            <Link to="#" className="btn" >
                <span className="svg-icon">
                    <RAYIconUser size="24" />
                </span>
                <span className="btn-label">{auth?.name}</span>
            </Link>
        </div>}
        <div className='text-muted text-end pe-2 pb-1'>v{process.env.REACT_APP_VERSION}</div>
    </div> */}
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const auth = state.AuthReducer.user;
    return { isLogin, auth };
};

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(DocAside);
