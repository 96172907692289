const initialState = { 
	appVersions: {}
};

export const ApplicationVersionConstant = {
	SET_APP_VERSIONS: "ACTION_APPLICATION_VERSION_SET_APP_VERSIONS",
};

export const ApplicationVersionReducer = (state = initialState, action) => {
    switch (action.type) {
		case ApplicationVersionConstant.SET_APP_VERSIONS:
			return {...state,
				appVersions: { ...state.appVersions, [action.payload.name]: action.payload.data }
			}
        default:
            return state
    }
}