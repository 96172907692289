import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import ReactDragListView from "react-drag-listview";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CommonAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";

const SettingHomepageAppsAppPageApps = ({ auth, homeApps, preset, callbackRefresh }) => {
    const [t] = useTranslation();
    const [edit, setEdit] = useState(false);
    const appPageApps = useMemo(() => preset?.appPageApps || [], [preset?.appPageApps]);
    const [dragProps, setDragProps] = useState({});
    const [data, setData] = useState([]);

    const resetData = useCallback(() => {
        const newItems = homeApps.filter(x => !appPageApps.includes(x.name)).map(x => x.name);
        setData([...appPageApps, ...newItems]);
    }, [appPageApps, appPageApps]);

    useEffect(() => {
        resetData();
    }, [resetData]);

    const orderedApps = useMemo(() => {
        let _apps = _.cloneDeep(homeApps);
        _apps = _.orderBy(_apps, ["title", "name"], ["asc", "asc"]);
        const newOrder = [];
        for (const _item of data) {
            const targetApp = _apps.find(x => x.name === _item);
            if (targetApp) {
                newOrder.push(targetApp);
                _apps = _apps.filter(x => x.name !== _item);
            }
        }
        // console.log("newOrder", newOrder);
        // console.log("_apps", _apps);
        const result = [...newOrder, ..._apps];
        // console.log("result", result);
        return result;
    }, [data, homeApps]);

    useEffect(() => {
        if (edit) {
            resetData();
        }
    }, [edit, resetData]);

    useEffect(() => {
        console.log("data", data);
    }, [data]);

    useEffect(() => {
        setDragProps({
            onDragEnd(fromIndex, toIndex) {
                let _data = _.cloneDeep(data);
                if (_data?.length === 0) {
                    _data = homeApps?.map(x => x.name);
                }
                const item = _data.splice(fromIndex, 1)[0];
                _data.splice(toIndex, 0, item);
                setData(_data);
            },
            nodeSelector: ".item-drag",
            handleSelector: ".item-drag",
        });
    }, [data]);

    const handlerClickSave = useCallback(async () => {
        const con = window.confirm("Save Menu Apps?");
        if (!con) {
            return;
        }
        const payload = {
            type: "update",
            region: "ap-northeast-2",
            params: {
                TableName: "rayrnd-applications",
                Key: {
                    name: "homepage",
                    type: "preset",
                },
                UpdateExpression: "SET #appPageApps = :appPageApps",

                ExpressionAttributeNames: { "#appPageApps": "appPageApps" },
                ExpressionAttributeValues: { ":appPageApps": data },
                ReturnValues: "ALL_NEW",
            },
        };
        await CommonAction.CallDB(payload);
        callbackRefresh && callbackRefresh();
        setEdit(false);
    }, [data]);

    return <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-2">
            <h3 className="mb-0">App Page App Order</h3>
            <div className="d-flex align-items-center gap-2">
                <RAYButton
                    label={!edit ? "Edit" : "Edit Cancel"}
                    color={!edit ? "secondary" : "primary"}
                    onClick={() => setEdit(prev => !prev)}
                    size="sm"
                />
                {edit && <>
                    <RAYButton
                        label="save"
                        color={"success"}
                        onClick={handlerClickSave}
                        size="sm"
                    />
                </>}
            </div>
        </div>
        {!edit && <>
            <div>
                {appPageApps?.length === 0 && <div>
                    There has no order.
                </div>}
                <div className="d-inline-flex gap-2 flex-wrap">
                    {orderedApps?.map(x => <div key={x.name} className="border rounded px-2 py-1 ">
                        {x?.title}
                    </div>)}
                </div>
            </div>
        </>}
        {edit && <>
            <div className="d-flex gap-3">
                <Card className="h-100">
                    <Card.Body className="flex-fill">
                        <div
                            className="d-flex flex-column gap-2"
                            style={{ minWidth: 500 }}
                        >
                            {data?.length === 0 && <div>
                                There has no items.<br />
                                Please Insert new Item.
                            </div>}
                            <ReactDragListView {...dragProps}>
                                <div className="d-flex gap-2 flex-wrap">
                                    {orderedApps?.map(x => <div key={x.name} className="border rounded px-2 py-1 cursor-pointer item-drag">
                                        <div className="d-flex align-items-center justify-content-between gap-4">
                                            <div>
                                                {x.title}
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </ReactDragListView>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div>
                <ul>
                    <li>
                        {t("These are the settings for the display order of apps in the app list on the homepage.")}
                    </li>
                    <li>
                        {t("You can adjust the order of the displayed apps by clicking and dragging and dropping them with the mouse.")}
                    </li>
                    <li>
                        {t("If a new app is added, it will be added to the end of the list.")}
                    </li>
                    <li>
                        {t("To remove an app, go to its detailed settings and change the visibility option to false.")}
                    </li>
                    <li>
                        {t("When you click the Save button, the values are immediately saved and can be checked on the homepage.")}
                    </li>
                </ul>
            </div>
        </>}
    </div>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(SettingHomepageAppsAppPageApps);
