import { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import RAYButton from "./RAYButton";
import RAYFormRawInline from './RAYFormRawInline';
import { Query, Update, Delete } from "../../libs/dynamo";
import { getAttributeUpdatesSet } from "../../libs/functions";
import RAYTextField from './RAYTextField';
import { Store } from '../../store';
import axios from 'axios';
import RAYSelect from "./RAYSelect";
import { AppConstant } from "../../redux/reducers";

const RAYMakeFilter = ({ wh, style, cancelCallback, doneCallback, app }) => {
    const [filter, setFilter] = useState({
        wh : ""
    });

    const [mode, setMode] = useState("list");
    const [list, setList] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        getFilters()
    }, []);

    useEffect(() => {
        setFilter({...filter, wh});
    }, [wh, app]);

    const getFilters = async() => {
        const user = Store.getState().AuthReducer.user;
        const params = {
            TableName: "rayteams-manager-user",
            KeyConditions: {
                _id: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [user._id],
                },
                sk: {
                    ComparisonOperator: "BEGINS_WITH",
                    AttributeValueList: ["filter:"],
                }
            },
            QueryFilter: {
                appKey: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [app.name],
                },
            },
        };
        const ret = await Query(params);
        setList(ret.Items)
    }

    const saveFilter = async() => {
        if(filter.title && filter.cols && filter.table){
            const user = Store.getState().AuthReducer.user;
            const dt = new Date().getTime();
            const item = {
                _id : user._id,
                sk: "filter:" + dt,
                fid: dt.toString(),
                appKey: app.name,
                title: filter.title,
                grp: filter.cols,
                table: filter.table,
                wh: ( filter.wh ? filter.wh + " and tp='" + app.alkey + "'" : "tp='" + app.alkey + "'"),
                updated: dt
            };
            const params = {
                TableName: "rayteams-manager-user",
                ...getAttributeUpdatesSet(["_id", "sk"], item)
            };
            const { Attributes: _item }  = await Update(params);
            await axios.post("https://api-teams-info.rayteams.com/athena/filterstart", {
                fid : item.fid,
                qtype : "all"
            });
            getFilters();
            setMode("list")
        }
    }

    const del = async (item) => {
        const params = {
            TableName: "rayteams-manager-user",
            Key: { _id: item._id, sk: item.sk }
        };
        await Delete(params);
        getFilters();
    }

    const TALBES = [
        { value : "rayteams_actions_logs", label : "Activity" },
        { value : "rayteams_infos_logs", label : "Info" },
        { value : "rayteams_errors_logs", label : "Error" }
    ]

    const view = (x) => {
        dispatch({ type: AppConstant.SET_SW_LOG_FILTER, filter : {...x, filter : x.wh }});
    }

    const cols = [
        { label: 'Code(cd)', value: 'cd', },
        { label: 'GUID(id)', value: 'id', },
        { label: 'USER(ud)', value: 'ud', },
        { label: 'SESSION(si)', value: 'si', },
        { label: 'Country(cc)', value: 'cc', },
        { label: 'Version(vr)', value: 'vr', },
        { label: 'Remote IP(rp)', value: 'rp', },
        { label: 'Local IP(lp)', value: 'rp', },
    ];

    return <div className="" style={{ ...style }}>
        {mode === "list" && <>
            <div className="text-end mb-1">
                <RAYButton color="primary" label={"Add new"} onClick={() => setMode("create")} />
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Type</th>
                        <th>Group</th>
                        <th>Where</th>
                        <th style={{ width : 50 }}></th>
                        <th style={{ width : 50 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((x, idx) => <tr key={"tr-" + idx}>
                        <td>{x.title}</td>
                        <td>{TALBES.find(n => n.value === x.table).label}</td>
                        <td>{x.grp}</td>
                        <td>{x.wh}</td>
                        <td className="text-end"><RAYButton color="success" label={"View"} onClick={() => view(x)} /></td>
                        <td className="text-end"><RAYButton color="danger" label={"Del"} onClick={() => del(x)} /></td>
                    </tr>)}
                </tbody>
            </table>
        </>}
        {mode === "create" && <>
            <div className="card-header d-flex justify-content-between py-3">
                <h3 className="card-title">Filter 생성하기</h3>
                <div className="d-flex align-items-center">
                    <RAYButton 
                        label="Cancel"
                        color="light"
                        onClick={() => setMode("list")} />
                    <RAYButton 
                        label="Save"
                        color="primary"
                        disabled={!(filter.title && filter.table && filter.cols) ? true : false}
                        onClick={() => saveFilter()} css="ms-1" />
                </div>
            </div>
            <div className="card-body">
                <div>
                    <RAYFormRawInline title="Title(*)" labelCss="" labelCol={3}>
                        <RAYTextField
                            type="text"
                            value={filter.title}
                            placeholder="Filter 이름"
                            onChange={e => setFilter({...filter, title : e.target.value})}
                            style={{ height: 40 }}
                        />
                    </RAYFormRawInline>
                    <RAYFormRawInline title="Type(*)" labelCss="" labelCol={3}>
                        <RAYSelect 
                            items={TALBES}
                            value={TALBES.find(x => x.value === filter.table)}
                            callbackOnChange={obj => setFilter({...filter, table : obj.value})}
                            clearable={false}
                            style={{ width : 300 }}
                        />
                    </RAYFormRawInline>
                    <RAYFormRawInline title="Column(*)" labelCss="" labelCol={3}>
                        <RAYSelect 
                            items={cols}
                            value={cols.find(x => x.value === filter.cols)}
                            callbackOnChange={obj => setFilter({...filter, cols : obj.value})}
                            clearable={false}
                            style={{ width : 300 }}
                        />
                    </RAYFormRawInline>
                    <RAYFormRawInline title="조건" labelCss="" labelCol={3}>
                        <RAYTextField
                            type="text"
                            value={filter.wh}
                            placeholder="데이터 조건(범위 등)"
                            onChange={e => setFilter({...filter, wh : e.target.value})}
                            style={{ height: 40 }}
                        />
                    </RAYFormRawInline>
                </div>
            </div>
        </>}
    </div>
}

export default RAYMakeFilter;
