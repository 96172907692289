export const getEnv = () => {
    if(location.href.indexOf("localhost") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}
export const AppConfig = {
    REACT_APP_S3_PATH : getEnv() === "production" ? 
        'https://ray-data.s3.ap-northeast-2.amazonaws.com/' : 
        'https://ray-data.s3.ap-northeast-2.amazonaws.com/',
        //'https://ray-data-development.s3.ap-northeast-2.amazonaws.com/',
    REACT_APP_ADMIN_USER_API : getEnv() === "production" ? 
        'https://api-rsp-user.rayteams.com/' :
        'https://api-rsp-user.raydevelop.com/',
    REACT_APP_APPLICATION_API_PROD : 'https://api-rsp-application.rayteams.com/',
    REACT_APP_APPLICATION_API : getEnv() === "production" ? 
        'https://api-rsp-application.rayteams.com/' :
        'https://api-rsp-application.raydevelop.com/',
    REACT_APP_INFO_API : getEnv() === "production" ? 
        'https://api-teams-info.rayteams.com/' :
        'https://api-teams-info.raydevelop.com/',
    REACT_APP_ENV : getEnv() === "production" ? 
        "production" :
        "development",
    REACT_APP_VERSION : "1.0.1",
    REACT_APP_ADMIN_USER_API_PROD : 'https://api-rsp-user.rayteams.com/',
    REACT_APP_S3_PATH_PROD : 'https://ray-data.s3.ap-northeast-2.amazonaws.com/'
};
