import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { RAYLogo } from '../../components/common/RAYIcon';
import { AuthAction } from '../../redux/actions';
import { Modal } from 'react-bootstrap';

function LoginProd({ Login, isLogin, initmodal = false, callbackLoginProd, clodeModal }) {
    const navigate = useNavigate();
    const [modal, setModal] = useState({ show : initmodal });
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        setModal({ show : initmodal })
    }, [initmodal]);

    useEffect(() => {
        if(isLogin){
            callbackLoginProd && callbackLoginProd();
        }
    }, [isLogin]);

    const onChangeHandler = useCallback((event, key) => {
        setData(prev => ({ ...prev, [key]: event.target.value }))
    }, []);

    const submit = useCallback(() => {
        Login(data);
    }, [Login, data]);

    const onKeyPressHandler = useCallback((event) => {
        if (event.key === 'Enter') {
            submit()
        }
    }, [submit]);

    return <Modal show={modal.show} onHide={() => clodeModal()} size="lg" style={{ zIndex: 1100 }}>
        <Modal.Header closeButton>
            <Modal.Title>Sign in Production Environment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="signin d-flex align-items-center justify-content-center">
                <div className="sign-wrapper w-100 pt-3">
                    <form>
                        <div className="sign-header">
                            <p>
                                Login to RAYTeams Developer.(<b>Production Environment</b>)
                            </p>
                        </div>
                        <div className="sign-email">
                            <label className="form-label">Email</label>
                            <input className="form-control" type="email" placeholder="" name="email" autoComplete="off" onChange={e => onChangeHandler(e, 'email')} />
                        </div>
                        <div className="sign-pw mb-2">
                            <label className="form-label">Password</label>
                            <input 
                                className="form-control" 
                                type="password" 
                                placeholder="" 
                                name="password" 
                                autoComplete="off"
                                onChange={e => onChangeHandler(e, 'password')}
                                onKeyPress={onKeyPressHandler}
                            />
                        </div>
                        <div className="w-100 my-4">
                            <button type="button" className="btn-primary btn-block w-100 btn btn-lg mb-2" onClick={submit}>
                                <span>Sign in production service</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLoginProd;
    return { isLogin };
};

const mapDispatch = (dispatch) => ({
    Login: (payload) => dispatch(AuthAction.LoginProd(payload))
})

export default connect(mapState, mapDispatch)(LoginProd);
