import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Select from 'react-select';
import { CONST_APPLICATION } from "../../constants";
import RAYAppArrAttrSetter from "../common/RAYAppArrAttrSetter";
import RAYFile from "../common/RAYFile";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import { Carousel } from "react-responsive-carousel"; // https://www.npmjs.com/package/react-responsive-carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RAYButton from "../common/RAYButton";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import RAYSelect from "../common/RAYSelect";
import { useTransition } from "react-spring";
import { toast } from "react-toastify";
const interval = 10000;
const AppSettingBanner = ({ edit = false, create = false, item, data, setData }) => {
  const [list, setList] = useState([]);
  const [bannerData, setBannerData] = useState({});
  const allApps = useSelector(state => state.ApplicationReducer.allApps);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    const _apps = allApps.filter(x => {
      if (!x.is_active) {
        return false;
      }
      if (x.isdev === "Y") {
        return false;
      }
      if (!x.last_published_version) {
        return false;
      }
      if (!x.rayteams_apps) {
        return false;
      }
      return true;
    });
    setApps(_apps);
  }, [allApps]);

  useEffect(() => {
    if (!data?.banner || !Array.isArray(data?.banner)) {
      setList([]);
      return;
    }
    setList(data.banner);
  }, [data?.banner])

  const handlerClickNewBanner = () => {
    const _id = v4();
    setBannerData({ _id });
  };

  const selectApp = (obj) => {
    const value = obj?.value;
    const app = apps.find(x => x.name === value);
    if (!app) {
      return;
    }
    setBannerData(prev => ({
      ...prev,
      app: {
        name: app.name,
        title: app.title
      },
      icon: app.app_icon
    }))
  };

  const handlerSetUploadHooks = useCallback(async (obj) => {
    if (!!obj?.upload) {
      try {
        const ret = await obj.upload();
        if (ret?.data) {
          setBannerData(prev => ({
            ...prev,
            img: ret.data,
          }))
        }
      } catch (error) {
        //
      }
    }
  }, []);

  const handlerClickAttach = () => {
    let valid = true;
    if (!bannerData?.app?.name) {
      valid = false;
    }
    if (valid && !bannerData?.img?.direct_link) {
      valid = false;
    }
    if (valid && !bannerData?.icon?.direct_link) {
      valid = false;
    }
    if (valid && !bannerData?.desc) {
      valid = false;
    }
    if (!valid) {
      toast("Missing props", { type: "error" });
      return;
    }
    setData(prev => {
      const _tmp = { ...prev };
      if (!_tmp.banner) {
        _tmp.banner = [];
      }
      const exist = _tmp.banner.find(x => x._id === bannerData._id);
      const _data = JSON.parse(JSON.stringify(bannerData));
      if (exist) {
        _tmp.banner = _tmp.banner.map(x => x._id === bannerData?._id ? _data : x);
      } else {
        _tmp.banner.push(_data);
      }
      return _tmp;
    });
    setBannerData({});
  };

  const removeBannerData = (item) => {
    setData(prev => {
      const _tmp = { ...prev };
      const banners = _tmp?.banner?.filter(x => x._id !== item._id);
      _tmp.banner = banners;
      return _tmp
    });
  };

  return <>
    <div className="d-flex flex-column gap-3">
      {!list || list?.length === 0 && <>
        Empty Banners
      </>}
      {list?.length > 0 && <Carousel
        autoPlay={false}
        infiniteLoop={true}
        interval={interval}
        showThumbs={false}
        showStatus={false}
      >
        {list?.map(item => <React.Fragment key={item._id}>
          <div className="position-relative rounded" style={{ backgroundColor: item?.bg }}>
            <img
              className="d-block w-100"
              style={{ height: 260, objectFit: "contain" }}
              src={item.img.direct_link}
              alt={item?.app?.title || item?.app?.name}
            />
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                textShadow: `0 0 6px ${item?.bg}`
              }}
            >
              <div className="text-start" style={{ width: "80%" }}>
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={item.icon.direct_link}
                    alt={(item?.app?.title || item?.app?.name) + " icon"}
                    style={{ width: 36, height: 36, borderRadius: 4 }}
                  />
                  <div className="text-white" style={{ fontSize: 24 }}>
                    {item?.app?.title || item?.app?.name}
                  </div>
                </div>
                {item.desc && <div className="mt-1 text-white fw-light" style={{ maxWidth: "60%", fontSize: 16 }}>
                  {item.desc}
                </div>}
                <div
                  className="mt-3 text-white fw-bolder raycur d-flex align-items-center gap-3 raycur"
                  style={{ fontSize: 18 }}
                >
                  <span></span>
                  View more
                </div>

              </div>
            </div>
            {edit && <div
              className="d-flex align-items-center gap-2"
              style={{
                position: "absolute",
                bottom: 20,
                right: 20
              }}
            >
              <RAYButton
                label="Dev Edit"
                color="primary"
                onClick={() => setBannerData(item)}
              />
              <RAYButton
                label="Remove"
                color="danger"
                onClick={() => removeBannerData(item)}
              />
            </div>}
          </div>
        </React.Fragment>)}
      </Carousel>}
      {edit && <>
        <div>
          <RAYButton
            label="Add Banner"
            color="primary"
            css="w-100"
            onClick={handlerClickNewBanner}
          />
        </div>
        {bannerData?._id && <div>
          <div className="position-relative rounded" style={{ backgroundColor: bannerData?.bg }}>
            <img
              className="d-block w-100"
              style={{ height: 260, objectFit: "contain" }}
              src={bannerData?.img?.direct_link}
              alt={"Missing main image"}
            />
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                textShadow: `0 0 6px ${bannerData?.bg}`
              }}
            >
              <div className="text-start" style={{ width: "80%" }}>
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={bannerData?.icon?.direct_link}
                    alt={"Missing app"}
                    style={{ width: 36, height: 36, borderRadius: 4 }}
                  />
                  <div className="text-white" style={{ fontSize: 24 }}>
                    {bannerData?.app?.title || bannerData?.app?.name}
                  </div>
                </div>
                {bannerData.desc && <div className="mt-1 text-white fw-light" style={{ maxWidth: "60%", fontSize: 16 }}>
                  {bannerData.desc}
                </div>}
                <div
                  className="mt-3 text-white fw-bolder raycur d-flex align-items-center gap-3 raycur"
                  style={{ fontSize: 16 }}
                >
                  <span></span>
                  View more
                </div>
              </div>
            </div>
          </div>
        </div>}
        {bannerData?._id && <div>
          <RAYFormRaw title="Back Color" labelCss="" labelCol={3}>
            <RAYTextField
              type="color"
              value={bannerData?.bg || "rgba(0,0,0,0)"}
              placeholder="Insert Background Color"
              onChange={e => setBannerData(prev => {
                const _tmp = { ...prev };
                _tmp.bg = e.target.value;
                return _tmp;
              })}
              style={{ height: 40 }}
            />
          </RAYFormRaw>
          <RAYFormRaw title="Target App" labelCss="" labelCol={3}>
            <RAYSelect
              items={apps.map(x => ({ value: x.name, label: (x.title || x.name) }))}
              value={bannerData?.app?.name}
              multi={false}
              callbackOnChange={selectApp}
              css="w-400px"
            />
          </RAYFormRaw>
          <RAYFormRaw title="Description" labelCss="" labelCol={3}>
            <RAYTextField
              type="text"
              value={bannerData?.desc || ""}
              placeholder="Description of Banner"
              onChange={e => setBannerData(prev => {
                const _tmp = { ...prev };
                _tmp.desc = e.target.value;
                return _tmp;
              })}
            />
          </RAYFormRaw>
          <RAYFormRaw title="Main Image" labelCss="" labelCol={3}>
            <RAYFile
              item={bannerData?.img || null}
              edit={true}
              hideFileName={true}
              fieldName={'banner.img'}
              keyPrefix={`rayrnd/applications/${item?.name}/assets/banner/${bannerData._id}`}
              callbackUploadHook={handlerSetUploadHooks}
            />
          </RAYFormRaw>
          <RAYFormRaw title="" labelCss="" labelCol={3}>
            <RAYButton
              label={"Attach"}
              onClick={handlerClickAttach}
            />
          </RAYFormRaw>
        </div>}
      </>}
    </div>
  </>
}

export default AppSettingBanner;
