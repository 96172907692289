import CheckClientHook from "../../hooks/CheckClientHook"
import LoadingSpinner from "./LoadingSpinner"


const Layout = ({ children }) => {
  CheckClientHook();

  return <div className='sign-area' style={{ minHeight: '100vh' }}>
      {children}
    <LoadingSpinner />
  </div>
}

export default Layout
