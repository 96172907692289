export const mdConverter = (content, uri, nowpath = "") => {
    const regex = /(\]\(\..*?\))/g;
    const found = content.match(regex);
    let data = content;
    const nowdir = nowpath.replace(nowpath.split('/').pop(), "");
    found?.map(x => {
        const gopath = x.replace("](", "");
        const npath = pathjoin(nowpath, gopath);
        data = data.replace("](" + gopath, "](/doc/" + uri + "?doc_name=" + npath);
    });
    return data;
}

export const pathjoin = (a, b) => {
    const parentCount = (b.match(/\.\.\//g) || []);
    let start = a;
    let append = b;
    let alldir = a.split("/");
    [...parentCount, "a"].forEach(element => {
        alldir.pop();
        append = append.replace("../", "");
    });
    append = append.replace("./", "");
    console.log(append);
    return alldir.join("/") + "/" + append;
}

//pathjoin("./case/ortho/README.md", "./aaa");
//pathjoin("./case/ortho/README.md", "../aaa");
//pathjoin("./case/ortho/aaa/README.md", "../../../aaa");
