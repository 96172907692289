import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { TOPMENU } from '../constants/topmenu';
import { mdConverter } from '../libs/converter';
import { connect, useDispatch } from 'react-redux';
import DocMenu from '../components/docmenu';
import MarkDown from '../components/markdown';
import GitHub from '../components/github';
import { ProgramConstant } from '../redux/reducers';
import axios from 'axios'

const Doc = ({ }) => {

	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [data, setData] = useState("");
	const { path } = useParams();

    let params = new URLSearchParams(window.location.search);
    const doc_name = params.get('doc_name') || "README.md";
    const base = "https://raw.githubusercontent.com/rayteams/docs/main/";
	
	useEffect(() => {
        getData()
	},[doc_name, path])

    const getData = async () => {
        const pre = doc_name.replace(doc_name.split('/').pop(), "");
        const docurl = (path + "/" +  doc_name).replace("//", "/");
        const getpath = base + docurl;
        try{
            const val = await axios(getpath);
            if(val.data){
                dispatch({ type : ProgramConstant.DOC, doc : docurl });
                setData(mdConverter(val.data, path, doc_name));
            }else{
                history.push("/err404");
            }
        }catch(error){
            if (error.response.status === 404) {
                history.push("/err404");
            }
        }
    }
	
  	return <div className='ray-doc-md'>
        {data && <div className="row">
            <div className="col-3">
                <DocMenu base={base} path={path} />
                <GitHub />
            </div>
            <div className="col-9">
                <div className="card card-action">
                    <div className="card-body">
                        <MarkDown data={data} />
                    </div>
                </div>
            </div>
        </div>}
    </div>
}

const mapState = (state) => {
	return { };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Doc);
