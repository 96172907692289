import { applicationAxios } from '../../libs/axios';

const CreateApplicationLang = async ({ appName, version, data }) => {
	try {
		const formData = {
			version,
			data
		};
		const ret = await applicationAxios.post(`/app/${appName}/lang`, formData);
		return ret.data;
	} catch (error) {
		return error;
	}
}

const UpdateApplicationLang = async ({ appName, version, lang, data }) => {
	try {
		const formData = {
			version,
			lang,
			data
		};
		const ret = await applicationAxios.put(`/app/${appName}/lang`, formData);
		return ret.data;
	} catch (error) {
		return error;
	}
}

const DeleteApplicationLang = async ({ appName, version, lang }) => {
	try {
		const formData = {
			version,
			lang
		};
		const ret = await applicationAxios.post(`/app/${appName}/lang/delete`, formData);
		return ret.data;
	} catch (error) {
		return error;
	}
}


export const ApplicationLangAction = {
	CreateApplicationLang,
	DeleteApplicationLang,
	UpdateApplicationLang
};
