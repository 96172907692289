import axios from "axios";
import yaml from 'js-yaml';
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
//import SwaggerUI from "swagger-ui-react";
//import "swagger-ui-react/swagger-ui.css";
import { PuffSpinner } from "../../components/common/RAYSpinner";
import SandboxToolbar from "../../components/toolbars/SandboxToolbar";
import { API_SANDBOX } from "../../constants";
import ModalHook from "../../hooks/ModalHook";
import PageInOutHook from "../../hooks/PageInOutHook";
import { passwordRegex } from "../../libs/regexs";
//import "../../theme-material.css";

const TABS = {
    USER: 'user',
    GROUP: 'group',
    PROJECT: 'project'
};
const URLS = {
    USER: 'https://rayapps-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/swaggers/swagger-rayteams-msa-user.yaml',
    GROUP: 'https://rayapps-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/swaggers/swagger-rayteams-msa-group.yaml',
    PROJECT: 'https://rayapps-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/swaggers/swagger-rayteams-msa-project.yaml',
};

const END_POINTS = {
    USER: 'api-ap-northeast-2-user-API_KEY.rayteams.com',
    GROUP: 'api-ap-northeast-2-group-API_KEY.rayteams.com',
    PROJECT: 'api-ap-northeast-2-project-API_KEY.rayteams.com',
};

const FORMATION_FOR_TAB = {
    'RAYTeams-User-Development': 'USER',
    'RAYTeams-Group-Development': 'GROUP',
    'RAYTeams-Project-Development': 'PROJECT',
    // TODO: 현재 사용중인 Production 환경이 모두 삭제되면 제거
    'RAYTeams-User-Production': 'USER',
    'RAYTeams-Group-Production': 'GROUP',
    'RAYTeams-Project-Production': 'PROJECT',
}

const DEFAULT_TAB = TABS.USER;

const ApiSandbox = ({ auth }) => {
    const [setToolbar] = PageInOutHook();
    const { openModal } = ModalHook();
    const [tabs] = useState([
        { code: TABS.USER, label: 'User', url: URLS.USER },
        { code: TABS.GROUP, label: 'Group', url: URLS.GROUP },
        { code: TABS.PROJECT, label: 'Project', url: URLS.PROJECT }
    ]);
    const [tab, setTab] = useState(tabs.find(x => x.code === DEFAULT_TAB));
    const [idToken, setIdToken] = useState('');
    const [loggedUser, setLoggedUSer] = useState({});
    const [swaggerUi, setSwaggerUi] = useState(null);
    const [provisioning, setProvisioning] = useState(true);
    const [apidocObj, setApiDocObj] = useState({});
    const [hasSandbox, setHasSandbox] = useState(false);
    const [environments, setEnvironments] = useState([]);
    const [environment, setEnvironment] = useState({});

    const provision = useCallback(async (doc) => {
        const jsDocs = {};
        for (const key of Object.keys(URLS)) {
            const url = URLS[key];
            const ret = await axios.get(url);
            const doc = yaml.load(ret.data);
            jsDocs[key] = doc;
        }
        const fixHost = (json, originHost, key) => {
            json.host = originHost.replace('API_KEY', key);
            return json;
        }
        const environment = {
            ...doc,
            swaggerJson: doc.items.reduce((acc, cur) => {
                const tabName = FORMATION_FOR_TAB[cur.doc.formationName];
                const json = jsDocs[tabName];
                const originHost = END_POINTS[tabName];
                const body = fixHost(json, originHost, doc.key);
                acc[TABS[tabName]] = body;
                return acc;
            }, {})
        };
        setApiDocObj(prev => ({ ...prev, [doc.key]: environment }))
    }, []);

    useEffect(() => {
        setApiDocObj({});
        const docs = [...(auth?.apidoc && Array.isArray(auth.apidoc) ? auth.apidoc : [])];
        if (auth?.groupName === 'RAY') {
            docs.push(JSON.stringify({
                key: "development",
                label: "Rayteams Development",
                desc: "Rayteams Development",
                items: API_SANDBOX.RAYTeams.map(x => ({ doc: x })),
                isDev: true
            }));
        } else {
            docs.push(JSON.stringify({
                key: "defaultsandbox",
                label: "Default Sandbox",
                desc: "Default Sandbox",
                items: API_SANDBOX.RAYTeams.map(x => ({ doc: x })),
                isDev: true
            }));
        }
        if (docs.length > 0) {
            let envs = [];
            for (const docStr of docs) {
                const doc = JSON.parse(docStr);
                envs.push(doc);
                provision(doc);
            }

            if (envs.length > 0) {
                envs = envs.map(prev => ({ ...prev, value: prev.key }));
                setEnvironments(envs);
                if (envs.length >= 1) {
                    setEnvironment(envs[0]);
                }
                setProvisioning(false);
                setHasSandbox(true)
            } else {
                setEnvironments([]);
                setProvisioning(false);
                setHasSandbox(false)
            }
        } else {
            setEnvironments([]);
            setProvisioning(false);
            setHasSandbox(false)
        }
    }, [provision, auth]);

    useEffect(() => {
        if (!provisioning) {
            setToolbar(<SandboxToolbar tabs={tabs} tab={tab} setTab={setTab} environments={environments} environment={environment} setEnvironment={setEnvironment} />)
        }
    }, [setToolbar, tab, tabs, provisioning, environments, environment]);

    useEffect(() => {
        const token = localStorage.getItem("swagger-token");
        token && setIdToken(token);
        let _user = localStorage.getItem("swagger-user");
        if (_user) {
            _user = JSON.parse(_user);
            setLoggedUSer(_user);
        }
    }, [])

    useEffect(() => {
        if (idToken) {
            swaggerUi && swaggerUi.preauthorizeApiKey('JWT', idToken);
        } else {
            const token = localStorage.getItem("swagger-token");
            token && swaggerUi && swaggerUi.preauthorizeApiKey('JWT', token);
        }
    }, [idToken, swaggerUi])

    const requestInterceptor = useCallback((req, code) => {
        if (req.loadSpec) return req;
        if (code === TABS.USER && req.method === "POST" && req.url.endsWith("/user")) {
            const body = JSON.parse(req.body);
            const password = body.data.password;
            const pwdPass = passwordRegex.test(password);
            if (!pwdPass) {
                openModal({
                    title: 'Oops!! ... Something\'s wrong',
                    body: <div>
                        <div>
                            Password format is wrong.
                        </div>
                        <div className="text-start">
                            The password has the following rules:
                            <ul className='mt-2'>
                                <li>At least 8 characters</li>
                                <li>At least one uppercase letter</li>
                                <li>One or more lowercase letters</li>
                                <li>One or more numbers</li>
                                <li>One or more special characters</li>
                                <li>Pass all rules</li>
                            </ul>
                        </div>
                    </div>
                });
                return {};
            }
        }
        if (code === TABS.USER && req.method === "POST" && req.url.endsWith("/unregister")) {
            const body = JSON.parse(req.body);
            const email = body.data.email;
            const sub = body.data.sub;
            if (email && sub) {
                openModal({
                    title: 'Sorry... It will be activated in the following versions.',
                    body: <div>
                        <div className="text-start">
                            <div>For the Leave Membership feature, user pool isolation is currently in progress.</div>
                            <div className="mt-3">Testing is currently prohibited due to possible problems with actual use.</div>
                            <div>We will provide a functional test function as soon as the work is completed.</div>
                        </div>
                    </div>
                });
                return {};
            }
        }

        return req;
    }, [openModal]);

    const responseInterceptor = useCallback((res, code) => {
        if (code === TABS.USER) {
            if (res?.body?.data?.token?.IdToken) {
                localStorage.setItem("swagger-token", res?.body?.data?.token?.IdToken);
                localStorage.setItem("swagger-user", JSON.stringify(res?.body?.data?.user));
                setIdToken(res?.body?.data?.token?.IdToken);
                setLoggedUSer(res?.body?.data?.user);
            }
        }
    }, []);

    const handlerClickToClipboard = useCallback((text) => {
        window.navigator.clipboard.writeText(text);
        toast(`Copy ${text}!`, { type: 'success' });
    }, []);

    const handlerClickLogout = useCallback(() => {
        localStorage.removeItem('swagger-token');
        localStorage.removeItem('swagger-user');
        setIdToken('');
        setLoggedUSer({});
        swaggerUi && swaggerUi.preauthorizeApiKey('JWT', null);
        openModal({
            title: 'Logout',
            body: <div>
                <div className="text-start">
                    You have been logged out.<br />
                    APIs that require authentication fail.
                </div>
            </div>
        });
    }, [swaggerUi, openModal]);

    return <>
        {!provisioning && hasSandbox && <div>
            <div className="apiDoc">
                <div>
                    {loggedUser?._id && <div className="d-flex justify-content-between">
                        <div></div>
                        <div className="d-flex align-items-center">
                            <div className="me-2 badge bg-warning raycur" onClick={handlerClickLogout}>
                                LOGOUT
                            </div>
                            <div className="me-2" onClick={() => handlerClickToClipboard(loggedUser?._id)}>
                                <span className="label me-1">ID : </span>
                                <span className="fw-bolder">{loggedUser?._id}, </span>
                            </div>
                            <div className="me-2">
                                <span className="label me-1">Email : </span>
                                <span className="fw-bolder">{loggedUser?.email}, </span>
                            </div>
                            <div className="me-2">
                                <span className="label me-1">Name : </span>
                                <span className="fw-bolder">{loggedUser?.name}, </span>
                            </div>
                            <div className="me-2">
                                <span className="label me-1">token : </span>
                                <span className="me-2" onClick={() => handlerClickToClipboard(idToken)}><input readOnly={true} value={idToken || ''} /></span>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="swagger-wrap">
                    {environments.map((env, idEnv) => <React.Fragment key={idEnv}>
                        {env.key === environment.key && <>
                            {tabs.map((t, idt) => <div key={idt}>
                                {tab.code === t.code && apidocObj[env.key]?.swaggerJson[t.code] && apidocObj[env.key]?.isDev && <SwaggerUI
                                    spec={apidocObj[env.key]?.swaggerJson[t.code]}
                                    // url={t.url}
                                    requestInterceptor={req => requestInterceptor(req, t.code)}
                                    responseInterceptor={res => responseInterceptor(res, t.code)}
                                    onComplete={ui => setSwaggerUi(ui)}
                                />}
                                {tab.code === t.code && !apidocObj[env.key]?.isDev && <div className="text-muted fw-bolder fs-1 mt-6 text-center">
                                    Production Environment<br />
                                    Please request API Production Environment.
                                </div>}
                            </div>)}
                        </>}
                    </React.Fragment>)}
                </div>
            </div>
        </div>}
        {provisioning && <div className="d-flex align-items-center justify-content-center h-500px">
            <div className="d-flex flex-column align-items-center">
                <PuffSpinner />
                <div className="text-muted fw-bolder fs-1 mt-6 text-center">
                    Provisioning API Sandbox...<br />
                    Please wait...
                </div>
            </div>
        </div>}
        {!provisioning && !hasSandbox && <>
            <div className="d-flex flex-column align-items-center mb-12">
                <div className="text-muted fw-bolder fs-1 mt-6 text-center">
                    Undefined API Sandbox Environment<br />
                    Please request API Sandbox Environment.
                </div>
            </div>
        </>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(ApiSandbox);
