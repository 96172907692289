import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PageInOutHook from "../../hooks/PageInOutHook";
import { CommonAction } from "../../redux/actions";
import ApplicationToolbar from "../toolbars/ApplicationToolbar";
import SettingHomepageAppsAppPageApps from "./SettingHomepageAppsAppPageApps";
import SettingHomepageAppsMenuApps from "./SettingHomepageAppsMenuApps";
import { Card } from "react-bootstrap";

const GetHomepagePreset = async () => {
    const payload = {
        type: "query",
        region: "ap-northeast-2",
        params: {
            TableName: "rayrnd-applications",
            KeyConditionExpression: "#name = :name and #type = :type",
            ExpressionAttributeNames: { "#name": "name", "#type": "type" },
            ExpressionAttributeValues: { ":name": "homepage", ":type": "preset" },
        },
    };
    const list = await CommonAction.CallDB(payload);
    return list[0] ?? {};
};

const SettingHomepageApps = ({ auth, apps }) => {
    const [setToolbar] = PageInOutHook();
    const [preset, setPreset] = useState({});

    const homeApps = useMemo(() => {
        const result = [];
        for (const _app of apps) {
            if (!_app.is_active || _app.isdev === "Y" || !_app.showHome || (!_app.last_published_version && !_app.inAppFeature)) {
                continue;
            }
            result.push(_app);
        }
        return result;
    }, [apps]);

    const refreshHomepagePreset = useCallback(async () => {
        const _preset = await GetHomepagePreset();
        setPreset(_preset);
    }, []);

    useEffect(() => {
        refreshHomepagePreset();
    }, []);

    useEffect(() => {
        setToolbar(<ApplicationToolbar
            title={"Setting Homepage Apps"}
            suffixDom={<>
                <Link to="/applications" className="btn btn-success">To Applications</Link>
            </>}
        />);
    }, [setToolbar, auth]);

    return <div className="d-flex flex-column gap-3">
        <Card>
            <Card.Body>
                <SettingHomepageAppsMenuApps
                    preset={preset}
                    homeApps={homeApps}
                    callbackRefresh={refreshHomepagePreset}
                />
            </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <SettingHomepageAppsAppPageApps
                    preset={preset}
                    homeApps={homeApps}
                    callbackRefresh={refreshHomepagePreset}
                />
            </Card.Body>
        </Card>
    </div>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const apps = state.ApplicationReducer.apps;
    return { auth, apps };
};

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(SettingHomepageApps);
