import axios from 'axios';

export const athenaQuery = async ({ q, withdata, qid, nextToken, itemcount }) => {
    const url = "https://api-teams-info.rayteams.com/athena"
    try {
        const ret = await axios.post(url + "/query", { data : {
            query : q,
            database : "default",
            qid,
            itemcount,
            nextToken,
            withdata
		}});
        console.log(ret.data);
        return ret.data
    } catch (error) {
        console.log('ERROR[versionSort]: ', error);
    }
};

export const athenaGetAll = async (qId) => {
    const url = "https://api-teams-info.rayteams.com/athena"
    try {
        const ret = await axios.post(url + "/getresultall", { data : {
            QueryExecutionId : qId
		}});
        console.log(ret.data);
        return ret.data
    } catch (error) {
        console.log('ERROR[versionSort]: ', error);
    }
};

export const athenaGet = async (qId, token = "") => {
    const url = "https://api-teams-info.rayteams.com/athena"
    try {
        const ret = await axios.post(url + "/getresult", { data : {
            QueryExecutionId : qId,
            NextToken : token
		}});
        console.log(ret.data);
        return ret.data
    } catch (error) {
        console.log('ERROR[versionSort]: ', error);
    }
};