import { useCallback, useState } from "react";
import RAYButton from "../common/RAYButton";
import RAYFile from "../common/RAYFile";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYSelect from "../common/RAYSelect";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import { v4 } from "uuid";
import { Badge, Card } from "react-bootstrap";
import CardRadio from "../forms/CardRadio";


const FORM_TYPES = [
  // { value: "text", label: "Text" },
  // { value: "number", label: "Number" },
  // { value: "textarea", label: "Text Area" },
  { value: "radiobutton", label: "Radio" },
  { value: "card_radio", label: "Card Radio" },
  // { value: "toggle", label: "Toggle" },
  // { value: "list", label: "Check Box" },
  { value: "subject_name_first", label: "Project Name First" },
  { value: "subject_name_last", label: "Project Name Last" },
  { value: "subject_birth", label: "Project Birth" },
];

const AddItemBlock = ({ callbackAddItem }) => {
  const [val, setVal] = useState("");
  const [label, setLabel] = useState("");
  const handlerClickAdd = () => {
    if (!val) {
      return;
    }
    callbackAddItem && callbackAddItem({
      uKey: v4(),
      value: val,
      label: label,
    });
    setVal("");
    setLabel("");
  }
  return <>
    <div></div>
    <RAYTextField
      placeholder="Radio Item Value"
      value={val}
      onChange={(e) => setVal(e.target.value)}
    />
    <RAYTextField
      placeholder="Radio Item Label"
      value={label}
      onChange={(e) => setLabel(e.target.value)}
    />
    <RAYButton
      label="ADD Item"
      color="primary"
      css="w-200px"
      onClick={handlerClickAdd}
    />
  </>
};

const AppSettingUIServiceProjectForm = ({ edit = false, create = false, item, data, setData, setUploadHooks }) => {

  const addFormType = (_type) => {
    setData(prev => {
      const _tmp = { ...prev };
      let _item = {};
      if (_type.value === "radiobutton") {
        _item = {
          uKey: v4(),
          type: _type.value,
          origin: _type.value,
          options: {
            label: "",
            name: "",
            list: [],
            required: false
          },
        };
      } else if (_type.value === "card_radio") {
        _item = {
          uKey: v4(),
          type: _type.value,
          origin: _type.value,
          options: {
            label: "",
            name: "",
            list: [],
            required: false
          },
        };
      } else if (_type.value === "subject_name_first" || _type.value === "subject_name_last") {
        _item = {
          uKey: v4(),
          type: "hidden",
          options: {
            name: _type.value,
            value: "",
          },
          origin: _type.value,
        };
      } else if (_type.value === "subject_birth") {
        _item = {
          uKey: v4(),
          type: "hidden",
          options: {
            name: _type.value,
            value: "",
          },
          origin: _type.value,
          dateFormat: "YYYY-MM-DD"
        };
      }
      if (_item.type) {
        _tmp.serviceProjectForm.controls = [...(_tmp?.serviceProjectForm?.controls || []), _item];
      }
      return _tmp;
    });
  };

  const removeControlItem = (uKey) => {
    setData(prev => {
      const _tmp = { ...prev };
      _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.filter(x => x.uKey !== uKey);
      return _tmp;
    });
  };

  const changeControlItemValue = (uKey, field, val) => {
    setData(prev => {
      const _tmp = { ...prev };
      _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map(x => {
        if (x.uKey !== uKey) {
          return x;
        }
        return { ...x, [field]: val };
      });
      return _tmp;
    });
  };

  const changeControlItemOptionsValue = (uKey, field, val) => {
    setData(prev => {
      const _tmp = { ...prev };
      _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map(x => {
        if (x.uKey !== uKey) {
          return x;
        }
        const options = { ...x.options };
        console.log(options)
        options[field] = val
        return { ...x, options };
      });
      return _tmp;
    });
  };

  const removeRadioItem = (uKey, itemUKey) => {
    setData(prev => {
      const _tmp = { ...prev };
      _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map(x => {
        if (x.uKey !== uKey) {
          return x;
        }
        x.options.list = x.options.list.filter(y => y.uKey !== itemUKey);
        return x;
      });
      return _tmp;
    });
  };

  const changeControlItem = (newItem) => {
    console.log("changeControlItem");
    setData(prev => {
      const _tmp = { ...prev };
      const isRemove = newItem.uKey && newItem?._remove;
      if (isRemove) {
        _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map(x => {
          if (x.uKey !== uKey) {
            return x;
          }
          x.options.list = x.options.list.filter(y => y.uKey !== itemUKey);
          return x;
        });
        return _tmp;
      }
      const targetIndex = _tmp.serviceProjectForm.controls.findIndex(x => x.uKey === newItem.uKey);
      if (targetIndex > -1) {
        _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map((x, idx) => idx === targetIndex ? { ...newItem } : x);
      }
      return _tmp;
    });
  };

  return <>
    <div className="w-100">
      <div>
        <span>Use Before Sync Form</span>
        <div>
          <RAYToggle
            value={data?.serviceProjectForm?.active || false}
            onChange={e => setData(prev => {
              const _tmp = { ...prev };
              _tmp.serviceProjectForm.active = e.target.checked;
              return _tmp;
            })}
            disabled={!edit}
          />
        </div>
      </div>
      <div>
        <span>Form Title</span>
        <RAYTextField
          value={data?.serviceProjectForm?.title || ""}
          readOnly={!edit}
          onChange={e => setData(prev => {
            const _tmp = { ...prev };
            _tmp.serviceProjectForm.title = e.target.value;
            return _tmp;
          })}
        />
        <span>Form Config</span>
        <div className="">
          {edit && <RAYSelect
            value={""}
            items={FORM_TYPES}
            clearable={false}
            placeholder="Select form type"
            css="w-200px"
            callbackOnChange={addFormType}
          />}
          {data?.serviceProjectForm?.controls && data?.serviceProjectForm?.controls?.length > 0 && <div className="mt-3">
            <h4>
              Form Controls
            </h4>
            <div className="d-flex flex-column gap-2">
              {data?.serviceProjectForm?.controls?.map(x => <div key={x.uKey} className="d-flex align-items-start gqp-2">
                <div style={{ border: "1px solid #ddd", borderRadius: 6 }} className="p-2 flex-fill">
                  {x.origin === "radiobutton" && <div className="d-flex flex-column gap-2">
                    <h5>Radio</h5>
                    <div className="d-flex align-items-center gap-2">
                      <i className="w-100px">Required</i>
                      <RAYToggle
                        value={x?.options?.required || false}
                        onChange={(e) => changeControlItemOptionsValue(x.uKey, "required", e.target.checked)}
                        disabled={!edit}
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <i className="w-100px">Label</i>
                      <RAYTextField
                        value={x.options.label || ""}
                        readOnly={!edit}
                        onChange={(e) => changeControlItemOptionsValue(x.uKey, "label", e.target.value)}
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <i className="w-100px">Field Name</i>
                      <RAYTextField
                        value={x.options.name || ""}
                        readOnly={!edit}
                        onChange={(e) => changeControlItemOptionsValue(x.uKey, "name", e.target.value)}
                      />
                    </div>
                    <div>
                      <div className="d-flex align-items-center gap-2">
                        <i className="w-100px">Radio Items</i>
                        {edit && <AddItemBlock callbackAddItem={val => changeControlItemOptionsValue(x.uKey, "list", [...(x.options.list || []), val])} />}
                      </div>
                      <div className="d-flex flex-column gap-2 mt-2">
                        {x?.options?.list?.map(_item => <div
                          key={_item.uKey}
                          className="d-flex align-items-center justify-content-between gap-2 p-2"
                          style={{ border: "1px dotted #ddd" }}
                        >
                          <div>
                            <i className="me-2">Value</i>
                            {_item?.value}
                            <br></br>
                            <i className="me-2">Label</i>
                            {_item?.label}
                          </div>
                          {edit && <Badge bg="danger" onClick={() => removeRadioItem(x.uKey, _item.uKey)}>Remove</Badge>}
                        </div>)}
                      </div>
                    </div>
                  </div>}
                  {x.origin === "card_radio" && <>
                    <CardRadio
                      appName={item?.name}
                      item={x}
                      setData={setData}
                      onChange={changeControlItem}
                      edit={edit}
                    />
                  </>}
                  {x.origin === "subject_name_first" && <div>
                    <h5>Project Name First</h5>
                    <i>
                      Project Name First is auth fill by project subject.<br />
                      Config only field name.
                    </i>
                    <div>Field Name</div>
                    <RAYTextField
                      value={x.options.name || ""}
                      readOnly={!edit}
                      placeholder="Ex) YYYY-MM-DD"
                      onChange={e => changeControlItemOptionsValue(x.uKey, "name", e.target.value)}
                    />
                  </div>}
                  {x.origin === "subject_name_last" && <div>
                    <h5>Project Name Last</h5>
                    <i>
                      Project Name Last is auth fill by project subject.<br />
                      Config only field name.
                    </i>
                    <div>Field Name</div>
                    <RAYTextField
                      value={x.options.name || ""}
                      readOnly={!edit}
                      placeholder="Ex) YYYY-MM-DD"
                      onChange={e => changeControlItemOptionsValue(x.uKey, "name", e.target.value)}
                    />
                  </div>}
                  {x.origin === "subject_birth" && <div>
                    <h5>Project Birth</h5>
                    <i>
                      Project Birth is auth fill by project subject.<br />
                      Config only field name and data format.<br />
                    </i>
                    <div>Field Name</div>
                    <RAYTextField
                      value={x.options.name || ""}
                      readOnly={!edit}
                      placeholder="Ex) YYYY-MM-DD"
                      onChange={e => changeControlItemOptionsValue(x.uKey, "name", e.target.value)}
                    />
                    <div>Date Format</div>
                    <RAYTextField
                      value={x.dateFormat || ""}
                      readOnly={!edit}
                      placeholder="Ex) YYYY-MM-DD"
                      onChange={e => changeControlItemValue(x.uKey, "dateFormat", e.target.value)}
                    />
                  </div>}
                </div>
                {edit && <div className="ms-2">
                  <RAYButton
                    label="Remove"
                    color="warning"
                    onClick={() => removeControlItem(x.uKey)}
                  />
                </div>}
              </div>)}
            </div>
          </div>}
        </div>
      </div>
    </div>
  </>
}

export default AppSettingUIServiceProjectForm;
