import { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import RAYButton from "../common/RAYButton";
import RAYFormRawInline from '../common/RAYFormRawInline';
import { Query, Update, Delete } from "../../libs/dynamo";
import { AppConstant } from "../../redux/reducers";
import RAYTextField from '../common/RAYTextField';
import RAYSelect from "../common/RAYSelect";
import AppLogCodeModal from "./AppLogCodeModal";

const AppLogCodes = ({ wh, style, cancelCallback, doneCallback, app }) => {
	const { appName } = useParams();
    const [filter, setFilter] = useState({
        wh : ""
    });

    const [mode, setMode] = useState("list");
    const [codeshow, setCodeShow] = useState(false);
    const [list, setList] = useState([]);
    const [code, setCode] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        setFilter({...filter, wh});
    }, [wh]);

    const getData = async() => {
        console.log(app);
        const params = {
            TableName: "rayrnd-applications",
            KeyConditions: {
                name: { ComparisonOperator: "EQ", AttributeValueList: [appName], },
                type: { ComparisonOperator: "BEGINS_WITH", AttributeValueList: ["code:"], }
            },
        };
        const ret = await Query(params);
        console.log(ret.Items)
        setList(ret.Items)
    }

    const del = (x) => {
        //dispatch({ type: AppConstant.SET_SW_LOG_FILTER, filter : {...x, filter : x.wh }});
    }

    const callback = (newitem) => {
		if(newitem?.type){
			if(list.find(x => x.type === newitem.type)){
				setList(list.map(x => x.type === newitem.type ? newitem : x));
			}else{
				setList([...list, newitem]);
			}
		}
        setCodeShow(false);
    }

    return <div className="" style={{ ...style }}>
        <div className="text-end mb-1">
            <RAYButton color="primary" label={"Add new"} onClick={() => setCodeShow(true)} />
        </div>
        <table className="table" style={{ fontSize : 13 }}>
            <thead>
                <tr>
                    <th style={{ width : 300 }}>Code</th>
                    <th>Description</th>
                    {/* <th style={{ width : 50 }}></th> */}
                </tr>
            </thead>
            <tbody>
                {list.map((x, idx) => <tr key={"tr-" + idx}>
                    <td>{x.type.replace("code:", "")}</td>
                    <td>{x.desc}</td>
                    {/*<td className="text-end"><RAYButton style={{ padding : "8px 12px" }} color="danger" label={"Del"} onClick={() => del(x)} /></td>*/}
                </tr>)}
            </tbody>
        </table>
        <AppLogCodeModal show={codeshow} item={{...code, appname : appName }} callback={(e) => callback(e) } />
    </div>
}

export default AppLogCodes;
