import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LANGUAGE } from "../../constants";
import ModalHook from "../../hooks/ModalHook";
import { ApplicationLangAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import RAYTabs from "../common/RAYTabs";
import TinyEditor from "../tinymce/TinyEditor";
import AppReleaseDetailNote from "./AppReleaseDetailNote";

const ApplicationVersionLangView = ({ version, item, lang, callbackCancel, callbackCreateSuccess, callbackDeleteLang, callbackUpdateSuccess }) => {
    const [isCreate, setIsCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState({});
    const { openModal, closeModal, changeFooter } = ModalHook();
    const [tabs, setTabs] = useState([
        { code: "dev", label: "Summary", subLabel: "For RAYTeams note and developers", disabled: false },
        { code: "user", label: "End-user", subLabel: "For End Users(Homepage)", disabled: true },
    ]);
    const [tab, setTab] = useState(tabs[0]);
    const [updateUser, setUpdateUser] = useState(null);
    const [deleteUser, setDeleteUser] = useState(null);

    useEffect(() => {
        setUpdateUser(null);
        setDeleteUser(null);
    }, [lang?.code, version?.name, version?.version, item?.type])

    useEffect(() => {
        setTab(tabs[0]);
    }, [lang?.code])

    useEffect(() => {
        if (item && item.lang) {
            setData({
                lang: item.lang,
                title: item.title,
                desc: item.desc,
                is_active: item.is_active
            });
            setTabs(prev => prev.map(x => x.code === "user" ? { ...x, disabled: false, subLabel: "For Homepage End Users" } : x))
        } else {
            setTabs(prev => prev.map(x => x.code === "user" ? { ...x, disabled: true, subLabel: "For End Users(Homepage)" } : x))
        }
    }, [item]);

    useEffect(() => {
        if (lang && lang.code) {
            setIsCreate(true);
            setEdit(true);
            setData({
                lang: lang.code,
                title: '',
                desc: '',
                is_active: false
            })
        }
    }, [lang]);

    const updateHandler = useCallback(async () => {
        if (tab.code === "dev") {
            if (isCreate) {
                if (!version || !version.name || !version.version || !lang?.code) {
                    toast('Wrong Data', { type: 'error' });
                    return;
                }
                const _data = {
                    ...data,
                    lang: lang.code,
                    is_active: data?.is_active || false
                }
                const ret = await ApplicationLangAction.CreateApplicationLang({ appName: version.name, version: version.version, data: _data })
                if (ret.status === 'success') {
                    toast('Success Create Release Note.', { type: 'success' });
                    callbackCreateSuccess && callbackCreateSuccess();
                    return
                }
                toast('Fail Create Release Note', { type: 'error' });
            } else {
                if (!data || !data.title || !data.desc || !item?.lang) {
                    toast('Wrong Data', { type: 'error' });
                    return;
                }
                const _data = {
                    ...data,
                    is_active: data?.is_active || false
                }
                const ret = await ApplicationLangAction.UpdateApplicationLang({ appName: version.name, version: version.version, lang: item.lang, data: _data })
                if (ret.status === 'success') {
                    toast('Success Update Release Note.', { type: 'success' });
                    callbackUpdateSuccess && callbackUpdateSuccess();
                    setEdit(false);
                    return
                }
                toast('Fail Update Release Note', { type: 'error' });
            }
        } else if (tab.code === "user") {
            if (updateUser) {
                await updateUser();
            }
        }
    }, [isCreate, version, lang?.code, data, callbackCreateSuccess, item?.lang, callbackUpdateSuccess, tab?.code, updateUser]);

    const deleteLang = useCallback(async () => {
        changeFooter(<>
            <RAYButton color="light" label="Cancel" disabled />
            <RAYButton color="danger" label="Delete" disabled />
        </>)
        const data = {
            appName: version.name,
            version: version.version,
            lang: item.lang
        }
        const ret = await ApplicationLangAction.DeleteApplicationLang(data);
        if (ret.status === 'success') {
            toast('Success Delete Release Note.', { type: 'success' });
            callbackDeleteLang && callbackDeleteLang();
            closeModal();
            return
        }
        toast('Fail Delete Release Note', { type: 'error' });
    }, [callbackDeleteLang, changeFooter, item?.lang, version?.name, version?.version, closeModal]);

    const deleteHandler = useCallback(() => {
        if (!item || !item.lang) {
            return;
        }
        const runDelete = async () => {
            if (tab.code === "dev") {
                deleteUser && await deleteUser();
                deleteLang();
            } else if (tab.code === "user") {
                deleteUser && await deleteUser();
                closeModal();
            }
        }
        openModal({
            title: <h3 className="text-danger">Delete Release Note - {LANGUAGE?.find(x => x.code === item.lang)?.label}</h3>,
            body: <div className="fs-4">
                <div>This operation cannot be recovered.</div>
                <div>Do you want to proceed?</div>
            </div>,
            footer: <>
                <RAYButton color="light" label="Cancel" onClick={closeModal} />
                <RAYButton color="danger" label="Delete" onClick={runDelete} />
            </>
        })
    }, [closeModal, deleteLang, deleteUser, item, openModal, tab.code]);

    const callbackUpdateFunction = useCallback(fnc => {
        setUpdateUser(() => fnc);
        return () => {
            setUpdateUser(null);
        }
    }, []);

    const callbackDeleteFunction = useCallback(fnc => {
        setDeleteUser(() => fnc);
        return () => {
            setDeleteUser(null);
        }
    }, []);


    return <>
        <div className="version-content">
            <div className="card">
                <div className="card-header border-bottom">
                    <h5 className="mb-0">Release note - {lang?.label || LANGUAGE?.find(x => x.code === item.lang)?.label}</h5>
                </div>
                <RAYTabs tabs={tabs} tab={tab} setTab={setTab} hideLine={true} itemCss="p-4" css="" />
                {tab.code === "dev" && <form className="form" onSubmit={e => e.preventDefault()}>
                    <div className="card-body border-top">
                        <div className="mb-2">
                            <label className="form-label fw-bolder text-end">Title</label>
                            <div className="mt-2">
                                {!edit && <span>{item?.title}</span>}
                                {edit && <>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={data?.title || ''}
                                        onChange={(e) => setData(prev => ({ ...prev, title: e.target.value }))}
                                    />
                                </>}
                            </div>
                        </div>
                        <div className="mb-2">
                            <label className="form-label fw-bolder text-end">Description</label>
                            <div className="mt-2">
                                {!edit && <div dangerouslySetInnerHTML={{ __html: item?.desc || '-' }} className="html-contents" />}
                                {edit && <TinyEditor
                                    originContent={item?.desc}
                                    callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                                    height={600}
                                    //name={data?.lang}
                                    //content={data?.desc} callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                                    //type={EDITOR_CONST.TYPE.MARKDOWN}
                                    //s3KeyPrefix={`rayrnd/applications/${item?.name}/versions/${version?.version}/release_attachments/`}
                                    //useImage={true}
                                />}
                            </div>
                        </div>
                        <div className="">
                            <label className="form-label fw-bolder text-end">Status</label>
                            <div className="mt-2">
                                {!edit && <span>{item?.is_active ? 'Published' : 'Draft'}</span>}
                                {edit && <label className="form-check d-inline-flex form-switch form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" checked={data?.is_active ? 'checked' : ''} onChange={() => setData(prev => ({ ...prev, is_active: !prev.is_active }))} />
                                    <span className="form-check-label fw-bold text-muted ms-2">
                                        {data?.is_active ? 'Published' : 'Draft'}
                                    </span>
                                </label>}
                            </div>
                        </div>
                    </div>
                </form>}
                <div className={`card-body border-top ${tab.code !== "user" ? "d-none" : ""}`}>
                    <AppReleaseDetailNote 
                        version={version} 
                        lang={lang?.code || item?.lang} 
                        edit={edit} 
                        callbackUpdateFunction={callbackUpdateFunction}
                        callbackDeleteFunction={callbackDeleteFunction}
                    />
                </div>
                <div className="card-footer text-end">
                    <button type="reset" className="btn btn-light btn-active-light-primary" onClick={() => {
                        if (edit) {
                            setEdit(false);
                        } else {
                            callbackCancel && callbackCancel()
                        }
                    }}>Cancel</button>
                    {!edit && <button type="reset" className="btn btn-light-danger ms-1" onClick={deleteHandler}>Delete</button>}
                    {!edit && <button className="btn btn-primary ms-1" onClick={() => setEdit(true)}>Edit</button>}
                    {edit && <button className="btn btn-primary ms-1" onClick={updateHandler}>Update</button>}
                </div>
            </div>
        </div>
    </>
};

export default ApplicationVersionLangView;
