import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { Dropdown } from "react-bootstrap";
import { AppAction, AuthAction } from "../redux/actions";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import UserDetailModal from './user/UserDetailModal';

const TopMenu = ({ isLogin, auth, Logout, isLoginProd, authProd }) => {

	const dispath = useDispatch();
	const [t] = useTranslation(['translation']);
    const navigator = useNavigate();
	const [modalShow, setModalShow] = useState(false);
	
    const logout = () => {
        Logout();
    }

    const setting = () => {
        setModalShow(true);
    }

    const callbackClose = () => {
        setModalShow(false);
    };

  	return <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
        <div className="container-fluid">
            <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
                <Link to="/" className="app-brand-link gap-2">
                    <img src="/logo.png" style={{ height : 24 }} />
                    <span className="app-brand-text demo menu-text fw-bold">RAYTeams Developer Center</span>
                </Link>

                <Link to="/" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                    <i className="ti ti-x ti-sm align-middle"></i>
                </Link>
            </div>

            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                <a className="nav-item nav-link px-0 me-xl-4" href="#">
                    <i className="ti ti-menu-2 ti-sm"></i>
                </a>
            </div>


            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                {!isLogin && <ul className="navbar-nav flex-row align-items-center ms-auto">
                    <li className="nav-item navbar-dropdown dropdown-user dropdown">
                        <Link className="nav-link dropdown-toggle hide-arrow" to={"/login"} data-bs-toggle="dropdown">
                            Login
                        </Link>
                    </li>
                </ul>}

                {isLogin && auth?.name && <>
                    <NavDropdown title={auth?.name +(authProd?.name ? "(Production : " + authProd?.name + ") " : "") } className="navbar-nav flex-row align-items-center ms-auto">
                    <NavDropdown.Item href="" onClick={setting}> Update Password </NavDropdown.Item>
                    {isLoginProd && <NavDropdown.Item href="">PRD : {authProd?.name}</NavDropdown.Item>}
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="" onClick={logout}> Logout </NavDropdown.Item>
                </NavDropdown>
                </>}

            </div>
        </div>
        {auth?.sub &&
            <div className="pt-4">
                <UserDetailModal show={modalShow} sub={auth.sub} isUpdate={modalShow} callbackClose={callbackClose} />
            </div>
        }
    </nav>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const isLoginProd = state.AuthReducer.isLoginProd;
    const auth = state.AuthReducer.user;
    const authProd = state.AuthReducer.prduser;
    return { isLogin, auth, isLoginProd, authProd };
};

const mapDispatch = (dispatch) => ({
    Logout: () => dispatch(AuthAction.Logout())
});

export default connect(mapState, mapDispatch)(TopMenu);
