import { useEffect } from "react";
import moment from "moment";
import { useCallback } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Delete, Get, Update } from "../../libs/dynamo";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYSelect from "../common/RAYSelect";
import { APP_CMS_TYPE } from "../../constants";
import { LANGUAGE } from "../../constants";
import RAYTextField from "../common/RAYTextField";
import TinyEditor from "../tinymce/TinyEditor";
import { getAttributeUpdatesSet } from "../../libs/functions";
import { ApplicationCMSAction } from "../../redux/actions/ApplicationCMSAction";

const AppSettingCMSEdit = ({ appName, auth, pk, version, lang, edit, callbackUpdateFunction, callbackDeleteFunction }) => {
    const [item, setItem] = useState({});
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (pk) {
            getCms();
        } else {
            setData({
                lang,
                version
            });
        }
    }, [appName, edit, lang, version, pk]);

    const getCms = async () => {
        const ret = await ApplicationCMSAction.GetCMSContent(pk);
        if(ret.length > 0){
            const tmpCode = ret[0].code.split(":");
            console.log(tmpCode);
            setItem({ ...ret[0], type : tmpCode[0], code : tmpCode[1] });
            setData({ ...ret[0], type : tmpCode[0], code : tmpCode[1] });
        }else{
            alert("No~ Data!");
        }
    }

    const save = async () => {
        const ret = await ApplicationCMSAction.CreateCMSContent({...data,
            code : data.type + ":" + data.code,
            pk : new Date().getTime().toString(),
            version : version,
            app : appName,
            sk : appName + ":" + version + ":" + lang});
        navigate("/applications/" + appName + "/cms/" + version + "?lang=" + lang + "&pk=" + ret.pk + "&type=view");
    }

    const update = async () => {
        await ApplicationCMSAction.UpdateCMSContent({...data,
            code : data.type + ":" + data.code,
            version : version,
        });
        navigate("/applications/" + appName + "/cms/" + version + "?lang=" + lang + "&pk=" + pk + "&type=view");
    }

    const removeItem = async () => {
        await ApplicationCMSAction.RemoveCMSContent(pk);
        navigate("/applications/" + appName + "/cms/" + version + "?lang=" + lang);
    }

    const getLang = (code) => {
        return LANGUAGE.find(x => x.code === code)?.label || code;
    }
    
    return <div>
        <div className="card">
            <div className="card-body">
                <div className="w-100">
                    <div className="mb-3">
                        <div>Version & Language</div>
                        <div className="fw-bolder fs-5">
                            Version : {data?.version || "Common"} / Language : {getLang(data?.lang)}
                        </div>
                    </div>

                    {data.updated && <div className="mb-3">
                        <div>Updated</div>
                        <div className="fw-bolder">{moment(data.updated).format("YYYY-MM-DD hh:mm:ss")}</div>
                    </div>}

                    <div className="mb-3">
                        <div>Type {edit && <span className="text-danger">*</span>}</div>
                        {!edit && <div className="fw-bolder">
                            {APP_CMS_TYPE.find(x => x.value === data?.type)?.label || "-"}
                        </div>}
                        {edit && <RAYSelect value={data.type} items={APP_CMS_TYPE}
                            callbackOnChange={(e) => setData(prev => ({ ...prev, type: e?.value })) }
                            style={{ width : 200 }} />}
                    </div>

                    <div className="mb-3">
                        <div>Code {edit && <span className="text-danger">*</span>}</div>
                        {!edit && <div className="fw-bolder">
                            {data?.code || "-"}
                        </div>}
                        {edit && <div className="d-flex align-items-center">
                            <RAYTextField
                                value={data?.code || ""}
                                onChange={e => setData(prev => ({ ...prev, code: e.target.value }))}
                                placeholder="insert content code"
                                style={{ width : 300 }}
                            /> <span className="ms-2">Lowercase Only</span>
                        </div>}
                    </div>

                    <div className="mb-3">
                        <div>Title {edit && <span className="text-danger">*</span>}</div>
                        {!edit && <div className="fw-bolder">
                            {data?.title || "-"}
                        </div>}
                        {edit && <RAYTextField
                            value={data?.title || ""}
                            onChange={e => setData(prev => ({ ...prev, title: e.target.value }))}
                            placeholder="Insert Title"
                        />}
                    </div>

                    <div className="mb-3">
                        <div>Content {edit && <span className="text-danger">*</span>}</div>
                        <div className="w-100 fw-bolder">
                            {!edit && <div dangerouslySetInnerHTML={{ __html: data?.content || '-' }} className="html-contents mt-2" />}
                            {edit && <TinyEditor
                                name={"t_" + lang}
                                originContent={item?.content || ""}
                                callbackOnChange={(content) => setData(prev => ({ ...prev, content: content }))}
                                s3KeyPrefix={`rayrnd/applications/${version}/versions/${version}/release_attachments/`}
                                height={600}
                            />}
                        </div>
                    </div>

                    {/*<div className="mb-3">
                        <div>Status</div>
                        {!edit && <span className="fw-bolder">{data?.is_active ? 'Published' : 'Draft'}</span>}
                        {edit && <label className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" checked={data?.is_active ? 'checked' : ''} onChange={() => setData(prev => ({ ...prev, is_active: !prev.is_active }))} />
                            <span className="form-check-label fw-bold text-muted">
                                {data?.is_active ? 'Published' : 'Draft'}
                            </span>
                        </label>}
                    </div>*/}

                    <div className="mb-3">
                        <div>Link</div>
                        {!edit && <div className="fw-bolder">
                            {data?.link || "-"}
                        </div>}
                        {edit && <RAYTextField
                            value={data?.link || ""}
                            onChange={e => setData(prev => ({ ...prev, link: e.target.value }))}
                            placeholder="Insert Link(Homepage...)"
                        />}
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
            {!edit && <div>
                <button className="btn btn-primary" onClick={() => {
                    navigate("/applications/" + appName + "/cms/" + version + "?lang=" + lang + "&pk=" + pk + "&type=edit");
                }}>Edit</button>
                <button className="btn btn-danger ms-2" onClick={() => {
                    if(confirm("Really?")){
                        remove();
                    }
                }}>Remove</button>
            </div>}
            {edit && <div>
                <button className="btn btn-primary" onClick={() => pk ? update() : save()}>{pk ? "Update" : "Save"}</button>
            </div>}
            <div>
                <button className="btn btn-light ms-1" onClick={() => {
                    navigate("/applications/" + appName + "/cms/" + version + "?lang=" + lang);
                }}>List</button>
            </div>
        </div>
    </div>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(AppSettingCMSEdit);
