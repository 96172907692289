export const ApplicationVersionTableHeader = [
    {
        Header: 'VERSION NAME',
        accessor: 'version_name',
    },
    {
        Header: 'VERSION TYPE',
        accessor: 'vtype',
    },
    {
        Header: 'AUTH-USERS',
        accessor: 'authusers',
    },
    {
        Header: 'FILE SIZE',
        accessor: 'file_size',
    },
    {
        Header: 'FULL FILE SIZE',
        accessor: 'full_file_size',
    },
    {
        Header: 'PUBLISH DATE',
        accessor: 'published_at',
    },
    {
        Header: 'STATUS',
        accessor: 'published',
    },
    // {
    //     Header: '',
    //     accessor: 'actions',
    //     noneSort: true
    // }
];

export const UsersTableHeader = [
    {
        Header: 'GROUP',
        accessor: 'groupName',
    },
    {
        Header: 'NAME',
        accessor: 'name',
    },
    {
        Header: 'EMAIL',
        accessor: 'email',
    },
    {
        Header: 'CREATED',
        accessor: 'created',
    },
    {
        Header: 'ROLES',
        accessor: 'roles',
    },
    {
        Header: '',
        accessor: 'actions',
        noneSort: true
    }
];

export const ApiDocTableForUser = [
    {
        Header: 'GROUP',
        accessor: 'groupName',
    },
    {
        Header: 'NAME',
        accessor: 'name',
    },
    {
        Header: 'EMAIL',
        accessor: 'email',
    },
    {
        Header: 'SANDBOX',
        accessor: 'apidocLabels',
        noneSort: true
    },
    {
        Header: '',
        accessor: 'actions',
        noneSort: true
    }
];

export const ApiDocTableForDoc = [
    {
        Header: 'KEY',
        accessor: 'key',
    },
    {
        Header: 'STATUS',
        accessor: 'devDiv',
    },
    {
        Header: 'LABEL',
        accessor: 'label',
    },
    {
        Header: 'DESCRIPTION',
        accessor: 'desc',
    },
    {
        Header: 'GROUP',
        accessor: 'user.groupName',
    },
    {
        Header: 'NAME',
        accessor: 'user.name',
    },
    {
        Header: 'EMAIL',
        accessor: 'user.email',
    },
    {
        Header: '',
        accessor: 'actions',
        noneSort: true
    }
];
export const AppCMSContentsHeader = [
    {
        Header: 'Code',
        accessor: 'code',
    },
    {
        Header: 'Title',
        accessor: 'title',
    },
    {
        Header: 'Version',
        accessor: 'version',
    },
    {
        Header: 'Language',
        accessor: 'lang',
    },
    {
        Header: 'Link',
        accessor: 'link',
    },
    {
        Header: 'Updated',
        accessor: 'updated',
    },
];
