const initialState = { 
	user: null,
	prduser: null,
	perms: [],
	prdperms: [],
	isLogin: false,
	isLoginProd: false,
	token: null,
	authToken: null,
};

export const AuthConstant = {
	LOGIN_SUCCESS: "ACTION_AUTH_LOGIN_SUCCESS",
	LOGIN_SUCCESS_PRD: "ACTION_AUTH_LOGIN_SUCCESS_PRD",
	LOGOUT: "ACTION_AUTH_LOGOUT",
	SET_IS_LOGIN: "ACTION_AUTH_SET_IS_LOGIN",
	VERSION_UPDATE : "ACTION_APP_VERSION_UPDATE",
	INFO_UPDATE : "ACTION_APP_INFO_UPDATE",
};

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
		case AuthConstant.LOGIN_SUCCESS:
			return {...state,
				isLogin: true,
				user: action.payload.user,
				token: action.payload.token,
				authToken: action.payload?.authToken || state.authToken,
				perms: action.payload.perms
			}
		case AuthConstant.LOGIN_SUCCESS_PRD:
			return {...state,
				isLoginProd: true,
				prduser: action.payload.user,
				prdtoken: action.payload.token,
				prdauthToken: action.payload?.authToken || state.authToken,
				prodperms: action.payload.perms
			}
		case AuthConstant.SET_IS_LOGIN:
			return {...state,
				isLogin: action.payload
			}
		case AuthConstant.LOGOUT:
			return {...state,
				isLogin: false,
				user: null,
				token: null,
				authToken: null,
				perms: []
			}
		case AuthConstant.VERSION_UPDATE : 
			return {...state, 
				version: action.value
			};
        case AuthConstant.INFO_UPDATE : 
			return {...state, 
				info: action.value
			};
        default:
            return state
    }
}
