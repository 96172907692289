import RAYButton from "../common/RAYButton";

const SandboxAdminToolbar = ({ tabs, tab, setTab }) => {
    return <div className="toolbar" style={{ height: 55 }}>
        <div className="toolbar-wrapper">
            <div className="page-title">
                <h1>API Sandbox Admin</h1>
            </div>
            <div className="ms-3">
                {tabs.map((x, idx) => <RAYButton
                    key={idx}
                    label={x.label}
                    onClick={() => setTab(x)}
                    color={x.code === tab.code ? 'primary' : 'light'}
                />)}
            </div>
        </div>
    </div>
}

export default SandboxAdminToolbar;