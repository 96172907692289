import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { mdConverter } from '../libs/converter';
import MarkDown from './markdown';
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'

const GitHub = ({ ndoc }) => {

	const [t] = useTranslation(['translation']);

	useEffect(() => {
	},[])
	
  	return <div className="mt-2">
        {ndoc && <button 
            className="btn btn-block w-100 btn-lg btn-white" 
            onClick={() => 
                window.open("https://github.com/rayteams/docs/blob/main/" + ndoc, "_blank")
            }>
            <i className="ti ti-brand-github me-2" /> Github
        </button>}
    </div>
}

const mapState = (state) => {
    const ndoc = state.ProgramReducer.doc;
	return { ndoc };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(GitHub);
