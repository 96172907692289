import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApplicationVersionDetailView from '../../../components/applicationVersion/ApplicationVersionDetailView';
import ApplicationToolbar from '../../../components/toolbars/ApplicationToolbar';
import PageInOutHook from '../../../hooks/PageInOutHook';
import { ApplicationAction, ApplicationVersionAction } from '../../../redux/actions';
import _ from "lodash";


const ApplicationVersionCreate = ({ apps, GetApplication }) => {
    const { appName } = useParams();
    const [setToolbar] = PageInOutHook();
    const [app, setApp] = useState({});
    const [info, setInfo] = useState(null);
    const [originVersions, setOriginVersion] = useState([]);
    const [versions, setVersions] = useState([]);
    const [uploadHooks, setUploadHooks] = useState({});
    const [viewAllVersions, setViewAllVersions] = useState(false);
    const navigator = useNavigate();

    useEffect(() => {
        appName && apps.length && setApp(apps.find(x => x.name === appName));
    }, [apps, appName]);

    const getApp = useCallback(async () => {
        const data = await GetApplication(appName);
        if (data) {
            setInfo(data.app);
            setOriginVersion(data.versions.sort((a, b) => a.created > b.created ? -1 : 1));
        }
    }, [GetApplication, appName]);

    useEffect(() => {
        setVersions(viewAllVersions ? originVersions : originVersions.slice(0, 5));
    }, [originVersions, viewAllVersions])

    useEffect(() => {
        if (!appName) { return; }
        getApp();
        return () => {
            setInfo(null);
            setVersions([]);
        }
    }, [getApp, appName]);

    useEffect(() => {
        if (app?.name) {
            setToolbar(<ApplicationToolbar
                title={<>{app.title} <small>Add new version</small></>}
                appName={app.name}
                suffixDom={<div>
                    <Link to={`/applications/${app.name}`} className="btn btn-warning me-1">Application Detail</Link>
                    <Link to={`/applications/${app.name}/templates/create`} className="btn btn-success">Create New Content Set</Link>
                </div>}
            />);
        }
    }, [app, setToolbar]);

    const submit = useCallback(async (data) => {
        const uploadFields = Object.keys(uploadHooks);
        delete data.file;
        const uploads = [];
        for (const uploadField of uploadFields) {
            const upload = uploadHooks[uploadField];
            const fnc = async () => {
                const upRet = await upload();
                if (upRet.name.startsWith("manuals_")) {
                    const manualLang = upRet.name.replace("manuals_", "");
                    let _manualDocs =  data.manualDocs || [];
                    _manualDocs = _manualDocs.filter(x => x.manualLang !== manualLang);
                    data.manualDocs = [..._manualDocs, { manualLang, ... upRet.data }];
                    delete data[upRet.name];
                } else {
                    data[upRet.name] = upRet.data;
                    data[upRet.name + '_name'] = upRet.data.file_name;
                }
            };
            uploads.push(fnc);
        }
        const next = async () => {
            const _data = {
                appName: appName,
                data
            }
            const ret = await ApplicationVersionAction.CreateApplicationVersion(_data);
            if (ret?.version) {
                await getApp();
                navigator(`/applications/${appName}/versions/${ret.version}`);
            } else {
                toast('Fail Create Version.', { type: 'error' })
            }
        }
        if (uploads.length > 0) {
            Promise.all(uploads.map(f => f())).then(async () => {
                await next();
                setUploadHooks({});
            }).catch(() => {
                toast('Fail Upload File', { type: 'error' });
            })
        } else {
            await next()
        }
    }, [uploadHooks, appName, getApp, navigator]);


    return <>
        {info && <div className="row">
            <div className="col-4">
                <div className="card">
                    <div className="card-header">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h5 className='m-0'>Recent versions</h5>
                            {originVersions.length > 5 && <div
                                className={`badge raycur bg-${viewAllVersions ? 'light' : 'success'} user-select-none`}
                                onClick={() => setViewAllVersions(prev => !prev)}
                            >more</div>}
                        </div>
                    </div>
                    <div className="card-body border-top pt-4">
                        <div className='d-flex flex-column gap-1'>
                            {versions.length > 0 && versions.map((x, idx) => <div key={idx}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="fw-bolder">{x.version}</div>
                                    <div className="d-flex align-items-center text-end">
                                        <span className='text-muted small'>{moment(x.created).format('LL')}</span>
                                    </div>
                                    <div className='text-end'>
                                        {x.is_active && <span className="badge bg-warning">Published</span>}
                                        {!x.is_active && x.is_plan && <span className="badge bg-warning">Plan</span>}
                                        {!x.is_active && !x.is_plan && <span className="badge bg-secondary">Draft</span>}
                                    </div>
                                </div>
                            </div>)}
                            {versions.length === 0 && <>
                                Not Find Versions.
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            {info && <div className="col-8"><ApplicationVersionDetailView
                create={true}
                callbackCancel={() => navigator(`/applications/${appName}`)}
                callbackUpdate={submit}
                callbackUploadHooks={val => setUploadHooks(val)}
            /></div>}
        </div>}
    </>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const apps = state.ApplicationReducer.apps;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    return { isLogin, apps, appVersions };
};

const mapDispatch = (dispatch) => ({
    GetApplication: (appName) => dispatch(ApplicationAction.GetApplication(appName))

})

export default connect(mapState, mapDispatch)(ApplicationVersionCreate);
