import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RAYToolTip } from "../common/RAYToolTip";

const ApidocLabel = ({ apidocJsonStr }) => {
    const [apidoc, setApidoc] = useState({});
    useEffect(() => {
        try {
            const obj = JSON.parse(apidocJsonStr);
            setApidoc(obj);
        } catch (error) {
            setApidoc({});
        }
    }, [apidocJsonStr]);


    return <>
        {apidoc.key && <RAYToolTip tooltip={apidoc?.desc || ''}>
            <div className={"badge " + (apidoc?.isDev ? 'bg-success' : 'bg-secondary')}>
                <span>{apidoc?.label || apidoc?.key}</span>
            </div>
        </RAYToolTip>}
    </>
}

const mapState = (state) => {
    return { };
};

const mapDispatch = (dispatch) => ({})

export default connect(mapState, mapDispatch)(ApidocLabel);
