import axios from "axios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppConstant } from "../redux/reducers/AppReducer";
import { Store } from '../store';

const storageKey = 'RSP_CLIENT_ETAG';
const DELAY = 1000 * 30;
let checked = null;


const checkVersion = async () => {
    try {
        if (!window?.localStorage) {
            return;
        }
        if (checked && (checked + DELAY) >= new Date().getTime()) {
            return;
        }
        checked = new Date().getTime();
        const ret = await axios.get(window.location.origin + '?v=' + checked);
        const etag = ret?.headers?.etag;
        const savedEtag = Store.getState().AppReducer.etag;
        if (savedEtag && savedEtag === etag) {
            return;
        }
        window.localStorage.setItem(storageKey, etag);
        if (!savedEtag) {
            Store.dispatch({ type: AppConstant.SET_ETAG, item: etag });
            return;    
        }
        Store.dispatch({ type: AppConstant.SET_ETAG, item: etag });
        // window?.navigator?.serviceWorker?.register("https://developer.rayteams.com/service-worker.js?v=" + new Date().getTime());
        // window?.navigator?.serviceWorker?.register("https://developer.rayteams.com/service-worker.js");
        window.location.reload();
    } catch (error) {
        //
    }
}

const CheckClientHook = () => {
    const location = useLocation();
    useEffect(() => {
        checkVersion();
    }, [location?.key]);

    return {};
}

export default CheckClientHook;