const initialState = { 
	info : {},
	doc : "",
};

export const ProgramConstant = {
    DOC : "NOW_DOC"
};

export const ProgramReducer = (state = initialState, action) => {
	switch (action.type) {
        case ProgramConstant.DOC : 
			return {...state, doc : action.doc };
		default:
			return state;
	}
};
