import { Store } from "../store";

export const checkPerm = (perm, role) => {
    const auth = Store.getState().AuthReducer.user;
    const perms = Store.getState().AuthReducer.perms;
    
    if (!auth) {
        return false;
    }
    // if (auth.is_super_admin) {
    //     return true;
    // }
    const targetPerm = perms.find(x => x.type === 'info:perm:' + perm)
    if (!targetPerm) {
        return false;
    }
    return targetPerm[role] || false;
}