import { useEffect, useRef, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LANGUAGE } from "../../constants";
import { APP_TERM_TYPES, TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import { ApplicationConstant } from "../../redux/reducers";
import RAYButton from "../common/RAYButton";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";
import AppSettingTermsAssetTermSelector from "./AppSettingTermsAssetTermSelector";

const AppSettingTermsRootTC = ({ app, create = false, auth, storedTerms, UpdateAppData, RemoveAppData }) => {
    const [appTerms, setAppTerms] = useState([]);
    const [rootTerm, setRootTerm] = useState(null);
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [readySubmit, setReadySubmit] = useState(false);
    const [versions, setVersions] = useState([]);
    const [aliasEdit, setAliasEdit] = useState(false);
    const [hasPublishedVersion, setGasPublishedVersion] = useState(false);
    const aliasRef = useRef();
    const [readySubmitPublish, setReadySubmitPublish] = useState(false);

    useEffect(() => {
        setReadySubmit(false);
        setAliasEdit(false);
    }, [data]);

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
    }, [storedTerms, app?.name]);

    useEffect(() => {
        if (appTerms && create) {
            const _temp = appTerms.find(x => x.type === TYPE.TEMP_TC_ROOT);
            _temp && setData({ ..._temp });
        } else if (appTerms && !create) {
            const _temp = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_ROOT);
            if (_temp) {
                const _rootTerm = appTerms.find(x => x.type === _temp.data.type);
                setRootTerm(_rootTerm);
                setData({ alias: _rootTerm?.alias });
            }
        } else {
            setRootTerm(null);
            setData({})
        }
    }, [appTerms, create]);

    useEffect(() => {
        if (!rootTerm?.type) {
            return;
        }
        const prefix = TYPE.TC_VERSION + rootTerm.type.replace(TYPE.TC_ROOT, "");
        setVersions(appTerms.filter(x => x.type.startsWith(prefix)))
    }, [rootTerm?.type, appTerms]);

    useEffect(() => {
        setGasPublishedVersion(!!versions.find(x => x.isPublished));
    }, [versions])

    const handlerChangeCode = (_val) => {
        const _code = _val?.code;
        if (!_code) {
            return;
        }
        const origin = appTerms.find(x => x.type === TYPE.TEMP_TC_ROOT);
        const payload = { ...origin, ...data, code: _code };
        dispatch({ type: ApplicationConstant.SET_TERM, appName: app.name, item: payload });
    };
    const handlerClickCancelCreate = () => {
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.TEMP_TC_ROOT });
    };

    const handlerClickCreate = async () => {
        if (!data?.alias) {
            return;
        }
        const rowType = TYPE.TC_ROOT + data.code + ":" + data.alias.replace(/[\s|:]/g, "_");
        if (appTerms.find(x => x.type === rowType)) {
            toast('It has the same alias.', { type: 'error' });
            return;
        }
        if (!readySubmit) {
            setReadySubmit(true);
            return;
        }
        const origin = appTerms.find(x => x.type === TYPE.TEMP_TC_ROOT);
        const _data = { ...origin, ...data };
        delete _data.name;
        delete _data.type;

        const payload = {
            appName: app.name,
            type: rowType,
            item: {
                ..._data,
                creator: auth.sub,
                created: new Date().getTime(),
                updater: auth.sub,
            },
        };
        const ret = await UpdateAppData(payload);
        if (ret.status === 'success') {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: origin.type });
        } else {
            toast('Error', { type: 'error' });
        }
    };

    const handlerClickRemoveDraft = async () => {
        if (rootTerm.isPublished) {
            toast('Don\'t Remove.', { type: 'error ' });
            return;
        }
        if (versions.length > 0) {
            toast('Version is not empty. Please delete all versions and try.', { type: 'error ' });
            return;
        }
        if (!readySubmit) {
            setReadySubmit(true);
            return;
        }
        const payload = {
            appName: rootTerm.name,
            type: rootTerm.type
        };
        const ret = await RemoveAppData(payload);
        if (ret?.status === 'success') {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_ROOT });
        }
        setReadySubmit(false);
    };

    const handlerUpdateRootTC = async () => {
        const payload = {
            appName: app.name,
            type: rootTerm.type,
            item: {
                alias: aliasRef.current.innerText
            },
        };
        await UpdateAppData(payload);
        setAliasEdit(false);
    };

    const handlerPublish = async () => {
        if (!readySubmitPublish) {
            setReadySubmitPublish(true);
            return;
        }
        const sortedVersion = versions.sort((a, b) => a.version > b.version ? -1 : 1);
        const currentPublishedVersion = sortedVersion.find(x => x.isPublished);
        if (!currentPublishedVersion) {
            toast('Error, please reload', { type: 'error' });
            return;
        }
        const payload = {
            appName: app.name,
            type: rootTerm.type,
            item: {
                isPublished: true,
                currentVersion: currentPublishedVersion.version,
                currentPublished: currentPublishedVersion.published,
                lastDoc: currentPublishedVersion.langs,
                docs: [{
                    version: currentPublishedVersion.version,
                    published: currentPublishedVersion.published,
                    docs: currentPublishedVersion.langs,
                }],
            }
        };
        await UpdateAppData(payload);
        setReadySubmitPublish(false);
    };

    return <>
        <h3 className="d-flex align-items-center">
            <div>
                {create && <>
                    {data?.alias && <span>{data.alias}</span>}
                    {!data?.alias && <span className="text-muted">Create Terms and Condition</span>}
                </>}
                {!create && <div>
                    <div className="">
                        <i>{APP_TERM_TYPES.find(x => x.code === rootTerm?.code)?.label}</i>
                    </div>
                    <div className="hover-wrap d-flex align-items-end pe-8">
                        {!aliasEdit && <div className="pe-2">{data?.alias}</div>}
                        {aliasEdit && <div ref={aliasRef} contentEditable={aliasEdit} suppressContentEditableWarning={true} className="editable-div-native pe-2">
                            {data?.alias}
                        </div>}
                        {!aliasEdit && <div className="hover-active">
                            <AiOutlineEdit size={20} className="raycur text-muted" onClick={() => setAliasEdit(true)} />
                        </div>}
                        {aliasEdit && <>
                            <AiOutlineEdit size={20} className="raycur text-primary" onClick={handlerUpdateRootTC} />
                            <MdOutlineCancel size={20} className="raycur text-muted ms-1" onClick={() => setAliasEdit(false)} />
                        </>}
                    </div>
                </div>}
            </div>
            <div className="flex-fill" />
            <div className="d-flex align-items-center">
                {create && <>
                    <RAYButton
                        label={readySubmit ? "Really Create?" : "Create"}
                        onClick={handlerClickCreate}
                        color="primary"
                        size="sm"
                        disabled={!data?.alias}
                    />
                    <RAYButton
                        label="Cancel Create"
                        onClick={handlerClickCancelCreate}
                        color="light"
                        size="sm"
                    />
                </>}

                {!create && <>
                    {!rootTerm?.isPublished && <>
                        {hasPublishedVersion && <>
                            <RAYButton
                                label={readySubmitPublish ? "Really Publish Terms and Condition?" : "Publish Terms and Condition"}
                                onClick={() => !readySubmitPublish && handlerPublish()}
                                color={readySubmitPublish ? "warning" : "primary"}
                            />
                        </>}
                        <RAYButton
                            label={readySubmit ? "Really Remove Draft?" : "Remove Draft"}
                            onClick={handlerClickRemoveDraft}
                            color="danger"
                        />
                    </>}
                </>}
            </div>
        </h3>
        {rootTerm?.isPublished && <div>
            <div className="d-flex align-items-center" style={{ gap: 6 }}>
                <i>Status : </i>
                <div className="badge bg-primary">Published</div>
                <div className="badge bg-primary">Current Version {rootTerm?.currentVersion}</div>
                <i>Languages : </i>
                {rootTerm?.lastDoc && rootTerm.lastDoc.map(x => <div className="badge bg-success" key={x.code}>
                    {LANGUAGE.find(l => l.code === x.code)?.label || x.code}
                </div>)}
            </div>
        </div>}

        {readySubmitPublish && <div className="p-4 mb-5 bg-danger text-white" style={{ borderRadius: 10 }}>
            <div className="fs-1 mb-4">Attention!</div>
            <div className="fs-3">
                This operation is not canceled.<br />
                After the terms and conditions are published, they will be exposed to service end users.<br />
            </div>
            <div className="mt-4 d-flex justify-content-end">
                <RAYButton
                    label="Publish"
                    onClick={handlerPublish}
                    color="warning"
                />
                <RAYButton
                    label="Not Yet"
                    onClick={() => setReadySubmitPublish(false)}
                    color="light"
                />
            </div>
        </div>}
        {create && <div>
            <RAYFormRaw title={"Term Type"} labelCol={2}>
                <RAYTextField
                    value={data?.code ? (APP_TERM_TYPES.find(x => x.code === data.code)?.label || data.code) : ""}
                    readOnly={true}
                />
                {create && <AppSettingTermsAssetTermSelector toggleTitle="Change" css="ms-2" onSelected={handlerChangeCode} />}
            </RAYFormRaw>
            <RAYFormRaw title={"Alias"} labelCol={2} required={true}>
                <div className="w-100">
                    <RAYTextField
                        value={data?.alias || ""}
                        onChange={e => setData(prev => ({ ...prev, alias: (e.target.value || "").replace(/[:]/g, "") }))}
                    />
                    <div className="small text-muted mt-1">Aliases are not exposed to end users.</div>
                </div>
            </RAYFormRaw>
        </div>}
    </>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsRootTC);
