import { useEffect, useState } from "react"

const RAYFormRaw = ({ children, title, required = false, labelCol = 3, css = '', labelCss = 'pt-6' }) => {
    const [colLabel, setColLabel] = useState(labelCol);
    const [colArea, setColArea] = useState(12 - labelCol);

    useEffect(() => {
        setColLabel(labelCol);
        setColArea(labelCol === 12 ? 12 : 12 - labelCol);
    }, [labelCol]);

    return <div className={`row ${css} ray-form-row mb-2`}>
        <div className={`text-end col-lg-${colLabel} col-form-label ${labelCss}`}>
            {title}
            {required && <span className="text-danger ms-2">*</span>}
        </div>
        <div className={`col-lg-${colArea} d-flex align-items-center py-0 my-0`}>
            {children}
        </div>
    </div>
};

export default RAYFormRaw;

