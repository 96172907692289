import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { RAYIconApps, RAYIconUser } from "../../components/common/RAYIcon";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import { AppConstant } from "../../redux/reducers";
import MenuItem from "./MenuItem";

const LOCAL_STORAGE_FAV_ITEMS_KEY = 'rsp-lsFavItems';

const Menu = ({ auth, GetAllApplication, apps, menuTracking }) => {
	const dispatch = useDispatch();
	const [visibleApplication, setVisibleApplication] = useState(false);
	const [visibleSendBoxAdmin, setVisibleSendBoxAdmin] = useState(false);
	const [visibleSendBox, setVisibleSendBox] = useState(false);
	const [menus, setMenus] = useState([]);

	useEffect(() => {
		setVisibleApplication(!!auth?.perms?.includes("application"));
		setVisibleSendBox(!!auth?.perms?.includes("sandbox"));
		setVisibleSendBoxAdmin(!!auth?.is_account_manager);
		return () => {
			setMenus([]);
		}
	}, [auth]);

	useEffect(() => {
		let _lsFavItems = window?.localStorage?.getItem(LOCAL_STORAGE_FAV_ITEMS_KEY);
		_lsFavItems = _lsFavItems ? JSON.parse(_lsFavItems) : [];
		const _menus = [];
		if (visibleApplication) {
			_menus.push({
				group: 'applications',
				path: '/applications',
				label: 'Applications',
				icon: <RAYIconApps size="24" />,
				sub: apps.filter(x => _lsFavItems?.includes(x.name)).sort((a, b) => (a.title || a.name) < (b.title || b.name) ? -1 : 1).map(x => ({
					label: x.title || x.name,
					group: 'applications',
					path: '/applications/' + x.name,
					sub: []
				}))
			});
		}
		setMenus(_menus);
	}, [apps, visibleApplication, visibleSendBox, visibleSendBoxAdmin, menuTracking, auth]);

	useEffect(() => {
		if (visibleApplication) {
			const getAllApps = async () => {
				await GetAllApplication();
			};
			getAllApps();
		}
	}, [visibleApplication, GetAllApplication]);

	useEffect(() => {
		dispatch({ type: AppConstant.SET_MENU, payload: menus });
	}, [dispatch, menus]);

	return <>
        {/*<div className="menu menu-column">
			{menus.map((menu, idx) => <MenuItem item={menu} key={idx} />)}
			{(!!auth && auth.is_account_manager) && <>
				<div className="menu-item">
					<Link to="/users" className="menu-link">
						<span className="menu-icon">
							<span className="svg-icon">
								<RAYIconUser size="24" />
							</span>
						</span>
						<span className="menu-title">User Setting</span>
					</Link>
				</div>
			</>}
		</div>*/}
	</>
}

const mapState = (state) => {
	const auth = state.AuthReducer.user;
	const apps = state.ApplicationReducer.apps;
	const menuTracking = state.AppReducer.menuTracking;
	return { auth, apps, menuTracking };
};

const mapDispatch = (dispatch) => ({
	GetAllApplication: () => dispatch(ApplicationAction.GetAllApplication())
});

export default connect(mapState, mapDispatch)(Menu);
