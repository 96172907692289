import { getAWSProd } from '../config/aws';

const getDdb = async () => {
	const AWS = await getAWSProd();
	if (!AWS) {
		return null;
	}
	const ddb = new AWS.DynamoDB.DocumentClient();
	return ddb;
};

const GetItem = async (TableName, Key, query) => {
	const ddb = await getDdb();
	if (!ddb) {
		return null;
	}
	const p = {
		TableName,
		Key,
	};
	if (query) {
		p.QueryFilter = query;
	}
	const { Item: item } = await ddb.get(p).promise();
	return item;
};

const Query = async (params) => {
	const ddb = await getDdb();
	if (!ddb) {
		return null;
	}
	const ret = await ddb.query(params).promise();
	return ret;
};

const Update = async (params) => {
	const ddb = await getDdb();
	if (!ddb) {
		return null;
	}
	const ret = await ddb.update(params).promise();
	return ret;
};

const Get = async (params) => {
	const ddb = await getDdb();
	if (!ddb) {
		return null;
	}
	const ret = await ddb.get(params).promise();
	return ret;
};

const Delete = async (params) => {
	const ddb = await getDdb();
	if (!ddb) {
		return null;
	}
	const ret = await ddb.delete(params).promise();
	return ret;
};

export {
    getDdb,
	GetItem,
	Query,
	Update,
	Get,
	Delete,
}

