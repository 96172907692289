import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { LANGUAGE } from "../../constants";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYButton from "../common/RAYButton";
import RAYDragItems from "../common/RAYDragItems";
import RAYFile from "../common/RAYFile";
import LangArea from "./LangArea";

const AppTemplateFormScreenshot = ({ app, auth, lang, setLang, version, templates, publishable = false, UpdateAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [image, setImage] = useState([]);
    const [uploadQueues, setUploadQueues] = useState([]);
    const [imageDataUrl, setImageDataUrl] = useState(null);
    const [uploadFileName, setUploadFileName] = useState(v4());

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        if (lang) {
            setImage(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:image:${lang}`) || {});
        }
    }, [templates, version, app.name, lang]);

    const handlerSetUploadHooks = useCallback((payload) => {
        setUploadQueues([payload]);
    }, []);

    const callbackOnChangeFile = useCallback((obj) => {
        fileToDataUrl(obj.value);
    }, []);

    const fileToDataUrl = (file) => {
        if (!file || !(file instanceof File)) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageDataUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handlerClickUpload = async () => {
        if (uploadQueues.length !== 1) {
            return;
        }
        const ret = await uploadQueues[0].upload();
        setImageDataUrl(null);
        setUploadFileName(v4());

        const item = {
            name: app.name,
            type: image?.type || `t:body:${version}:image:${lang}`,
            images: [...(image?.images || []), ret.data],
        };
        if (!image?.mainImage) {
            item.mainImage = ret.data;
        }
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const handlerClickToMainImage = async (obj) => {
        if (!image?.type) {
            return;
        }
        const item = {
            name: app.name,
            type: image.type,
            mainImage: obj,
        };
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const handlerClickRemoveImage = async (obj) => {
        if (!image?.type) {
            return;
        }
        const _images = image.images.filter(x => x.s3_path !== obj.s3_path);
        const item = {
            name: app.name,
            type: image.type,
            images: _images
        };
        if (obj.s3_path === image.mainImage.s3_path) {
            item.mainImage = _images.length > 0 ? _images[0] : null;
        }
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const callBackItems = async (arr) => {
        if (!image?.type) {
            return;
        }
        const item = {
            name: app.name,
            type: image.type,
            images: arr.items.map((x, idx) => ({ ...x, order: idx + 1 }))
        };
        await UpdateAppTemplate({ appName: app.name, item });
    };


    return <div className="d-flex gap-2">
        <div style={{ maxWidth: 200, minWidth: 200 }}>
            <LangArea langs={header.langs || []} css="w-100" lang={lang} setLang={setLang} />
        </div>
        <div className="flex-fill">
            <div className="h5">
                {lang && <>
                    {LANGUAGE.find(l => l.code === lang)?.label || lang}
                </>}
                {!lang && <div className="text-muted">
                    <i>Please select a language.</i>
                </div>}
            </div>
            {lang && <div key={lang}>
                <RAYFile
                    item={null}
                    edit={true}
                    uploadFileName={uploadFileName}
                    hideFileName={true}
                    isArrItem={false}
                    fieldName={'image'}
                    keyPrefix={`rayrnd/applications/${app?.name}/template/${version}/image/${lang}/`}
                    callbackOnChangeFile={callbackOnChangeFile}
                    callbackUploadHook={handlerSetUploadHooks}
                />
                <div className="my-4">
                    {imageDataUrl && <>
                        <img src={imageDataUrl} alt="file thumbnail" style={{ maxHeight: 100 }} />
                        <div>
                            <RAYButton
                                label="Upload"
                                color="primary"
                                onClick={handlerClickUpload}
                            />
                        </div>
                    </>}
                </div>
                <div className="d-flex gap-2" style={{ flexWrap: "wrap" }}>
                    <RAYDragItems items={image?.images || []} itemQss=".drag-item-image" callBackItems={callBackItems}>
                        {image?.images?.length > 0 && image.images.map(x => <div key={x.s3_path} className="position-relative drag-item-image">
                            <img src={x.direct_link} alt={x.name} className="w-200px h-200px" style={{ objectFit: "contain", width : "240px" }} />
                            <div className="position-absolute bottom-0 end-0 d-flex gap-1">
                                {x.s3_path === image.mainImage.s3_path && <div className="badge bg-primary">
                                    Main Image
                                </div>}
                                {x.s3_path !== image.mainImage.s3_path && <>
                                    <div
                                        className="badge bg-warning"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handlerClickToMainImage(x)}
                                    >
                                        To Main Image
                                    </div>
                                </>}
                                <div
                                    className="badge bg-danger"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handlerClickRemoveImage(x)}
                                >
                                    Remove
                                </div>
                            </div>
                        </div>)}
                    </RAYDragItems>
                </div>
            </div>}

        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormScreenshot);
