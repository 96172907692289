import saveAs from 'file-saver';
import axios from 'axios';

export const saveFile = async (url, name, onDownloadProgress) => {
    try {
        const data = (await axios({ url, onDownloadProgress, responseType: "blob" })).data;
        saveAs(data, name);
    } catch (error) {
        saveAs(url, name);
    }
    return true;
} 

export const getFileSize = (bytes, si = true, dp = 1) => {
	const thresh = si ? 1000 : 1024;
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}
	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10 ** dp;
	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
	return bytes.toFixed(dp) + ' ' + units[u];
}