import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ApidocLabel from "../../components/apidoc/ApidocLabel";
import RAYButton from "../../components/common/RAYButton";
import RAYTable from "../../components/common/RAYTable";
import ApidocUserModal from "../../components/modals/ApidocUserModal";
import SandboxAdminToolbar from "../../components/toolbars/SandboxAdminToolbar";
import { ApiDocTableForDoc, ApiDocTableForUser } from "../../constants/tableHeaders";
import PageInOutHook from "../../hooks/PageInOutHook";
import { UserAction } from "../../redux/actions";

const TABS = {
    USER: 'user',
    APIDOC: 'apidoc'
};

const ApiSandboxAdmin = ({ auth, users, GetAllUser }) => {
    const [sandboxUserList, setSandboxUserList] = useState([]);
    const [apidocList, setApidocList] = useState([]);
    const [tabs] = useState([
        { code: TABS.USER, label: 'User ' },
        { code: TABS.APIDOC, label: 'Apidoc ' },
    ]);
    const [tab, setTab] = useState(tabs[0]);
    const [list, setList] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [setToolbar] = PageInOutHook();
    const [userModal, setUserModal] = useState({ show: false });

    useEffect(() => {
        if (userModal.show) {
            const modalUser = userModal?.user ? sandboxUserList.find(x => x.sub === userModal.user.sub) : userModal?.user;
            modalUser && setUserModal(prev => ({ ...prev, user: modalUser }));
        }
    }, [userModal.show, userModal?.user, sandboxUserList])

    useEffect(() => {
        if (tab.code === TABS.USER) {
            setList(sandboxUserList);
            setTableHeader(ApiDocTableForUser)
        } else if (tab.code === TABS.APIDOC) {
            setList(apidocList);
            setTableHeader(ApiDocTableForDoc)
        }
    }, [tab, sandboxUserList, apidocList]);

    useEffect(() => {
        setToolbar(<SandboxAdminToolbar tab={tab} setTab={setTab} tabs={tabs} />);
    }, [setToolbar, tabs, tab]);

    useEffect(() => {
        GetAllUser && GetAllUser()
    }, [GetAllUser])

    useEffect(() => {
        setSandboxUserList(users?.filter(x => x?.perms?.includes('sandbox')).map(x => {
            const hasDocs = x?.apidoc && Array.isArray(x?.apidoc) && x.apidoc.length > 0;
            return {
                ...x,
                apidocLabels: <div className="d-flex" style={{ gap: 2 }}>
                    {hasDocs && x.apidoc.map((doc, idDoc) => <ApidocLabel key={idDoc} apidocJsonStr={doc} />)}
                    {!hasDocs && <i>NONE</i>}
                </div>,
                actions: <>
                    <RAYButton label={'Detail'} color="primary" onClick={() => setUserModal({ user: x, show: true })} />
                </>
            }
        }));
        setApidocList(users.reduce((acc, cur) => {
            if (cur?.apidoc && Array.isArray(cur?.apidoc)) {
                for (const doc of cur.apidoc) {
                    const docObj = JSON.parse(doc);
                    acc.push({
                        ...docObj,
                        devDiv: <div className={`badge ${docObj.isDev ? 'bg-success' : 'bg-secondary'}`}>
                            {docObj.isDev ? 'Development' : 'Production'}
                        </div>,
                        user: cur,
                        actions: <>
                            <RAYButton label={'Detail'} color="primary" onClick={() => setUserModal({ user: cur, show: true, selectDoc: docObj })} />
                        </>
                    });
                }
            }
            return acc;
        }, []));
    }, [users]);

    return <>
        <RAYTable tableData={list} tableHeaders={tableHeader} />
        {list.length === 0 && <div className="fs-2 text-muted p-12 m-12 d-flex align-items-center justify-content-center">
            <i>None API Sandbox or User.</i>
        </div>}
        <ApidocUserModal modal={userModal} callbackClose={() => setUserModal(prev => ({ ...prev, show: false }))} />
    </>
};



const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const users = state.UserReducer.items;
    return { auth, users };
};

const mapDispatch = (dispatch) => ({
    GetAllUser: () => dispatch(UserAction.GetAll())
})

export default connect(mapState, mapDispatch)(ApiSandboxAdmin);

