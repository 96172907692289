import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import RAYButton from "../components/common/RAYButton";
import { ApplicationAction } from "../redux/actions";
import { ApplicationConstant } from "../redux/reducers";
import ModalHook from "./ModalHook";

const ApplicationHook = () => {
    const { openModal, closeModal, changeFooter } = ModalHook();
    const dispatch = useDispatch();

    const _createApp = useCallback(async (data, resolve, reject) => {
        try {
            changeFooter(<>
                <RAYButton color="light" disabled label="Cancel" />
                <RAYButton color="primary" disabled label="Create" />
            </>);
            const ret = await ApplicationAction.CreateApplication(data);
            if (ret.status === 'success') {
                toast('Success Create App', { type: 'success' });
                resolve(true);
            } else {
                toast('Fail Create App', { type: 'error' });
                resolve(false);
            }
        } catch (error) {
            toast('Fail Create App', { type: 'error' });
            reject(error);
        } finally {
            closeModal();
        }
    }, [changeFooter, closeModal]);

    const createApp = useCallback((data) => {
        return new Promise((resolve, reject) => {
            try {
                if (!data?.name) {
                    toast('require name', { type: 'info' });
                    return;
                }
                openModal({
                    title: <h3>Create Application.</h3>,
                    body: <div className="fs-4"> Create {data.name} application. </div>,
                    footer: <>
                        <RAYButton color="light" label="Cancel" onClick={() => {
                            closeModal();
                            resolve(false);
                        }} />
                        <RAYButton color="primary" label="Create" onClick={() => _createApp(data, resolve, reject)} />
                    </>
                })
            } catch (error) {
                reject(error);
            }
        })
    }, [_createApp, closeModal, openModal]);

    const _updateApp = useCallback(async (appName, data, resolve, reject) => {
        try {
            changeFooter(<>
                <RAYButton color="light" disabled label="Cancel" />
                <RAYButton color="primary" disabled label="Update" />
            </>);
            const ret = await ApplicationAction.UpdateApplication({ appName, data });
            if (ret.status === 'success') {
                toast('Success Update App', { type: 'success' });
                resolve(true);
            } else {
                toast('Fail Update App', { type: 'error' });
                resolve(false);
            }
        } catch (error) {
            toast('Fail Update App', { type: 'error' });
            reject(error);
        } finally {
            closeModal();
        }
    }, [changeFooter, closeModal]);

    const updateApp = useCallback((appName, data) => {
        return new Promise((resolve, reject) => {
            try {
                openModal({
                    title: <h3>Update Application.</h3>,
                    body: <div className="fs-4"> Update {appName} application. </div>,
                    footer: <>
                        <RAYButton color="light" label="Cancel" onClick={() => {
                            closeModal();
                            resolve(false);
                        }} />
                        <RAYButton color="primary" label="Update" onClick={() => _updateApp(appName, data, resolve, reject)} />
                    </>
                })
            } catch (error) {
                reject(error);
            }
        })
    }, [_updateApp, closeModal, openModal]);


    const _deleteApp = useCallback(async (appName, resolve, reject) => {
        try {
            changeFooter(<>
                <RAYButton color="light" disabled label="Cancel" />
                <RAYButton color="danger" disabled label="Delete" />
            </>);
            const ret = await ApplicationAction.DeleteApplication(appName);
            if (ret.status === 'success') {
                dispatch({ type: ApplicationConstant.DELETE_APP, payload: { appName } });
                toast('Success Delete App', { type: 'success' });
                resolve(true);
            } else {
                toast('Fail Delete App', { type: 'error' });
                resolve(false);
            }
        } catch (error) {
            toast('Fail Delete App', { type: 'error' });
            reject(error);
        } finally {
            closeModal();
        }
    }, [changeFooter, closeModal, dispatch]);

    const deleteApp = useCallback((appName) => {
        return new Promise((resolve, reject) => {
            try {
                openModal({
                    title: <h3 className="text-danger">Delete Application.</h3>,
                    body: <div className="fs-4"> Delete {appName} application. </div>,
                    footer: <>
                        <RAYButton color="light" label="Cancel" onClick={() => {
                            closeModal();
                            resolve(false);
                        }} />
                        <RAYButton color="danger" label="Delete" onClick={() => _deleteApp(appName, resolve, reject)} />
                    </>
                })
            } catch (error) {
                reject(error);
            }
        })
    }, [_deleteApp, closeModal, openModal]);


    return { createApp, updateApp, deleteApp };
}


export default ApplicationHook;

