import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import UploadHook from '../../hooks/UploadHook';
import { v4 } from 'uuid';

const TinyEditor = ({ originContent = "", callbackOnChange, height = 500, s3KeyPrefix = 'rayrnd/attachments/', placeholder }) => {
    const [value, setValue] = useState(originContent);
    const editorRef = useRef(null);
    const { uploadFileDirect } = UploadHook({});

    useEffect(() => {
        callbackOnChange && callbackOnChange(value);
    }, [value]);

    const onEditorChange = (newValue, editor) => {
        setValue(newValue)
    };

    const handlerOnDragEnd = useCallback(async (event) => {
        event.preventDefault();
        if (event.dataTransfer.files) {
            for (const file of event.dataTransfer.files) {
                if (!file.type.includes('image')) continue;
                const key = s3KeyPrefix + v4() + "_" + file.name;
                const ret = await uploadFileDirect(key, file, null, true);
                if (ret?.data?.direct_link) {
                    const imgStr = `<img src="${ret.data.direct_link}" class="attach-img" />`;
                    editorRef.current.execCommand("mceInsertContent", false, imgStr);
                }
            }
        }
    }, [s3KeyPrefix, uploadFileDirect]);

    return <>
        <Editor
            tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={originContent || ""}
            // onEditorChange={(newValue, editor) => setValue(newValue)}
            onEditorChange={onEditorChange}
            init={{
                height: height,
                skin: 'oxide', // oxide, oxide-dark, tinymce-5, tinymce-5-dark
                menubar: false,
                placeholder: placeholder,
                statusbar: false,
                fullscreen_native: true,
                selector: 'textarea',
                plugins: 'anchor autolink charmap code codesample emoticons image link lists media searchreplace table visualblocks wordcount ' +
                    'preview fullscreen ',
                toolbar: 'undo redo | h1 h2 h3 | ' +
                    'bold italic forecolor backcolor | fontsize | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'table tableinsertdialog tablecellprops tableprops advtablerownumbering | ' +
                    'emoticons image media link codesample | ' +
                    'removeformat | preview code visualblocks fullscreen',
                font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                content_style: 'body { font-size: 14px }',
            }}
            onDrop={handlerOnDragEnd}
        />
    </>
};

export default TinyEditor;
