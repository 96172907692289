import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ApplicationItem from '../../components/application/ApplicationItem';
import RAYSelect from '../../components/common/RAYSelect';
import RAYTextField from '../../components/common/RAYTextField';
import RAYToggle from '../../components/common/RAYToggle';
import ApplicationToolbar from '../../components/toolbars/ApplicationToolbar';
import { CONST_APPLICATION } from '../../constants';
import PageInOutHook from '../../hooks/PageInOutHook';
import { AppAction, ApplicationVersionAction } from '../../redux/actions';


const LOCAL_STORAGE_FAV_ITEMS_KEY = 'rsp-lsFavItems';
const LOCAL_STORAGE_OPTIONS_KEY = 'rsp-app-filter-options';

const ApplicationDashboard = ({ auth, apps, CallMenuChange }) => {
    const [setToolbar] = PageInOutHook();
    const [favItems, setFavItems] = useState([]);
    const [appList, setAppList] = useState([]);
    const [options, setOptions] = useState({});

    useEffect(() => {
        try {
            const _storedOptions = JSON.parse(window?.localStorage?.getItem(LOCAL_STORAGE_OPTIONS_KEY) || "{}");
            setOptions(_storedOptions);
        } catch (error) {
            setOptions({});
        }
    }, []);

    useEffect(() => {
        let _apps = [...apps];

        if (options?.q && options?.q?.length > 1) {
            const _q = options.q.toLowerCase();
            _apps = _apps.filter(x => (x.title || '').toLowerCase().includes(_q)|| x.name.toLowerCase().includes(_q) || (x.licenseId || '').toLowerCase().includes(_q));
        }
        if (options?.isAppStore) {
            _apps = _apps.filter(x => x.rayteams_apps)
        }
        if (options?.c && Array.isArray(options.c) && options?.c?.length > 0) {
            const _cs = options.c.map(x => x.value);
            _apps = _apps.filter(x => _cs.includes(x.category));
        }
        _apps = _apps.sort((a, b) => {
            const incA = favItems.includes(a.name);
            const incB = favItems.includes(b.name);
            if (incA && !incB) {
                return -1;
            } else if (!incA && incB) {
                return 1;
            } else {
                return (a.title || a.name) < (b.title || b.name) ? -1: 1;
            }
        });
        setAppList([..._apps]);
    }, [apps, favItems, options]);

    useEffect(() => {
        const lsFavItems = window?.localStorage?.getItem(LOCAL_STORAGE_FAV_ITEMS_KEY);
        console.log("_favItems", lsFavItems);
        if (lsFavItems) {
            setFavItems(JSON.parse(lsFavItems));
        } else {
            setFavItems([]);
        }
    }, []);

    useEffect(() => {
        setToolbar(<ApplicationToolbar suffixDom={<>
            {auth?.is_super_admin && <>
                <Link to="/applications/_homepage_assets" className="btn btn-success me-2">Setting Homepage Apps</Link>
            </>}
            <Link to="/applications/create" className="btn btn-success">Add new application</Link>
        </>} />);
    }, [setToolbar, auth]);

    const callbackFavItem = useCallback((payload) => {
        let _favItems = [...favItems];
        if (payload.type === 'in') {
            _favItems.push(payload.data);
        } else if (payload.type === 'out') {
            _favItems = _favItems.filter(x => x !== payload.data);
        }
        
        setFavItems([...new Set(_favItems)]);
        window?.localStorage?.setItem(LOCAL_STORAGE_FAV_ITEMS_KEY, JSON.stringify(_favItems));
        CallMenuChange();
    }, [CallMenuChange, favItems]);

    useEffect(() => {
        window?.localStorage?.setItem(LOCAL_STORAGE_OPTIONS_KEY, JSON.stringify(options));
    }, [options]);

    return <div>
        <div className='mb-2 text-end'>
            <RAYTextField 
                placeholder="Keyword" 
                value={options?.q || ""} 
                onChange={e => setOptions(prev => ({ ...prev, q: e.target.value }))} css="w-200px" />
            {/* <RAYToggle value={options?.isAppStore || false} trueLabel="App Store Apps" 
                falseLabel='All Apps' onChange={() => setOptions(prev => ({ ...prev, isAppStore: !prev.isAppStore }))} />
            <RAYSelect value={options?.c || []} items={CONST_APPLICATION.CATEGORIES} 
                placeholder="Categories" 
                multi={true} 
                callbackOnChange={_arr => setOptions(prev => ({ ...prev, c: _arr || [] }))}/> */}
        </div>
        {appList.length > 0 && <div className="row g-4 mt-1">
            {appList.map((x, idx) => <ApplicationItem item={x} key={x.name} callbackFavItem={callbackFavItem} favItems={favItems} />)}
        </div>}
        {appList.length === 0 && <div className='my-12 text-center'>
            <i>Not Find Applications.</i>
        </div>}
    </div>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const isLogin = state.AuthReducer.isLogin;
    const apps = state.ApplicationReducer.apps;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    return { auth, isLogin, apps, appVersions };
};

const mapDispatch = (dispatch) => ({
    GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName)),
    CallMenuChange: () => dispatch(AppAction.CallMenuChange())
})

export default connect(mapState, mapDispatch)(ApplicationDashboard);
