import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { RAYLogo } from '../../components/common/RAYIcon';
import { AuthAction } from '../../redux/actions';

function Login({ Login, isLogin }) {
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        isLogin && navigate('/')
    }, [navigate, isLogin]);

    const onChangeHandler = useCallback((event, key) => {
        setData(prev => ({ ...prev, [key]: event.target.value }))
    }, []);

    const submit = useCallback(() => {
        Login(data);
    }, [Login, data]);

    // const redirectSignup = useCallback(() => {
    //     navigate('/signup')
    // }, [navigate]);

    const onKeyPressHandler = useCallback((event) => {
        if (event.key === 'Enter') {
            submit()
        }
    }, [submit]);

    return <div className="signin d-flex align-items-center justify-content-center">
        <div className="sign-wrapper" style={{ width : 400, marginTop : 100 }}>
            <form>
                <div className="sign-header">
                    <h3>Login</h3>
                    <p>Login to RAYTeams Developer.<br />Improve user experience, make users feel valued.</p>
                </div>
                <div className="sign-email">
                    <label className="form-label">Email</label>
                    <input className="form-control" type="email" placeholder="" name="email" autoComplete="off" onChange={e => onChangeHandler(e, 'email')} />
                </div>
                <div className="sign-pw mb-2">
                    <label className="form-label">Password</label>
                    <input 
                        className="form-control" 
                        type="password" 
                        placeholder="" 
                        name="password" 
                        autoComplete="off"
                        onChange={e => onChangeHandler(e, 'password')}
                        onKeyPress={onKeyPressHandler}
                    />
                </div>
                <div className="w-100 my-4">
                    <button type="button" className="btn-primary btn-block w-100 btn btn-lg mb-2" onClick={submit}>
                        <span>Login</span>
                    </button>
                </div>
                <div className="forgot d-flex justify-content-between">
                    <div>Forgot your password?</div>
                    <Link to="/auth/password/request" className='link-primary'>Request password</Link>
                </div>
            </form>
            <div className="copyright text-center mt-4"><span className="">(C) Ray, All rights reserved.</span></div>
        </div>
    </div>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    return { isLogin };
};

const mapDispatch = (dispatch) => ({
    Login: (payload) => dispatch(AuthAction.Login(payload))
})

export default connect(mapState, mapDispatch)(Login);
