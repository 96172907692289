import { Dropdown } from "react-bootstrap";
import { APP_TERM_TYPES } from "../../constants/appTerms";

const AppSettingTermsAssetTermSelector = ({ onSelected, toggleTitle = 'Add', css = "", menuCss = "", toggleCss = "", itemCss = "" }) => {
    return <>
        <Dropdown className={css}>
            <Dropdown.Toggle vagrant="primary" className={toggleCss}>
                {toggleTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu className={menuCss}>
                {APP_TERM_TYPES.map(x => <Dropdown.Item 
                    key={x.code} 
                    onClick={() => onSelected && onSelected(x)} 
                    className={itemCss}
                >
                    {x.label}
                </Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    </>
}

export default AppSettingTermsAssetTermSelector;
