import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { LANGUAGE } from "../../constants";
import { TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import { ApplicationConstant } from "../../redux/reducers";
import RAYButton from "../common/RAYButton";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
//import { EDITOR_CONST } from "../tiptap/Editor";
import TinyEditor from "../tinymce/TinyEditor";
import TipTabEditor from "../tiptap/TipTabEditor";

const AppSettingTermsRootLang = ({ app, storedTerms, GetAppData, UpdateAppData, RemoveAppData }) => {
    const [appTerms, setAppTerms] = useState([]);
    const [selectedLang, setSelectedLang] = useState(null);
    const [data, setData] = useState({});
    const [valid, setValid] = useState(false);
    const [readySave, setReadySave] = useState(false);
    const [readyRemove, setReadyRemove] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setValid(!!data?.title && !!data?.contents);
        setReadySave(false);
    }, [data]);


    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
    }, [storedTerms, app?.name]);

    useEffect(() => {
        const _temp = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_LANG);
        setSelectedLang(_temp ? appTerms.find(x => x.type === _temp.data.type) : null);
    }, [appTerms]);

    useEffect(() => {
        if (selectedLang) {
            setData({
                title: selectedLang.title,
                contents: selectedLang.contents,
                isReady: selectedLang.isReady,
            });
        } else {
            setData({});
        }
    }, [selectedLang]);

    const handlerSave = async () => {
        if (!readySave) {
            setReadySave(true);
            return;
        }
        const payload = {
            appName: app.name,
            type: selectedLang.type,
            item: { ...data },
        };
        await UpdateAppData(payload);
    };

    const handlerRemove = async () => {
        if (!readyRemove) {
            setReadyRemove(true);
            return;
        }
        const ret = await GetAppData({ appName: app.name, prefix: selectedLang.type });
        if (!ret) {
            toast('Server Error', { type: 'error' });
            return;
        }
        const _thisLang = ret.data.find(x => x.type === selectedLang.type);
        if (!_thisLang) {
            toast('No Exist Lang', { type: 'error' });
            return;
        }
        if (_thisLang.isReady || _thisLang.isPublished) {
            toast('Not Sync data, please reload', { type: 'error' });
            return;
        }
        const payload = {
            appName: app.name,
            type: selectedLang.type
        };
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
        await RemoveAppData(payload);
    };


    return <>
        {!!selectedLang && <>
            <div className="d-flex align-items-end mt-6">
                <h4>
                    <div className="small text-muted"><i>Language</i></div>
                    <span className="fs-1">{LANGUAGE.find(x => x.code === selectedLang.lang)?.label || selectedLang.lang}</span>

                </h4>
                <div className="flex-fill" />
                <div className="d-flex align-items-center">
                    {!selectedLang.isPublished && <RAYToggle
                        value={data.isReady}
                        onChange={() => setData(prev => ({ ...prev, isReady: !prev.isReady }))}
                        css="me-2"
                        disabled={!valid}
                        trueLabel="Ready to publish"
                        falseLabel={valid ? "Click to prepare for publication." : "Please enter a title and contents."}
                        labelColor="black"
                        RTL={true}
                    />}
                    {!selectedLang.isPublished && <>
                        <RAYButton
                            label={readySave ? "Really Save?" : "Save"}
                            color="primary"
                            onClick={handlerSave}
                        />
                    </>}
                    {!selectedLang.isPublished && !selectedLang.isReady && <>
                        <RAYButton
                            label={readyRemove ? "Really Remove?" : "Remove"}
                            color="danger"
                            onClick={handlerRemove}
                        />
                    </>}
                </div>
            </div>
            <RAYFormRaw title="Title" required={!selectedLang.isReady} labelCol={12}>
                {(selectedLang.isPublished || selectedLang.isReady) && <div className="form-control form-control-lg">
                    {selectedLang.title}
                </div>}
                {(!selectedLang.isPublished && !selectedLang.isReady) && <RAYTextField
                    value={data?.title || ''}
                    onChange={(e) => setData(prev => ({ ...prev, title: e.target.value }))}
                />}
            </RAYFormRaw>
            <RAYFormRaw title="Contents" required={!selectedLang.isReady} labelCol={12}>
                <div className="form-control form-control-lg p-0">
                    {(selectedLang.isPublished || selectedLang.isReady) && <div className="p-3 html-contents" dangerouslySetInnerHTML={{ __html: selectedLang.contents }} />}
                    {(!selectedLang.isPublished && !selectedLang.isReady) && <TinyEditor
                        originContent={selectedLang.contents}
                        callbackOnChange={(content) => setData(prev => ({ ...prev, contents: content }))}
                        height={300}

                        //name={selectedLang.type}
                        //menuType={EDITOR_CONST.MENU.TOP}
                        //content={data?.contents || ""}
                        //originContent={selectedLang.contents}
                        //previewTitle={data?.title}
                        //callbackOnChange={_html => setData(prev => ({ ...prev, contents: _html }))}
                        //css="h-300px overflow-auto py-2 px-3"
                        //placeholder="Write Terms and Condition."
                        //useImage={true}
                    />}
                </div>
            </RAYFormRaw>
        </>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsRootLang);
