import { applicationAxios } from '../../libs/axios';
import { ApplicationVersionConstant } from '../reducers/ApplicationVersionReducer';

const GetApplicationVersions = (appName, params) => async (dispatch) => {
    const ret = await applicationAxios.get(`/app/${appName}/all-versions`, { params });
    if (ret.data.status === 'success') {
        const list = ret.data.data;
        dispatch({ type: ApplicationVersionConstant.SET_APP_VERSIONS, payload: { name: appName, data: list }})
    } else {
        dispatch({ type: ApplicationVersionConstant.SET_APP_VERSIONS, payload: { name: appName, data: [] }})
    }
}

const GetApplicationVersion = async (appName, version) => {
    const ret = await applicationAxios.get(`/app/${appName}/version?version=${version}`);
    if (ret.data.status === 'success') {
        return ret.data.data;
    } else {
        return {}
    }
}

const CreateApplicationVersion = async ({ appName, data }) => {
    const ret = await applicationAxios.post(`/app/${appName}/version`, data);
    if (ret.data.status === 'success') {
        return ret.data.data;
    } else {
        return {}
    }
}

const UpdateApplicationVersion = async ({ appName, data }) => {
    const ret = await applicationAxios.put(`/app/${appName}/version`, data);
    if (ret.data.status === 'success') {
        return ret.data.data;
    } else {
        return {}
    }
}

const DeleteApplicationVersion = async ({ appName, data }) => {
    const ret = await applicationAxios.post(`/app/${appName}/version/delete`, data);
    if (ret.data.status === 'success') {
        return ret.data.data;
    } else {
        return {}
    }
}

export const ApplicationVersionAction = {
	GetApplicationVersions,
    GetApplicationVersion,
    CreateApplicationVersion,
    UpdateApplicationVersion,
    DeleteApplicationVersion
};
