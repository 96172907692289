export const APP_TERM_TYPES = [
	{ code: 'terms_of_use', label: 'Terms of Use' },
	{ code: 'security_privacy_addendum', label: 'Security & Privacy Addendum' },
	{ code: 'license_agreement', label: 'License Agreement' },
	{ code: 'partnership_agreement', label: 'Partnership Agreement' },
	{ code: 'sub_terms_of_use', label: '구독시 이용 약관', hideTeams: true },
	{ code: 'sub_cancel_notice', label: '환불 정책', hideTeams: true },
    { code: "privacyConstantKor", label: "개인정보처리위탁 계약서", hideTeams: true },
    { code: "DPA", label: "DPA (Data Processing Agreement)", hideTeams: true },
    { code: "BAA", label: "BAA (Business Associate Agreement)", hideTeams: true },
];

export const TYPE = {
    TC_ROOT: 'd:tc:root:',
    TC_VERSION: 'd:tc:ver:',
    TC_VERSION_RELEASE: 'd:tc:ver_release:',
    TC_DOCS: 'd:tc:docs:',
    TEMP_TC_ROOT: 'temp:d:tc:root:',
    EXTRA_SELECT_ROOT: 'extra:selectRoot',
    EXTRA_SELECT_VERSION: 'extra:selectVersion',
    EXTRA_SELECT_VERSION_RELEASE: 'extra:selectVersionRelease',
    EXTRA_SELECT_LANG: 'extra:selectLang',
};