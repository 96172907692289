import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';

const Footer = ({ }) => {

	const dispath = useDispatch();
	const [t] = useTranslation(['translation']);
	
	useEffect(() => {
	},[])
	
  	return <footer className="content-footer footer bg-footer-theme">
        <div className="container-fluid">
            <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                <div>
                    © 2023, made with ❤️ by <a href="https://www.rayteams.com" target="_blank" className="fw-semibold">Ray Co. Ltd.</a>
                </div>
                <div>
                    <a href="https://www.rayteams.com" target="_blank" className="footer-link me-4">RAYTeams</a>
                </div>
            </div>
        </div>
    </footer>
}

const mapState = (state) => {
	return { };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Footer);
