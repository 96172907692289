import { useEffect, useState } from "react";
import { connect } from "react-redux";
import YouTube from 'react-youtube';
import { v4 } from "uuid";
import { LANGUAGE } from "../../constants";
import { getYoutubeIdByLink } from "../../libs/functions";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYButton from "../common/RAYButton";
import RAYDragItems from "../common/RAYDragItems";
import RAYTextField from "../common/RAYTextField";
import LangArea from "./LangArea";

const opts = {
    height: '300',
    width: '460',
    playerVars: {
        autoplay: 0,
        origin: window.location.origin
    },
};


const AppTemplateFormMovie = ({ app, auth, lang, setLang, version, templates, publishable = false, UpdateAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [movie, setMovie] = useState({});
    const [url, setUrl] = useState("");

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        if (lang) {
            setMovie(templates?.find(x => x.name === app.name && x.type === `t:body:${version}:movie:${lang}`) || {});
        }
    }, [templates, version, app.name, lang]);

    const handlerClickToMainMovie = async (obj) => {
        if (!movie?.type) {
            return;
        }
        const item = {
            name: app.name,
            type: movie.type,
            mainMovie: obj,
        };
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const handlerClickAdd = async () => {
        const youtubeId = getYoutubeIdByLink(url);
        if (!youtubeId) {
            return;
        }
        const _m = {
            url: url,
            youtubeId: youtubeId,
            code: v4(),
        };
        const item = {
            name: app.name,
            type: movie?.type || `t:body:${version}:movie:${lang}`,
            movies: [...(movie.movies || []), _m]
        };
        if (!movie?.mainMovie) {
            item.mainMovie = _m;
        }
        await UpdateAppTemplate({ appName: app.name, item });
        setUrl("")
    };

    const handlerClickRemove = async (obj) => {
        if (!movie?.type) {
            return;
        }
        const _movies = movie.movies.filter(x => x.code !== obj.code);
        const item = {
            name: app.name,
            type: movie.type,
            movies: _movies,
        };
        if (obj.s3_path === movie.mainMovie.s3_path) {
            item.mainMovie = _movies.length > 0 ? _movies[0] : null;
        }
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const callBackItems = async (arr) => {
        if (!movie?.type) {
            return;
        }
        const item = {
            name: app.name,
            type: movie.type,
            movies: arr.items.map((x, idx) => ({ ...x, order: idx + 1 }))
        };
        await UpdateAppTemplate({ appName: app.name, item });
    };


    return <div className="d-flex gap-2">
        <div style={{ maxWidth: 200, minWidth: 200 }}>
            <LangArea langs={header.langs || []} css="w-100" lang={lang} setLang={setLang} />
        </div>
        <div className="flex-fill">
            <div className="h5">
                {lang && <>
                    {LANGUAGE.find(l => l.code === lang)?.label || lang}
                </>}
                {!lang && <div className="text-muted">
                    <i>Please select a language.</i>
                </div>}
            </div>
            {lang && <div key={lang}>
                <RAYTextField
                    value={url}
                    placeholder="Insert Youtube link"
                    onChange={(e) => setUrl(e.target.value)}
                />
                <RAYButton
                    css={"mt-1"}
                    label="Add"
                    color="primary"
                    onClick={handlerClickAdd}
                />

                <div className="d-flex gap-2 mt-2" style={{ flexWrap: "wrap" }}>
                    <RAYDragItems items={movie?.movies || []} itemQss=".drag-item-movie" callBackItems={callBackItems}>
                        {movie?.movies?.length > 0 && movie.movies.map(x => <div key={x.code} className="position-relative drag-item-movie">
                            <div style={{ width: "100%", height: 30, background: "gray" }}>
                            </div>
                            <YouTube videoId={x.youtubeId} id={x.youtubeId} opts={opts} />
                            <div className="position-absolute end-0 d-flex gap-1" style={{ top: 4 }}>
                                {x.code === movie?.mainMovie?.code && <div
                                    className="badge bg-primary"
                                    style={{ top: 4 }}
                                >
                                    Main Movie
                                </div>}
                                {x.code !== movie?.mainMovie?.code && <>
                                    <div
                                        className="badge bg-warning"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handlerClickToMainMovie(x)}
                                    >
                                        To Main Movie
                                    </div>
                                </>}
                                <div
                                    className="badge bg-danger"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handlerClickRemove(x)}
                                >
                                    Remove
                                </div>
                            </div>
                        </div>)}
                    </RAYDragItems>

                </div>
            </div>}
        </div>
    </div>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormMovie);
