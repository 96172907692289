const initialState = { 
	item: [],
	items: [],
	pipeRecentCommitIds: {}
};

export const UserConstant = {
	SET_USERS: "ACTION_USER_SET_USERS",
	SET_USER: "ACTION_USER_SET_USER",
	REMOVE_USER: "ACTION_USER_REMOVE_USER",
	SET_PIPE_RECENT_COMMIT_IDS: "ACTION_USER_SET_PIPE_RECENT_COMMIT_IDS",
};

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
		case UserConstant.SET_PIPE_RECENT_COMMIT_IDS:
			return {...state,
				pipeRecentCommitIds: action.item
			}
		case UserConstant.REMOVE_USER:
			return {...state,
				item: [],
				items: state.items.filter(x => x.sub !== action.sub)
			}
		case UserConstant.SET_USERS:
			return {...state,
				items: action.items
			}
		case UserConstant.SET_USER:
			const detail = action.items.find(x => x.type === 'info:detail');
			return {...state,
				item: action.items,
				items: state.items.map(x => x.sub === detail?.sub ? { ...x, ...detail } : x)
			}
        default:
            return state
    }
}