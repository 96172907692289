import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationAction, ApplicationVersionAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import { TYPE } from "../../constants/appTerms";
import RAYSelectAdmin from "../../components/common/RAYSelectAdmin";
import { AppConstant } from "../../redux/reducers";
import RAYSelect from "../../components/common/RAYSelect";
import RAYTable from "../../components/common/RAYTable";
import RAYModal from "../../components/common/RAYModal";
import { Query } from "../../libs/athena";
import { LANGUAGE } from "../../constants";
import { poolColors } from "../../libs/functions";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import moment from "moment";

const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const AppSettingErrors = ({ item, UpdateApplication }) => {
    const storedLanguages = useSelector(state => state.ApplicationReducer.languages);
    const [viewType, setViewType] = useState(VIEW_TYPE.DEFAULT);
    const [data, setData] = useState([]);
    const [sess, setSess] = useState("");
    const [act, setAct] = useState("");
    const [menu, setMenu] = useState("chart");
    const [loading, setLoading] = useState(true);
    const [acts, setActs] = useState([]);
    const [logs, setLogs] = useState([]);
    const [dataChart, setDataChart] = useState({});
    const [dataChartPie, setDataChartPie] = useState({});
    const [modal, setModal] = useState({
        shoe : false
    });
    const dispatch = useDispatch();

    const options = {
        plugins: {
            title: { display: true, text: 'Error Logs(Last 7 Days, Top 10)' },
        },
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: { stacked: true, },
            y: { stacked: true, },
        },
    }

    ChartJS.register(CategoryScale, LinearScale, BarElement,ArcElement, Title, Tooltip, Legend, Colors);

    useEffect(() => {
        dispatch({ type: AppConstant.ADD_LOAD_STACK });
        getQuery();
    }, []);

    useEffect(() => {
        console.log(item);
    }, [item]);

    useEffect(() => {
        console.log(sess);
    }, [sess]);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getTableBody = (obj) => {
        return <div className="me-2">
            <table className="table table-bordered">
                <tbody>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                    {Object.keys(obj).filter(x => x !== "st").map(x => <tr key={x}>
                        <td>{x}</td>
                        <td style={{ wordBreak : "break-all" }}>{obj[x]}</td>
                    </tr>) }
                </tbody>
            </table>
        </div>
    }

    const showModal = async (x) => {
        const mg = await getMg(x);
        dispatch({ type: AppConstant.SET_MODAL_SHOW, payload: { 
            title : moment.unix(x.st/1000).format('YYYY-MM-DD hh:mm:ss'),
            body : getTableBody({...x, mg : mg}),
            footer : <></>
        } });
    }

    const getMg = async (item) => {
        const last7D = moment().add( -14, "days").unix();
        const q = "select mg from rayteams_errors_logs " + 
                " where si = '"+ item.si + "' and st = "+ item.st + " and cd = '"+ item.cd + "';";
        const ret = await Query("default", "rayteams_errors_logs", q);
        return ret ? ret[0].mg : "";
    }

    const getQuery = async () => {
        setLoading(true);
        const last7D = moment().add( -14, "days").unix();
        const q = "select tp, fr, si, id, ud, cd, lp, rp, cc, vr, tt, sv, sz, st from rayteams_errors_logs " + 
                " where tp= '" + item.alkey+ "' and st > " + (last7D + "000") + ";";
        const ret = await Query("default", "rayteams_errors_logs", q);
        setLogs(ret);
        const newdata = ret.map(x => ( {...x, 
            l : moment.unix(x.st).format('LLL'), 
            st : moment.unix(x.st/1000).format('YYYY-MM-DD hh:mm:ss'),
            sess : x.si,
            si : <button className='btn btn-sm btn-light' onClick={() => setSess(x.si)}>{x.si}</button>,
            ymd : moment.unix(x.st/1000).format('YYYY-MM-DD'),
            mg : <button className='btn btn-sm btn-info' onClick={() => showModal(x)}>View</button>
        }));
        setData(newdata);

        const labels_tmp = []
        for(var d = 0; d < 7; d++){
            labels_tmp.push(moment().add( -1 * d, "days").format("YYYY-MM-DD"));
        }
        const labels = labels_tmp.reverse();
        const tactions = [...new Set(newdata.map(x => x.cd))];
        const nAction = [];
        tactions.map(x => {
            nAction.push({
                name : x,
                count : newdata.filter(r => r.cd === x).length
            });
        });
        const actions = nAction.sort((a, b) => b.count - a.count).map(x => x.name);
        console.log(labels);

        let dtset = [];
        let dtset_other = [];

        let cn = 0;
        console.log(newdata[0]);
        const colors = poolColors(actions.length);
        setActs(actions);
        for(const a of actions){
            const values = [];
            const adata = [];
            labels.map(x => {
                const v_tmp = newdata.filter(r => r.ymd === x && r.cd === a);
                adata.push(v_tmp.length > 0 ? v_tmp.length : 0);
            });
            dtset.push({ 
                label: a, 
                data: adata, 
                total : newdata.filter(r => r.cd === a).length,
                backgroundColor : colors[cn]
            });
            cn++;
            if(cn > 9)
                break;
        }

        setDataChart({
            labels,
            datasets: dtset,
        })

        setDataChartPie({
            labels: dtset.map(x => x.label),
            datasets: [
                {
                    label: 'Called',
                    data: dtset.map(x => x.total),
                    backgroundColor : colors
                }
            ]
        });
        console.log(dtset.map(x => x.total));

        dispatch({ type: AppConstant.SUB_LOAD_STACK });
        setLoading(false);
    }

    const updateTransUser = async () => {
        await ApplicationAction.UpdateApplication({
            appName : item.name,
            data : { tusers : data.tusers },
        })
    }

    const aLogHeader = [
        { Header: 'GUID', accessor: 'id', },
        { Header: 'USER', accessor: 'ud', },
        { Header: 'SESSION', accessor: 'si', },
        { Header: 'E-Code', accessor: 'cd', },
        { Header: 'CC', accessor: 'cc', },
        { Header: 'Version', accessor: 'vr', },
        { Header: 'IP', accessor: 'rp', },
        { Header: 'Time', accessor: 'st', Type : "DATE" },
        { Header: 'View', accessor: 'mg', Type : "LOG" },
        //{ Header: 'Country', accessor: 'w', },
        //{ Header: 'Version', accessor: 'v', },
    ];

    return <>
        {loading && <div className="text-center w-100" style={{ height : "500px", paddingTop : "200px" }}>
            <h4>로그를 가져오고 정리하는 중....</h4>
            <h4>로그가 많다면, 매우 오래걸릴 수 있습니다.</h4>
        </div>}
        {!loading && <div className="row">
            <div className="col-12 px-4 pb-4">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={"nav-link " + (menu === "chart" && "active": "")} 
                            onClick={() => setMenu("chart")} aria-current="page" href="#">Chart</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (menu === "logs" && "active": "")} 
                            onClick={() => setMenu("logs")} aria-current="page" href="#">Logs</a>
                    </li>
                </ul>
            </div>
            <div className="col-12">
                {menu === "chart" && <div> 
                    <div style={{ height : "500px" }}>
                        {dataChart.datasets && <Bar options={options} data={dataChart} />}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div style={{ height : "500px" }} className="mt-4">
                                {dataChartPie.datasets && <Pie data={dataChartPie} options={{
                                        plugins: {
                                            legend: { display: false, },
                                            title: { display: true, text: 'Top 10 Actions' },
                                          },
                                }}/>}
                            </div>
                        </div>
                        <div className="col-6">
                            <h4 className="mt-4">Summary</h4>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th style={{ width : "50%" }}>Total Error</th><td style={{ width : "50%" }}>{numberWithCommas(logs.length)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Error(Undefined User)</th><td>{numberWithCommas(logs.filter(x => x.ud === "UNDEFINED").length)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Error(Defined User)</th><td>{numberWithCommas(logs.filter(x => x.ud !== "UNDEFINED").length)}</td>
                                    </tr>
                                    <tr>
                                        <th>IPs(Remote)</th><td>{numberWithCommas([...new Set(logs.map(x => x.rp))].length)}</td>
                                    </tr>
                                    <tr>
                                        <th>IPs(Local)</th><td>{numberWithCommas([...new Set(logs.map(x => x.lp))].length)}</td>
                                    </tr>
                                    <tr>
                                        <th>Sessions</th><td>{numberWithCommas([...new Set(logs.map(x => x.si))].length)}</td>
                                    </tr>
                                    <tr>
                                        <th>Users</th><td>{numberWithCommas([...new Set(logs.map(x => x.ud))].length)}</td>
                                    </tr>
                                    <tr>
                                        <th>Clients</th><td>{numberWithCommas([...new Set(logs.map(x => x.id))].length)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
                {menu === "logs" && <>
                    <div className="d-flex justify-content-between">
                        <div>
                            <b>{"Total items : " + logs.length}</b>
                        </div>
                        <div style={{ width : "400px" }}>
                            <RAYSelect
                                items={acts.map(x => { return { value : x, label : x } })}
                                value={act ? acts.map(x => { return { value : x, label : x } }).find(x => x.value === act) : {}}
                                multi={false}
                                clearable={false}
                                callbackOnChange={v => setAct(v.value)}
                                css="w-400px"
                            />
                        </div>
                    </div>
                    {(sess || act) && <div className="py-2">
                        {sess && <><label>Session :</label> <button className='btn btn-sm btn-light me-2' onClick={() => setSess("")}>{sess}</button></>}
                        {act && <><label>Action :</label> <button className='btn btn-sm btn-light me-2' onClick={() => setAct("")}>{act}</button></>}
                    </div>}
                    <RAYTable
                        style={{ "fontSize" : "12px" }}
                        tableHeaders={aLogHeader}
                        initPageSize={25}
                        tableData={data.filter(x => {
                            if(sess && act)
                                return x.sess === sess && x.cd === act;
                            if(sess)
                                return x.sess === sess;
                            if(act)
                                return x.cd === act;
                            return true;
                        })}
                        useSort
                        usePaginate={true}
                    />
                </>}
            </div>
        </div>}
    </>
}

export default AppSettingErrors;
