import { useEffect } from "react";
import { useState } from "react";
import { APP_AUTH_LIST } from "../../constants";
import RAYSelect from "./RAYSelect";

const RAYAppAuth = ({ value, onChange }) => {
    const [val, setVal] = useState(value && Array.isArray(value) ? value : [])

    useEffect(() => {
        setVal(value && Array.isArray(value) ? value : []);
    }, [value]);

    const callbackOnChange = (arr) => {
        const _valList = arr.map(x => x.value);
        setVal(_valList);
        onChange && onChange(_valList);
    };

    return <>
        {/* RAYAppAuth */}
        <RAYSelect
            multi={true}
            items={APP_AUTH_LIST}
            value={APP_AUTH_LIST.filter(x => val?.includes(x.value))}
            callbackOnChange={callbackOnChange}
            css="w-100"
        />
    </>
};

export default RAYAppAuth;
