import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppConstant } from "../redux/reducers";

const ModalHook = () => {
    const dispatch = useDispatch();

    const openModal = useCallback(({ title, body, footer }) => {
        dispatch({ type: AppConstant.SET_MODAL_SHOW, payload: { title, body, footer } });
    }, [dispatch]);

    const closeModal = useCallback(() => {
        dispatch({ type: AppConstant.SET_MODAL_CLOSE });
    }, [dispatch]);

    const changeTitle = useCallback((payload) => {
        dispatch({ type: AppConstant.SET_MODAL, payload: { title: payload } });
    }, [dispatch]);

    const changeBody = useCallback((payload) => {
        dispatch({ type: AppConstant.SET_MODAL, payload: { body: payload } });
    }, [dispatch]);

    const changeFooter = useCallback((payload) => {
        dispatch({ type: AppConstant.SET_MODAL, payload: { footer: payload } });
    }, [dispatch]);

    const setOptions = useCallback((payload) => {
        if (typeof payload !== 'object') {
            return;
        }
        dispatch({ type: AppConstant.SET_MODAL_OPTIONS, payload: { options: payload } });
    }, [dispatch]);

    return { openModal, closeModal, changeTitle, changeBody, changeFooter, setOptions };
}

export default ModalHook;
