import { AppConstant } from '../reducers/AppReducer';

const UpdateVersion = (version) => async (dispatch) => {
    dispatch({ type: AppConstant.VERSION_UPDATE, value: version });
};
const UpdateInfo = (payload) => async (dispatch) => {
    dispatch({ type: AppConstant.INFO_UPDATE, value: payload });
};

const CallMenuChange = () => (dispatch) => {
    dispatch({ type: AppConstant.SET_MENU_TRACKING });
}

export const AppAction = {
	UpdateVersion,
	UpdateInfo,
    CallMenuChange
};
