import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'

const MarkDown = ({ data }) => {

	const dispath = useDispatch();
	const [t] = useTranslation(['translation']);
    const navigator = useNavigate();
	
	useEffect(() => {
	},[])
	
  	return <>
        {data && <ReactMarkdown 
            children={data} 
            remarkPlugins={[remarkGfm]} 
            components={{
                code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                        <SyntaxHighlighter
                            {...props}
                            children={String(children).replace(/\n$/, '')}
                            language={"JSON"}
                            PreTag="div"
                        />
                    ) : (
                    <code {...props} className={className + ' raycode'}>
                        {children}
                    </code>
                    )
                },
                table : ({node, ...props}) => <table className={"table mb-4"} {...props} />,
                a : ({node, ...props}) => {
                    return <a {...props} href='' onClick={(e) => {
                        e.preventDefault();
                        navigator(node.properties.href)
                    }} />
                }
            }}
        />}
    </>
}

const mapState = (state) => {
	return { };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(MarkDown);
