import { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import { ApplicationConstant } from "../../redux/reducers";

const AppSettingTermsNavigatorVersionRelease = ({ app, auth, storedTerms, GetAppData, UpdateAppData }) => {
    const [appTerms, setAppTerms] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [versionRelease, setVersionRelease] = useState(null);
    const [isSelected, setIsSelected] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        app?.name && selectedVersion?.type && GetAppData({ appName: app.name, prefix: selectedVersion.type.replace(TYPE.TC_VERSION, TYPE.TC_VERSION_RELEASE) });
    }, [GetAppData, selectedVersion?.type, app?.name]);

    useEffect(() => {
        selectedVersion?.type && setVersionRelease(appTerms.find(x => x.type === selectedVersion.type.replace(TYPE.TC_VERSION, TYPE.TC_VERSION_RELEASE)));
    }, [appTerms, selectedVersion?.type]);

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
    }, [storedTerms, app?.name]);

    useEffect(() => {
        const _selectedVersion = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION);
        setSelectedVersion(_selectedVersion ? appTerms.find(x => x.type === _selectedVersion.data.type) : null);
    }, [appTerms]);

    useEffect(() => {
        setIsSelected(!!appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION_RELEASE && x.rowType === versionRelease?.type))
    }, [appTerms, versionRelease]);

    const handlerClickItem = () => {
        if (isSelected) {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION_RELEASE });
        } else {
            dispatch({
                type: ApplicationConstant.SET_TERM, appName: app.name, item: {
                    type: TYPE.EXTRA_SELECT_VERSION_RELEASE,
                    rowType: versionRelease.type,
                    data: versionRelease,
                },
            });
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
        }
    };

    return <>
        {versionRelease?.type && <div className="mt-2">
            <ListGroup>
                <ListGroup.Item
                    action={true}
                    onClick={handlerClickItem}
                    active={isSelected}
                >
                    <div className="d-flex align-items-center">
                        <div>Change Document</div>
                        <div className="flex-fill" />
                        {versionRelease?.type && <div
                            className={`badge bg-${versionRelease.isPublished ? 'primary' : (versionRelease?.isReady ? 'success' : 'light')}`}
                        >
                            {versionRelease.isPublished && <span>Published</span>}
                            {!versionRelease.isPublished && <>
                                {versionRelease?.isReady && <span>Ready</span>}
                                {!versionRelease?.isReady && <span>Draft</span>}
                            </>}
                        </div>}
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </div>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsNavigatorVersionRelease);
