import { useState } from "react";
import { connect } from "react-redux";
import RAYButton from "../common/RAYButton";
import RAYSelect from "../common/RAYSelect";
import UserDetailModal from "../user/UserDetailModal";

const SandboxToolbar = ({ tabs, tab, setTab, environments, environment, setEnvironment }) => {
    const [showCreate, setShowCreate] = useState(false);

    return <>
        <div className="toolbar" style={{ height: 55 }}>
            <div className="toolbar-wrapper">
                <div className="page-title">
                    <h1>API Sandbox</h1>
                    {environments.length > 1 && <RAYSelect 
                        items={environments}
                        value={environments.find(x => x.key === environment?.key)}
                        callbackOnChange={envObj => setEnvironment(envObj)}
                        clearable={false}
                        css="ms-2"
                    />}
                </div>
                <div className="d-flex">
                    {environments.length > 0 && tabs?.length > 0 && tabs.map((x, idx) => <RAYButton 
                        key={idx}
                        color={tab.code === x.code ? 'primary' : 'light'}
                        label={x.label}
                        onClick={() => setTab(x)}
                    />)}
                </div>
            </div>
        </div>
        <UserDetailModal show={showCreate} callbackClose={() => setShowCreate(false)} isCreate={true} />
    </>
}
const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({

})

export default connect(mapState, mapDispatch)(SandboxToolbar);
