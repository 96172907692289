import { useCallback } from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LANGUAGE } from "../../constants";
import { ApplicationAction } from "../../redux/actions/ApplicationAction";
import RAYAppAuth from "../common/RAYAppAuth";
import RAYButton from "../common/RAYButton";
import RAYFile from "../common/RAYFile";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYSelect from "../common/RAYSelect";
import { RAYTags, RAYTagInput } from "../common/RAYTag";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import TinyEditor from "../tinymce/TinyEditor";

const AppTemplateFormBasic = ({ app, version, templates, publishable = false, UpdateAppTemplate }) => {
    const [header, setHeader] = useState({});
    const [uploadQueues, setUploadQueues] = useState([]);
    const [data, setData] = useState({
        isVisible: false,
        isPreventUnpublish: false,
        desc: "",
        langs: [],
        tags: [],
        authList: [],
    });
    const [langs] = useState(LANGUAGE.map(x => ({ value: x.code, label: x.label })));

    useEffect(() => {
        setHeader(templates?.find(x => x.name === app.name && x.type === `t:header:${version}`) || {});
    }, [templates, version, app.name]);

    useEffect(() => {
        if (header.type && header.version === version) {
            setData(prev => ({
                ...prev,
                isVisible: header.isVisible || false,
                isPreventUnpublish: header.isPreventUnpublish || false,
                desc: header.desc || "",
                langs: header.langs || [],
                tags: header.tags || [],
                provider: header.provider || "",
                providerLink: header.providerLink || "",
                privacyLink: header?.privacyLink || "",
                authList: header.authList || [],
                banner: header?.banner || {},
                bgImage: header.bgImage || {},
                icon: header.icon || {},
                iconColorCode: header.iconColorCode || "#ffffff",
            }));
        }
    }, [header, version, app.name])

    const handlerClick = async () => {
        const item = {
            ...data,
            name: app.name,
            type: `t:header:${version}`,
        };
        if (uploadQueues.length > 0) {
            for (const q of uploadQueues) {
                const fileRet = await q.upload();
                if (q.fieldName === "image") {
                    item.banner[q.fieldName] = fileRet.data;
                } else {
                    item[q.fieldName] = fileRet.data;
                }
            }
            setUploadQueues([]);
        }
        await UpdateAppTemplate({ appName: app.name, item });
    };

    const [imageDataUrl, setImageDataUrl] = useState(null);
    const callbackOnChangeFile = useCallback((obj) => {
        fileToDataUrl(obj.value);
    }, []);
    const fileToDataUrl = (file) => {
        if (!file || !(file instanceof File)) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageDataUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };
    const handlerSetUploadHooks = useCallback((payload) => {
        setUploadQueues(prev => [...prev.filter(x => x.fieldName !== payload.fieldName), payload]);
    }, []);

    const handlerClickRemoveBanner = () => {
        setUploadQueues([]);
        setImageDataUrl(null);
        setData(prev => ({ ...prev, banner: {} }));
    };

    return <>
        <RAYFormRaw title="Visible" labelCol={3}>
            <RAYToggle
                value={data?.isVisible || ""}
                trueLabel="Visible"
                // disabled={!publishable}
                falseLabel="None"
                onChange={e => setData(prev => ({ ...prev, isVisible: e.target.checked }))}
            />
        </RAYFormRaw>
        <RAYFormRaw title="Prevent Unpublish" labelCol={3}>
            <RAYToggle
                value={data?.isPreventUnpublish || ""}
                trueLabel="Prevent Unpublish"
                falseLabel="Unpublish from other versions"
                onChange={e => setData(prev => ({ ...prev, isPreventUnpublish: e.target.checked }))}
            />
        </RAYFormRaw>
        <RAYFormRaw title="Content Set Description" labelCol={3}>
            <div className="w-100">
                <TinyEditor
                    originContent={header?.desc}
                    callbackOnChange={(content) => setData(prev => ({ ...prev, desc: content }))}
                    height={200}
                />
                <i className="text-muted">Content Set descriptions are only used by developers.</i>
            </div>
        </RAYFormRaw>
        <RAYFormRaw title="Provider" labelCol={3}>
            <RAYTextField
                value={data?.provider || ""}
                onChange={e => setData(prev => ({ ...prev, provider: e.target.value }))}
                placeholder="Insert Provider Name"
            />
        </RAYFormRaw>
        <RAYFormRaw title="Provider Link" labelCol={3}>
            <RAYTextField
                value={data?.providerLink || ""}
                onChange={e => setData(prev => ({ ...prev, providerLink: e.target.value }))}
                placeholder="Insert Web URL"
            />
        </RAYFormRaw>
        <RAYFormRaw title="Privacy Link" labelCol={3}>
            <RAYTextField
                value={data?.privacyLink || ""}
                onChange={e => setData(prev => ({ ...prev, privacyLink: e.target.value }))}
                placeholder="Insert Web URL"
            />
        </RAYFormRaw>
        <RAYFormRaw title="Supported Languages" labelCol={3}>
            <RAYSelect
                items={langs}
                value={langs.filter(x => data?.langs?.includes(x.value))}
                multi={true}
                callbackOnChange={v => setData(prev => ({ ...prev, langs: v.map(x => x.value) }))}
                css="w-400px"
            />
        </RAYFormRaw>
        <RAYFormRaw title="Tags" labelCol={3}>
            <div>
                <RAYTagInput as="div" items={data.tags || []} callbackAddTag={valObj => setData(prev => ({ ...prev, tags: valObj.items }))} />
                <RAYTags as="div" css="mt-2" items={data.tags || []} callbackTagChange={(valObj) => setData(prev => ({ ...prev, tags: valObj.items }))} />
            </div>
        </RAYFormRaw>
        <RAYFormRaw title="App Auth" labelCol={3}>
            <RAYAppAuth value={data.authList || []} onChange={(arr) => setData(prev => ({ ...prev, authList: arr }))} />
        </RAYFormRaw>
        <RAYFormRaw title="Background Image" labelCol={3}>
            <div className="w-100">
                <RAYFile
                    item={header?.bgImage || null}
                    edit={true}
                    hideFileName={true}
                    isArrItem={false}
                    fieldName={'bgImage'}
                    keyPrefix={`rayrnd/applications/${app?.name}/template/${version}/header/bg_image/`}
                    // callbackOnChangeFile={callbackOnChangeFile}
                    callbackUploadHook={handlerSetUploadHooks}
                />
                <div style={{ maxHeight: 100 }} className="mb-2">
                    {header?.bgImage?.direct_link && <>
                        <img src={header.bgImage.direct_link} alt="file thumbnail" style={{ maxHeight: 100 }} />
                    </>}
                </div>
            </div>
        </RAYFormRaw>
        <RAYFormRaw title="App Icon" labelCol={3}>
            <div className="w-100">
                <RAYFile
                    item={header?.icon || null}
                    edit={true}
                    hideFileName={true}
                    isArrItem={false}
                    fieldName={'icon'}
                    keyPrefix={`rayrnd/applications/${app?.name}/template/${version}/header/icon/`}
                    // callbackOnChangeFile={callbackOnChangeFile}
                    callbackUploadHook={handlerSetUploadHooks}
                />
                <div style={{ maxHeight: 100 }} className="mb-2">
                    {header?.icon?.direct_link && <>
                        <img src={header.icon.direct_link} alt="icon thumbnail" style={{ maxHeight: 100 }} />
                    </>}
                </div>
            </div>
        </RAYFormRaw>
        <RAYFormRaw title="App Icon Color Code" labelCol={3}>
            <div className="w-100">
                <RAYTextField
                    type="color"
                    value={data?.iconColorCode || "#ffffff"}
                    placeholder="Insert Button Color"
                    onChange={e => setData(prev => ({ ...prev, iconColorCode: e.target.value }))}
                    style={{ height: 40 }}
                />
            </div>
        </RAYFormRaw>
        <RAYFormRaw title="App Banner" labelCol={3}>
            <div className="w-100">
                <RAYFile
                    item={header?.banner?.image || null}
                    edit={true}
                    hideFileName={true}
                    isArrItem={false}
                    fieldName={'image'}
                    keyPrefix={`rayrnd/applications/${app?.name}/template/${version}/header/banner/`}
                    callbackOnChangeFile={callbackOnChangeFile}
                    callbackUploadHook={handlerSetUploadHooks}
                />
                <div className="d-flex w-100 mt-1">
                    <RAYTextField
                        value={data?.banner?.link || ""}
                        placeholder="Banner Link"
                        onChange={e => setData(prev => {
                            const _tmp = { ...prev };
                            _tmp.banner.link = e.target.value;
                            return _tmp;
                        })}
                    />
                    <RAYButton
                        label="Remove Banner"
                        color="warning"
                        style={{ flexShrink: 0 }}
                        onClick={handlerClickRemoveBanner}
                    />
                </div>
                <div style={{ maxHeight: 100 }} className="my-4">
                    {imageDataUrl && <>
                        <img src={imageDataUrl} alt="file thumbnail" style={{ maxHeight: 100 }} />
                    </>}
                    {!imageDataUrl && data?.banner?.image && <>
                        <img src={data.banner.image.direct_link} alt="file thumbnail" style={{ maxHeight: 100 }} />
                    </>}
                </div>
            </div>
        </RAYFormRaw>
        <RAYFormRaw labelCol={3} css="mt-3">
            <RAYButton
                label="Save"
                color="primary"
                onClick={handlerClick}
            />
        </RAYFormRaw>
    </>
};

const mapState = (state) => {
    const apps = state.ApplicationReducer.apps;
    const auth = state.AuthReducer.user;
    const allTemplates = state.ApplicationReducer.templates;
    return { apps, auth, allTemplates };
};

const mapDispatch = (dispatch) => ({
    GetAppTemplates: ({ appName, type }) => dispatch(ApplicationAction.GetAppTemplates({ appName, type })),
    UpdateAppTemplate: ({ appName, item }) => dispatch(ApplicationAction.UpdateAppTemplate({ appName, item })),
});

export default connect(mapState, mapDispatch)(AppTemplateFormBasic);
