import { applicationAxios } from '../../libs/axios';
import { Create, Query, Update, Delete, Get } from '../../libs/dynamo';
import { Query as QueryProd, Update as UpdateProd, Delete as DeleteProd, Get as GetProd } from '../../libs/dynamoProd';
import { getAttributeUpdatesSet } from '../../libs/functions';
import { Store } from '../../store';
import { ApplicationConstant } from '../reducers/ApplicationReducer';

const TableName = "rayteams-cms";

const GetCMSAllVersionContents = async ({ appName }) => {
    const ret = await Query({
        TableName,
        IndexName: "app-sk-index",
        KeyConditions: {
            app: {
                ComparisonOperator: "EQ",
                AttributeValueList: [appName],
            }
        },
    });
    return ret.Items;
};

const GetCMSContents = async ({ appName, version = "common", lang = "en" }) => {
    const ret = await Query({
        TableName,
        IndexName: "app-sk-index",
        KeyConditions: {
            app: {
                ComparisonOperator: "EQ",
                AttributeValueList: [appName],
            },
            sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: [appName + ":" + version + ":" + lang],
            },
        },
    });
    return ret.Items;
};

const DeployCMSContent = async (item) => {
    const ret = await Update({
        TableName,
        Key : { pk : item.pk },
        AttributeUpdates : { deploy : true },
        ReturnValues: "ALL_NEW"
    });
    return ret?.Attributes;
};

const GetCMSContent = async ( pk ) => {
    const params = {
        TableName,
        KeyConditions: {
            pk: {
                ComparisonOperator: "EQ",
                AttributeValueList: [pk],
            }
        },
    };
    const ret = await Query(params);
    return ret.Items;
};

const RemoveCMSContent = async ( pk ) => {
    const now = new Date().getTime();
    const ret = await Update({
        TableName,
        Key : { pk : item.pk },
        AttributeUpdates : { removed : now },
        ReturnValues: "ALL_NEW"
    });
    return {};
};

const UpdateCMSContent = async (item) => {
    const now = new Date().getTime();
    const user = Store.getState().AuthReducer.user;
    item.updated = now;
    item.updater = user._id;
    const params = {
        TableName,
        ...getAttributeUpdatesSet(["pk", "sk"], item),
    };
    const ret = await Update(params);
    return ret.Attributes;
};

const CreateCMSContent = async (item) => {
    const now = new Date().getTime();
    const user = Store.getState().AuthReducer.user;
    item.created = now;
    item.creator = user._id;
    item.updated = now;
    item.updater = user._id;
    const params = {
        TableName,
        Item : item
    };
    const ret = await Create(params);
    return item;
};

const CopyCreateCMSContent = async ( pk, lang ) => {
    const params = {
        TableName,
        KeyConditions: {
            pk: {
                ComparisonOperator: "EQ",
                AttributeValueList: [pk],
            }
        },
    };
    const ori_ret = await Query(params);
    const ori = ori_ret.Items[0];

    const now = new Date().getTime();
    const user = Store.getState().AuthReducer.user;
    ori.pk = now.toString();
    ori.lang = lang;
    ori.updated = now;
    ori.updater = user._id;
    ori.created = now;
    ori.creator = user._id;
    ori.sk = ori.sk.split(":")[0] + ":" + ori.sk.split(":")[1] + ":" + lang;
    console.log({
        TableName,
        Item : ori
    });
    const ret = await Create({
        TableName,
        Item : ori
    });
    return ori;
};

export const ApplicationCMSAction = {
    GetCMSAllVersionContents,
    GetCMSContents,
    GetCMSContent,
    CreateCMSContent,
    RemoveCMSContent,
    UpdateCMSContent,
    DeployCMSContent,
    CopyCreateCMSContent
};
