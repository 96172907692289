import axios from "axios";
import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationAction, ApplicationVersionAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import { TYPE } from "../../constants/appTerms";
import { getEnv } from "../../libs/config";
import RAYSelectAdmin from "../../components/common/RAYSelectAdmin";
import RAYSelect from "../../components/common/RAYSelect";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYTextField from "../common/RAYTextField";
import { LANGUAGE } from "../../constants";
import { UploadS3 } from "../../libs/s3";

const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const AppSettingConfig = ({ item, UpdateApplication }) => {
    const storedLanguages = useSelector(state => state.ApplicationReducer.languages);
    const [viewType, setViewType] = useState(VIEW_TYPE.DEFAULT);
    const [data, setData] = useState({});
    const [err, setErr] = useState("");
    const [nofile, setNoFile] = useState(false);
    const [shownew, setShowNew] = useState(false);
    const [newitem, setNewItem] = useState({
        key : "",
        value : "",
    });
    const [lan, setLan] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        console.log(item);
    }, [item]);

    const getConfigPath = () => {
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/"+ item.name + "/applicationConfig.json";
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/"+ item.name + "/applicationConfig.json";
        }
    }

    const getConfig = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            setData(conf?.data || {});
        }catch{
            setData({});
            setNoFile(true);
        }
    }

    const addNew = async () => {
        if(!newitem.key || !newitem.value){
            setErr("Key/Value 모두 값을 입력해야합니다.");
            return;
        }
        const newUpData = {...data, [newitem.key] : newitem.value }
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/applications/"+ item.name + "/applicationConfig.json",
            newUpData
        );
        setNewItem({ key : "", value : "" })
        await getConfig();
    }

    const delKey = async (key) => {
        let newUpData = {}
        Object.keys(data).map(x => {
            if(x === key)
                return;
            newUpData[x] = data[x];
        });
        console.log( newUpData );
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/applications/"+ item.name + "/applicationConfig.json",
            newUpData
        );
        await getConfig();
    }

    return <>
        <div>
            <div className="d-flex justify-content-between">
                <h3>
                    Config <small>{item.title}</small>
                </h3>
                <div className="card-toolbar">
                    <RAYButton
                        label={shownew ? "Cancel" : "Add Config"}
                        color={shownew ? "light" : "primary"}
                        style={{ width: 200, maxWidth: 200 }}
                        onClick={() => setShowNew(!shownew)}
                    />
                </div>
            </div>
            {!shownew && <div className="card mb-2 border border-2">
                <div className="card-body">
                    <div>
                        Software에 Config를 저장/관리할 수 있습니다.<br/>
                        이 정보는 Dev/Prod에 각각 독립적으로 적용됩니다.
                    </div>
                    {nofile && <div>초기 상태의 경우,  Config 파일이 없을 수 있습니다.</div>}
                    {!nofile && <div>Download path : {getEnv() === "production" ? <>
                            <a href={"https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/" + item.name + "/applicationConfig.json"} target='_blank'>
                                https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/{item.name}/applicationConfig.json
                            </a>
                        </> : <>
                            <a href={"https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/" + item.name + "/applicationConfig.json"} target='_blank'>
                                https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/applications/{item.name}/applicationConfig.json
                            </a>
                        </>}
                    </div>}
                </div>
            </div>}
            {shownew && <div className="card">
                <div className="card-header">
                    <div className="card-title">Add Key/Value</div>
                </div>
                <div className="card-body">
                    <RAYFormRaw title="Key Name(alphanumeric)">
                        <RAYTextField
                            name="key"
                            value={newitem.key}
                            style={{ width : 300 }}
                            onChange={(event) => setNewItem(prev => ({ ...prev, key: event.target.value }))}
                        />
                    </RAYFormRaw>
                    <RAYFormRaw title="Value(string only)">
                        <RAYTextField
                            name="value"
                            value={newitem.value}
                            onChange={(event) => setNewItem(prev => ({ ...prev, value: event.target.value }))}
                        />
                    </RAYFormRaw>
                    {err && <p className="text-warning text-end">{err}</p>}
                    <div className="text-end">
                        <RAYButton
                            label="Save new config"
                            color="primary"
                            style={{ width: 200, maxWidth: 200 }}
                            onClick={() => addNew()}
                        />
                    </div>
                </div>
            </div>}
            {!nofile && <div className="mt-4">
                <h4>Configs</h4>
                <table className="table table-bordered mt-2">
                    <thead>
                        <tr><th>Key</th><th>Value</th><th style={{ width : "80px" }}></th></tr>
                    </thead>
                    <tbody>
                        {Object.keys(data).map(x => <tr>
                            <td>{x}</td>
                            <td style={{ "overflowWrap" : "anywhere" }}>{data[x]}</td>
                            <td className="text-end ">
                                <RAYButton
                                    label="Del"
                                    color="danger"
                                    style={{ width: 100 }}
                                    onClick={() => delKey(x)}
                                />
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
        </div>
    </>
}

export default AppSettingConfig;
