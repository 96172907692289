import { ReactComponent as IconApplication } from '../../assets/svgs/applications.svg';
import { ReactComponent as IconUser } from '../../assets/svgs/user.svg';
import { ReactComponent as IconSetting } from '../../assets/svgs/setting.svg';
import { ReactComponent as IconMenu } from '../../assets/svgs/menu.svg';
// import { ReactComponent as IconBI } from '../../assets/svgs/bi.svg';
import { ReactComponent as SvgLogo } from '../../assets/svgs/logo.svg';
import { ReactComponent as SvgLogoDark } from '../../assets/svgs/logo-dark.svg';
import { ReactComponent as IconArrow } from '../../assets/svgs/arrow.svg';
import { ReactComponent as IconMore } from '../../assets/svgs/more.svg';
import { ReactComponent as IconCircle } from '../../assets/svgs/circle.svg';
import { ReactComponent as IconAngleBracket } from '../../assets/svgs/angle-bracket.svg';
import { ReactComponent as IconClip } from '../../assets/svgs/clip.svg';

function setAttributes (props) {
    const result = { ...props };
    if (result?.size) {
        result.height = props.size;
        result.width = props.size;
    }
    return result;
}

export const RAYLogo = (props) => <SvgLogo {...setAttributes(props)} />
export const RAYLogoDark = (props) => <SvgLogoDark {...setAttributes(props)} />
export const RAYIconBI = (props) => <SvgLogo {...setAttributes(props)} />
export const RAYIconApps = (props) => <IconApplication {...setAttributes(props)} />
export const RAYIconUser = (props) => <IconUser {...setAttributes(props)} />
export const RAYIconSetting = (props) => <IconSetting {...setAttributes(props)} />
export const RAYIconMenu = (props) => <IconMenu {...setAttributes(props)} />
export const RAYIconArrow = (props) => <IconArrow {...setAttributes(props)} />
export const RAYIconMore = (props) => <IconMore {...setAttributes(props)} />
export const RAYIconCircle = (props) => <IconCircle {...setAttributes(props)} />
export const RAYIconAngleBracket = (props) => <IconAngleBracket {...setAttributes(props)} />
export const RAYIconClip = (props) => <IconClip {...setAttributes(props)} />








