import { getAWS, getAWSCredential } from '../config/aws';
import { getEnv } from './config';
import AWS from 'aws-sdk';

const RAYLOG_APPLICATION_DEV = "s3://raylog-development/activitylog";
const RAYLOG_APPLICATION = "s3://raylog-production/activitylog";

const getQueryResults = async (athena, QueryExecutionId, NextToken = "") => {
    const { QueryExecution } = await athena
        .getQueryExecution({ QueryExecutionId })
        .promise();
    const allRows = [];
    if (
        QueryExecution.Status.State !== "SUCCEEDED" &&
        QueryExecution.Status.State !== "FAILED" &&
        QueryExecution.Status.State !== "CANCELLED"
    ) {
        //console.log(`Not ready yet: ${JSON.stringify(QueryExecution, null, 2)}`);
        return getQueryResults(athena, QueryExecutionId, NextToken);
    }

    console.log(`(${QueryExecutionId}) Results ready, download started`);
    console.log("->");
    console.log(QueryExecution.Status);
    console.log(QueryExecutionId);
    const data = await athena.getQueryResults(NextToken ? { QueryExecutionId, NextToken } : { QueryExecutionId }).promise();
    console.log(data);
    console.log("<-");

    let columnInfo;
    const [header, ...tableRows] = data.ResultSet.Rows;
    allRows.push(...(allRows.length ? data.ResultSet.Rows : tableRows));
    if (!columnInfo && header) {
        columnInfo = data.ResultSet.ResultSetMetadata.ColumnInfo;
    }

    return { NextToken : data.NextToken, items : allRows.map((r) => columnInfo
        .map((c, i) => ({
            [c.Name]: r.Data[i]?.VarCharValue?.replace(".000000000", ""),
        }))
        .reduce((a, b) => ({ ...a, ...b }), {})
    )};
};


const Query = async (database, table, query) => {
    //const client = new AthenaClient({ region: "ap-northeast-2", credentials: getAWSCredential() });
	const AWS = await getAWS();
    const athena = new AWS.Athena({ region: "ap-northeast-2" });

    const tableName = table;
    const sqlString = query;

    const params = {
        QueryString : query,
        QueryExecutionContext: {
            Database: "default",
            Catalog: "AwsDataCatalog",
        },
        ResultConfiguration: {
            OutputLocation: getEnv() === "production" ? 
                "s3://raylog-production/querydata/" : 
                "s3://raylog-development/querydata/",
        },
    };
    var { QueryExecutionId } = await athena
        .startQueryExecution(params)
        .promise();

    try {
        let data = [];
        let tmp = {};
        while(true){
            tmp = await getQueryResults(athena, QueryExecutionId, tmp?.NextToken || "");
            data = [...data, ...tmp.items];
            console.log(tmp);
            if(!tmp.NextToken)
                break;
        }
        return data;
    } catch (error) {
        console.log(error);
    }
    return [];
};

export {
    Query,
}

