import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { mdConverter } from '../libs/converter';
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'

const E404 = ({ base, path, uri }) => {

	const dispath = useDispatch();
	const [t] = useTranslation(['translation']);
	
	useEffect(() => {
	},[])

  	return <div className="card">
        <div className="card-body text-center">
            <div className="misc-wrapper">
                <h2 className="mb-1 mt-4">Page Not Found :(</h2>
                <p className="mb-4 mx-2">Oops! 😖 The requested URL was not found on this server.</p>
                <a href="" onClick={(e) => { e.preventDefault();  history.push("/") }} className="btn btn-primary mb-4 waves-effect waves-light">Back to home</a>
                <div className="mt-4">
                    <img src="/assets/img/illustrations/page-misc-error.png" alt="page-misc-error" width="225" className="img-fluid" />
                </div>
            </div>
        </div>
    </div>
}

const mapState = (state) => {
	return { };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(E404);
