import { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { LANGUAGE } from "../../constants";
import { TYPE } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";
import { ApplicationConstant } from "../../redux/reducers";

const AppSettingTermsNavigatorLang = ({ app, auth, storedTerms, GetAppData, UpdateAppData  }) => {
    const [appTerms, setAppTerms] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [langs, setLangs] = useState([]);
    const [selectedLang, setSelectedLang] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        app?.name && dispatch({ type: ApplicationConstant.CLEAR_TERM, appName: app.name });
    }, [dispatch, app.name]);

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }
    }, [storedTerms, app?.name]);

    useEffect(() => {
        const _selectedVersion = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_VERSION);
        setSelectedVersion(_selectedVersion ? appTerms.find(x => x.type === _selectedVersion.data.type) : null);
        const _selectedLang = appTerms.find(x => x.type === TYPE.EXTRA_SELECT_LANG);
        setSelectedLang(_selectedLang ? appTerms.find(x => x.type === _selectedLang.data.type) : null);
    }, [appTerms]);

    useEffect(() => {
        if (selectedVersion) {
            const prefix = TYPE.TC_DOCS + selectedVersion.type.replace(TYPE.TC_VERSION, "");
            setLangs(appTerms.filter(x => x.type.startsWith(prefix)).sort((a, b) => a.version > b.version ? -1 : 1));
        } else {
            setLangs([]);
        }

    }, [appTerms, selectedVersion])

    const handlerCreateLanguage = async (_lang) => {
        if (!selectedVersion || !_lang) {
            return;
        }
        if (selectedLang?.lang === _lang.lang) {
            dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_LANG });
            return
        }
        const item = {
            type: TYPE.EXTRA_SELECT_LANG,
            rowType: _lang.type,
            data: _lang
        };
        dispatch({ type: ApplicationConstant.SET_TERM, appName: app.name, item });
        dispatch({ type: ApplicationConstant.UNSET_TERM, appName: app.name, rowType: TYPE.EXTRA_SELECT_VERSION_RELEASE });
    };

    return <>
        {selectedVersion && <>
            <h4 className="d-flex align-items-center justify-content-between mt-3" style={{ minHeight: 45 }}>
                <div>Languages</div>
            </h4>
            {langs?.length === 0 && <div className="text-muted text-center my-2">
                <i>None Languages</i>
            </div>}
            {langs?.length > 0 && <ListGroup>
                {langs.map(x => <ListGroup.Item
                    key={x.type}
                    action={true}
                    onClick={() => handlerCreateLanguage(x)}
                    active={selectedLang?.type === x.type}
                >
                    <div className="d-flex w-100 align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <div>
                                    <span>{LANGUAGE.find(l => l.code === x.lang)?.label || x.lang}</span>
                                    <span className="ms-2 text-muted">{x.lang}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex-fill" />
                        <div className={`badge bg-${x.isPublished ? 'primary' : (x.isReady ? 'success' : 'light')}`}>
                            {x.isPublished ? 'Publish' : (x.isReady ? 'Ready' : 'Draft')}
                        </div>
                    </div>
                </ListGroup.Item>)}
            </ListGroup>}
        </>}
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsNavigatorLang);
