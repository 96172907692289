import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

const Home = ({ }) => {

	const dispath = useDispatch();
	const [t] = useTranslation(['translation']);
	
	useEffect(() => {
	},[])
	
  	return <>
        <div className="flex-grow-1 container-p-y" 
            style={{ 
                marginTop : -24,
                marginLeft : -24,
                marginRight : -24,
                background : "url('/assets/img/pages/header.png')"
            }} >
        <div className="d-flex justify-content-center w-100">
            <div style={{ width : 800 }} className="p-4">
                <h2 className="text-center">Hello! Developers</h2>
                <div className="text-center px-3 mb-0">
                    이곳은 RAYTeams Platform Service를 이용하여 다양한 앱을 개발하는 개발자들을 위한 사이트입니다.<br />
                    RAYTeams App을 개발하기 위한 개발 문서들은 계속 업데이트 될 예정입니다.
                </div>
                <div className="input-wrapper mb-3 input-group input-group-lg input-group-merge pt-4 text-center">
                    <span className="input-group-text" id="basic-addon1"><i className="ti ti-search"></i></span>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="아직 미지원 중...." 
                        aria-label="Search" aria-describedby="basic-addon1" />
                </div>
                <p className="text-center px-3 mb-0">RAYTeams 관련 다양한 개발 문서들을 찾아볼 수 있습니다.</p>
                <div className="text-center mt-4">
                    <button 
                        className="btn btn-lg btn-white" 
                        onClick={() => window.open("https://github.com/rayteams/docs", "_blank") }>
                        <i className="ti ti-brand-github me-2" /> Github
                    </button>
                    <button 
                        className="btn btn-lg btn-white ms-2" 
                        onClick={() => window.open("https://www.rayteams.com", "_blank") }>
                        <img src="/logo.png" style={{ width : 20, marginRight : 8 }} /> RAYTeams
                    </button>
                </div>
            </div>
        </div>
    </div>
    {/* <div className="text-center" style={{ paddingTop : 64, paddingBottom : 64 }}>
        <p className="text-center p-3 mb-0">RAYTeams Developer Center에 이미 가입되어 있다면 로그인하여 App을 관리할 수 있습니다.</p>
        <Link to={"/login"} className="btn btn-lg btn-dark mb-4">Sign-in RAYTeams Developer Center</Link>
    </div> */}
    <div className="help-center-popular-articles bg-white py-5">
        <div className="container-xl">
            <h3 className="text-center my-4">Popular Articles</h3>
            <div className="row">
                <div className="col-lg-10 mx-auto mb-4">
                    <div className="row">
                        <div className="col-md-4 mb-md-0 mb-4">
                            <div className="card border shadow-none">
                                <div className="card-body text-center">
                                    <span className="ti ti-notebook" style={{ fontSize : 48 }} ></span>
                                    <h5 className="my-2"> Getting Started </h5>
                                    <p>Whether you're new or you're a power user, this article will…</p>
                                    <Link className="btn btn-sm btn-label-primary waves-effect" to={"/doc/manual"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-md-0 mb-4">
                            <div className="card border shadow-none">
                                <div className="card-body text-center">
                                    <span className="ti ti-api" style={{ fontSize : 48 }} ></span>
                                    <h5 className="my-2">Client APIs</h5>
                                    <p>Are you a new customer wondering how to get started?</p>
                                    <Link className="btn btn-sm btn-label-primary waves-effect" to={"/doc/local-api"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card border shadow-none">
                                <div className="card-body text-center">
                                    <span className="ti ti-api" style={{ fontSize : 48 }} ></span>
                                    <h5 className="my-2">Cloud APIs</h5>
                                    <p>This article will show you how to expand the functionality of...</p>
                                    <Link className="btn btn-sm btn-label-primary waves-effect" to={"/doc/cloud-api"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
}

const mapState = (state) => {
	return { };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Home);
