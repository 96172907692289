import { connect } from 'react-redux';
import ApplicationView from '../../components/application/ApplicationView';
import { ApplicationVersionAction } from '../../redux/actions';


const ApplicationCreate = ({ item }) => {

    return <>
        <ApplicationView create={true} />
    </>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const apps = state.ApplicationReducer.apps;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    return { isLogin, apps, appVersions };
};

const mapDispatch = (dispatch) => ({
    GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName))
})

export default connect(mapState, mapDispatch)(ApplicationCreate);
