import React, { useCallback, useEffect } from "react";
import RAYToggle from "../common/RAYToggle";
import RAYTextField from "../common/RAYTextField";
import { Badge } from "react-bootstrap";
import RAYButton from "../common/RAYButton";
import { v4 } from "uuid";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYFile from "../common/RAYFile";
import { RAYToolTip } from "../common/RAYToolTip";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";

const CardRadio = ({ appName, item, edit, setData }) => {

  const changeControlItem = useCallback((newItem) => {
    console.log("changeControlItem");
    setData(prev => {
      const _tmp = { ...prev };
      const targetIndex = _tmp.serviceProjectForm.controls.findIndex(x => x.uKey === newItem.uKey);
      if (targetIndex > -1) {
        _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map((x, idx) => idx === targetIndex ? { ...newItem } : x);
      }
      return _tmp;
    });
  }, []);

  const addEmptyListItem = () => {
    const _item = {
      uKey: v4(),
      order: item?.options?.list?.length || 0,
      visible: false,
      icon: null,
      Title: "",
      tooltip: "",
      value: "",
    }
    changeControlItem({
      ...item,
      options: {
        ...item?.options,
        list: [
          ...(item?.options?.list || []),
          _item,
        ],
      }
    });
  };

  const removeListItem = (targetItem) => {
    changeControlItem({
      ...item,
      options: {
        ...item?.options,
        list: (item?.options?.list || []).filter(x => x.uKey !== targetItem.uKey),
      }
    });
  };

  const handlerSetUploadHooks = useCallback(async (uploadHook) => {
    if (!item?.uKey) {
      return;
    }
    try {
      const listFieldUKey = uploadHook.name;
      const uploadRet = await uploadHook.upload();
      const fileData = uploadRet.data;
      if (!fileData) {
        return;
      }
      console.log("fileData", fileData);
      setData(prev => {
        const _tmp = { ...prev };
        const targetIndex = _tmp?.serviceProjectForm?.controls?.findIndex(x => x.uKey === item.uKey);
        if (targetIndex > -1) {
          const listItems = _tmp?.serviceProjectForm?.controls?.find(x => x.uKey === item.uKey)?.options?.list?.map(x => {
            if (x.uKey === listFieldUKey) {
              return { ...x, icon: fileData, };
            }
            return x;
          }) || [];
          _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map((x, idx) => idx === targetIndex ? {
            ...x,
            options: {
              ...x.options,
              list: listItems,
            }
          } : x);
        }
        return _tmp;
      });
    } catch (error) {
      console.log("ERROR[handlerSetUploadHooks]", error.toString());
    }
  }, [item?.uKey]);

  const changeListItemVal = useCallback((key, name, val) => {
    if (!item?.uKey) {
      return;
    }
    try {
      setData(prev => {
        const _tmp = { ...prev };
        const targetIndex = _tmp?.serviceProjectForm?.controls?.findIndex(x => x.uKey === item.uKey);
        if (targetIndex > -1) {
          const listItems = _tmp?.serviceProjectForm?.controls?.find(x => x.uKey === item.uKey)?.options?.list?.map(x => {
            if (x.uKey === key) {
              const _tmpX = { ...x };
              _tmpX[name] = val;
              return _tmpX;
            }
            return x;
          }) || [];
          _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map((x, idx) => idx === targetIndex ? {
            ...x,
            options: {
              ...x.options,
              list: [...listItems],
            }
          } : x);
        }
        return _tmp;
      });
    } catch (error) {
      console.log("ERROR[changeListItemVal]", error.toString());
    }
  }, [item?.uKey]);

  const listItemOrder = useCallback((key, isUp) => {
    if (!item?.uKey) {
      return;
    }
    try {
      setData(prev => {
        const _tmp = { ...prev };
        const targetIndex = _tmp?.serviceProjectForm?.controls?.findIndex(x => x.uKey === item.uKey);
        if (targetIndex > -1) {
          let oldOrder = 0;
          let newOrder = 0;
          let replaceIdx = 0;
          let listItems = _tmp?.serviceProjectForm?.controls?.find(x => x.uKey === item.uKey)?.options?.list?.map((x, idx) => {
            if (x.uKey === key) {
              const _tmpX = { ...x };
              oldOrder = _tmpX["order"]
              newOrder = isUp ? _tmpX["order"] - 1 : _tmpX["order"] + 1;
              _tmpX["order"] = newOrder;
              replaceIdx = isUp ? idx - 1 : idx + 1;
              return _tmpX;
            }
            return x;
          }) || [];
          listItems[replaceIdx].order = oldOrder;
          _tmp.serviceProjectForm.controls = _tmp.serviceProjectForm.controls.map((x, idx) => idx === targetIndex ? {
            ...x,
            options: {
              ...x.options,
              list: [...listItems],
            }
          } : x);
        }
        return _tmp;
      });
    } catch (error) {
      console.log("ERROR[changeListItemVal]", error.toString());
    }
  }, [item?.uKey]);

  return <>
    {item?.uKey && <>
      <div className="d-flex flex-column gap-2">
        <h5>Card Radio</h5>
        <div className="d-flex align-items-center gap-2">
          <i className="w-100px">Required</i>
          <RAYToggle
            value={item?.options?.required || false}
            onChange={() => changeControlItem({ ...item, options: { ...item?.options, required: !item?.options?.required } })}
            disabled={!edit}
          />
        </div>
        <div className="d-flex align-items-center gap-2">
          <i className="w-100px">Label</i>
          <RAYTextField
            value={item.options.label || ""}
            readOnly={!edit}
            onChange={(e) => changeControlItem({ ...item, options: { ...item?.options, label: e.target.value } })}
          />
        </div>
        <div className="d-flex align-items-center gap-2">
          <i className="w-100px">Field Name</i>
          <RAYTextField
            value={item.options.name || ""}
            readOnly={!edit}
            onChange={(e) => changeControlItem({ ...item, options: { ...item?.options, name: e.target.value } })}
          />
        </div>
        <div>
          <div className="d-flex align-items-center gap-2">
            <i className="w-100px">Card Radio Items</i>
            {edit && <>
              <div className="flex-fill" />
              <RAYButton
                label={"Add Radio Item"}
                onClick={addEmptyListItem}
              />
            </>}
          </div>
          <div className="d-flex align-items-center flex-wrap mt-2 row g-2">
            {item?.options?.list?.sort((a, b) => a?.order < b?.order ? -1 : 1)?.map((_item, _idx) => <React.Fragment key={_item.uKey}>
              <div className={`d-flex flex-column gap-2 col-${edit ? 12 : 4}`}>
                <RAYToolTip
                  tooltip={_item?.tooltip}
                  show={edit}
                >
                  <div
                    className="d-flex align-items-center flex-column gap-2 p-2 position-relative"
                    style={{ border: "1px dotted #ddd", color: "white", backgroundColor: "#53598B" }}
                  >
                    <div>
                      {_item?.icon?.direct_link && <>
                        <img src={_item?.icon?.direct_link} alt="Card Icon" style={{ maxWidth: 48 }} />
                      </>}
                      {!_item?.icon?.direct_link && <>
                        <i>empty icon</i>
                      </>}
                    </div>
                    <div className="fw-bolder">
                      {_item?.title || <i>empty title</i>}
                    </div>
                    {edit && <>
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          right: 6
                        }}
                      >
                        Value: {_item.value || <i>empty value</i>}
                      </div>
                    </>}
                  </div>
                </RAYToolTip>
                {!edit && <i>
                  Value: {_item.value || <i>empty value</i>}
                </i>}
                {edit && <div className="d-flex flex-column">
                  <div className="text-end">
                    <Badge bg="danger" className="cursor-pointer" onClick={() => removeListItem(_item)}>Remove</Badge>
                  </div>
                  <RAYFormRaw title={"Value"}>
                    <RAYTextField
                      value={_item.value || ""}
                      readOnly={!edit}
                      onChange={(e) => changeListItemVal(_item.uKey, "value", e.target.value)}
                    />
                  </RAYFormRaw>
                  <RAYFormRaw title={"Icon"}>
                    <RAYFile
                      item={_item?.icon || null}
                      accept="image/*"
                      edit={edit}
                      fieldName={_item.uKey}
                      keyPrefix={`rayrnd/applications/${appName}/icons/rayteams/`}
                      callbackUploadHook={handlerSetUploadHooks}
                    />
                  </RAYFormRaw>
                  <RAYFormRaw title={"Visible"}>
                    <RAYToggle
                      value={!!_item?.visible}
                      onChange={() => changeListItemVal(_item.uKey, "visible", !_item?.visible)}
                      disabled={!edit}
                    />
                  </RAYFormRaw>
                  <RAYFormRaw title={"Title"}>
                    <RAYTextField
                      value={_item.title || ""}
                      readOnly={!edit}
                      onChange={(e) => changeListItemVal(_item.uKey, "title", e.target.value)}
                    />
                  </RAYFormRaw>
                  <RAYFormRaw title={"Tooltip"}>
                    <RAYTextField
                      value={_item.tooltip || ""}
                      readOnly={!edit}
                      onChange={(e) => changeListItemVal(_item.uKey, "tooltip", e.target.value)}
                    />
                  </RAYFormRaw>
                  <RAYFormRaw title={"Order"}>
                    <div className="d-flex align-items-center gap-2">
                      <RAYButton
                        label={<BiUpArrowAlt />}
                        disabled={_idx === 0}
                        onClick={() => listItemOrder(_item.uKey, true)}
                      />
                      <RAYButton
                        label={<BiDownArrowAlt />}
                        disabled={_idx === item?.options?.list?.length - 1}
                        onClick={() => listItemOrder(_item.uKey, false)}
                      />
                    </div>
                  </RAYFormRaw>
                </div>}
              </div>
            </React.Fragment>)}
          </div>
        </div>
      </div>
    </>}
  </>
};

export default CardRadio;