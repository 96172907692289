import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { APP_TERM_TYPES } from "../../constants/appTerms";
import { ApplicationAction } from "../../redux/actions";

const TCRule = ({ css = "", style }) => <div className={css} style={style ? style : {}}>
    <div>
        The rules regarding the preparation and management of the terms and conditions are as follows.
    </div>
    <ul>
        <li>It will not be exposed to the end user until the terms and conditions are posted.</li>
        <li>You can publish the terms and conditions after the version is ready.</li>
        <li>You can change the version to Ready state only if you have prepared language specific terms and conditions.</li>
        <li>After the terms and conditions or versions are published, you cannot modify or delete the terms and conditions by language.</li>
        <li>When publishing a version, if the terms and conditions are already in the publishing state, the version is also changed to the publishing state.</li>
    </ul>
</div>

const AppSettingTermsRootDashboard = ({ app, storedTerms, GetAppData, UpdateAppData, RemoveAppData }) => {
    const [appTerms, setAppTerms] = useState([]);
    const [typeForData, setTypeForData] = useState(APP_TERM_TYPES.map(x => ({ ...x, published: [], unpublished: [], hasTerms: 0 })));

    useEffect(() => {
        if (storedTerms && app?.name) {
            setAppTerms(storedTerms[app.name]?.items || []);
        }

    }, [storedTerms, app?.name]);

    useEffect(() => {
        const _pubs = appTerms.filter(x => x.isPublished);
        const _unPubs = appTerms.filter(x => !x.isPublished);
        const _data = APP_TERM_TYPES.map(x => {
            const item = { ...x };
            const code = item.code;
            item.published = _pubs.filter(y => y.code === code);
            item.unpublished = _unPubs.filter(y => y.code === code);
            item.hasTerms = item.published.length + item.unpublished.length > 0;
            return item;
        });
        setTypeForData(_data);
    }, [appTerms]);


    return <>
        <div>
            {appTerms.length === 0 && <>
                <div className="fs-2 text-black text-center mb-3">
                    Terms and conditions do not exist.
                </div>
                <div className="text-black fs-4">
                    You can add terms and conditions if necessary.
                </div>
                <TCRule css="text-muted my-4" />
            </>}
            {appTerms.length > 0 && <>
                <h3 className="mb-6">Term ans Conditions</h3>
                {typeForData.map(x => <React.Fragment key={x.code}>
                    <div className="mt-2">
                        <h4>{x.label}</h4>
                        <ul>
                            <li>
                                Published {x.published.length}
                                {x.published.length > 0 && <ul>
                                    {x.published.map(t => <li key={t.type}>
                                        {t.alias} - {t.currentVersion}
                                    </li>)}
                                </ul>}
                            </li>
                            <li>
                                Unpublished {x.unpublished.length}
                                {x.unpublished.length > 0 && <ul>
                                    {x.unpublished.map(t => <li key={t.type}>
                                        {t.alias}
                                    </li>)}
                                </ul>}
                            </li>
                        </ul>
                    </div>
                </React.Fragment>)}
                <TCRule css="text-muted my-4" />
            </>}
        </div>
    </>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const storedTerms = state.ApplicationReducer.terms;
    return { auth, storedTerms };
};

const mapDispatch = (dispatch) => ({
    GetAppData: (payload) => dispatch(ApplicationAction.GetAppData(payload)),
    UpdateAppData: (payload) => dispatch(ApplicationAction.UpdateAppData(payload)),
    RemoveAppData: (payload) => dispatch(ApplicationAction.RemoveAppData(payload)),
})

export default connect(mapState, mapDispatch)(AppSettingTermsRootDashboard);
